import Button from 'components/Buttons/Button/Button';
import ButtonText from 'components/Buttons/ButtonText/ButtonText';
import colors from 'constants/colors';
import IconDirection from 'enums/IconDirection';
import Routes from 'enums/Routes';
import ChevronBold from 'icons/ChevronBold';

export type BackToStudiesProps = {
  label: string;
  route: Routes;
  onUploadsClick: (route: Routes) => void;
};
const { LEFT } = IconDirection;
const { NEUTRAL_BACKGROUND_ON_VARIANT } = colors;

const BackToStudies = ({ onUploadsClick, label, route }: BackToStudiesProps): JSX.Element => {
  return (
    <Button onClick={() => onUploadsClick(route)}>
      <ChevronBold direction={LEFT} fill={NEUTRAL_BACKGROUND_ON_VARIANT} />
      <ButtonText>{label}</ButtonText>
    </Button>
  );
};

export default BackToStudies;
