import { STUDY_SORTBY } from 'constants/studySortBy';
import Limits from 'enums/Limits';
import { CookieOptionsType } from 'helpers/cookies/userSettingsHelper';
import { ColumnIds, ColumnSettingsConfig } from './WorklistTypes';

const { STUDY_UPDATED_DATE, PRIORITY, STUDY_DATE } = STUDY_SORTBY;
const { RESULTS_10, RESULTS_15, RESULTS_30, RESULTS_50 } = Limits;

export const ALL = 'All';
export const ALL_COL = 'toggleAll';

export const PROCESSING = 'Processing...';
export const PLACEHOLDER = '-';
export const ERROR = 'Error';

export const NO_RESULTS = 'No results found.';
export const RESET_SEARCH = 'Reset search';

// Text shown in name of column, in order of view
export const DATE_ANALYSED = 'Date Analysed';
export const DATE_OF_STUDY = 'Date of Study';
export const PATIENT_ID = 'Patient ID';
export const PATIENT_NAME = 'Name';
export const PATIENT_AGE_SEX = 'Age/Sex';
export const PATIENT_DOB = 'DoB';
export const ACC = 'ACC';
export const SCAN = 'Scan';
export const INSTITUTION = 'Hospital';
export const DEPARTMENT = 'Dept.';
export const AI_FINDINGS = 'AI Findings';

// Column ids, in order of view
export const DATE_ANALYSED_COL = 'updatedDate';
export const DATE_OF_STUDY_COL = 'studyDate';
export const PATIENT_ID_COL = 'patientId';
export const PATIENT_NAME_COL = 'patientName';
export const PATIENT_AGE_SEX_COL = 'patientAgeSex';
export const PATIENT_DOB_COL = 'patientDob';
export const ACC_COL = 'accessionNumber';
export const SCAN_COL = 'studyType';
export const INSTITUTION_COL = 'institutionName';
export const DEPARTMENT_COL = 'requestingService';
export const AI_FINDINGS_COL = 'priority';

// Tooltips
export const DATE_ANALYSED_DEFAULT_TOOLTIP = 'Change to sort by newest AI results first.';
export const DATE_ANALYSED_SORTED_TOOLTIP = 'Change to sort by oldest AI results first.';

export const DATE_OF_STUDY_DEFAULT_TOOLTIP = 'Change to sort by oldest study first.';
export const DATE_OF_STUDY_SORTED_TOOLTIP = 'Change to sort by newest study first.';

export const AI_FINDINGS_DEFAULT_TOOLTIP = 'Change to sort by most critical first.';
export const AI_FINDINGS_SORTED_TOOLTIP = 'Change to sort by least critical first.';

export const ACCESSION_NUMBER_TOOLTIP = 'Accession number';
export const DEPARTMENT_TOOLTIP = 'Department (requesting service)';

// Maps the column heading to boolean for sortable columns
export const SORTABLE_COLS: { [key: string]: boolean } = {
  [DATE_OF_STUDY]: true,
  [DATE_ANALYSED]: true,
  [AI_FINDINGS]: true,
};

// Maps the column IDs to associated query param
export const ID_TO_QUERY_PARAM: { [key: string]: string } = {
  [DATE_OF_STUDY_COL]: STUDY_DATE,
  [DATE_ANALYSED_COL]: STUDY_UPDATED_DATE,
  [AI_FINDINGS_COL]: PRIORITY,
};

// Options relate to number of results that will be seen per page (the query limit)
export const RESULTS_PER_PAGE_OPTIONS = [RESULTS_10, RESULTS_15, RESULTS_30, RESULTS_50];

// Date column width based on 88/88/8888, string columns width and truncation based on maximum 'W' length (OR column header if longer)
export const ROW_WIDTH_CONFIG = {
  DATE_ANALYSED_CONFIG: { width: '6.25rem' },
  DATE_OF_STUDY_CONFIG: { width: '6rem' },
  PATIENT_ID_CONFIG: { width: '3.75rem', truncationLength: 4 }, // Keep to 4 to intentionally obfuscate
  PATIENT_NAME_CONFIG: { width: '9rem', truncationLength: 20 },
  PATIENT_AGE_CONFIG: { width: '2rem' },
  PATIENT_DOB_CONFIG: { width: '4.5rem' },
  ACC_CONFIG: { width: '2.75rem', truncationLength: 5 },
  SCAN_CONFIG: { width: '3rem' },
  INSTITUTION_CONFIG: { width: '3rem', truncationLength: 4 },
  DEPARTMENT_CONFIG: { width: '3rem', truncationLength: 7 },
};

export const RESULTS_DISCLAIMER = 'Not a medical device. Not for clinical or research use.';

export const cookieExpiryOption: CookieOptionsType = { expires: 365 };
/**
 * Configuration for each column's viewability in the results list and column selector.
 * Note: temporary HK implementation, pending organisation configuration/admin portal
 * @type {Record<ColumnIds, ColumnSettingsConfig>}
 * @property {boolean} isPresent - Indicates if the column is present as an option.
 * @property {boolean} isVisible - Indicates if the column is visible by default (toggled on).
 * @property {boolean} isMandatory - Indicates if the column is mandatory; if true, it cannot be toggled off.
 */

// default config (not used for HK)
export const resultsListConfig: Record<ColumnIds, ColumnSettingsConfig> = {
  [ACC_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: false,
  },
  [AI_FINDINGS_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: true,
  },
  [DATE_ANALYSED_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: true,
  },
  [DATE_OF_STUDY_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: false,
  },
  [DEPARTMENT_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: false,
  },
  [INSTITUTION_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: false,
  },
  [PATIENT_AGE_SEX_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: false,
  },
  [PATIENT_DOB_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: false,
  },
  [PATIENT_ID_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: true,
  },
  [PATIENT_NAME_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: false,
  },
  [SCAN_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: false,
  },
};

// HK config
export const resultsListConfigHK: Record<ColumnIds, ColumnSettingsConfig> = {
  [ACC_COL]: {
    isPresent: true,
    isVisible: false,
    isMandatory: false,
  },
  [AI_FINDINGS_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: true,
  },
  [DATE_ANALYSED_COL]: {
    isPresent: true,
    isVisible: false,
    isMandatory: false,
  },
  [DATE_OF_STUDY_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: true,
  },
  [DEPARTMENT_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: false,
  },
  [INSTITUTION_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: false,
  },
  [PATIENT_AGE_SEX_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: false,
  },
  [PATIENT_DOB_COL]: {
    isPresent: true,
    isVisible: false,
    isMandatory: false,
  },
  [PATIENT_ID_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: true,
  },
  [PATIENT_NAME_COL]: {
    isPresent: true,
    isVisible: true,
    isMandatory: false,
  },
  [SCAN_COL]: {
    isPresent: true,
    isVisible: false,
    isMandatory: false,
  },
};
