import styled, { DataAttributes } from 'styled-components';
import Button from 'components/Buttons/Button/Button';
import colors from 'constants/colors';
import Z_INDEXES from 'constants/zIndexes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { ContainerProps } from './LogoNav.types';

const { LOGO_CONTAINER, LOGO_BREADCRUMBS, LOGO_WRAPPER, NAV_CONTAINER } = ReactTestingLibraryDataProperties;
const { MENU } = Z_INDEXES;
const { SECONDARY_CONTAINER } = colors;

export const Container = styled.div.attrs<DataAttributes>({
  'data-testid': LOGO_CONTAINER,
})<ContainerProps>`
  height: ${({ size }) => `${size}rem`};
  display: flex;
`;

export const NavContainer = styled.div.attrs<DataAttributes>({
  'data-testid': NAV_CONTAINER,
})`
  z-index: ${MENU};
  margin-left: 0.5rem;
  padding-top: 0.25rem;
  overflow: visible;
  width: 2rem;
`;

export const BreadcrumbButton = styled(Button).attrs<DataAttributes>({
  'data-testid': LOGO_BREADCRUMBS,
})`
  &:hover {
    background-color: ${SECONDARY_CONTAINER};
  }
  &:focus {
    background-color: ${SECONDARY_CONTAINER};
  }
  &:active {
    background-color: ${SECONDARY_CONTAINER};
  }
`;

export const LogoWrapper = styled.div.attrs<DataAttributes>({ 'data-testid': LOGO_WRAPPER })`
  cursor: pointer;
`;
