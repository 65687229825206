import { push } from 'connected-react-router';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import errorsMapping from 'constants/errorsMapping';
import CustomErrors from 'enums/CustomErrors';
import Routes from 'enums/Routes';
import { getImageWithBytesArray as getImageWithBytesArrayDicom } from 'helpers/cornerstone/dicomHelper';
import getCxrImagesWithBytesFromImageFiles from 'helpers/cxrImage/getCxrImagesWithBytesFromImageFiles';
import isDicomFile from 'helpers/file/isDicomFile';
import isEmptyFile from 'helpers/file/isEmptyFile';
import isImageFile from 'helpers/file/isImageFile';
import { ActionTypes } from 'slices/ActionTypes';
import { errorActions, SetErrorType } from 'slices/errorSlice';
import { SetDicomFilesType, SetJpegFilesType, SetFilesType, fileActions } from 'slices/fileSlice';
import { studyActions, ResetStudyType } from 'slices/studySlice';
import { uploadActions, UploadImagesType } from 'slices/uploadSlice';
import HistoryAction from 'types/HistoryAction';

const { PROGRESS } = Routes;
const { NO_IMAGES_SELECTED } = CustomErrors;

const { runSetFilesEffect, runSetDicomFilesEffect, runSetJpegFilesEffect } = fileActions;

export const setFilesEpic: Epic<
  ActionTypes,
  SetDicomFilesType | SetJpegFilesType | ResetStudyType | SetErrorType | HistoryAction
> = action$ =>
  action$.pipe(
    ofType<ActionTypes, SetFilesType>(runSetFilesEffect.type),
    switchMap(({ payload }) => {
      const nonEmptyFiles = payload.filter(file => !isEmptyFile(file));
      const file = nonEmptyFiles[0];

      if (file) {
        if (isDicomFile(file)) {
          return of(studyActions.runResetStudyEffect(), fileActions.runSetDicomFilesEffect(payload), push(PROGRESS));
        }
        if (isImageFile(file)) {
          return of(studyActions.runResetStudyEffect(), fileActions.runSetJpegFilesEffect(payload), push(PROGRESS));
        }
      }

      return of(errorActions.setError(errorsMapping[NO_IMAGES_SELECTED]));
    }),
  );

export const setDicomFilesEpic: Epic<ActionTypes, UploadImagesType> = action$ =>
  action$.pipe(
    ofType<ActionTypes, SetDicomFilesType>(runSetDicomFilesEffect.type),
    switchMap(async ({ payload }) => {
      const images = await getImageWithBytesArrayDicom(payload);
      return uploadActions.runUploadImagesEffect(images);
    }),
  );

export const setJpegFilesEpic: Epic<ActionTypes, UploadImagesType> = action$ =>
  action$.pipe(
    ofType<ActionTypes, SetJpegFilesType>(runSetJpegFilesEffect.type),
    switchMap(async ({ payload }) => {
      const images = await getCxrImagesWithBytesFromImageFiles(payload);

      return uploadActions.runUploadImagesEffect(images);
    }),
  );

export const fileEpic = combineEpics(setFilesEpic, setDicomFilesEpic, setJpegFilesEpic);
