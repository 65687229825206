import colors from 'constants/colors';

type IndicatorColorType = {
  color: string;
  ariaLabel: string;
};

const determineIndicatorColor = (options: {
  subServiceCount?: number;
  runningSubServiceCount?: number;
  status?: string;
}): IndicatorColorType => {
  const { status, runningSubServiceCount, subServiceCount } = options;
  const { RED, GREEN, YELLOW } = colors;
  if (status === 'running' || runningSubServiceCount === subServiceCount)
    return { color: GREEN, ariaLabel: 'Service is running' };
  if (status === 'waiting' || (runningSubServiceCount && runningSubServiceCount > 0))
    return { color: YELLOW, ariaLabel: 'Service has some issues' };
  return { color: RED, ariaLabel: 'Service is down' };
};

export default determineIndicatorColor;
