import { RootState } from 'slices/RootState';
import { SettingsState } from 'slices/settingsSlice';

export const selectRequestingServices = ({ settings }: RootState): SettingsState['requestingServices'] =>
  settings?.requestingServices;

export const selectInstitutionNames = ({ settings }: RootState): SettingsState['institutionNames'] =>
  settings?.institutionNames;

export const selectDocsManifest = ({ settings }: RootState): SettingsState['docsManifest'] => settings?.docsManifest;

export const selectDisplayData = ({ settings }: RootState): SettingsState['displayData'] => settings?.displayData;
