enum FetchStates {
  INITIAL,
  SENDING,
  FULFILLED,
  FAILED,
}

export const isFetchFulfilled = (fetchState: FetchStates): boolean => fetchState === FetchStates.FULFILLED;
export const isFetchSending = (fetchState: FetchStates): boolean => fetchState === FetchStates.SENDING;

export default FetchStates;
