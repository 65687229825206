import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { DataAttributes, css } from 'styled-components';
import { LoadingIndicator } from 'components';
import imageToolCursorMapping from 'constants/imageToolCursorMapping';
import { CXR_IMAGE_TOOLS } from 'constants/imageTools';
import { APP } from 'constants/sizes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import {
  checkIsElementEnabled,
  getDefaultViewport,
  getEnabledElement,
  resetViewport,
} from 'helpers/cornerstone/cornerstoneHelper';
import { activateTool, disableTools } from 'helpers/cornerstone/cornerstoneToolsHelper';
import renderSegment from 'helpers/cornerstone/renderSegment';
import {
  getBaseSynchronizer,
  getResizeSynchronizer,
  getSegmentSynchronizer,
} from 'helpers/cornerstone/synchronizerHelper';
import useDisplayImage from 'hooks/useDisplayImage';
import selectCxrViewer from 'selectors/viewer/cxr/selectCxrViewer';
import { cxrViewerActions, selectActiveControl } from 'slices/cxrViewerSlice';
import { selectIsLocalisationToggleOn } from 'slices/viewerSlice';
import CornerstoneRenderEvent from 'types/cornerstone/CornerstoneRenderEvent';
import ImageMetadata from 'types/ImageMetadata';
import { CxrSegment } from 'types/study/CxrSegment';
import CxrImageControls from 'types/viewer/cxrViewer/CxrImageControls';
import LateralSegment from './LateralSegment';

const { setImageViewportInfo, setActiveControl } = cxrViewerActions;

const { IMAGE, SEGMENT, LOCALISATION_CONTAINER } = ReactTestingLibraryDataProperties;

const { VIEWER_HORIZONTAL_PADDING } = APP;

type ImageProps = {
  sourceUrl: string;
  sourceMetadata: ImageMetadata;
  segment?: CxrSegment;
};

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-left: ${VIEWER_HORIZONTAL_PADDING}rem;
`;

const BaseContainer = styled.div.attrs<DataAttributes>({ 'data-testid': IMAGE })<{
  activeControl: CxrImageControls | null;
}>`
  height: 100%;

  ${({ activeControl }) => {
    if (!activeControl || activeControl === 'reset') {
      return css`
        cursor: auto;
      `;
    }

    const cursorImage = imageToolCursorMapping[activeControl];
    return cursorImage
      ? css`
          cursor: url('${cursorImage}'), auto;
        `
      : css``;
  }};
`;

const SegmentContainer = styled.div.attrs<DataAttributes>({ 'data-testid': SEGMENT })`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  pointer-events: none;
`;

const LocalisationContainer = styled.div.attrs<DataAttributes>({ 'data-testid': LOCALISATION_CONTAINER })<{
  isVisible: boolean;
}>`
  ${({ isVisible }) =>
    isVisible
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}
`;

const CxrImage: FC<ImageProps> = ({ sourceUrl, sourceMetadata, segment }) => {
  const { url: segmentUrl, laterality } = segment || {};

  const dispatch = useDispatch();

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const activeControl = useSelector(selectActiveControl);
  const { activeFinding } = useSelector(selectCxrViewer) || {};
  const isLocalisationToggleOn = useSelector(selectIsLocalisationToggleOn);

  const { findingUuid } = activeFinding || {};

  const baseRef = useRef<HTMLDivElement>(null);
  const segmentRef = useRef<HTMLDivElement>(null);
  const isFirstMountRef = useRef(true);

  const synchronizer = useMemo(getResizeSynchronizer, []);
  const baseSynchronizer = useMemo(() => getBaseSynchronizer(synchronizer), [synchronizer]);
  const segmentSynchronizer = useMemo(() => getSegmentSynchronizer(synchronizer), [synchronizer]);

  const synchronizeImages = useCallback(() => {
    const { current: baseElement } = baseRef;

    if (!baseElement || !checkIsElementEnabled(baseElement)) {
      return;
    }

    getEnabledElement(baseElement).needsRedraw = true;
  }, []);

  const onImageDisplayed = useCallback(() => {
    setImageLoaded(true);
    dispatch(setActiveControl(null));
    synchronizeImages();
  }, [dispatch, synchronizeImages]);

  const onImageRendered = useCallback(
    ({ detail }: CornerstoneRenderEvent) => {
      const { current: baseElement } = baseRef;
      const {
        viewport: { scale, voi },
      } = detail;

      const { windowWidth, windowCenter } = voi;

      if (baseElement && windowWidth !== undefined && windowCenter !== undefined) {
        const { scale: defaultScale } = getDefaultViewport(baseElement);

        dispatch(
          setImageViewportInfo({
            scale: scale / defaultScale,
            windowWidth,
            windowCenter,
          }),
        );
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const { current: baseElement } = baseRef;

    if (!baseElement || !checkIsElementEnabled(baseElement)) {
      return;
    }

    const { element } = getEnabledElement(baseElement);

    disableTools(element, CXR_IMAGE_TOOLS);

    if (activeControl === 'reset') {
      resetViewport(element);
    } else if (activeControl) {
      const toolConfig = CXR_IMAGE_TOOLS[activeControl];
      if (toolConfig) {
        const { toolName } = toolConfig;
        activateTool(element, toolName);
      }
    }
  }, [activeControl]);

  useEffect(() => {
    const { current: isFirstMount } = isFirstMountRef;
    if (!isFirstMount) {
      dispatch(setActiveControl('reset'));
    } else {
      isFirstMountRef.current = false;
    }
  }, [dispatch, findingUuid]);

  useEffect(() => setImageLoaded(false), [sourceUrl]);

  useDisplayImage({
    containerRef: baseRef,
    url: sourceUrl,
    synchronizer: baseSynchronizer,
    onImageDisplayed,
    metadata: sourceMetadata,
    tools: CXR_IMAGE_TOOLS,
    onImageRendered,
  });
  useDisplayImage({
    containerRef: segmentRef,
    url: segmentUrl,
    synchronizer: segmentSynchronizer,
    render: renderSegment,
    onImageDisplayed: synchronizeImages,
  });

  return (
    <ImageContainer>
      {!imageLoaded && <LoadingIndicator />}
      <BaseContainer ref={baseRef} activeControl={activeControl} />
      <LocalisationContainer isVisible={isLocalisationToggleOn}>
        <SegmentContainer ref={segmentRef} />
        {laterality && <LateralSegment laterality={laterality} />}
      </LocalisationContainer>
    </ImageContainer>
  );
};

export default CxrImage;
