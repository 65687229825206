import { AboutModalInfoPanel, AboutContentWrapper, AboutIcon } from './AboutModal.styles';
import { InfoPanelProps } from './AboutModal.types';

const InfoPanelComponent = ({ items }: InfoPanelProps): JSX.Element => (
  <AboutModalInfoPanel>
    {items.map((item, index) => (
      <AboutContentWrapper key={index}>
        <AboutIcon>{item.icon}</AboutIcon>
        {item.text}
      </AboutContentWrapper>
    ))}
  </AboutModalInfoPanel>
);

export default InfoPanelComponent;
