import { useCallback, useContext } from 'react';
import MatomoContext from './MatomoContext';
import { TrackEventParams, TrackLinkParams, TrackPageViewParams, TrackSiteSearchParams } from './types';
import useOutboundClickListener from './utils/useOutboundClickListener';

export type UseMatomo = {
  pushInstruction: (name: string, ...args: unknown[]) => void;
  trackPageView: (params?: TrackPageViewParams) => void | undefined;
  trackSiteSearch: (params: TrackSiteSearchParams) => void | undefined;
  trackLink: (params: TrackLinkParams) => void | undefined;
  trackEvent: (params: TrackEventParams) => void | undefined;
  trackEvents: () => void | undefined;
  enableLinkTracking: () => void;
};

const useMatomo = (): UseMatomo => {
  const instance = useContext(MatomoContext);

  const { setMatomoInstance } = useOutboundClickListener();

  const trackPageView = useCallback((params?: TrackPageViewParams) => instance?.trackPageView(params), [instance]);

  const trackEvent = useCallback((params: TrackEventParams) => instance?.trackEvent(params), [instance]);

  const trackEvents = useCallback(() => instance?.trackEvents(), [instance]);

  const trackSiteSearch = useCallback((params: TrackSiteSearchParams) => instance?.trackSiteSearch(params), [instance]);

  const trackLink = useCallback((params: TrackLinkParams) => instance?.trackLink(params), [instance]);

  const enableLinkTracking = useCallback(() => {
    if (instance) {
      setMatomoInstance(instance);
    }
  }, [instance, setMatomoInstance]);

  const pushInstruction = useCallback(
    (name: string, ...args: unknown[]) => {
      instance?.pushInstruction(name, ...args);
    },
    [instance],
  );

  return {
    trackEvent,
    trackEvents,
    trackPageView,
    trackSiteSearch,
    trackLink,
    enableLinkTracking,
    pushInstruction,
  };
};

export default useMatomo;
