import { css } from 'styled-components';
import colors from 'constants/colors';

const { SECONDARY_CONTAINER, SECONDARY_INVERT } = colors;

export const buttonBaseStyle = css`
  appearance: none !important;
  box-shadow: none;
  outline: none !important;
`;

export const inputBaseStyle = css`
  appearance: none !important;
  box-shadow: none;
  outline: none !important;
`;

export const inputDisabledStyle = css`
  background-color: ${SECONDARY_CONTAINER};
  box-shadow: 0 0 0 1px ${SECONDARY_CONTAINER};
  color: ${SECONDARY_INVERT};
  cursor: not-allowed;

  &::placeholder {
    color: ${SECONDARY_INVERT};
  }

  & + div path,
  & + div svg {
    fill: ${SECONDARY_INVERT};
  }
`;
