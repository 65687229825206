import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Expand from 'icons/Expand';
import Paper from 'icons/Paper';
import { selectDocsManifest } from 'selectors/settings/selectSettings';
import { settingsActions } from 'slices/settingsSlice';
import { AboutModalSubheader, AboutContentWrapper, AboutLink, AboutIcon } from './AboutModal.styles';
import { DOCUMENTATION_SUBHEADER } from './constants';
import { documentationItems } from './constants';

const DocumentationItemComponent = (): JSX.Element => {
  const dispatch = useDispatch();
  const docsManifest = useSelector(selectDocsManifest);

  useEffect(() => {
    !docsManifest && dispatch(settingsActions.fetchDocsManifest());
  }, [dispatch, docsManifest]);

  return (
    <>
      <AboutModalSubheader>{DOCUMENTATION_SUBHEADER}</AboutModalSubheader>
      {documentationItems.map(({ key, link, text }) => (
        <AboutContentWrapper key={key}>
          <AboutLink href={docsManifest?.[key] ? docsManifest[key] : link} target="_blank">
            <AboutIcon>{docsManifest?.[key] ? <Paper /> : <Expand />}</AboutIcon>
            {text}
          </AboutLink>
        </AboutContentWrapper>
      ))}
    </>
  );
};

export default DocumentationItemComponent;
