import { colors as annaColors } from '@annaliseai/anna-design-tokens';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import FindingAccordion from 'components/FindingAccordion';
import colors from 'constants/colors';
import Z_INDEXES from 'constants/zIndexes';
import IconDirection from 'enums/IconDirection';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import ChevronBold from 'icons/ChevronBold';
import Priority from 'icons/Priority';
import { DisplayFindingGroup, ParentFinding } from 'types/finding/FindingGroup';
import FindingType from 'types/study/Finding';
import Finding, { FindingProps } from './Finding';
import FindingBadge from './FindingBadge';

export type FindingGroupFindingProps = Pick<FindingProps, 'setFinding' | 'shouldOnMouseOverInteract'>;

export type FindingGroupProps = {
  findingGroup: DisplayFindingGroup;
  isRelevant?: boolean;
  findingProps: FindingGroupFindingProps;
  activeFindingId: string | undefined;
};

const { GREY_2, GREY_4, GREY_6, BLACK } = colors;
const { DARK_3 } = annaColors;
const { OVERLAY } = Z_INDEXES;
const { FINDING_GROUP } = ReactTestingLibraryDataProperties;
const { DOWN, RIGHT } = IconDirection;

const Container = styled.div`
  margin: 1rem 0 0 2em;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  z-index: ${OVERLAY};
  background: ${DARK_3};
  position: sticky;
  top: 0px;
  width: 100%;
  padding: 0.25rem 1.25rem 0.25rem 0.75rem;
  display: flex;
  align-items: center;
  outline: none;
`;

const IconContainer = styled.div`
  width: 2rem;
`;

const NameBadgeContainer = styled.div`
  display: flex;
  flex-grow: 1;
  text-transform: uppercase;
  align-items: center;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  border-color: ${GREY_2};
  border-bottom-width: 1px;
`;

const Name = styled.div`
  color: ${GREY_6};
  flex-grow: 1;
  font-size: 0.688rem;
  line-height: 1rem;
  font-weight: 900;
  letter-spacing: 0.13em;
  text-shadow: 1px 1px 0 ${BLACK};
`;

const ChevronContainer = styled.div`
  margin-left: 0.25rem;
`;

const FindingGroup: FC<FindingGroupProps> = ({ findingGroup, isRelevant = false, findingProps, activeFindingId }) => {
  const { findings, name, findingCount, isFirstFindingsGroup } = findingGroup;
  const [expanded, setExpanded] = useState<boolean>(isFirstFindingsGroup || false);
  const isCollapsible = !isFirstFindingsGroup;

  const onClick = () => isCollapsible && setExpanded(currentValue => !currentValue);

  return (
    <Container>
      <HeaderContainer
        style={{ cursor: isCollapsible ? 'pointer' : 'initial' }}
        onClick={onClick}
        role="button"
        tabIndex={0}
        data-testid={FINDING_GROUP}
      >
        <IconContainer>
          {isCollapsible ? (
            findings.length > 0 && (
              <ChevronContainer>
                <ChevronBold direction={expanded ? DOWN : RIGHT} />
              </ChevronContainer>
            )
          ) : (
            <Priority />
          )}
        </IconContainer>
        <NameBadgeContainer>
          <Name>{name}</Name>
          <FindingBadge backgroundColor={GREY_4} color={BLACK}>
            {(findingCount > 0 && findingCount) || '-'}
          </FindingBadge>
        </NameBadgeContainer>
      </HeaderContainer>
      {expanded &&
        findings.map(finding => {
          if (!finding?.uuid) {
            return null;
          }

          if (!finding.isParent) {
            return (
              <Finding
                key={finding.uuid}
                finding={finding as FindingType}
                isRelevant={isRelevant}
                isFindingActive={activeFindingId === finding.uuid}
                {...findingProps}
              />
            );
          } else {
            return (
              <FindingAccordion
                key={finding.uuid}
                finding={finding as ParentFinding}
                isRelevant={isRelevant}
                isFindingActive={activeFindingId === finding.uuid}
                {...findingProps}
              />
            );
          }
        })}
    </Container>
  );
};

export default FindingGroup;
