import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type NotificationState = {
  headerNotification: string;
};

export const initialState: NotificationState = {
  headerNotification: '',
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setHeaderNotification: (state, action: PayloadAction<string>) => ({ ...state, headerNotification: action.payload }),
    resetHeaderNotification: state => ({ ...state, headerNotification: '' }),
  },
});

export const notificationActions = notificationSlice.actions;
export type SetHeaderNotificationType = ReturnType<typeof notificationActions.setHeaderNotification>;
export type ResetHeaderNotificationType = ReturnType<typeof notificationActions.resetHeaderNotification>;
