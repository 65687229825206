import { GetLatestCxrPredictionRes } from '@annaliseai/api-specifications';
import log from 'loglevel';
import client from 'api/client';
import endpoints from 'api/endpoints';

const getCxrPrediction = async (studyInstanceUid: string): Promise<GetLatestCxrPredictionRes> => {
  const { studyPrediction } = endpoints.v1;
  const path = studyPrediction.getPath({ studyInstanceUid });

  const { data } = await client.get(path);
  log.debug('received getCxrPrediction response=', data);
  return data;
};

export default getCxrPrediction;
