import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Parent } from 'types/study/Finding';

export type ParentsState = {
  parentsMap: Record<Parent['uuid'], Parent>;
};

export const initialState: ParentsState = {
  parentsMap: {},
};

export const parentsSlice = createSlice({
  name: 'parents',
  initialState,
  reducers: {
    reset: _ => ({
      ...initialState,
    }),
    setParentsMap: (state, { payload: parentsMap }: PayloadAction<ParentsState['parentsMap']>) => ({
      ...state,
      parentsMap,
    }),
  },
});

export const parentsActions = parentsSlice.actions;
