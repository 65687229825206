import { colors } from '@annaliseai/anna-design-tokens';
import styled from 'styled-components';

const Popup = styled.div`
  padding: 2.2rem 2.2rem 2rem;
  position: absolute;
  width: 27.5rem;
  height: 16.25rem;
  left: 5.15rem;
  bottom: 5.15rem;
  background: ${colors.DARK_GRAY};
  box-shadow: 0px 0px 1rem ${colors.BLACK_25};
  color: ${colors.WHITE};
`;

export default Popup;
