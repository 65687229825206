import cornerstoneTools from 'cornerstone-tools';
import { CornerstoneSynchronizer, CornerstoneSynchronizerHandler } from 'types/cornerstone/CornerstoneSynchronizer';
import Synchronizer from 'types/Synchronizer';
import { getEnabledElement } from './cornerstoneHelper';

export const resizeSynchronizerHandler: CornerstoneSynchronizerHandler = (
  synchronizer,
  sourceElement,
  targetElement,
) => {
  const { image: sourceImage, viewport: sourceViewport } = getEnabledElement(sourceElement);
  const { image: targetImage, viewport: targetViewport } = getEnabledElement(targetElement);

  /**
   * Calculate the ratio between X-ray and Segment images.
   * The segment image size is based on the largest X-ray side.
   * So we should pick the largest side of the X-ray and divide it
   * on the segment width or height as a segment is always a square.
   * */
  const scaleRatio = Math.max(sourceImage.width, sourceImage.height) / targetImage.width;

  targetViewport.scale = sourceViewport.scale * scaleRatio;
  targetViewport.translation.x = sourceViewport.translation.x / scaleRatio;
  targetViewport.translation.y = sourceViewport.translation.y / scaleRatio;

  synchronizer.setViewport(targetElement, targetViewport);
};

export const getResizeSynchronizer = (): CornerstoneSynchronizer =>
  new cornerstoneTools.Synchronizer('cornerstoneimagerendered', resizeSynchronizerHandler);

export const getBaseSynchronizer = (synchronizer: CornerstoneSynchronizer): Synchronizer => {
  const { addSource: addElement, removeSource: removeElement } = synchronizer;
  return {
    addElement,
    removeElement,
  };
};

export const getSegmentSynchronizer = (synchronizer: CornerstoneSynchronizer): Synchronizer => {
  const { addTarget: addElement, removeTarget: removeElement } = synchronizer;
  return {
    addElement,
    removeElement,
  };
};
