import SEARCH_QUERY_PARAMS from 'constants/searchQueryParams';
import { KeyValuePairs } from 'types/KeyValuePairs';
import retainValidParamValues from './retainValidParamValues';

// Loop object and build a new object containing only key/value pairs in a given array of keys
const retainValidParams = (obj: KeyValuePairs): KeyValuePairs => {
  const result: KeyValuePairs = {};
  // Iterate over each key in the object
  if (obj) {
    // in case where we have saved default filter values with empty strings, we want to remove those now
    const validValues = retainValidParamValues(obj);
    Object.entries(validValues).forEach(([key, value]) => {
      // If the key is in the SEARCH_QUERY_PARAMS array, add to the resulting object
      if (SEARCH_QUERY_PARAMS.includes(key)) {
        result[key] = value;
      }
    });
  }
  return result;
};

export default retainValidParams;
