import Cookies from 'js-cookie';
import CookieKeys from 'enums/CookieKeys';

const { AGREE_TO_TERMS_CHECK_BOX } = CookieKeys;

export const toggleAgreeToTerms = (): void => {
  const agreed = Cookies.get(AGREE_TO_TERMS_CHECK_BOX) === 'true';
  Cookies.set(AGREE_TO_TERMS_CHECK_BOX, agreed ? 'false' : 'true', { expires: 30 });
};

export const retrieveAgreeToTerms = (): boolean => Cookies.get(AGREE_TO_TERMS_CHECK_BOX) === 'true';
