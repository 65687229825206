import { SegmentLateralityType } from '@annaliseai/api-specifications';
import { useSelector } from 'react-redux';
import FindingIndicatorType from 'enums/FindingIndicatorType';
import getAdjustedLocalisationType, {
  GetAdjustedLocalisationTypeProps,
} from 'epics/study/helpers/ctb/getAdjustedLocalisationType';
import selectCtbViewer from 'selectors/viewer/ctb/selectCtbViewer';
import selectActiveFindingLaterality from 'selectors/viewer/selectActiveFindingLaterality';
import { RootState } from 'slices/RootState';
import Finding, { Parent } from 'types/study/Finding';
import { FindingLocalisationType } from 'types/study/Localisation';

const {
  NO_LOCALISATION,
  BILATERAL_LOCALISATION,
  LEFT_LATERALITY_LOCALISATION,
  RIGHT_LATERALITY_LOCALISATION,
  SINGLE_IMAGE_LOCALISATION,
  MULTIPLE_IMAGES_LOCALISATION,
} = FindingIndicatorType;
const { LEFT, RIGHT, BILATERAL, NONE } = SegmentLateralityType;
const { SINGLE_IMAGE, MULTIPLE_IMAGE } = FindingLocalisationType;

const getTypeByLaterality = (laterality: SegmentLateralityType) => {
  switch (laterality) {
    case LEFT:
      return LEFT_LATERALITY_LOCALISATION;
    case RIGHT:
      return RIGHT_LATERALITY_LOCALISATION;
    case BILATERAL:
      return BILATERAL_LOCALISATION;
    case NONE:
      return NO_LOCALISATION;
  }
};

const getTypeByLocalisation = (localisation: FindingLocalisationType | null) => {
  switch (localisation) {
    case SINGLE_IMAGE:
      return SINGLE_IMAGE_LOCALISATION;
    case MULTIPLE_IMAGE:
      return MULTIPLE_IMAGES_LOCALISATION;
    default:
      return NO_LOCALISATION;
  }
};

const getTypeByLateralityAndLocalisation = (
  laterality: SegmentLateralityType | undefined,
  adjustedLocalisationTypeProps: GetAdjustedLocalisationTypeProps | undefined,
) => {
  if (laterality) {
    return getTypeByLaterality(laterality);
  }
  if (adjustedLocalisationTypeProps) {
    // When AI data indicates localisation however no associated slice to view, display as per 'no localisation'
    const adjustedLocalisation = getAdjustedLocalisationType(adjustedLocalisationTypeProps);

    return getTypeByLocalisation(adjustedLocalisation);
  }

  return NO_LOCALISATION;
};

const useFindingIndicatorType = (findingUuid: Finding['uuid'] | Parent['uuid'] | null): FindingIndicatorType | null => {
  const { laterality, localisation } = useSelector((state: RootState) =>
    selectActiveFindingLaterality(state, findingUuid),
  );
  const { segmentSliceIdsMap } = useSelector(selectCtbViewer);

  if (!findingUuid) {
    return null;
  }

  let adjustedLocalisationTypeProps;
  if (localisation) {
    adjustedLocalisationTypeProps = { segmentSliceIdsMap, findingUuid, localisation };
  }

  return getTypeByLateralityAndLocalisation(laterality, adjustedLocalisationTypeProps);
};

export default useFindingIndicatorType;
