import styled, { DataAttributes } from 'styled-components';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { NEUTRAL_BACKGROUND_ON, NEUTRAL_BACKGROUND_ON_VARIANT, TERTIARY_OUTLINE_VARIANT } = colors;
const { FOOTER } = ReactTestingLibraryDataProperties;

export const FooterContainer = styled.footer.attrs<DataAttributes>({
  'data-testid': FOOTER,
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5rem;
  height: 5rem;
  width: 100%;
`;

export const ItemsContainer = styled.div`
  display: flex;
  gap: 2rem;
  color: ${NEUTRAL_BACKGROUND_ON};
  font-size: 0.875rem;
  line-height: 1rem;
`;

export const FooterLink = styled.a`
  text-decoration: underline;
  outline-color: transparent;

  &:focus {
    border-radius: 0.5rem;
    outline: 0.06rem solid ${TERTIARY_OUTLINE_VARIANT};
    color: ${NEUTRAL_BACKGROUND_ON_VARIANT};
  }
  &:hover {
    color: ${NEUTRAL_BACKGROUND_ON_VARIANT};
  }
`;
