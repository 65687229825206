enum Directions {
  CRANIAL = 'Cranial',
  CAUDAL = 'Caudal',
  ANTERIOR = 'Anterior',
  POSTERIOR = 'Posterior',
  RIGHT = 'Right',
  LEFT = 'Left',
}

export default Directions;
