import { CtbImageSlicesCompleted, Status } from '@annaliseai/api-specifications';
import { interval, throwError } from 'rxjs';
import { exhaustMap, first, map, timeoutWith } from 'rxjs/operators';
import { getCtbBaseImages } from 'api/queries';
import getErrorCode from 'api/queries/getErrorCode';
import { POLLING } from 'constants/durations';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';

const { UNEXPECTED_BACKEND_ERROR } = CustomErrors;
const { COMPLETED, COMPLETED_ERROR } = Status;
const { PERIOD, TIMEOUT } = POLLING;

const loadCtbImageSlices = async (
  seriesInstanceUid: string,
  seriesVersionId: string,
): Promise<CtbImageSlicesCompleted> =>
  await interval(PERIOD)
    .pipe(
      exhaustMap(
        async () =>
          await getCtbBaseImages({
            seriesInstanceUid,
            seriesVersionId,
          }),
      ),
      map(ctbImageSlicesRes => {
        if (ctbImageSlicesRes.status === COMPLETED_ERROR) {
          throw new CustomError(getErrorCode(ctbImageSlicesRes.error.code));
        }
        return ctbImageSlicesRes;
      }),
      first(
        (ctbImageSlicesRes): ctbImageSlicesRes is CtbImageSlicesCompleted => ctbImageSlicesRes.status === COMPLETED,
      ),
      timeoutWith(TIMEOUT, throwError(new CustomError(UNEXPECTED_BACKEND_ERROR))),
    )
    .toPromise();

export default loadCtbImageSlices;
