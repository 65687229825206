import { colors } from '@annaliseai/anna-design-tokens';
import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { DataAttributes } from 'styled-components';
import DropzoneStates from 'enums/DropzoneStates';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { setToFileCache } from 'helpers/cornerstone/fileCache';
import { isDropzoneStateEmpty, isDropzoneStateLoaded, isDropzoneStateLoading } from 'helpers/dropzoneStateHelper';
import isDicomFile from 'helpers/file/isDicomFile';
import isImageFile from 'helpers/file/isImageFile';
import newEmptyFile from 'helpers/file/newEmptyFile';
import useResizeAware from 'react-resize-aware';
import { SetFiles } from 'types/SetFiles';
import { ValidationResult } from 'types/ValidationResult';
import DicomFileViewer from './DicomFileViewer';
import FilePlaceholder from './FilePlaceholder';
import ImageFileViewer from './ImageFileViewer';
import Trash from './Trash';

const { DROPZONE, DROPZONE__INPUT, DROPZONE__LOADER } = ReactTestingLibraryDataProperties;
const { EMPTY, LOADING } = DropzoneStates;

const { WHITE, PRIMARY_BRIGHT, DARK_2 } = colors;

// eslint-disable-next-line
const Container = styled.div.attrs<DataAttributes>({ 'data-testid': DROPZONE })<{
  disabled: boolean;
  $isContentLoaded: boolean;
}>`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;

  background:
    linear-gradient(to right, ${DARK_2} 50%, ${WHITE} 50%) top/20px 1px repeat-x,
    linear-gradient(${DARK_2} 50%, ${WHITE} 50%) right/1px 20px repeat-y,
    linear-gradient(to right, ${DARK_2} 50%, ${WHITE} 50%) bottom/20px 1px repeat-x,
    linear-gradient(${DARK_2} 50%, ${WHITE} 50%) left/1px 20px repeat-y;

  border-radius: var(--dropzone--border-radius);
  outline: none;
  cursor: pointer;
  opacity: 1;

  ${({ disabled }) =>
    disabled &&
    `
    
    
    pointer-events: none;
    opacity: 0.6;
  `}

  ${({ $isContentLoaded }) =>
    $isContentLoaded
      ? `
    background: linear-gradient(to right, ${DARK_2} 50%, ${PRIMARY_BRIGHT} 50%) top/20px 1px repeat-x,
      linear-gradient(${DARK_2} 50%, ${PRIMARY_BRIGHT} 50%) right/1px 20px repeat-y,
      linear-gradient(to right, ${DARK_2} 50%, ${PRIMARY_BRIGHT} 50%) bottom/20px 1px repeat-x,
      linear-gradient(${DARK_2} 50%, ${PRIMARY_BRIGHT} 50%) left/1px 20px repeat-y;
    background-color: ${PRIMARY_BRIGHT};
  `
      : `  &:hover {
    background: linear-gradient(to right, ${DARK_2} 50%, ${PRIMARY_BRIGHT} 50%) top/20px 1px repeat-x,
      linear-gradient(${DARK_2} 50%, ${PRIMARY_BRIGHT} 50%) right/1px 20px repeat-y,
      linear-gradient(to right, ${DARK_2} 50%, ${PRIMARY_BRIGHT} 50%) bottom/20px 1px repeat-x,
      linear-gradient(${DARK_2} 50%, ${PRIMARY_BRIGHT} 50%) left/1px 20px repeat-y;
    opacity: 1;
  }`}

  margin: 0;
  padding: 0;
`;

const Input = styled.input.attrs<DataAttributes>({
  'data-testid': DROPZONE__INPUT,
})`
  outline: none;
`;

const Loader = styled.div.attrs<DataAttributes>({ 'data-testid': DROPZONE__LOADER })`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  border: var(--dropzone__loader--thickness) solid var(--dropzone__loader--secondary-color);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  border-top: var(--dropzone__loader--thickness) solid var(--dropzone__loader--primary-color);
  border-radius: 50%;
  width: var(--dropzone__loader--size);
  height: var(--dropzone__loader--size);
`;

type DropzoneProps = {
  disabled?: boolean;
  files: File[];
  index: number;
  setFiles: SetFiles;
  validateOnChangeFiles: (files: File[]) => ValidationResult<File[]>;
  validateOnChangeFile: (index: number, file: File) => Promise<ValidationResult<File>>;
  contentOnHover?: ReactNode;
  contentOnLoaded?: ReactNode;
  children?: ReactNode;
};

const Dropzone = ({
  disabled = false,
  files,
  index,
  setFiles,
  validateOnChangeFiles,
  validateOnChangeFile,
  children,
  contentOnHover = children,
  contentOnLoaded = children,
}: DropzoneProps): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const [resizeListener, layoutSize] = useResizeAware();
  const [dropzoneState, setDropzoneState] = useState(EMPTY);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const clearCurrentImage = (newFiles: File[]) => {
    newFiles[index] = newEmptyFile();

    setDropzoneState(EMPTY);

    setIsHovering(false);

    return newFiles;
  };

  const performFileValidation = async (newFile: File): Promise<File[]> => {
    if (!newFile) {
      return files;
    }
    const newFiles = [...files];
    newFiles[index] = newFile;

    const { error: invalidFileType } = validateOnChangeFiles(newFiles);

    if (invalidFileType) {
      clearCurrentImage(newFiles);
    }

    const { error: invalidDICOMImage } = await validateOnChangeFile(index, newFile);

    if (invalidDICOMImage) {
      clearCurrentImage(newFiles);
    }

    if (isDicomFile(newFile)) {
      setToFileCache(index, newFile);
    }

    return newFiles;
  };

  const { getInputProps, getRootProps } = useDropzone({
    multiple: false,
    disabled,
    onDrop: async acceptedFiles => {
      const newFile = acceptedFiles[0];

      setDropzoneState(LOADING);

      const nextFiles = await performFileValidation(newFile);
      setFiles(nextFiles);
    },
  });

  useEffect(() => {
    if (layoutSize && ref && ref.current) {
      ref.current.setAttribute('style', `height:${layoutSize.height}px;width:${layoutSize.width}px;`);
    }
  }, [ref, layoutSize]);

  const onTrash = async () => {
    const newFiles = clearCurrentImage(files);
    setFiles(newFiles);
  };

  const renderFileViewer = () => {
    const file = files[index];

    if (file) {
      if (isDicomFile(file)) {
        return (
          <DicomFileViewer
            file={file}
            index={index}
            dropzoneState={dropzoneState}
            setDropzoneState={setDropzoneState}
          />
        );
      } else if (isImageFile(file)) {
        return (
          <ImageFileViewer
            index={index}
            file={file}
            dropzoneState={dropzoneState}
            setDropzoneState={setDropzoneState}
          />
        );
      }
    }
  };

  const isContentLoaded = isDropzoneStateLoaded(dropzoneState);
  const showOnHoverContent = isHovering && !disabled;
  return (
    <Container
      ref={ref}
      {...getRootProps()}
      disabled={disabled}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      $isContentLoaded={isContentLoaded}
    >
      {resizeListener}
      <Input {...getInputProps()} disabled={disabled} />
      {isDropzoneStateEmpty(dropzoneState) && (showOnHoverContent ? contentOnHover : children || <FilePlaceholder />)}
      {isDropzoneStateLoading(dropzoneState) && <Loader />}
      {renderFileViewer()}
      {isContentLoaded && (
        <>
          <Trash onTrash={onTrash} />
          {contentOnLoaded}
        </>
      )}
    </Container>
  );
};

export default Dropzone;
