import { VisionClassificationExternal, VisionStudyImageFinding } from '@annaliseai/api-specifications';
import uuid from 'helpers/uuid';
import Finding from 'types/study/Finding';
import { WholeImage } from 'types/study/Image';
import Localisation from 'types/study/Localisation';
import CxrFindingViewer, { CxrFindingView } from 'types/viewer/cxrViewer/CxrFindingViewer';
import compareCxrViewPositions from './compareCxrViewPositions';

type GetCxrFindingViewers = (_: {
  images: VisionStudyImageFinding[];
  relevantFindings: VisionClassificationExternal[];
  sourceImagesMapByImageInstanceId: Record<string, WholeImage>;
  targetImagesMapByImageInstanceId: Record<string, WholeImage>;
  localisationsMapBySegmentId: Record<string, Localisation>;
  findingsMapByLabel: Record<string, Finding>;
}) => {
  findingViewsMap: Record<CxrFindingView['uuid'], CxrFindingView>;
  findingViewersMap: Record<Finding['uuid'], CxrFindingViewer>;
};

const getCxrFindingViewers: GetCxrFindingViewers = ({
  images,
  relevantFindings,
  sourceImagesMapByImageInstanceId,
  targetImagesMapByImageInstanceId,
  localisationsMapBySegmentId,
  findingsMapByLabel,
}) => {
  const findingViewsMap: Record<CxrFindingView['uuid'], CxrFindingView> = {};
  const findingViewersMap: Record<Finding['uuid'], CxrFindingViewer> = {};

  relevantFindings.forEach(({ isLatViewByDefault, label }) => {
    const findingImages = images.map(image => {
      const segment = image.segments.find(({ relatedLabels }) =>
        relatedLabels.some(relatedLabel => relatedLabel === label),
      );

      return {
        image,
        segment,
      };
    });

    const sortedFindingImages = findingImages.sort(({ image: a }, { image: b }) =>
      compareCxrViewPositions(a.viewPosition, b.viewPosition, isLatViewByDefault),
    );

    const views = sortedFindingImages
      .map(({ image: { imageInstanceUid }, segment }, index) => {
        const findingView: CxrFindingView = {
          uuid: uuid('cxr-finding-view'),
          baseImageUuid: sourceImagesMapByImageInstanceId[imageInstanceUid].uuid,
          localisationUuid: segment && localisationsMapBySegmentId[segment.id].uuid,
          thumbnailImageUuid: targetImagesMapByImageInstanceId[imageInstanceUid].uuid,
        };

        if (!segment && index > 0) return null;

        findingViewsMap[findingView.uuid] = findingView;
        return findingView;
      })
      .filter((data): data is NonNullable<typeof data> => !!data);

    const findingViewer: CxrFindingViewer = {
      uuid: uuid('cxr-finding-viewer'),
      viewUuids: views.map(({ uuid }) => uuid),
    };

    findingViewersMap[findingsMapByLabel[label].uuid] = findingViewer;
  });

  return {
    findingViewsMap,
    findingViewersMap,
  };
};

export default getCxrFindingViewers;
