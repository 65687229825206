import { StudyExternalV2Ctb, ViewType } from '@annaliseai/api-specifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CornerstoneCtbImageSliceId from 'types/cornerstone/CornerstoneCtbImageSliceId';
import CornerstoneImageId from 'types/cornerstone/CornerstoneImageId';
import Finding, { Parent } from 'types/study/Finding';
import { CtbSeriesInfo } from 'types/study/Study';
import CtbFindingViewer, { CtbFindingView } from 'types/viewer/ctbViewer/CtbFindingViewer';
import CtbView from 'types/viewer/ctbViewer/CtbView';

export type CtbViewerState = {
  findingViewsMap: Record<CtbFindingView['uuid'], CtbFindingView>;
  findingViewersMap: Record<Finding['uuid'] | Parent['uuid'], CtbFindingViewer>;
  activeFinding: CtbView | null;
  seriesInfo: CtbSeriesInfo | null;
  imageSliceIdsMap: Record<ViewType, CornerstoneCtbImageSliceId[]>;
  segmentSliceIdsMap: Record<Finding['uuid'], Record<ViewType, Record<number, CornerstoneImageId>>>;
};

export const initialState: CtbViewerState = {
  findingViewsMap: {},
  findingViewersMap: {},
  activeFinding: null,
  seriesInfo: null,
  imageSliceIdsMap: {
    AXIAL: [],
    CORONAL: [],
    SAGITTAL: [],
  },
  segmentSliceIdsMap: {},
};

export const ctbViewerSlice = createSlice({
  name: 'ctbViewer',
  initialState,
  reducers: {
    runInitCtbStudyEffect: (_state, _action: PayloadAction<StudyExternalV2Ctb>) => undefined,
    reset: _ => ({
      ...initialState,
    }),
    setFindingViewersMap: (
      state,
      { payload }: PayloadAction<Pick<CtbViewerState, 'findingViewsMap' | 'findingViewersMap'>>,
    ) => ({
      ...state,
      ...payload,
    }),
    setActiveFinding: (state, { payload: findingUuid }: PayloadAction<Finding['uuid'] | Parent['uuid']>) => ({
      ...state,
      activeFinding: {
        findingView: state.findingViewersMap[findingUuid],
        findingUuid,
      },
    }),
    setSeriesInfo: (state, { payload }: PayloadAction<CtbSeriesInfo>) => ({
      ...state,
      seriesInfo: payload,
    }),
    setSliceIdsMap: (
      state,
      { payload }: PayloadAction<Pick<CtbViewerState, 'imageSliceIdsMap' | 'segmentSliceIdsMap'>>,
    ) => ({
      ...state,
      ...payload,
    }),
  },
});

export const ctbViewerActions = ctbViewerSlice.actions;
