import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { ICON_ONBOARDING_SUBJECTIVE } = ReactTestingLibraryDataProperties;

const OnboardSubjective: FC = () => (
  <svg
    data-testid={ICON_ONBOARDING_SUBJECTIVE}
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
  >
    <path
      d="M31.115 10.787c-.256-.256-.607-.352-.96-.288l-5.978.477c-.351.096-.64.352-.735.704-.096.351 0 .735.256.991l1.663 1.663-6.687 6.687c-.288-.16-.608-.288-.928-.384V8.098h2.367c.384 0 .704-.191.896-.511.191-.32.16-.736-.032-1.024L17.075 1.48c-.192-.288-.512-.48-.864-.48s-.672.192-.863.48l-3.902 5.05c-.192.32-.192.704-.032 1.024.191.32.511.512.895.512h2.367v12.571c-.32.096-.64.224-.928.384l-6.687-6.687 1.663-1.663c.256-.256.352-.64.256-.991-.096-.352-.384-.64-.735-.704l-5.978-.445c-.352-.096-.704.032-.96.288s-.352.607-.288.96l.445 5.977c.096.352.352.64.704.736.096.032.192.032.288.032.256 0 .543-.096.735-.288l1.663-1.663 6.688 6.687c-.384.736-.608 1.567-.608 2.463 0 2.91 2.367 5.277 5.277 5.277s5.277-2.367 5.277-5.277c0-.896-.224-1.727-.607-2.463l6.687-6.687 1.663 1.663c.192.192.448.288.735.288.096 0 .192 0 .288-.032.352-.096.64-.384.704-.736l.445-5.978c.064-.383-.064-.735-.288-.991zm-14.904 17.11c-1.215 0-2.207-.99-2.207-2.206 0-1.215.992-2.207 2.207-2.207 1.215 0 2.207.991 2.207 2.207 0 1.215-.992 2.207-2.207 2.207z"
      stroke="#fff"
      strokeWidth="1.375"
    />
  </svg>
);

export default OnboardSubjective;
