// limit is the number of results queried/returned by the studies search api

enum Limits {
  RESULTS_10 = 10,
  RESULTS_15 = 15,
  RESULTS_30 = 30,
  RESULTS_50 = 50,
}

export default Limits;
