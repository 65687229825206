import { colors } from '@annaliseai/anna-design-tokens';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import CxrSampleUpload from 'components/CxrSampleUpload';
import { Tabs } from 'components/CxrUploadTabs';
import { TabsId } from 'components/CxrUploadTabs/types';
import Sample from 'components/sample/Sample';
import loganColors from 'constants/colors';
import cxrSamples from 'constants/cxrSamples';
import cxrUploadsTabs from 'constants/cxrUploadsTabs';
import { PORTRAIT } from 'constants/orientation';
import { BREAK_POINTS } from 'constants/sizes';
import Routes from 'enums/Routes';
import useRedirectIfLoggedIn from 'hooks/useRedirectIfLoggedIn';
import { BodyContainer, Container, Row } from 'layouts/ResponsiveContainers';
import { useMatomo } from 'matomo/react';
import { EventActions, EventCategories } from 'types/Matomo';

const { WHITE } = colors;

const MIN_LARGE_SCREEN_WIDTH = 1360;
const MIN_LARGE_SCREEN_HEIGHT = 900;

const { BACKGROUNDS_WINDOW } = loganColors;
const { SAMPLE_SCREEN_LARGE_MIN_WIDTH } = BREAK_POINTS;
const { VIEWER } = Routes;

const { VIEW_SAMPLE_STUDIES, UPLOAD_YOUR_OWN_IMAGES } = TabsId;

const BodyContainerStyled = styled(BodyContainer)`
  color: ${WHITE};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
`;

const SamplesInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SamplesContainer = styled.div<{ isPortrait: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 1.5rem;
`;

const UploadInfoPanel = styled.div`
  display: flex;
  align-content: space-between;
  width: 100%;
  background-color: ${BACKGROUNDS_WINDOW};
  padding: 3rem 2rem;
  border-radius: 0.5rem;

  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    padding: 6rem 4rem;
  }
`;

const TabsContainer = styled.div`
  margin: 1rem 0 1rem 0;

  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    margin: 2rem 0 2rem 0;
  }
`;

const TabContentContainer = styled.div<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? `flex` : `none`)};
  justify-content: center;
`;

const CxrSampleScreen: FC = () => {
  const [activeTabId, setActiveTabId] = useState<string>(VIEW_SAMPLE_STUDIES);
  const dispatch = useDispatch();

  const { trackEvent } = useMatomo();
  const { SAMPLE_STUDY } = EventCategories;
  const { VISITED } = EventActions;
  const isPortrait = useMediaQuery({ orientation: PORTRAIT });
  const isSmallScreenWidth = useMediaQuery({
    maxWidth: MIN_LARGE_SCREEN_WIDTH - 1,
  });
  const isSmallScreenHeight = useMediaQuery({
    maxHeight: MIN_LARGE_SCREEN_HEIGHT - 1,
  });

  const isSmallScreen = isSmallScreenWidth || isSmallScreenHeight;

  useRedirectIfLoggedIn();

  const onSampleClick = (accessionNumber: string, studyInstanceUid: string, description: string) => {
    const query = queryString.stringify({
      accessionNumber,
      studyInstanceUid,
    });
    trackEvent({
      category: SAMPLE_STUDY,
      action: VISITED,
      name: `CXR - ${description}`,
    });
    dispatch(push(`${VIEWER}?${query}`));
  };

  const onTabChange = (tabId: string) => {
    setActiveTabId(tabId);
  };

  return (
    <BodyContainerStyled>
      <Container>
        <SamplesInfoContainer>
          <TabsContainer>
            <Tabs tabs={cxrUploadsTabs} onChange={onTabChange} initialActiveTab={VIEW_SAMPLE_STUDIES} />
          </TabsContainer>
        </SamplesInfoContainer>
      </Container>
      <TabContentContainer isActive={activeTabId === VIEW_SAMPLE_STUDIES}>
        <SamplesContainer isPortrait={isPortrait}>
          {cxrSamples.map(({ accessionNumber, studyInstanceUid, ...restConfig }, index) => (
            <Sample
              key={index}
              size={isSmallScreen ? 'small' : 'medium'}
              {...restConfig}
              onClick={() => onSampleClick(accessionNumber, studyInstanceUid, restConfig.description)}
            />
          ))}
        </SamplesContainer>
      </TabContentContainer>
      <TabContentContainer isActive={activeTabId === UPLOAD_YOUR_OWN_IMAGES}>
        <Container>
          <UploadInfoPanel>
            <Row>
              <CxrSampleUpload />
            </Row>
          </UploadInfoPanel>
        </Container>
      </TabContentContainer>
    </BodyContainerStyled>
  );
};

export default CxrSampleScreen;
