import { AxiosRequestConfig, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import keycloak, { TOKEN_CHECK_AHEAD_EXPIRY } from 'keycloak';

// Note: The difference between InternalAxiosRequestConfig & AxiosRequestConfig is that the former expects `headers`.
//       This is a requirement for the interceptors, though causes headaches with the various header types in axios.
//       See: https://github.com/axios/axios/issues/5967 & https://github.com/axios/axios/issues?q=is%3Aissue+is%3Aopen+header+types.

export const addAuthorizationHeader = async (
  requestConfig: AxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  // this will only update the token if it expires in 5 seconds
  await keycloak.doUpdate(TOKEN_CHECK_AHEAD_EXPIRY);

  const token = keycloak.getToken();

  requestConfig.headers = {
    ...requestConfig.headers,
    Authorization: `Bearer ${token}`,
  } as AxiosRequestHeaders;

  return requestConfig as InternalAxiosRequestConfig;
};

export default addAuthorizationHeader;
