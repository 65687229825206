import { ReactComponent as Grid } from '@fluentui/svg-icons/icons/grid_16_regular.svg';
import React, { FC } from 'react';
import styled from 'styled-components';
import Button from 'components/Buttons/Button/Button';
import ButtonText from 'components/Buttons/ButtonText/ButtonText';
import Share from 'icons/Share';

export type SidebarShareProps = {
  onNewStudyClick: () => void;
  onShareClick: () => void;
};

const ShareWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0.75rem;
  justify-content: space-between;
`;

const SidebarShare: FC<SidebarShareProps> = ({ onShareClick, onNewStudyClick }) => {
  return (
    <ShareWrapper>
      <Button onClick={onNewStudyClick}>
        <Grid />
        <ButtonText>Studies</ButtonText>
      </Button>
      <Button onClick={onShareClick}>
        <Share fillOpacity={0.75} />
        <ButtonText>Share</ButtonText>
      </Button>
    </ShareWrapper>
  );
};

export default SidebarShare;
