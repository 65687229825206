import { combineEpics, Epic, ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { getStudies } from 'api/queries';
import getOrgDisplayData from 'api/queries/getOrgDisplayData';
import errorsMapping from 'constants/errorsMapping';
import CustomErrors from 'enums/CustomErrors';
import formatStudy from 'helpers/formatStudy';
import { ActionTypes } from 'slices/ActionTypes';
import { errorActions, SetErrorType } from 'slices/errorSlice';
import { SetOrgDisplayDataType, settingsActions, SettingsState } from 'slices/settingsSlice';
import { FetchFailedType, FetchType, SetStudiesType, studyListActions } from 'slices/studyListSlice';

const { UNEXPECTED_BACKEND_ERROR } = CustomErrors;

const { fetch: runFetchEffect } = studyListActions;

const fetchEpic: Epic<ActionTypes, FetchFailedType | SetStudiesType | SetOrgDisplayDataType | SetErrorType> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType<ActionTypes, FetchType>(runFetchEffect.type),
    switchMap(async ({ payload = {} }) => {
      const { studies, totalRecords } = await getStudies(payload);

      return { studies, totalRecords };
    }),
    switchMap(async ({ studies, totalRecords }) => {
      let displayData: SettingsState['displayData'] = state$?.value?.settings?.displayData;

      if (!displayData) {
        displayData = await getOrgDisplayData();
      }

      return { studies, totalRecords, displayData };
    }),
    mergeMap(({ studies, totalRecords, displayData }) => {
      const formattedStudies = studies.map(study => formatStudy(study, displayData?.dateFormats?.displayFormat));

      return of(
        studyListActions.setStudies({ studies: formattedStudies, totalRecords }),
        settingsActions.setOrgDisplayData(displayData),
      );
    }),
    catchError(() =>
      merge(of(studyListActions.fetchFailed(), errorActions.setError(errorsMapping[UNEXPECTED_BACKEND_ERROR]))),
    ),
  );

const studyListEpic = combineEpics(fetchEpic);

export default studyListEpic;
