import { RootState } from 'slices/RootState';
import Image from 'types/study/Image';

const selectDefaultThumbnail = ({
  cxrViewer: { defaultBaseImageThumbnailUuid },
  images: { imagesMap },
}: RootState): Image | null => {
  if (!defaultBaseImageThumbnailUuid) {
    return null;
  }

  return imagesMap[defaultBaseImageThumbnailUuid];
};

export default selectDefaultThumbnail;
