import { push } from 'connected-react-router';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import configuration from 'configuration';
import Routes from 'enums/Routes';
import { isLoggedIn } from 'helpers/limitationHelper';
import { selectStudyIds } from 'selectors/viewer/selectStudy';
import { RootState } from 'slices/RootState';
import { studyListActions } from 'slices/studyListSlice';
import { studyActions } from 'slices/studySlice';
import { StudyIds } from 'types/study/Study';

const { LIST, HOME } = Routes;

const convertNullToUndefined = (value: string | null) => (value === null ? undefined : value);

const isStudyResPresent = (accessionNumber: string, studyIds?: StudyIds): boolean =>
  accessionNumber === studyIds?.accessionNumber;

const useViewerScreenRouter = (): void => {
  const { isDemo } = configuration;
  const dispatch = useDispatch();
  const studyIds = useSelector(selectStudyIds);
  const { action } = useSelector(({ router }: RootState) => router);
  const { accessionNumber, studyInstanceUid } = queryString.parse(window.location.search);
  const userLoggedIn = isLoggedIn();
  const isPrivateDemo = userLoggedIn && isDemo;

  useEffect(() => {
    return () => {
      dispatch(studyActions.runResetStudyEffect());
    };
  }, [dispatch, action]);

  useEffect(() => {
    // We only want to show study pagination on the private demo, so we don't need to fetch studies
    // to get the length unless this is private demo
    isPrivateDemo && dispatch(studyListActions.fetch());
  }, [dispatch, isPrivateDemo]);

  useEffect(() => {
    if (typeof accessionNumber === 'string' && (!studyInstanceUid || typeof studyInstanceUid === 'string')) {
      !isStudyResPresent(accessionNumber, studyIds) &&
        dispatch(
          studyActions.runInitStudyEffect({
            accessionNumber,
            studyInstanceUid: convertNullToUndefined(studyInstanceUid),
          }),
        );
    } else {
      dispatch(push(userLoggedIn && configuration.isDemo ? LIST : HOME));
    }
  }, [accessionNumber, studyInstanceUid, dispatch, studyIds, userLoggedIn]);
};

export default useViewerScreenRouter;
