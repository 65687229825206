import DateFormats from 'enums/DateFormats';
import convertDateAndTimeToDateTime from './dates/convertDateAndTimeToDateTime';
import formatDateTime from './dates/formatDateTime';
import getFormattedDateTimeInformation from './dates/getFormattedDateTimeInformation';

const { FORMAT_D_MMMM_YYYY, FORMAT_24H_HH_MM_SS } = DateFormats;

export type StudyInformation = {
  studyDate?: string;
  studyDateTime?: string;
  studyTime?: string;
  studyType?: string;
  description?: string;
  sampleDescription?: string;
  accessionNumber?: string;
};

export type StudyInformationWithTitles = StudyInformation & {
  studyDateTimeTitle: string;
  descriptionTitle: string;
  accessionNumberTitle: string;
  studyTypeTitle: string;
};

// The formatStudyInformation helper function formats the study data for display and titles (tooltips).
// Missing data is replaced with a placeholder (if one is provided).
// Titles (for use as tooltips) are returned for each piece of data used in the web viewer.
// Where data is missing, tooltips display a message indicating which data is missing.
// For combined data, such as studyDateTime, if this data is missing then it is generated
// by concatenating existing studyDate and studyTime data or placeholder.

const formatStudyInformation = (
  study: StudyInformation | null,
  displayFormat?: string,
  placeholder = '',
): Required<
  Pick<
    StudyInformationWithTitles,
    | 'description'
    | 'accessionNumber'
    | 'descriptionTitle'
    | 'accessionNumberTitle'
    | 'studyDateTime'
    | 'studyDateTimeTitle'
    | 'studyType'
    | 'studyTypeTitle'
  >
> => {
  const { studyDate, studyTime, studyType, accessionNumber, description, sampleDescription } = study || {};

  const studyDateTime = convertDateAndTimeToDateTime({ date: studyDate, time: studyTime });
  const studyDateTimeMMMM = studyDateTime
    ? formatDateTime(studyDateTime, `${FORMAT_D_MMMM_YYYY()} ${FORMAT_24H_HH_MM_SS()}`)
    : placeholder;
  const displayFormatWithTime = displayFormat ? `${displayFormat} HH:mm:ss` : '';
  const displayStudyDateTime = getFormattedDateTimeInformation({
    dateTime: studyDateTime,
    displayFormat: displayFormatWithTime,
  });

  return {
    accessionNumber: accessionNumber || placeholder,
    accessionNumberTitle: accessionNumber || 'Accession number not available',
    description: sampleDescription || description || placeholder,
    descriptionTitle: sampleDescription || description || 'Study description not available',
    studyDateTime: displayStudyDateTime,
    studyDateTimeTitle: studyDateTimeMMMM,
    studyType: studyType || placeholder,
    studyTypeTitle: studyType || 'Study type not available',
  };
};

export default formatStudyInformation;
