import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useMatomo } from 'matomo/react';
import { ParentFinding } from 'types/finding/FindingGroup';
import { EventActions, EventCategories } from 'types/Matomo';
import ChildFinding from './ChildFinding';
import Finding, { FindingProps } from './Finding';

const AccordionWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const { CTB_VIEWER } = EventCategories;
const { PARENT_EXPANDED } = EventActions;
const FindingAccordion: FC<FindingProps & { finding: ParentFinding }> = ({
  finding,
  isRelevant = true,
  setFinding: setFindingCallback,
  isFindingActive,
  shouldOnMouseOverInteract,
}) => {
  const [expanded, setExpanded] = useState(false);
  const { trackEvent } = useMatomo();
  const findingProps = { setFinding: setFindingCallback, shouldOnMouseOverInteract };

  const onClick = () => {
    !expanded &&
      trackEvent({
        category: CTB_VIEWER,
        action: PARENT_EXPANDED,
        name: finding.name,
      });
    setExpanded(!expanded);
  };

  return (
    <>
      <AccordionWrapper role="button" onClick={onClick}>
        <Finding
          key={finding.uuid}
          finding={finding}
          isRelevant={isRelevant}
          isExpanded={expanded}
          isFindingActive={isFindingActive}
          {...findingProps}
        />
      </AccordionWrapper>
      {expanded &&
        finding.findings.map(childFinding => {
          return <ChildFinding key={childFinding.name} {...childFinding} isParentActive={isFindingActive} />;
        })}
    </>
  );
};

export default FindingAccordion;
