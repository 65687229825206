import {
  CtbImageSetSliceInfo,
  CtbImageSlicesCompleted,
  CtbImageSetViewInfo,
  ViewType,
} from '@annaliseai/api-specifications';
import createCtbImageSliceId from 'helpers/cornerstone/createCtbImageSliceId';
import createMapFromArrayValues from 'helpers/createMapFromArrayValues';
import uuid from 'helpers/uuid';
import { CtbViewerState } from 'slices/ctbViewerSlice';
import Image, { SliceImage } from 'types/study/Image';

const { AXIAL, CORONAL, SAGITTAL } = ViewType;

type GetCtbImageSliceViews = (_: { imageSliceViews: CtbImageSlicesCompleted['views'] }) => {
  imageSlicesMap: Record<Image['uuid'], Image>;
  imageSliceIdsMap: CtbViewerState['imageSliceIdsMap'];
};

const getViewSliceImages = (url: string, slicesInfo: CtbImageSetSliceInfo[]): SliceImage[] =>
  slicesInfo.map(({ bytesRange }) => {
    const image: SliceImage = {
      uuid: uuid('slice-image'),
      type: 'SLICE',
      url,
      bytesRange,
    };

    return image;
  });

const getImageSliceIdsMap = (viewData: CtbImageSetViewInfo) =>
  viewData.slicesInfo.map(slice => slice && createCtbImageSliceId(viewData.url, slice.bytesRange));

const getCtbImageSliceViews: GetCtbImageSliceViews = ({ imageSliceViews }) => {
  const { AXIAL: axialViews, CORONAL: coronalViews, SAGITTAL: sagittalViews } = imageSliceViews;

  // Axial images should be reverted to orient slices from top to bottom
  const axialImages = getViewSliceImages(axialViews.url, axialViews.slicesInfo.reverse());
  const coronalImages = getViewSliceImages(coronalViews.url, coronalViews.slicesInfo);
  const sagittalImages = getViewSliceImages(sagittalViews.url, sagittalViews.slicesInfo);

  const imageSlicesMap = createMapFromArrayValues([...axialImages, ...coronalImages, ...sagittalImages]);
  const imageSliceIdsMap = {
    [AXIAL]: getImageSliceIdsMap(axialViews),
    [CORONAL]: getImageSliceIdsMap(coronalViews),
    [SAGITTAL]: getImageSliceIdsMap(sagittalViews),
  };

  return {
    imageSlicesMap,
    imageSliceIdsMap,
  };
};

export default getCtbImageSliceViews;
