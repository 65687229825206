import React, { FC } from 'react';
import styled from 'styled-components';
import Button, { ButtonSize, ButtonVariant } from 'components/Buttons/Button/Button';
import ButtonText from 'components/Buttons/ButtonText/ButtonText';
import configuration from 'configuration';
import getPaginationRange from 'helpers/getPaginationRange';
import PaginationCurrentDiv from './PaginationCurrentDiv';

const { ICON, SMALL } = ButtonSize;
const { DEFAULT } = ButtonVariant;

type PaginationIndexesProps = {
  currentPageIndex: number;
  pageCount: number;
  range?: number;
  gotoPage: (pageIndex: number) => void;
  size?: ButtonSize;
  variant?: ButtonVariant;
};

type GridProps = {
  range: number;
};

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(${({ range }) => range}, 1fr);
  gap: 0.25rem;
`;

const defaultRange = 11;

const PaginationIndexes: FC<PaginationIndexesProps> = ({
  currentPageIndex,
  gotoPage,
  pageCount,
  range = defaultRange,
  size = ICON,
  variant = DEFAULT,
}) => {
  const paginationRange = getPaginationRange(currentPageIndex, range, pageCount);

  const { isViewer } = configuration;
  // Demo list uses client side pagination; viewer uses serverside pagination
  // Their gotoPage functions are different (index vs currentPage)
  const indexAmendment = isViewer ? 0 : -1;
  return (
    <Grid range={paginationRange.length}>
      {paginationRange.map(pageIndex => {
        if (pageIndex === currentPageIndex)
          return (
            <PaginationCurrentDiv key={`paginationRange${currentPageIndex}${pageIndex}`}>
              {pageIndex}
            </PaginationCurrentDiv>
          );
        return (
          <Button
            key={`paginationRange${currentPageIndex}${pageIndex}`}
            onClick={() => gotoPage(pageIndex + indexAmendment)}
            size={size}
            variant={variant}
          >
            <ButtonText size={SMALL}>{pageIndex}</ButtonText>
          </Button>
        );
      })}
    </Grid>
  );
};

export default PaginationIndexes;
