import styled from 'styled-components';
import Z_INDEXES from 'constants/zIndexes';

const { OVERLAY } = Z_INDEXES;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  z-index: ${OVERLAY};
  background: rgba(153, 153, 153, 0.5);
  backdrop-filter: blur(10px);
`;

export default Overlay;
