import {
  Constants,
  CtbClassificationExternal,
  CtbPredictionSegmentSliceInfo,
  CtbPredictionSegmentSlicesCompleted,
  CtbSegmentationExternal,
  ViewType,
} from '@annaliseai/api-specifications';
import CustomErrors from 'enums/CustomErrors';
import createCtbSegmentSliceId from 'helpers/cornerstone/createCtbSegmentSliceId';
import createMapFromArrayValues from 'helpers/createMapFromArrayValues';
import CustomError from 'helpers/error/CustomError';
import uuid from 'helpers/uuid';
import { CtbViewerState } from 'slices/ctbViewerSlice';
import Finding, { Parent } from 'types/study/Finding';
import { SliceImage } from 'types/study/Image';
import { SegmentLocalisation } from 'types/study/Localisation';
import CtbFindingViewer, { CtbSegmentFindingView } from 'types/viewer/ctbViewer/CtbFindingViewer';
import getCtbRelevantLocalisations from './getCtbRelevantLocalisations';

const { AXIAL, CORONAL, SAGITTAL } = ViewType;
const { UNEXPECTED_BACKEND_ERROR } = CustomErrors;
const { VIEW_SLICES_MAX_COUNT } = Constants;

type SegmentSliceMap = {
  segmentationSlicesMap: Record<SliceImage['uuid'], SliceImage>;
  segmentLocalisationsMap: Record<SegmentLocalisation['uuid'], SegmentLocalisation>;
  segmentFindingView: CtbSegmentFindingView;
};

const getSegmentSlicesMap = (url: string, slicesInfo: CtbPredictionSegmentSliceInfo[]): SegmentSliceMap => {
  const segmentationSlicesMap: Record<SliceImage['uuid'], SliceImage> = {};
  const segmentLocalisationsMap: Record<SegmentLocalisation['uuid'], SegmentLocalisation> = {};

  const segmentSlices = slicesInfo.reduce(
    (acc: CtbSegmentFindingView['segmentSliceUuidsMap'], { index, bytesRange }) => {
      const image: SliceImage = {
        uuid: uuid('slice-image'),
        type: 'SLICE',
        url,
        bytesRange,
      };
      segmentationSlicesMap[image.uuid] = image;

      const segmentLocalisation: SegmentLocalisation = {
        uuid: uuid('segment-localisation'),
        type: 'SEGMENT',
        imageUuid: image.uuid,
      };
      segmentLocalisationsMap[segmentLocalisation.uuid] = segmentLocalisation;

      acc[index] = segmentLocalisation.uuid;
      return acc;
    },
    {},
  );

  const segmentFindingView: CtbSegmentFindingView = {
    uuid: uuid('ctb-segment-finding-view'),
    type: 'SEGMENT',
    segmentSliceUuidsMap: segmentSlices,
  };

  return {
    segmentationSlicesMap,
    segmentLocalisationsMap,
    segmentFindingView,
  };
};

const getSegmentSliceIdsMap = (viewData: SegmentSliceMap) => {
  const sliceIdsMap = {};
  Object.entries(viewData.segmentFindingView.segmentSliceUuidsMap).forEach(([index, localisationUuid]) => {
    const sliceUuid =
      localisationUuid && (viewData.segmentLocalisationsMap[localisationUuid].imageUuid as SliceImage['uuid']);
    const slice = sliceUuid && viewData.segmentationSlicesMap[sliceUuid];
    Object.assign(sliceIdsMap, { [index]: slice && createCtbSegmentSliceId(slice.url, slice.bytesRange) });
  });
  return sliceIdsMap;
};

type GetCtbSegmentations = (_: {
  segmentations: CtbSegmentationExternal[];
  ctbPredictionSegmentSlices: CtbPredictionSegmentSlicesCompleted[];
  relevantFindings: CtbClassificationExternal[];
  findingsMapByLabel: Record<string, Finding>;
  parentsMapByLabel: Record<string, Parent>;
}) => {
  segmentSliceIdsMap: CtbViewerState['segmentSliceIdsMap'];
  segmentationSlicesMap: Record<SliceImage['uuid'], SliceImage>;
  segmentLocalisationsMap: Record<SegmentLocalisation['uuid'], SegmentLocalisation>;
  segmentFindingViewsMap: Record<CtbSegmentFindingView['uuid'], CtbSegmentFindingView>;
  viewsMapByFindingLabel: Record<string, CtbFindingViewer['viewUuidsMap'] | undefined>;
};

const getCtbSegmentations: GetCtbSegmentations = ({
  segmentations,
  ctbPredictionSegmentSlices,
  relevantFindings,
  findingsMapByLabel,
  parentsMapByLabel,
}) => {
  const segmentSliceIdsMap: CtbViewerState['segmentSliceIdsMap'] = {};
  const segmentationSlicesMap: Record<SliceImage['uuid'], SliceImage> = {};
  const segmentLocalisationsMap: Record<SegmentLocalisation['uuid'], SegmentLocalisation> = {};
  const segmentFindingViewsMap: Record<CtbSegmentFindingView['uuid'], CtbSegmentFindingView> = {};
  const viewsMapByFindingLabel: Record<string, CtbFindingViewer['viewUuidsMap'] | undefined> = {};

  const relevantSegmentations = getCtbRelevantLocalisations(segmentations, relevantFindings);

  relevantSegmentations.forEach(relevantSegmentation => {
    const ctbPredictionSegmentSlice = ctbPredictionSegmentSlices.find(
      ({ segmentId }) => segmentId === relevantSegmentation.segmentId,
    );

    const finding = relevantFindings.find(({ label }) =>
      relevantSegmentation.relatedLabels.some(relatedLabel => relatedLabel === label),
    );

    if (!ctbPredictionSegmentSlice || !finding) {
      throw new CustomError(UNEXPECTED_BACKEND_ERROR);
    }

    const { views } = ctbPredictionSegmentSlice;

    // Axial segments should be flipped because we have flipped axial base images
    const axialData = getSegmentSlicesMap(
      views[AXIAL].url,
      views[AXIAL].slicesInfo.map(sliceInfo => ({
        ...sliceInfo,
        index: VIEW_SLICES_MAX_COUNT[AXIAL] - sliceInfo.index - 1,
      })),
    );
    const coronalData = getSegmentSlicesMap(views[CORONAL].url, views[CORONAL].slicesInfo);
    const sagittalData = getSegmentSlicesMap(views[SAGITTAL].url, views[SAGITTAL].slicesInfo);

    Object.assign(segmentationSlicesMap, {
      ...axialData.segmentationSlicesMap,
      ...coronalData.segmentationSlicesMap,
      ...sagittalData.segmentationSlicesMap,
    });
    Object.assign(segmentLocalisationsMap, {
      ...axialData.segmentLocalisationsMap,
      ...coronalData.segmentLocalisationsMap,
      ...sagittalData.segmentLocalisationsMap,
    });
    Object.assign(
      segmentFindingViewsMap,
      createMapFromArrayValues([
        axialData.segmentFindingView,
        coronalData.segmentFindingView,
        sagittalData.segmentFindingView,
      ]),
    );

    const viewsMap: CtbFindingViewer['viewUuidsMap'] = {
      [AXIAL]: axialData.segmentFindingView.uuid,
      [CORONAL]: coronalData.segmentFindingView.uuid,
      [SAGITTAL]: sagittalData.segmentFindingView.uuid,
    };

    const findingUuid =
      relevantSegmentation.relatedLabels.length > 1
        ? parentsMapByLabel[relevantSegmentation.label].uuid
        : findingsMapByLabel[finding.label].uuid;

    Object.assign(segmentSliceIdsMap, {
      [findingUuid]: {
        [AXIAL]: getSegmentSliceIdsMap(axialData),
        [CORONAL]: getSegmentSliceIdsMap(coronalData),
        [SAGITTAL]: getSegmentSliceIdsMap(sagittalData),
      },
    });

    const viewsMapLabel = relevantSegmentation.relatedLabels.length > 1 ? relevantSegmentation.label : finding.label;

    viewsMapByFindingLabel[viewsMapLabel] = viewsMap;
  });

  return {
    segmentSliceIdsMap,
    segmentationSlicesMap,
    segmentLocalisationsMap,
    segmentFindingViewsMap,
    viewsMapByFindingLabel,
  };
};

export default getCtbSegmentations;
