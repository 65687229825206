// For the record I wanted to call this file Cookeys!

enum CookieKeys {
  AGREE_TO_TERMS_CHECK_BOX = 'acceptedTermsAndConditions',
  AI_GDPR_5 = 'ai_gdpr_5',
  ANALYTICS_USER_ID = 'analyticsUserId',
  CLIENT_ID = 'clientId',
  CLIENT_SECRET = 'clientSecret',
  ONBOARDING_DONE = 'onboardingDone',
  UPLOADED_STUDIES = 'uploadedStudies',
  USER_INFORMATION_SUBMITTED = 'userInformationSubmitted',
  ACCESS_TOKEN = 'token',
  REFRESH_TOKEN = 'refreshToken',
  ID_TOKEN = 'idToken',
  COLUMN_SELECTION = 'columnSelection',
  RESULTS_PER_PAGE_SELECTION = 'resultsPerPageSelection',
  SEARCH_SELECTION = 'searchSelection',
}

export default CookieKeys;
