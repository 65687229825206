import { useDispatch, useSelector } from 'react-redux';
import { UserInput } from 'components/Form/Form.types';
import { isSubmitAction } from 'components/Form/Form.utils';
import { ID_TO_QUERY_PARAM } from 'components/WorklistTable/WorklistTable.constants';
import { STUDY_SORTBY, STUDY_SORT_DIRECTION } from 'constants/studySortBy';
import CookieKeys from 'enums/CookieKeys';
import Pages from 'enums/Pages';
import { storeUserSettings } from 'helpers/cookies/userSettingsHelper';
import { selectStudiesQuery } from 'selectors/studyList/selectStudyList';
import { StudyListState, studyListActions } from 'slices/studyListSlice';

const { PAGE_1 } = Pages;
const { SEARCH_SELECTION } = CookieKeys;

const { PRIORITY } = STUDY_SORTBY;
const { ASC, DESC } = STUDY_SORT_DIRECTION;

const { setQuery } = studyListActions;

export type UseStudiesSortType = {
  direction: StudyListState['query']['direction'];
  handleSort: (sortBy?: string) => (e: UserInput) => void;
  sortBy: StudyListState['query']['sortBy'];
};

const isDesc = (direction = '') => direction === DESC;
const isSortByPriority = (sortBy = '') => sortBy === PRIORITY;

const useStudiesSort = (): UseStudiesSortType => {
  const { sortBy, direction } = useSelector(selectStudiesQuery);
  const dispatch = useDispatch();

  const handleSort =
    (id = '') =>
    (e: UserInput) => {
      // Early-exit
      if (!isSubmitAction(e)) return;

      const newQuery: StudyListState['query'] = { sortBy, direction, page: PAGE_1 };
      // Translate the column ID to a valid query param
      const colQueryParam = ID_TO_QUERY_PARAM[id];

      if (colQueryParam === sortBy) {
        // Update the sort direction only, if we're already sorting by this column
        newQuery.direction = isDesc(direction) ? ASC : DESC;
      } else if (colQueryParam) {
        // Update the column and set the default direction
        newQuery.sortBy = colQueryParam;
        // Sort asc for initial priorty sort, else desc
        newQuery.direction = isSortByPriority(colQueryParam) ? ASC : DESC;
      }

      dispatch(setQuery(newQuery));
      storeUserSettings(SEARCH_SELECTION, newQuery);
    };

  return {
    direction,
    handleSort,
    sortBy,
  };
};

export default useStudiesSort;
