import { resultsListConfig, resultsListConfigHK } from 'components/WorklistTable/WorklistTable.constants';
import { ColumnIds } from 'components/WorklistTable/WorklistTypes';
import configuration from 'configuration';

/**
 * Determines whether a column visibility can be toggled.
 * If `false` is returned for a visible column, it signifies that the column is mandatory and cannot be hidden.
 * Note: temporary HK implementation, pending organisation configuration/admin portal
 * @param {ColumnIds} id - The ID of the column to check for toggling capability.
 * @returns {boolean} `true` if the column can be toggled (not mandatory), `false` if it is mandatory and cannot be hidden.
 */

export const maybeEnableHiding = (id: ColumnIds): boolean =>
  configuration.isBespokeSpecial ? !resultsListConfigHK[id].isMandatory : !resultsListConfig[id].isMandatory;

export default maybeEnableHiding;
