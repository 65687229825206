import { CxrImage, VisionStudyImageFinding } from '@annaliseai/api-specifications';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';
import uuid from 'helpers/uuid';
import Image, { WholeImage } from 'types/study/Image';
import UrlMap from 'types/UrlMap';
import compareCxrViewPositions from './compareCxrViewPositions';

const { UNEXPECTED_BACKEND_ERROR } = CustomErrors;

type GetCxrBaseImages = (_: { images: VisionStudyImageFinding[]; urlMap: UrlMap; imagesMetadata: CxrImage[] }) => {
  sourceImagesMapByImageInstanceId: Record<string, WholeImage>;
  targetImagesMapByImageInstanceId: Record<string, WholeImage>;
  defaultBaseImageUuid: Image['uuid'];
  defaultBaseImageThumbnailUuid: Image['uuid'];
  imagesMetadataMap: Record<Image['uuid'], CxrImage>;
};

const getCxrBaseImages: GetCxrBaseImages = ({ images, urlMap, imagesMetadata }) => {
  const sourceImagesMapByImageInstanceId: Record<string, WholeImage> = {};
  const targetImagesMapByImageInstanceId: Record<string, WholeImage> = {};
  const imagesMetadataMap: Record<Image['uuid'], CxrImage> = {};

  if (images.length === 0) {
    throw new CustomError(UNEXPECTED_BACKEND_ERROR);
  }

  const imageInstanceUids = images
    .slice()
    .sort((a, b) => compareCxrViewPositions(a.viewPosition, b.viewPosition, false))
    .map(({ imageInstanceUid }) => {
      const imageUrls = urlMap[imageInstanceUid];
      if (!imageUrls) {
        throw new CustomError(UNEXPECTED_BACKEND_ERROR);
      }

      const { sourceUrl, targetUrl } = imageUrls;

      const sourceImage: WholeImage = {
        uuid: uuid('whole-image'),
        type: 'WHOLE',
        url: sourceUrl,
      };

      const targetImage: WholeImage = {
        uuid: uuid('whole-image'),
        type: 'WHOLE',
        url: targetUrl,
      };

      const metadata = imagesMetadata.find(imageMetadata => imageMetadata.imageInstanceUid === imageInstanceUid);
      if (!metadata) {
        throw new CustomError(UNEXPECTED_BACKEND_ERROR);
      }

      imagesMetadataMap[sourceImage.uuid] = metadata;
      sourceImagesMapByImageInstanceId[imageInstanceUid] = sourceImage;
      targetImagesMapByImageInstanceId[imageInstanceUid] = targetImage;

      return imageInstanceUid;
    });

  const defaultBaseImageUuid = sourceImagesMapByImageInstanceId[imageInstanceUids[0]].uuid;
  const defaultBaseImageThumbnailUuid = targetImagesMapByImageInstanceId[imageInstanceUids[0]].uuid;

  return {
    sourceImagesMapByImageInstanceId,
    targetImagesMapByImageInstanceId,
    defaultBaseImageUuid,
    defaultBaseImageThumbnailUuid,
    imagesMetadataMap,
  };
};

export default getCxrBaseImages;
