import log from 'loglevel';
import client from 'api/client';
import endpoints from 'api/endpoints';

const getInstitutionNames = async (): Promise<string[]> => {
  const { distinctInstitutionNames } = endpoints.v1;
  const path = distinctInstitutionNames.getPath();
  const { data } = await client.get(path);
  log.debug('received getInstitutionNames response=', data);
  return data.values;
};

export default getInstitutionNames;
