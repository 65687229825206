import { v4 } from 'uuid';
import CookieKeys from 'enums/CookieKeys';
import getCookie from 'helpers/cookies/getCookie';
import setCookie from 'helpers/cookies/setCookie';
import { retrieveAiGdpr5 } from './aiGdpr5Helper';

const retrieveAnalyticsUserId = (): string => {
  let userId = getCookie(CookieKeys.ANALYTICS_USER_ID) as string;

  const cookieAccepted = !!retrieveAiGdpr5();

  if (!userId && cookieAccepted) {
    userId = v4();

    setCookie(CookieKeys.ANALYTICS_USER_ID, userId);
  }

  return userId;
};

export default retrieveAnalyticsUserId;
