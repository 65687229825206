import { PostFilterStudiesV2Res } from '@annaliseai/api-specifications';
import log from 'loglevel';
import client from 'api/client';
import endpoints from 'api/endpoints';

const postStudiesFilter = async (accessionNumber: string): Promise<PostFilterStudiesV2Res> => {
  const { studiesFilter } = endpoints.v2;
  const path = studiesFilter.getPath();
  const body = { accessionNumber };

  const { data } = await client.post(path, body);
  log.debug('received postStudiesFilter response=', data);

  return data;
};

export default postStudiesFilter;
