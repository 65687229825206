import { Status } from '@annaliseai/api-specifications';
import {
  ACC_COL,
  ACCESSION_NUMBER_TOOLTIP,
  AI_FINDINGS_COL,
  AI_FINDINGS_DEFAULT_TOOLTIP,
  AI_FINDINGS_SORTED_TOOLTIP,
  DATE_ANALYSED_COL,
  DATE_ANALYSED_DEFAULT_TOOLTIP,
  DATE_ANALYSED_SORTED_TOOLTIP,
  DATE_OF_STUDY_COL,
  DATE_OF_STUDY_DEFAULT_TOOLTIP,
  DATE_OF_STUDY_SORTED_TOOLTIP,
  DEPARTMENT_COL,
  DEPARTMENT_TOOLTIP,
  ID_TO_QUERY_PARAM,
  SORTABLE_COLS,
} from 'components/WorklistTable/WorklistTable.constants';
import { Columns } from 'components/WorklistTable/WorklistTypes';
import { STUDY_SORT_DIRECTION } from 'constants/studySortBy';
import IconDirection from 'enums/IconDirection';
import { Direction } from 'slices/studyListSlice';

const { IN_PROGRESS, PENDING } = Status;
const { UP, DOWN } = IconDirection;
const { ASC, DESC } = STUDY_SORT_DIRECTION;

// CXR does not have a processing status, so if !status, we should consider it to be processing
export const isProcessing = (status: Status | undefined): boolean => {
  return status === IN_PROGRESS || status === PENDING || !status;
};

export const isColumnSortable = (heading = ''): boolean => {
  return heading in SORTABLE_COLS && SORTABLE_COLS[heading];
};

export const isColumnSortActive = (id = '', sortBy?: string): boolean => {
  return id in ID_TO_QUERY_PARAM && ID_TO_QUERY_PARAM[id] === sortBy;
};

// Due to the backend sorting rank in asc order (1 being highest priority, etc..) we need to reverse the arrow
export const getArrowDirection = (id = '', direction?: Direction): IconDirection => {
  if (id === AI_FINDINGS_COL) {
    return direction === ASC ? DOWN : UP;
  } else {
    return direction === ASC ? UP : DOWN;
  }
};

export const getColumnTooltip = (id?: keyof Columns, direction?: Direction, isSortActive?: boolean): string => {
  if (!id) {
    return '';
  }
  const columnToolTips = {
    [ACC_COL]: ACCESSION_NUMBER_TOOLTIP,
    [AI_FINDINGS_COL]: isSortActive && direction === ASC ? AI_FINDINGS_SORTED_TOOLTIP : AI_FINDINGS_DEFAULT_TOOLTIP,
    [DATE_ANALYSED_COL]:
      isSortActive && direction === DESC ? DATE_ANALYSED_SORTED_TOOLTIP : DATE_ANALYSED_DEFAULT_TOOLTIP,
    [DATE_OF_STUDY_COL]:
      isSortActive && direction === DESC ? DATE_OF_STUDY_DEFAULT_TOOLTIP : DATE_OF_STUDY_SORTED_TOOLTIP,
    [DEPARTMENT_COL]: DEPARTMENT_TOOLTIP,
  };
  return columnToolTips[id];
};
