import cornerstoneTools from 'cornerstone-tools';
import ToolNames from 'enums/ToolNames';
import { CornerstoneToolsConfig } from 'types/cornerstone/CornerstoneToolsConfig';

const { PAN, WWWC, ZOOM, STACK_SCROLL, STACK_SCROLL_MOUSE_WHEEL } = ToolNames;
const { WwwcTool, PanTool, ZoomTool, StackScrollTool, StackScrollMouseWheelTool } = cornerstoneTools;

export const CXR_IMAGE_TOOLS: CornerstoneToolsConfig = {
  [WWWC]: { tool: WwwcTool, toolName: WWWC, defaultConfig: {} },
  [PAN]: { tool: PanTool, toolName: PAN, defaultConfig: {} },
  [ZOOM]: {
    tool: ZoomTool,
    toolName: ZOOM,
    defaultConfig: {
      configuration: {
        minScale: 0.05,
        maxScale: 20.0,
      },
    },
  },
};

export const CTB_IMAGE_TOOLS: CornerstoneToolsConfig = {
  [STACK_SCROLL]: {
    tool: StackScrollTool,
    toolName: STACK_SCROLL,
    defaultConfig: {},
  },
  [STACK_SCROLL_MOUSE_WHEEL]: {
    tool: StackScrollMouseWheelTool,
    toolName: STACK_SCROLL_MOUSE_WHEEL,
    defaultConfig: {},
  },
};
