import {
  CtbImageSlicesCompleted,
  CtbPredictionCompleted,
  CtbPredictionSegmentSlicesCompleted,
  StudyExternalV2Ctb,
} from '@annaliseai/api-specifications';
import filterValidFindings from 'epics/study/helpers/filterValidFindings';
import getFamiliesFromClassifications from 'epics/study/helpers/getFamiliesFromClassifications';
import getFindings from 'epics/study/helpers/getFindings';
import getFindingsMenu from 'epics/study/helpers/getFindingsMenu';
import getParents from 'epics/study/helpers/getParents';
import sortByDisplayOrder from 'epics/study/helpers/sortByDisplayOrder';
import createMapFromMapValues from 'helpers/createMapFromMapValues';
import { CtbViewerState } from 'slices/ctbViewerSlice';
import Finding, { Parent } from 'types/study/Finding';
import Image from 'types/study/Image';
import Localisation from 'types/study/Localisation';
import { CtbSeriesInfo } from 'types/study/Study';
import CtbFindingViewer, { CtbFindingView } from 'types/viewer/ctbViewer/CtbFindingViewer';
import FindingsMenu, { FindingItem, FindingsGroup } from 'types/viewer/FindingsMenu';
import getCtbFindingViewers from './getCtbFindingViewers';
import getCtbImageSliceViews from './getCtbImageSliceViews';
import getCtbLateralities from './getCtbLateralities';
import getCtbSegmentations from './getCtbSegmentations';

type GetCtbStudyNormalisedData = (_: {
  studyExternal: StudyExternalV2Ctb;
  prediction: CtbPredictionCompleted;
  ctbImageSlices: CtbImageSlicesCompleted;
  ctbPredictionSegmentSlices: CtbPredictionSegmentSlicesCompleted[];
}) => {
  imagesMap: Record<Image['uuid'], Image>;
  localisationsMap: Record<Localisation['uuid'], Localisation>;
  findingsMap: Record<Finding['uuid'], Finding>;
  parentsMap: Record<Parent['uuid'], Parent>;
  findingItemsMap: Record<FindingItem['uuid'], FindingItem>;
  findingsGroupsMap: Record<FindingsGroup['uuid'], FindingsGroup>;
  findingsMenu: FindingsMenu;
  findingViewsMap: Record<CtbFindingView['uuid'], CtbFindingView>;
  findingViewersMap: Record<Finding['uuid'], CtbFindingViewer>;
  seriesInfo: CtbSeriesInfo;
  imageSliceIdsMap: CtbViewerState['imageSliceIdsMap'];
  segmentSliceIdsMap: CtbViewerState['segmentSliceIdsMap'];
};

const getCtbStudyNormalisedData: GetCtbStudyNormalisedData = ({
  studyExternal,
  prediction,
  ctbImageSlices,
  ctbPredictionSegmentSlices,
}) => {
  const segmentations = prediction.result.segmentations;

  const relevantClassifications = prediction.result.classifications.relevant.map(classification => {
    return {
      ...classification,
      predictions: sortByDisplayOrder(filterValidFindings(classification.predictions)),
    };
  });
  const relevantClassificationsWithFamilies = getFamiliesFromClassifications(relevantClassifications, segmentations);

  const relevantFindings = relevantClassifications.flatMap(({ predictions }) => predictions);
  const irrelevantFindings = sortByDisplayOrder(filterValidFindings(prediction.result.classifications.irrelevant));

  const { findingsMapByLabel } = getFindings({
    findings: [...relevantFindings, ...irrelevantFindings],
  });

  const { imageSlicesMap, imageSliceIdsMap } = getCtbImageSliceViews({
    imageSliceViews: ctbImageSlices.views,
  });

  const { parentsMapByLabel } = getParents(segmentations);

  const {
    segmentSliceIdsMap,
    segmentationSlicesMap,
    segmentLocalisationsMap,
    segmentFindingViewsMap,
    viewsMapByFindingLabel: segmentationViewsMapByFindingLabel,
  } = getCtbSegmentations({
    segmentations: prediction.result.segmentations,
    ctbPredictionSegmentSlices,
    relevantFindings,
    findingsMapByLabel,
    parentsMapByLabel,
  });

  const {
    lateralityLocalisationsMap,
    lateralityFindingViewsMap,
    viewsMapByFindingLabel: lateralityViewsMapByFindingLabel,
  } = getCtbLateralities({
    lateralities: prediction.result.lateralities,
    relevantFindings,
  });

  const viewsMapByFindingLabel: Record<string, CtbFindingViewer['viewUuidsMap'] | undefined> = {
    ...segmentationViewsMapByFindingLabel,
    ...lateralityViewsMapByFindingLabel,
  };

  const { findingViewersMap } = getCtbFindingViewers({
    relevantFindings,
    viewsMapByFindingLabel,
    findingsMapByLabel,
    parentsMapByLabel,
  });

  const { findingItemsMap, findingsGroupsMap, findingsMenu } = getFindingsMenu({
    relevantClassifications: relevantClassificationsWithFamilies,
    irrelevantClassifications: irrelevantFindings,
    findingsMapByLabel,
    parentsMapByLabel,
  });

  const imagesMap: Record<Image['uuid'], Image> = {
    ...imageSlicesMap,
    ...segmentationSlicesMap,
  };
  const localisationsMap: Record<Localisation['uuid'], Localisation> = {
    ...segmentLocalisationsMap,
    ...lateralityLocalisationsMap,
  };
  const findingViewsMap: Record<CtbFindingView['uuid'], CtbFindingView> = {
    ...segmentFindingViewsMap,
    ...lateralityFindingViewsMap,
  };
  const findingsMap: Record<Finding['uuid'], Finding> = {
    ...createMapFromMapValues(findingsMapByLabel),
  };

  const parentsMap: Record<Parent['uuid'], Parent> = {
    ...createMapFromMapValues(parentsMapByLabel),
  };
  const seriesInfo: CtbSeriesInfo = {
    description: studyExternal.study.series.description,
    number: studyExternal.study.series.seriesNumber,
  };
  return {
    imagesMap,
    localisationsMap,
    parentsMap,
    findingsMap,
    findingItemsMap,
    findingsGroupsMap,
    findingsMenu,
    findingViewsMap,
    findingViewersMap,
    seriesInfo,
    imageSliceIdsMap,
    segmentSliceIdsMap,
  };
};

export default getCtbStudyNormalisedData;
