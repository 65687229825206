import { AuthScope } from '@annaliseai/api-specifications';
import { ReactElement, useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import tokenHelpers from 'helpers/cookies/tokenHelper';
import decodeAuthorizationPayload from 'helpers/decodeAuthorizationPayload';
import keycloak from 'keycloak';
import NoPermission from 'screens/NoPermission/NoPermission';

type PrivateRouteProps = RouteProps & { path: string; requiredScopes: AuthScope[] };

const PrivateRouteKeycloak = ({ children, ...rest }: PrivateRouteProps): ReactElement => {
  const isLoggedIn = keycloak.isLoggedIn();
  const { path, requiredScopes } = rest;
  const { idToken } = tokenHelpers.retrieveTokens();
  const payload = decodeAuthorizationPayload(idToken);

  const authorized = Array.isArray(payload.scopes)
    ? requiredScopes.every(scope => payload.scopes.includes(scope))
    : false;
  useEffect(() => {
    if (!isLoggedIn) keycloak.doLogin({ redirectUri: `${window.location.origin}${path}` });
  }, [isLoggedIn, path]);
  return (
    <Route
      {...rest}
      render={() => {
        if (isLoggedIn) {
          if (authorized) {
            return children as ReactElement;
          } else return <NoPermission />;
        }
      }}
    />
  );
};

export default PrivateRouteKeycloak;
