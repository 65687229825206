import { CxrPredictionCompleted, ImageWithBytes, StudyExternalV2Cxr } from '@annaliseai/api-specifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type UploadState = {
  imagesWithBytes: ImageWithBytes[];
  studyExternal?: StudyExternalV2Cxr;
  predictionResult?: CxrPredictionCompleted;
};

export const initialState: UploadState = {
  imagesWithBytes: [],
};

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    runUploadImagesEffect: (state, action: PayloadAction<ImageWithBytes[]>) => ({ ...state, uploads: action.payload }),
    reset: _ => ({ ...initialState }),
  },
});

export const uploadActions = uploadSlice.actions;
export type ResetType = ReturnType<typeof uploadActions.reset>;
export type UploadImagesType = ReturnType<typeof uploadActions.runUploadImagesEffect>;
export type UploadActionTypes = UploadImagesType | ResetType;
