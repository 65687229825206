const INPUTS = {
  // Search/filter form
  SEARCH_FORM_LABEL: 'Search & Filter',

  // Date type select
  ANY_TIME: 'Any Time',
  LAST_24_HOURS: 'Last 24 hrs',
  LAST_48_HOURS: 'Last 48 hrs',
  LAST_7_DAYS: 'Last 7 days',
  CUSTOM_DATES: 'Dates...',

  // Study type select
  STUDY_TYPE_ALL: 'Any Scan',
  STUDY_TYPE_LABEL: 'Scan',
  STUDY_TYPE_TITLE: 'Search by scan-type or modality.',

  // Hospital filter select
  INSTITUTION_NAME_ALL: 'Any Hospital',
  INSTITUTION_NAME_AVAILABLE_TITLE: 'Search by hospital (institution name).',
  INSTITUTION_NAME_UNAVAILABLE_TITLE: 'Hospital (Institution Name) information not available at your site.',
  INSTITUTION_NAME_LABEL: 'Hospital',

  // Requesting service select
  REQUESTING_SERVICES_ALL: 'Any Dept.',
  REQUESTING_SERVICES_AVAILABLE_TITLE: 'Search by department (requesting service).',
  REQUESTING_SERVICES_UNAVAILABLE_TITLE: 'Department (Requesting Service) information not available at your site.',
  REQUESTING_SERVICES_LABEL: 'Requesting Service',

  // Search input
  SEARCH_LABEL: 'Search Term',
  SEARCH_PLACEHOLDER: 'Patient ID, Name, or ACC',
  SEARCH_TITLE: 'Search for a specific patient.',

  // Clear search button
  CLEAR_SEARCH_BUTTON_LABEL: 'Clear Search Term',
  CLEAR_SEARCH_BUTTON_TITLE: 'Clear.',

  // Date type select
  DATE_TYPE_LABEL: 'Date(s)',
  DATE_TYPE_TITLE: 'Select date ranges or Any Time to see all studies. Default: Last 24 hrs.',

  // Created after date
  CREATED_AFTER_INPUT_LABEL: 'Created After (Formatted Date)',
  CREATED_AFTER_LABEL: 'Created After',
  CREATED_AFTER_TITLE: 'Search from this date.',

  // Created until date
  CREATED_UNTIL_INPUT_LABEL: 'Created Until (Formatted Date)',
  CREATED_UNTIL_LABEL: 'Created Until',
  CREATED_UNTIL_TITLE: 'Search until this date (optional).',

  // Search button
  SEARCH_BUTTON_LABEL: 'Search',
  SEARCH_BUTTON_AVAILABLE_TITLE: 'Search to view studies matching these criteria.',
  SEARCH_BUTTON_UNAVAILABLE_TITLE: 'Enter criteria for your search.',

  // Reset button
  RESET_BUTTON_LABEL: 'Reset',
  RESET_BUTTON_TITLE: 'Reset to your saved filters.',

  // Save filters button
  SAVE_FILTERS_BUTTON_LABEL: 'Save filters',
  SAVE_FILTERS_BUTTON_TITLE: 'Save selection as default filters.',
  FILTERS_SAVED_BUTTON_LABEL: 'Filters saved',
  FILTERS_SAVED_BUTTON_TITLE: 'Selection saved as default filters.',
};

export default INPUTS;
