import { colors } from '@annaliseai/anna-design-tokens';
import { ReactElement } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { WHITE } = colors;
const { ICON_SAVE_SOLID } = ReactTestingLibraryDataProperties;

const SaveSolid = ({ size = 1, color = WHITE }: Icon): ReactElement => (
  <Container size={size} $testId={ICON_SAVE_SOLID}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M4.00002 2.39999C3.11637 2.39999 2.40002 3.11634 2.40002 3.99999V12C2.40002 12.8837 3.11637 13.6 4.00002 13.6L4.00002 9.19999C4.00002 8.53725 4.53728 7.99999 5.20002 7.99999H10.3549L11.2824 7.07252C11.9095 6.44542 12.8091 6.25688 13.6 6.50689V5.29705C13.6 4.8727 13.4315 4.46574 13.1314 4.16568L11.8343 2.86862C11.5343 2.56857 11.1273 2.39999 10.703 2.39999H10.4V5.19999C10.4 5.86274 9.86277 6.39999 9.20002 6.39999L6.00002 6.39999C5.33728 6.39999 4.80002 5.86274 4.80002 5.19999L4.80002 2.39999H4.00002ZM9.55493 8.79999L7.41887 10.9361C7.09108 11.2638 6.85854 11.6746 6.74611 12.1243L6.44653 13.3226C6.42317 13.416 6.40893 13.5087 6.40309 13.6H4.80002V9.19999C4.80002 8.97908 4.97911 8.79999 5.20002 8.79999H9.55493ZM5.60002 2.39999H9.60002V5.19999C9.60002 5.42091 9.42094 5.59999 9.20002 5.59999L6.00002 5.59999C5.77911 5.59999 5.60002 5.42091 5.60002 5.19999L5.60002 2.39999ZM11.8474 7.6382L7.98383 11.5017C7.75857 11.727 7.59876 12.0092 7.5215 12.3183L7.22192 13.5166C7.09164 14.0378 7.56367 14.5098 8.0848 14.3795L9.28312 14.0799C9.59218 14.0027 9.87442 13.8429 10.0997 13.6176L13.9632 9.75406C14.5475 9.16978 14.5475 8.22248 13.9632 7.6382C13.3789 7.05392 12.4316 7.05392 11.8474 7.6382Z"
        fill={color}
      />
    </svg>
  </Container>
);

export default SaveSolid;
