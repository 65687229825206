import endpoints, { Endpoint } from 'api/endpoints';

const {
  studyPrediction,
  imagesStatus,
  segmentsStatus,
  seriesBaseImages,
  ctbSegmentsStatus,
  ctbSegments,
  seriesPrediction,
} = endpoints.v1;
const { studiesFilter } = endpoints.v2;

const studyEndpoints: Endpoint[] = [
  studiesFilter,
  studyPrediction,
  imagesStatus,
  segmentsStatus,
  seriesBaseImages,
  ctbSegmentsStatus,
  ctbSegments,
  seriesPrediction,
];

export default studyEndpoints;
