import { StudyOverview } from '@annaliseai/api-specifications';
import dayjs from 'dayjs';
import configuration from 'configuration';
import TimeUnits from 'enums/TimeUnits';
import keycloak from 'keycloak';
import { retrieveCredential } from './cookies/credentialHelper';
import { retrieveUploadedStudies, storeUploadedStudies } from './cookies/uploadStudiesHelper';

const { DAYS, MINUTES } = TimeUnits;

export const RETAINING_WINDOW = 7;
export const MAX_FREE_STUDIES = 10;

export const getRemainingFreeStudies = (): number => {
  const studies = retrieveUploadedStudies();

  const count = MAX_FREE_STUDIES - studies.length;

  if (count < 0) {
    return 0;
  }

  return count;
};

export const cleanupUploadedStudies = (): void => {
  const studies = retrieveUploadedStudies();
  const updatedStudies = studies.reduce((nonExpiredStudies: StudyOverview[], studyOverview: StudyOverview) => {
    const { createdAt } = studyOverview;
    const aWeekAgo = dayjs(new Date()).add(-RETAINING_WINDOW, DAYS);
    if (dayjs(createdAt).isAfter(aWeekAgo)) {
      return [...nonExpiredStudies, studyOverview];
    }
    return nonExpiredStudies;
  }, []);
  if (studies.length !== updatedStudies.length) {
    storeUploadedStudies(updatedStudies);
  }
};

export const addUploadedStudy = (study: StudyOverview): void => {
  const studies = retrieveUploadedStudies();
  storeUploadedStudies([...studies, study]);
};

export const deltaInMinutesBetweenFirstAndLastStudy = (storedStudies: StudyOverview[]): number => {
  if (storedStudies.length < 2) return -1;
  const firstStudy = storedStudies[0] as StudyOverview;
  const lastStudy = storedStudies[storedStudies.length - 1] as StudyOverview;
  const lastStudyDate = lastStudy.createdAt as unknown as string; // Retrieving from cookies and types are not friends
  const firstStudyDate = firstStudy.createdAt as unknown as string; // Retrieving from cookies and types are not friends
  return dayjs(lastStudyDate).diff(dayjs(firstStudyDate), MINUTES);
};

export const getLastUploadedStudy = (): StudyOverview | undefined => retrieveUploadedStudies()?.pop();

export const isLoggedIn = (): boolean => {
  let isLoggedIn = false;

  if (configuration.isViewer) {
    isLoggedIn = keycloak.isLoggedIn();
  } else if (configuration.isDemo) {
    const { clientId, clientSecret } = retrieveCredential();
    isLoggedIn = Boolean(clientSecret && clientId);
  }

  return isLoggedIn;
};
