import { HttpSuccessResponse, PostStudyPredictReq } from '@annaliseai/api-specifications';
import log from 'loglevel';
import client from 'api/client';
import endpoints from 'api/endpoints';
import { postImagesStatus } from '.';

const postStudiesPredict = async (accessionNumber: string, imageIds: string[]): Promise<HttpSuccessResponse> => {
  const { studiesPredict } = endpoints.v1;
  const path = studiesPredict.getPath();

  const body: PostStudyPredictReq = {
    accessionNumber,
    triggeredAt: new Date(),
  };

  await postImagesStatus(accessionNumber, imageIds); // wait for all images to be uploaded
  const { data } = await client.post(path, body);
  log.debug('received postStudiesPredict response=', data);
  return data;
};

export default postStudiesPredict;
