import { CtbPredictionCompleted, CxrPredictionCompleted, Status } from '@annaliseai/api-specifications';
import {
  StudyExternalV2Ctb,
  StudyExternalV2Cxr,
} from '@annaliseai/api-specifications/build/definitions/core/StudyExternalV2';
import { from, interval, Observable, throwError } from 'rxjs';
import { exhaustMap, filter, first, map, mergeMap, timeoutWith } from 'rxjs/operators';
import { POLLING } from 'constants/durations';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';
import { isCtbStudyExternal, isCxrStudyExternal } from 'typeguards/studyExternal';
import getCxrPrediction from './getCxrPrediction';
import getErrorCode from './getErrorCode';
import postStudiesFilter from './postStudiesFilter';
import { getCtbPrediction } from './index';

const {
  UNEXPECTED_BACKEND_ERROR,
  STUDY_NOT_FOUND,
  UNEXPECTED_FRONTEND_ERROR,
  IN_PROGRESS: IN_PROGRESS_STATUS_ERROR,
  PENDING: PENDING_STATUS_ERROR,
} = CustomErrors;
const { COMPLETED, COMPLETED_ERROR, PENDING, IN_PROGRESS } = Status;
const { PERIOD, TIMEOUT } = POLLING;

const getCompletedStudy = (
  accessionNumber: string,
  studyInstanceUid: string,
  period = PERIOD,
  due = TIMEOUT,
): Observable<{
  result?: CxrPredictionCompleted | CtbPredictionCompleted;
  study?: StudyExternalV2Cxr | StudyExternalV2Ctb;
}> => {
  return from((async () => await postStudiesFilter(accessionNumber))()).pipe(
    filter(postStudiesFilterResponse => postStudiesFilterResponse.studies.length > 0),
    map(postStudiesFilterResponse => {
      const studyExternal = postStudiesFilterResponse.studies.filter(
        (studyExternal: { study: { studyInstanceUid: string } }) =>
          studyExternal.study.studyInstanceUid === studyInstanceUid,
      )[0];

      if (!studyExternal) {
        throw new CustomError(STUDY_NOT_FOUND);
      }
      return studyExternal;
    }),
    mergeMap(studyExternal => {
      return interval(period).pipe(
        exhaustMap(async () => {
          if (isCxrStudyExternal(studyExternal)) {
            return await getCxrPrediction(studyInstanceUid);
          } else if (isCtbStudyExternal(studyExternal)) {
            const {
              study: {
                series: { seriesInstanceUid },
              },
            } = studyExternal;
            return await getCtbPrediction(seriesInstanceUid);
          } else {
            throw new CustomError(UNEXPECTED_FRONTEND_ERROR);
          }
        }),

        map(predictions => {
          const { status } = predictions;
          if (status === COMPLETED_ERROR) throw new CustomError(getErrorCode(predictions.error.code));
          if (status === COMPLETED) {
            return {
              result: predictions,
              study: studyExternal,
            };
          }
          if (isCtbStudyExternal(studyExternal)) {
            switch (status) {
              case IN_PROGRESS:
                throw new CustomError(IN_PROGRESS_STATUS_ERROR);
              case PENDING:
                throw new CustomError(PENDING_STATUS_ERROR);
              default:
                return {};
            }
          }
          return {};
        }),
        first(predictions => predictions?.result?.status === COMPLETED),
        timeoutWith(due, throwError(new CustomError(UNEXPECTED_BACKEND_ERROR))),
      );
    }),
  );
};

export default getCompletedStudy;
