import { AboutModalSubheader, AboutContentWrapper } from './AboutModal.styles';
import { VERSION_TITLE, UDI_TITLE } from './constants';

// Temporarily use string for version and udi, also leaving these as not props for now
const VersionComponent = ({
  subheader,
  version,
  udi,
}: {
  subheader: string;
  version: string;
  udi: string;
}): JSX.Element => (
  <>
    <AboutModalSubheader>{subheader}</AboutModalSubheader>
    <AboutContentWrapper>
      {VERSION_TITLE} {version}
    </AboutContentWrapper>
    <AboutContentWrapper>
      {UDI_TITLE} {udi}
    </AboutContentWrapper>
  </>
);

export default VersionComponent;
