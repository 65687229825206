import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import history from 'browserHistory';
import { authSlice } from './authSlice';
import { ctbViewerSlice } from './ctbViewerSlice';
import { cxrViewerSlice } from './cxrViewerSlice';
import { errorSlice } from './errorSlice';
import { fileSlice } from './fileSlice';
import { findingsMenuSlice } from './findingsMenuSlice';
import { findingsSlice } from './findingsSlice';
import { imagesSlice } from './imagesSlice';
import { infoSlice } from './infoSlice';
import { localisationsSlice } from './localisationsSlice';
import { notificationSlice } from './notificationSlice';
import { parentsSlice } from './parentsSlice';
import { settingsSlice } from './settingsSlice';
import { studyListSlice } from './studyListSlice';
import { studySlice } from './studySlice';
import { uploadSlice } from './uploadSlice';
import { viewerSlice } from './viewerSlice';

const slicesMap = {
  auth: authSlice,
  ctbViewer: ctbViewerSlice,
  cxrViewer: cxrViewerSlice,
  error: errorSlice,
  file: fileSlice,
  findingsMenu: findingsMenuSlice,
  findings: findingsSlice,
  images: imagesSlice,
  info: infoSlice,
  localisations: localisationsSlice,
  notification: notificationSlice,
  parents: parentsSlice,
  settings: settingsSlice,
  studyList: studyListSlice,
  study: studySlice,
  upload: uploadSlice,
  viewer: viewerSlice,
} as const;

export type SlicesMap = typeof slicesMap;

type ReducersMap = {
  [K in keyof SlicesMap]: SlicesMap[K]['reducer'];
};

const reducers = Object.entries(slicesMap).reduce(
  (acc, [sliceName, { reducer }]) => ({
    ...acc,
    [sliceName]: reducer,
  }),
  {} as ReducersMap,
);

const rootReducer = combineReducers({
  router: connectRouter(history),
  ...reducers,
});

export default rootReducer;
