import { Direction } from 'slices/studyListSlice';

export const STUDY_SORTBY = {
  STUDY_UPDATED_DATE: 'studyUpdatedAt', // Date analysed column
  PRIORITY: 'rank', // Sorting on rank (instead of priority) because it's always a number
  STUDY_DATE: 'studyDate',
};

export const STUDY_SORT_DIRECTION: { [key: string]: Direction } = {
  ASC: 'asc',
  DESC: 'desc',
};
