import { SidebarStudyProps } from 'components/sidebar/Sidebar';
import {
  PatientDetails,
  PrimaryRow,
  SecondaryRow,
  StudyIdsContainer,
  TertiaryRow,
} from 'components/sidebar/Sidebar.styles';
import configuration from 'configuration';
import maybeTruncate from 'helpers/maybeTruncate';

export const PrimarySidebarSection = (props: SidebarStudyProps): JSX.Element => {
  const { isViewer, isDemo } = configuration;
  const {
    patientName,
    patientId,
    patientBirthDate,
    description,
    descriptionTitle,
    patientNameTitle,
    patientAgeSexTitle,
    patientDobTitle,
    pidTitle,
    patientAgeSex,
  } = props;

  if (isDemo) {
    return (
      <StudyIdsContainer>
        <PrimaryRow title={descriptionTitle}>{maybeTruncate(description, 100)}</PrimaryRow>
      </StudyIdsContainer>
    );
  }

  if (isViewer) {
    return (
      <StudyIdsContainer>
        <PatientDetails>
          <PrimaryRow title={patientNameTitle}>{maybeTruncate(patientName, 30)}</PrimaryRow>
          <PrimaryRow title={patientAgeSexTitle}>{patientAgeSex}</PrimaryRow>
        </PatientDetails>
        <PatientDetails>
          <TertiaryRow title={pidTitle}>PID: {maybeTruncate(patientId, 18)}</TertiaryRow>
          <TertiaryRow title={patientDobTitle}>{`DOB: ${patientBirthDate}`}</TertiaryRow>
        </PatientDetails>
      </StudyIdsContainer>
    );
  }

  return <></>;
};

export const SecondarySidebarSection = (props: SidebarStudyProps): JSX.Element => {
  const { isViewer, isDemo } = configuration;

  const { studyIdentifiers, descriptionTitle, studyDateTime, studyDateTimeTitle, description } = props;

  if (isDemo) {
    return (
      <StudyIdsContainer>
        <TertiaryRow title={studyIdentifiers || 'Accession number not available'}>
          {studyIdentifiers || '-'}
        </TertiaryRow>
      </StudyIdsContainer>
    );
  }

  if (isViewer) {
    return (
      <StudyIdsContainer>
        <SecondaryRow title={studyDateTimeTitle}>{studyDateTime}</SecondaryRow>
        <TertiaryRow title={descriptionTitle}>{description}</TertiaryRow>
        <TertiaryRow title={studyIdentifiers}>{studyIdentifiers}</TertiaryRow>
      </StudyIdsContainer>
    );
  }

  return <></>;
};
