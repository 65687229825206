import { OrganizationV4Settings } from '@annaliseai/api-specifications';
import client from 'api/client';
import endpoints from 'api/endpoints';
import { SettingsState } from 'slices/settingsSlice';

const getOrgDisplayData = async (): Promise<SettingsState['displayData']> => {
  const type = 'organization';
  const { settings } = endpoints.v1;
  const path = settings.getPath({ type });

  try {
    const {
      data: { data },
    } = await client.get(path);

    return (data as OrganizationV4Settings).displayData;
  } catch (error) {
    console.error('Failed to retrieve organisation configuration');
  }
};

export default getOrgDisplayData;
