export const BREAK_POINTS = {
  // MOVE AWAY FROM THESE
  HEADER_ICON_SHRINK_APP_WIDTH: 1780,
  SAMPLE_SCREEN_LARGE_MIN_WIDTH: 1921,
  IPAD_MINI_LENGTH: 1024,
  IPAD_MINI_BREADTH: 768,
  IPAD_11_BREADTH: 834,
  IPAD_PRO_LENGTH: 1366,
  SAMPLE_SCREEN_SMALL_MAX_WIDTH: 980,
  SMALLEST_HEIGHT: 584,
  SMALLEST_WIDTH: 920,
  // NEW BREAKPOINTS
  TABLET: 768,
  SMALL_DESKTOP: 1024,
  MEDIUM_DESKTOP: 1400,
  LARGE_DESKTOP: 1440,
};

const HEADER_HEIGHT = 4;
const CONFIDENCE_INDICATOR_HEIGHT = 2;
const CONFIDENCE_INDICATOR_MARGIN_BOTTOM = 0.5;
const DISCLAIMER_HEIGHT = 1;
const DISCLAIMER_MARGIN_BOTTOM = 1.5;
const SIDEBAR_WIDTH = 360;
const VIEWER_HORIZONTAL_PADDING = 0;
const IMAGE_CONTROL_WIDTH_CXR = 4;
const IMAGE_CONTROL_WIDTH_CTB = 6;
const LOCALISATION_TOGGLE_HEIGHT = 2;
const LOCALISATION_TOGGLE_MARGIN_BOTTOM = 0.25;
const LOCALISATION_TOGGLE_PADDING_TOP = 0.5;
const STUDY_CONTAINER_MARGIN_BOTTOM = 0.5;

const STUDY_CONTAINER_FIXED_HEIGHT =
  HEADER_HEIGHT +
  STUDY_CONTAINER_MARGIN_BOTTOM +
  CONFIDENCE_INDICATOR_HEIGHT +
  CONFIDENCE_INDICATOR_MARGIN_BOTTOM +
  DISCLAIMER_HEIGHT +
  DISCLAIMER_MARGIN_BOTTOM +
  LOCALISATION_TOGGLE_HEIGHT +
  LOCALISATION_TOGGLE_MARGIN_BOTTOM;

const FINDING_PANEL_SUBHEADING_HEIGHT = 1.688;

export const APP = {
  HEADER_HEIGHT,
  CONFIDENCE_INDICATOR_HEIGHT,
  DISCLAIMER_HEIGHT,
  PORTRAIT_DEVICE_MARGIN_TOP: 10.375,
  STUDY_CONTAINER_FIXED_HEIGHT,
  HELP_ICON_HEIGHT: 1.25,
  SIDEBAR_WIDTH,
  VIEWER_HORIZONTAL_PADDING,
  IMAGE_CONTROL_WIDTH_CXR,
  IMAGE_CONTROL_WIDTH_CTB,
  CONFIDENCE_INDICATOR_MARGIN_BOTTOM,
  DISCLAIMER_MARGIN_BOTTOM,
  LOCALISATION_TOGGLE_MARGIN_BOTTOM,
  LOCALISATION_TOGGLE_PADDING_TOP,
  STUDY_CONTAINER_MARGIN_BOTTOM,
  LOCALISATION_TOGGLE_HEIGHT,
  FINDING_PANEL_SUBHEADING_HEIGHT,
};

export const FONT_WEIGHTS = {
  REGULAR: 500,
  BOLD: 700,
};
