import Keys from 'enums/Keys';

const { ESCAPE_KEY, TAB_KEY } = Keys;

type KeyboardEventOnDiv = React.KeyboardEvent<HTMLDivElement>;

export const handleKeyDown = (
  onClose: () => void,
  previouslyFocusedElement: HTMLElement | null,
): ((event: KeyboardEventOnDiv) => void) => {
  return (event: KeyboardEventOnDiv): void => {
    if (event.key === ESCAPE_KEY) {
      previouslyFocusedElement?.focus();
      onClose();
    }
  };
};

export const trapFocusModal = (
  firstFocusableElementRef: HTMLElement | null,
  lastFocusableElementRef: HTMLElement | null,
  modalContainer: HTMLDivElement | null,
): ((event: KeyboardEventOnDiv) => void) => {
  const focusableElements = modalContainer?.querySelectorAll('a, button');

  if (focusableElements && focusableElements.length > 0) {
    firstFocusableElementRef = focusableElements[0] as HTMLElement;
    lastFocusableElementRef = focusableElements[focusableElements.length - 1] as HTMLElement;
    firstFocusableElementRef.focus();
  }

  const handleTabKey = (event: KeyboardEvent) => {
    if (event.key === TAB_KEY) {
      if (event.shiftKey && document.activeElement === firstFocusableElementRef) {
        event.preventDefault();
        lastFocusableElementRef?.focus();
      } else if (!event.shiftKey && document.activeElement === lastFocusableElementRef) {
        event.preventDefault();
        firstFocusableElementRef?.focus();
      }
    }
  };

  modalContainer?.addEventListener('keydown', handleTabKey);

  return () => {
    modalContainer?.removeEventListener('keydown', handleTabKey);
  };
};
