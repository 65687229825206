import { Image, ImageSchema, ImageWithBytes } from '@annaliseai/api-specifications';
import log from 'loglevel';
import getDisplayError from 'api/client/getDisplayError';
import dicomToBytesParserMapping from 'constants/dicomToBytesParserMapping';
import errorsMapping from 'constants/errorsMapping';
import TRANSFER_SYNTAX from 'constants/sessionStorageKeys';
import cornerstone from 'cornerstone-core';
import CornerstoneLoaderPrefixes from 'enums/CornerstoneLoaderPrefixes';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';
import isEmptyFile from 'helpers/file/isEmptyFile';
import randomId from 'helpers/random/randomId';
import { isCornerstoneWADOImage } from 'typeguards/cornerstone';
import isCustomError from 'typeguards/isCustomError';
import CornerstoneImageId from 'types/cornerstone/CornerstoneImageId';
import { ValidationResult } from 'types/ValidationResult';
import {
  getBitsAllocated,
  getBitsStored,
  getHighBit,
  getModality,
  getPhotometricInterpretation,
  getPixelSpacing,
  getSamplesPerPixel,
  getSopClassUid,
  getTransferSyntax,
} from './cornerstoneImageHelper';
import { createImageId } from './imageIdHelper';
import { loadImage } from './loadImageHelper';

const { UNEXPECTED_FRONTEND_ERROR, UNSUPPORTED_TRANSFER_SYNTAX, INCORRECT_MODALITY, UNSUPPORTED_DICOM_FILE } =
  CustomErrors;
const { DICOMFILE } = CornerstoneLoaderPrefixes;

const supportedModalityRegExp = /(CR|DX)/i;

const isTransferSyntaxSupported = (transferSyntax: string): boolean =>
  Object.keys(dicomToBytesParserMapping).some(supportedTransferSyntax => supportedTransferSyntax === transferSyntax);

const isModalitySupported = (modality: string): boolean => supportedModalityRegExp.test(modality);

const getImage = async (imageId: CornerstoneImageId): Promise<Image> => {
  const cornerstoneImage = await loadImage(imageId);

  if (!isCornerstoneWADOImage(cornerstoneImage)) {
    throw new CustomError(UNEXPECTED_FRONTEND_ERROR);
  }

  const { height, width, intercept, slope } = cornerstoneImage;

  const transferSyntax = getTransferSyntax(cornerstoneImage);

  sessionStorage.setItem(TRANSFER_SYNTAX, transferSyntax);

  if (!isTransferSyntaxSupported(transferSyntax)) {
    throw new CustomError(UNSUPPORTED_TRANSFER_SYNTAX);
  }

  const modality = getModality(cornerstoneImage);
  if (!isModalitySupported(modality)) {
    throw new CustomError(INCORRECT_MODALITY);
  }

  const rescaleIntercept = intercept || 0;
  const rescaleSlope = slope || 1;

  const image: Image = {
    sopClassUid: getSopClassUid(cornerstoneImage),
    bitsAllocated: getBitsAllocated(cornerstoneImage),
    bitsStored: getBitsStored(cornerstoneImage),
    height,
    width,
    samplesPerPixel: getSamplesPerPixel(cornerstoneImage),
    highBit: getHighBit(cornerstoneImage),
    imageInstanceUid: randomId(64),
    photometricInterpretation: getPhotometricInterpretation(cornerstoneImage),
    rescaleIntercept,
    rescaleSlope,
  };

  const pixelSpacing = getPixelSpacing(cornerstoneImage);
  if (pixelSpacing.length === 2) {
    image.pixelSpacing = pixelSpacing;
  }

  return image;
};

const getImageWithBytes = async (imageId: CornerstoneImageId): Promise<ImageWithBytes> => {
  const cornerstoneImage = await cornerstone.loadImage(imageId);

  const image: Image = await getImage(imageId);

  const transferSyntax = getTransferSyntax(cornerstoneImage);
  const bytesParser = dicomToBytesParserMapping[transferSyntax];

  if (!bytesParser) {
    throw new CustomError(UNSUPPORTED_TRANSFER_SYNTAX);
  }

  return {
    ...image,
    data: await bytesParser(cornerstoneImage),
  };
};

export const getImageWithBytesArray = async (files: File[]): Promise<ImageWithBytes[]> => {
  const promises: Promise<ImageWithBytes>[] = [];

  for (const index in files) {
    if (!isEmptyFile(files[index])) {
      const imageId = createImageId(DICOMFILE, index);
      promises.push(getImageWithBytes(imageId));
    }
  }

  return await Promise.all(promises);
};

export const validateDicom = async (imageId: CornerstoneImageId): Promise<ValidationResult<string>> => {
  const value = imageId;

  let image;
  try {
    image = await getImage(imageId);
  } catch (error) {
    log.error(JSON.stringify(error));
    return { error: getDisplayError(isCustomError(error) ? error.errorCode : ''), value };
  }

  const { error } = ImageSchema.validate(image);

  if (error) {
    return { error: errorsMapping[UNSUPPORTED_DICOM_FILE], value };
  }

  return { value };
};
