import dayjs from 'dayjs';

type ConvertDateAndTimeToDateTimeProps = {
  date?: string;
  fallback?: string;
  time?: string;
};

const convertDateAndTimeToDateTime = ({ date, fallback = '', time }: ConvertDateAndTimeToDateTimeProps): string => {
  if (date) {
    const dateTime = `${date}${time ? ` ${time}` : ''}`;
    if (!dayjs(dateTime).isValid()) {
      return fallback;
    }
    return dateTime;
  } else {
    return fallback;
  }
};

export default convertDateAndTimeToDateTime;
