import styled from 'styled-components';
import colors from 'constants/colors';

const { SECONDARY_CONTAINER, SECONDARY_ON, SECONDARY_INVERT, WHITE, WHITE_15 } = colors;

export const AboutContainer = styled.div.attrs({ role: 'button', focusable: true })`
  display: flex;
  border: none;
  line-height: 2em;
  align-items: left;
  color: ${WHITE};
  border-radius: 0.5em;
  opacity: 0.75;
  padding: 0.65em 1em;

  &:hover {
    color: ${WHITE};
    background-color: ${WHITE_15};
  }

  span {
    padding-left: 0.5em;
  }
`;

export const ModalTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
  text-align: left;
`;

export const ModalSubtitle = styled.h2`
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0.25em 0;
  text-align: left;
  color: ${SECONDARY_ON};
`;

export const AboutModalSubheader = styled.h2`
  font-size: 0.6rem;
  font-weight: 600;
  margin: 0.5em 0;
  text-align: left;
  color: ${SECONDARY_INVERT};
  text-transform: uppercase;
  padding-top: 0.75rem;
`;

export const AboutContentWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0.25em 0;
  text-align: left;
  color: ${WHITE};
`;

export const AboutModalInfoPanel = styled.div`
  background-color: ${SECONDARY_CONTAINER};
  padding: 0.75rem 1.125rem;
  margin-right: 1.5rem;
  margin-top: 1.25rem;
`;

export const AboutIcon = styled.span`
  margin-right: 0.5rem;
`;

export const AboutLink = styled.a`
  display: flex;
  align-items: center;
`;
