enum Pages {
  PAGE_1 = 1,
  PAGE_2 = 2,
  PAGE_3 = 3,
  PAGE_4 = 4,
  PAGE_5 = 5,
  PAGE_6 = 6,
  PAGE_7 = 7,
}

export default Pages;
