import styled from 'styled-components';

const Button = styled.button`
  padding: 0 1.5rem;
  color: white;
  border: 1px solid white;
  border-radius: 0.5em;
  height: 100%;
  outline: none;
  background-color: transparent;

  &:disabled {
    cursor: not-allowed;
  }
`;

export default Button;
