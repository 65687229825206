import {
  CtbGroupedRelevantClassificationExternal,
  CtbPredictionSegmentSlicesCompleted,
  CtbSegmentationExternal,
  CtbClassificationsExternal,
  CtbLaterality,
} from '@annaliseai/api-specifications';
import { CtbClassificationGroup, CtbFinding } from 'imageQueue/types';
import findCtbLaterality from './findCtbLaterality';
import findCtbSegment from './findCtbSegment';
import normaliseCtbKeyViewSlices from './normaliseCtbKeyViewSlices';

export interface SharedLocalisationItem {
  label: string;
  labelName: string;
  relatedLabels: string[];
  children: CtbFinding[];
}

const groupSharedLocalisationClassifications = ({
  sharedLocalisationClassifications,
  finding,
}: {
  sharedLocalisationClassifications: SharedLocalisationItem[];
  finding: CtbFinding;
}) => {
  let sharedLocalisationParentLabel;
  const sharedLocalisations = sharedLocalisationClassifications;
  sharedLocalisations.forEach(({ relatedLabels, label: sharedLocalisationLabel, children }: SharedLocalisationItem) => {
    // If there is only one relatedLabel, then we don't need to group anything
    if (relatedLabels.length > 1) {
      relatedLabels.forEach((relatedLabel: string) => {
        const { label: findingLabel } = finding;
        if (relatedLabel === findingLabel) {
          sharedLocalisationParentLabel = sharedLocalisationLabel;
          children.push(finding as CtbFinding);
        }
      });
    }
  });

  return { sharedLocalisationParentLabel };
};

export const getGroupedClassifications = ({
  classifications,
  segmentations,
  segments,
  lateralities,
}: {
  classifications: CtbClassificationsExternal;
  segmentations: CtbSegmentationExternal[];
  segments: CtbPredictionSegmentSlicesCompleted[];
  lateralities: CtbLaterality[];
}): {
  groupedClassifications: CtbClassificationGroup[];
  sharedLocalisationClassifications: SharedLocalisationItem[];
} => {
  const sharedLocalisationClassifications: SharedLocalisationItem[] = segmentations.map(segmentation => ({
    ...segmentation,
    children: [],
  }));
  const { relevant } = classifications;
  const groupedClassifications = (relevant?.map((classification: CtbGroupedRelevantClassificationExternal) => {
    const { predictions } = classification;
    return {
      ...classification,
      findings: predictions.map((ctbFinding: CtbFinding) => {
        const { label, keyViewSlices } = ctbFinding;
        const segment = findCtbSegment({
          segmentations,
          segmentsSlices: segments,
          finding: ctbFinding,
        });

        const laterality = findCtbLaterality({
          findingLabel: label,
          lateralities,
        });

        const finding = {
          ...ctbFinding,
          laterality,
          keyViewSlices: normaliseCtbKeyViewSlices(keyViewSlices),
          // Note(marco): Findings can have either a laterality or a segmentation, not both.
          // In the chance that it happens then the priority is to display only the laterality.
          segment: !laterality && segment,
        } as CtbFinding;

        const { sharedLocalisationParentLabel } = groupSharedLocalisationClassifications({
          sharedLocalisationClassifications,
          finding,
        });

        return {
          ...finding,
          sharedLocalisationParentLabel,
        };
      }),
    };
  }) || []) as CtbClassificationGroup[];
  return { groupedClassifications, sharedLocalisationClassifications };
};
