import {
  CxrImage,
  CxrPredictionCompleted,
  PostImageStatusRes,
  PostSegmentsStatusRes,
  StudyExternalV2Cxr,
} from '@annaliseai/api-specifications';
import filterValidFindings from 'epics/study/helpers/filterValidFindings';
import getFindings from 'epics/study/helpers/getFindings';
import getFindingsMenu from 'epics/study/helpers/getFindingsMenu';
import sortByDisplayOrder from 'epics/study/helpers/sortByDisplayOrder';
import createMapFromMapValues from 'helpers/createMapFromMapValues';
import { getUrlMap } from 'helpers/cxrImageHelper';
import Finding from 'types/study/Finding';
import Image from 'types/study/Image';
import Localisation from 'types/study/Localisation';
import { CxrStudyDescription } from 'types/study/Study';
import CxrFindingViewer, { CxrFindingView } from 'types/viewer/cxrViewer/CxrFindingViewer';
import FindingsMenu, { FindingItem, FindingsGroup } from 'types/viewer/FindingsMenu';
import getCxrBaseImages from './getCxrBaseImages';
import getCxrFindingViewers from './getCxrFindingViewers';
import getCxrLocalisations from './getCxrLocalisations';
import getCxrRelevantImages from './getCxrRelevantImages';

type GetCxrStudyNormalisedData = (_: {
  studyExternal: StudyExternalV2Cxr;
  prediction: CxrPredictionCompleted;
  postImageStatusRes: PostImageStatusRes;
  postSegmentsStatusRes: PostSegmentsStatusRes;
}) => {
  imagesMap: Record<Image['uuid'], Image>;
  localisationsMap: Record<Localisation['uuid'], Localisation>;
  findingsMap: Record<Finding['uuid'], Finding>;
  findingViewsMap: Record<CxrFindingView['uuid'], CxrFindingView>;
  findingViewersMap: Record<Finding['uuid'], CxrFindingViewer>;
  findingItemsMap: Record<FindingItem['uuid'], FindingItem>;
  findingsGroupsMap: Record<FindingsGroup['uuid'], FindingsGroup>;
  findingsMenu: FindingsMenu;
  defaultBaseImageUuid: Image['uuid'];
  defaultBaseImageThumbnailUuid: Image['uuid'];
  imagesMetadataMap: Record<Image['uuid'], CxrImage>;
  studyDescription: CxrStudyDescription;
};

const getCxrStudyNormalisedData: GetCxrStudyNormalisedData = ({
  studyExternal,
  prediction,
  postImageStatusRes,
  postSegmentsStatusRes,
}) => {
  const { study } = studyExternal;
  const { series, description: studyDescription = null } = study;

  const relevantClassifications = prediction.result.classifications.relevant.map(classification => {
    return {
      ...classification,
      findings: sortByDisplayOrder(filterValidFindings(classification.findings)),
    };
  });

  const relevantFindings = relevantClassifications.flatMap(({ findings }) => findings);
  const irrelevantFindings = sortByDisplayOrder(filterValidFindings(prediction.result.classifications.irrelevant));
  const relevantImages = getCxrRelevantImages(series);
  const urlMap = getUrlMap(postImageStatusRes);
  const {
    sourceImagesMapByImageInstanceId,
    targetImagesMapByImageInstanceId,
    defaultBaseImageUuid,
    defaultBaseImageThumbnailUuid,
    imagesMetadataMap,
  } = getCxrBaseImages({
    images: prediction.result.images,
    urlMap,
    imagesMetadata: relevantImages,
  });

  const { segmentImagesMap, localisationsMapBySegmentId } = getCxrLocalisations({
    images: prediction.result.images,
    relevantFindings,
    findingsSegments: postSegmentsStatusRes.findingsSegments,
  });

  const { findingsMapByLabel } = getFindings({
    findings: [...relevantFindings, ...irrelevantFindings],
  });

  const { findingViewsMap, findingViewersMap } = getCxrFindingViewers({
    images: prediction.result.images,
    relevantFindings,
    sourceImagesMapByImageInstanceId,
    targetImagesMapByImageInstanceId,
    localisationsMapBySegmentId,
    findingsMapByLabel,
  });

  const { findingItemsMap, findingsGroupsMap, findingsMenu } = getFindingsMenu({
    relevantClassifications,
    irrelevantClassifications: irrelevantFindings,
    findingsMapByLabel,
  });

  const imagesMap: Record<Image['uuid'], Image> = {
    ...segmentImagesMap,
    ...createMapFromMapValues(sourceImagesMapByImageInstanceId),
    ...createMapFromMapValues(targetImagesMapByImageInstanceId),
  };
  const localisationsMap: Record<Localisation['uuid'], Localisation> = {
    ...createMapFromMapValues(localisationsMapBySegmentId),
  };
  const findingsMap: Record<Finding['uuid'], Finding> = {
    ...createMapFromMapValues(findingsMapByLabel),
  };

  return {
    imagesMap,
    localisationsMap,
    findingsMap,
    findingViewsMap,
    findingViewersMap,
    findingItemsMap,
    findingsGroupsMap,
    findingsMenu,
    defaultBaseImageUuid,
    defaultBaseImageThumbnailUuid,
    imagesMetadataMap,
    studyDescription,
  };
};

export default getCxrStudyNormalisedData;
