import { getExtension, NO_EXTENSION } from 'helpers/fileHelper';

const extensionDicomRegExp = /^dcm$/i;

// DICOM standard does not require an extension, so we must allow files that have no extension,
// Cornerstone will catch them if they are not true DICOM
const isDicomFile = (file: File): boolean => {
  const hasNoExtension = getExtension(file) === NO_EXTENSION;
  return extensionDicomRegExp.test(getExtension(file)) || hasNoExtension;
};

export default isDicomFile;
