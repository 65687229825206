import { colors as annaColors } from '@annaliseai/anna-design-tokens';
import styled from 'styled-components';
import colors from 'constants/colors';

const { RED, WHITE_50, GREY_9 } = colors;
const { WHITE, PURPLE_1 } = annaColors;

export const ServiceCardWrapper = styled.button<{ active: boolean }>`
  background: ${props => (props.active ? PURPLE_1 : GREY_9)};
  width: 19rem;
  height: 7.5rem;
  border-radius: 0.5rem;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  &:focus {
    outline: 2px solid ${PURPLE_1};
    outline-offset: 2px;
  }
`;

export const ServiceHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const ServiceCardHeader = styled.h2`
  color: ${WHITE};
  text-align: left;
  font-family: Inter;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.125rem;
`;
export const ServiceCardSubHeader = styled.h3`
  width: 100%;
  text-align: left;
  color: ${WHITE_50};
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SystemStatusContainer = styled.div`
  justify-self: flex-end;
  bottom: 1rem;
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
`;

export const StatusIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const StatusIndicator = styled.div<{ $indicatorColor?: string }>`
  border-radius: 50%;
  background: ${props => props.$indicatorColor || RED};
  height: 1rem;
  width: 1rem;
`;

export const SubServiceCount = styled.p`
  color: ${WHITE_50};
  font-weight: 500;
`;
