import { AxiosRequestHeaders, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import queryString from 'query-string';
import configuration from 'configuration';
import studyEndpoints from 'constants/studyEndpoints';
import SigningHeaders from 'enums/SigningHeaders';
import { retrieveCredential } from 'helpers/cookies/credentialHelper';
import isSample from 'helpers/isSample';

const { isViewer } = configuration;

const {
  X_ANNALISE_AI_CLIENT_ID,
  X_ANNALISE_AI_CLIENT_SECRET,
  X_ANNALISE_AI_FORWARD_PUBLIC,
  X_ANNALISE_AI_FORWARD_SAMPLE,
  X_ANNALISE_AI_TRANSLATION,
} = SigningHeaders;

// Note: The difference between InternalAxiosRequestConfig & AxiosRequestConfig is that the former expects `headers`.
//       This is a requirement for the interceptors, though causes headaches with the various header types in axios.
//       See: https://github.com/axios/axios/issues/5967 & https://github.com/axios/axios/issues?q=is%3Aissue+is%3Aopen+header+types.

const addSigningHeaders = (requestConfig: AxiosRequestConfig): InternalAxiosRequestConfig => {
  const { REACT_APP_FORWARD_PUBLIC_ID, REACT_APP_FORWARD_SAMPLE_ID } = process.env;
  const { clientId, clientSecret } = retrieveCredential();
  const { headers, url = '' } = requestConfig;
  const { accessionNumber } = queryString.parse(window.location.search);
  const isStudyUrl = studyEndpoints.some(studyEndpoint => studyEndpoint.testMatchPath(url));
  const language = 'en-hk';

  let signingHeaders: Record<string, string | undefined>;

  if (clientId && clientSecret) {
    signingHeaders = {
      [X_ANNALISE_AI_CLIENT_ID]: clientId,
      [X_ANNALISE_AI_CLIENT_SECRET]: clientSecret,
      [X_ANNALISE_AI_TRANSLATION]: isViewer ? language : 'en',
    };
  } else if (isStudyUrl && typeof accessionNumber === 'string' && isSample(accessionNumber)) {
    signingHeaders = {
      [X_ANNALISE_AI_FORWARD_SAMPLE]: REACT_APP_FORWARD_SAMPLE_ID,
    };
  } else {
    signingHeaders = {
      [X_ANNALISE_AI_FORWARD_PUBLIC]: REACT_APP_FORWARD_PUBLIC_ID,
    };
  }

  requestConfig.headers = {
    ...headers,
    ...signingHeaders,
  } as AxiosRequestHeaders;

  return requestConfig as InternalAxiosRequestConfig;
};

export default addSigningHeaders;
