import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionTypes } from 'slices/ActionTypes';
import { ctbViewerActions } from 'slices/ctbViewerSlice';
import { cxrViewerActions } from 'slices/cxrViewerSlice';
import { findingsMenuActions } from 'slices/findingsMenuSlice';
import { findingsActions } from 'slices/findingsSlice';
import { imagesActions } from 'slices/imagesSlice';
import { localisationsActions } from 'slices/localisationsSlice';
import { parentsActions } from 'slices/parentsSlice';
import { ResetStudyType, studyActions } from 'slices/studySlice';

const { runResetStudyEffect } = studyActions;

export const resetStudyEpic: Epic<ActionTypes> = action$ =>
  action$.pipe(
    ofType<ActionTypes, ResetStudyType>(runResetStudyEffect.type),
    switchMap(_ =>
      of(
        ...[
          imagesActions,
          localisationsActions,
          findingsActions,
          findingsMenuActions,
          cxrViewerActions,
          ctbViewerActions,
          parentsActions,
        ].map(({ reset }) => reset()),
      ),
    ),
  );

export default resetStudyEpic;
