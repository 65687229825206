import { StudyType } from '@annaliseai/api-specifications';
import { RootState } from 'slices/RootState';
import { PatientInformation } from 'types/DisplayStudyInformation';
import Study, { StudyIds } from 'types/study/Study';

export const selectStudyType = (state: RootState): StudyType | undefined => state.study.study?.type;

export const selectStudyPatient = (state: RootState): PatientInformation | null => state.study.patientInformation;

export const selectStudyIds = (state: RootState): StudyIds | undefined => state.study.study?.ids;

export const selectStudyInformation = (state: RootState): Study | null => state.study.study;
