import { push } from 'connected-react-router';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import errorsMapping from 'constants/errorsMapping';
import modalityConfig from 'constants/viewerConfig';
import CustomErrors from 'enums/CustomErrors';
import Routes from 'enums/Routes';
import { isLoggedIn } from 'helpers/limitationHelper';
import { useMatomo } from 'matomo/react';
import { selectStudyType } from 'selectors/viewer/selectStudy';
import { errorActions } from 'slices/errorSlice';
import { studyActions } from 'slices/studySlice';
import { EventActions, EventCategories } from 'types/Matomo';

const { HOME } = Routes;
const { SHARED } = EventActions;
const { STUDY_LINK } = EventCategories;
const { SHARE_UNAVAILABLE } = CustomErrors;
const { setError } = errorActions;
const { runResetStudyEffect } = studyActions;

const useSidebarNav = (): {
  onShareClick: () => void;
  onNewStudyClick: () => void;
  onUploadsClick: (route: Routes) => void;
} => {
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const studyType = useSelector(selectStudyType);

  const onNewStudyClick = useCallback(() => {
    if (!studyType) {
      dispatch(push(HOME));
    } else {
      dispatch(push(modalityConfig[studyType].demoRoute));
      dispatch(runResetStudyEffect());
    }
  }, [dispatch, studyType]);

  const onShareClick = useCallback(() => {
    dispatch(setError(errorsMapping[SHARE_UNAVAILABLE]));
    if (!isLoggedIn()) {
      trackEvent({
        category: STUDY_LINK,
        action: SHARED,
      });
    }
  }, [dispatch, trackEvent]);

  const onUploadsClick = useCallback(
    (route: string) => {
      dispatch(push(route));
      dispatch(runResetStudyEffect());
    },
    [dispatch],
  );

  return useMemo(
    () => ({
      onNewStudyClick,
      onShareClick,
      onUploadsClick,
    }),
    [onNewStudyClick, onShareClick, onUploadsClick],
  );
};

export default useSidebarNav;
