import { RootState } from 'slices/RootState';
import CtbFindingViewer from 'types/viewer/ctbViewer/CtbFindingViewer';

const selectDefaultViews = (state: RootState): Partial<CtbFindingViewer> => {
  return {
    window: state.ctbViewer.activeFinding?.findingView?.window,
    keyView: state.ctbViewer.activeFinding?.findingView?.keyView,
    keyViewSlices: state.ctbViewer.activeFinding?.findingView?.keyViewSlices,
  };
};

export default selectDefaultViews;
