import { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { USER_LOGGED_IN } = ReactTestingLibraryDataProperties;

export const UserLoggedIn: FC<Icon> = ({ size = 1 }) => (
  <Container size={size} $testId={USER_LOGGED_IN}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 14V13C4.43216 13 3 11.4376 3 10V9.5C3 9.22386 3.22386 9 3.5 9H7.22427C7.48135 8.51612 7.94027 8.1516 8.5 8.03754V8H3.5C2.67157 8 2 8.67157 2 9.5V10C2 11.9714 3.85951 14 7 14ZM9.75 4.25C9.75 2.73122 8.51878 1.5 7 1.5C5.48122 1.5 4.25 2.73122 4.25 4.25C4.25 5.76878 5.48122 7 7 7C8.51878 7 9.75 5.76878 9.75 4.25ZM5.25 4.25C5.25 3.2835 6.0335 2.5 7 2.5C7.9665 2.5 8.75 3.2835 8.75 4.25C8.75 5.2165 7.9665 6 7 6C6.0335 6 5.25 5.2165 5.25 4.25ZM9.5 8V9H8.875C8.39175 9 8 9.40294 8 9.9V14.1C8 14.5971 8.39175 15 8.875 15H14.125C14.6082 15 15 14.5971 15 14.1V9.9C15 9.40294 14.6082 9 14.125 9H13.5V8C13.5 6.89543 12.6046 6 11.5 6C10.3954 6 9.5 6.89543 9.5 8ZM11.5 7C12.0523 7 12.5 7.44772 12.5 8V9H10.5V8C10.5 7.44772 10.9477 7 11.5 7ZM11.5 12.75C11.0858 12.75 10.75 12.4142 10.75 12C10.75 11.5858 11.0858 11.25 11.5 11.25C11.9142 11.25 12.25 11.5858 12.25 12C12.25 12.4142 11.9142 12.75 11.5 12.75Z"
        fill="#848484"
      />
    </svg>
  </Container>
);

export default UserLoggedIn;
