import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FileState = {
  files: File[];
  dicomFiles: File[];
  jpegFiles: File[];
};

export const initialState: FileState = {
  files: [],
  dicomFiles: [],
  jpegFiles: [],
};

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    runSetFilesEffect: (state, action: PayloadAction<File[]>) => ({ ...state, files: action.payload }),
    runSetDicomFilesEffect: (state, action: PayloadAction<File[]>) => ({ ...state, dicomFiles: action.payload }),
    runSetJpegFilesEffect: (state, action: PayloadAction<File[]>) => ({ ...state, jpegFiles: action.payload }),
  },
});

export const fileActions = fileSlice.actions;
export type SetFilesType = ReturnType<typeof fileActions.runSetFilesEffect>;
export type SetDicomFilesType = ReturnType<typeof fileActions.runSetDicomFilesEffect>;
export type SetJpegFilesType = ReturnType<typeof fileActions.runSetJpegFilesEffect>;
export type FileActionTypes = SetJpegFilesType | SetFilesType | SetDicomFilesType;
