import { GetCtbPredictionSegmentsStatusRes, Status } from '@annaliseai/api-specifications';
import client from 'api/client';
import endpoints from 'api/endpoints';

const getCtbSegmentsStatuses = async ({ predictionId }: { predictionId: string }): Promise<Status[]> => {
  const { ctbSegmentsStatus } = endpoints.v1;
  const path = ctbSegmentsStatus.getPath({ predictionId });

  const { data }: { data: GetCtbPredictionSegmentsStatusRes } = await client.get(path);

  return data.segmentsStatus.map(({ status }) => status);
};

export default getCtbSegmentsStatuses;
