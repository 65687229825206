import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { RESULTS_PER_PAGE_SELECTOR } = ReactTestingLibraryDataProperties;

export const RESULTS_PER_PAGE_SELECTOR_LABEL = 'Results per page selection';
export const RESULTS_PER_PAGE_TEXT = 'results per page';

export const RESULTS_PER_PAGE_SELECTOR_ATTRS = {
  'aria-label': RESULTS_PER_PAGE_SELECTOR_LABEL,
  'data-testid': RESULTS_PER_PAGE_SELECTOR,
};
