import styled, { css } from 'styled-components';
import { TableCellCss } from './sharedCss';

type TableCellProps = {
  align: 'left' | 'center' | 'right';
  skipEllipsis: boolean;
};

const TableCell = styled.td<TableCellProps>`
  ${TableCellCss};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  ${({ skipEllipsis }) =>
    skipEllipsis &&
    css`
      text-overflow: clip;
    `}
`;

export default TableCell;
