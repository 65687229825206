import {
  CtbPredictionSegmentSliceInfo,
  CtbClassificationExternal,
  CtbPredictionSegmentSlicesCompleted,
  CtbSegmentation,
} from '@annaliseai/api-specifications';
import { UICtbSegmentSlices } from 'imageQueue/types';
import normaliseCtbSegmentViews from './normaliseCtbSegmentViews';

interface FindCtbSegmentParams {
  segmentations: CtbSegmentation[];
  segmentsSlices: CtbPredictionSegmentSlicesCompleted[];
  finding: CtbClassificationExternal;
}

const findCtbSegment = ({
  segmentations,
  segmentsSlices,
  finding,
}: FindCtbSegmentParams): UICtbSegmentSlices | null => {
  const foundSegment = segmentations.find(segment => segment.relatedLabels.includes(finding.label));
  if (!foundSegment) {
    return null;
  }

  const foundSegmentSlices = segmentsSlices.find(
    segment => segment.segmentId === foundSegment.segmentId,
  ) as CtbPredictionSegmentSlicesCompleted;
  if (!foundSegmentSlices) {
    return null;
  }

  // Note(marco): If the segment exists but all its slices are not active
  // then the segment is technically not there.
  const hasAtLeastOneActiveSlice = Object.entries(foundSegmentSlices.views).some(entry =>
    (entry[1].slicesInfo as CtbPredictionSegmentSliceInfo[]).some(sliceInfo => sliceInfo.isActivated),
  );

  if (!hasAtLeastOneActiveSlice) {
    return null;
  }

  // We have a valid segment, we need to flatten the slice urls
  // and flip the Axial array.
  return {
    ...foundSegmentSlices,
    views: normaliseCtbSegmentViews(foundSegmentSlices.views),
  };
};

export default findCtbSegment;
