import { replace } from 'connected-react-router';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import UserInformationForm from 'components/UserInformation/UserInformation';
import { REDIRECT_URL_PARAM } from 'components/UserInformationCheck';
import Routes from 'enums/Routes';
import { storeUserInformationSubmitted } from 'helpers/cookies/userInformationHelper';
import { Container, BodyContainer } from 'layouts/ResponsiveContainers';

const { HOME } = Routes;

export const REDIRECT_URL_KEY = 'redirectUrl';

const UserInformationScreen: FC = () => {
  const dispatch = useDispatch();

  const handleRecordUserInformationSubmitted = (): void => {
    storeUserInformationSubmitted('true');
  };

  useEffect(() => {
    const sessionRedirectUrl = window.sessionStorage.getItem(REDIRECT_URL_KEY);

    const { search } = window.location;
    const redirectUrl = new URLSearchParams(search).get(REDIRECT_URL_PARAM);

    if (sessionRedirectUrl) {
      dispatch(replace(sessionRedirectUrl));
    } else if (redirectUrl) {
      sessionStorage.setItem(REDIRECT_URL_KEY, redirectUrl);
    } else {
      dispatch(replace(HOME));
    }

    return () => {
      sessionStorage.removeItem(REDIRECT_URL_KEY);
    };
  }, [dispatch]);

  return (
    <>
      <Container>
        <BodyContainer>
          <UserInformationForm onSubmitCallback={handleRecordUserInformationSubmitted} />
        </BodyContainer>
      </Container>
    </>
  );
};

export default UserInformationScreen;
