import configuration from 'configuration';
import retrieveAnalyticsUserId from 'helpers/cookies/analyticsUserHelper';
import { InstanceParams } from 'types/Matomo';

const getMatomoEnv = (): InstanceParams => {
  const userId = retrieveAnalyticsUserId();

  let matomoEnv = {
    disabled: !configuration.tracking.enabled,
    linkTracking: false,
    siteId: configuration.tracking?.siteId ? configuration.tracking.siteId : -1,
    urlBase: `${window.location.protocol}//${window.location.host}/`,
    userId,
  };

  const accountUrl = configuration.tracking.accountUrl;
  if (accountUrl) {
    const matomoUrlConfigs = {
      srcUrl: `${accountUrl}/matomo.js`,
      trackerUrl: `${accountUrl}/matomo.php`,
    };
    matomoEnv = { ...matomoEnv, ...matomoUrlConfigs };
  }

  return matomoEnv;
};

export default getMatomoEnv;
