import { CtbWindowingType } from '@annaliseai/api-specifications';
import { WindowingButton } from '@annaliseai/windowing-bar';

const { BRAIN, BONE, SOFT_TISSUE, STROKE, SUBDURAL } = CtbWindowingType;

const windowingButtons: WindowingButton[] = [
  {
    windowingType: BRAIN,
    text: 'Brain',
  },
  {
    windowingType: BONE,
    text: 'Bone',
  },
  {
    windowingType: STROKE,
    text: 'Stroke',
  },
  {
    windowingType: SUBDURAL,
    text: 'Subdural',
  },
  {
    windowingType: SOFT_TISSUE,
    text: 'Tissue',
  },
];

export default windowingButtons;
