import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Button, { ButtonSize } from 'components/Buttons/Button/Button';
import BackToStudies, { BackToStudiesProps } from 'components/viewer/BackToStudies';
import configuration from 'configuration';

const SMALL = 'sm';

const SidebarNavContainer = styled.div<{ isVisible: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.75em;
  ${({ isVisible }) =>
    isVisible
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CounterContainer = styled.div`
  font-size: 0.75rem;
  margin: 0 0.25em;
`;

export type SidebarNavProps = {
  page: number;
  totalPages: number;
  onPageChange: (nextPage: number) => void;
  backToStudiesProps: BackToStudiesProps;
};
const { ICON } = ButtonSize;
const SidebarNav: FC<SidebarNavProps> = ({ page, totalPages, onPageChange, backToStudiesProps }) => {
  const { isDemo, isViewer } = configuration;
  return (
    <SidebarNavContainer isVisible={isViewer || totalPages !== 0}>
      <BackToStudies {...backToStudiesProps} />
      {isDemo && (
        <PaginationContainer>
          <Button size={ICON} disabled={page <= 0} onClick={() => onPageChange(page - 1)}>
            <FontAwesomeIcon size={SMALL} icon={faArrowLeft} />
          </Button>
          <CounterContainer>
            {page + 1}/{totalPages}
          </CounterContainer>
          <Button size={ICON} disabled={page >= totalPages - 1} onClick={() => onPageChange(page + 1)}>
            <FontAwesomeIcon size={SMALL} icon={faArrowRight} />
          </Button>
        </PaginationContainer>
      )}
    </SidebarNavContainer>
  );
};

export default SidebarNav;
