import { useEffect, useReducer, useState } from 'react';
import client from 'api/client';
import endpoints from 'api/endpoints';
import Header from 'components/Header';
import ScreenVariant from 'enums/ScreenVariant';
import { InitialState, ACTIONS, ActionTypes, SuccessAction, StatusLastUpdate } from 'types/AdminPortal';
import { Container } from './AdminPortal.styles';
import Services from './components/Services/Services';

const { ADMIN } = ScreenVariant;

const initialState = {
  services: null,
  loading: true,
  error: false,
};

const servicesReducer = (state: InitialState, action: ActionTypes) => {
  const reducer = {
    [ACTIONS.CALL_API]: {
      ...state,
      loading: true,
    },
    [ACTIONS.SUCCESS]: {
      ...state,
      loading: false,
      services: (action as SuccessAction).data,
    },
    [ACTIONS.ERROR]: {
      ...state,
      loading: false,
      error: true,
    },
  };

  return reducer[action.type];
};

const AdminPortal = (): JSX.Element => {
  const [infoState, dispatchInfoState] = useReducer(servicesReducer, initialState);
  const [statusLastUpdate, setStatusLastUpdate] = useState<StatusLastUpdate>();

  useEffect(() => {
    const { SUCCESS, ERROR, CALL_API } = ACTIONS;
    const getInfo = async () => {
      try {
        const { status } = endpoints.v1;
        const { data } = await client.get(status.getPath());
        setStatusLastUpdate(data.statusLastUpdate);
        dispatchInfoState({ type: SUCCESS, data: data });
      } catch (error) {
        dispatchInfoState({ type: ERROR });
      }
    };
    dispatchInfoState({ type: CALL_API });
    getInfo();
  }, []);

  return (
    <Container>
      <Header screenVariant={ADMIN} />
      <Services infoState={infoState} statusLastUpdate={statusLastUpdate} />
    </Container>
  );
};

export default AdminPortal;
