enum ReactTestingLibraryDataProperties {
  ALERT_ICON = 'alert-icon',
  ANNA_LOGO = 'anna-logo',
  ANNA_LOGO_SOLID = 'anna-logo-solid',
  BADGE = 'badge',
  BRAIN = 'brain',
  BREADCRUMBS = 'breadcrumbs',
  CHECKBOX_CHECKED = 'checkbox-checked',
  CHECKBOX_UNCHECKED = 'checkbox-unchecked',
  CONFIDENCE_INDICATOR = 'confidence-indicator',
  CONFIDENCE_SCORE = 'confidence-indicator-score',
  CONFIDENCE_INDICATOR_INTERVAL = 'confidence-indicator-interval',
  CONFIDENCE_INTERVAL = 'confidence-interval',
  CONFIDENCE_PROBABILITY = 'confidence-probability',
  CONFIDENCE_THRESHOLD = 'confidence-threshold',
  CONFIDENCE_CONTAINER = 'confidence-container',
  CONTENT_CONTAINER = 'content-container',
  COOKIES_TOGGLE_CONTAINER = 'cookies-toggle-container',
  CTB_VIEWER_CONTAINER = 'ctb-viewer-container',
  CXR_VIEWER_CONTAINER = 'cxr-viewer-container',
  DICOM_FILE_VIEWER = 'dicom-file-viewer',
  DIVIDER = 'divider',
  DROPZONE = 'dropzone',
  DROPZONE_CONTAINER = 'dropzone_container',
  DROPZONE__INPUT = 'dropzone__input',
  DROPZONE__LOADER = 'dropzone__loader',
  ERROR_ID_CONTAINER = 'error__error-id-container',
  ERROR_PARAGRAPH = 'error__paragraph',
  ERROR_TITLE_CONTAINER = 'error__title-container',
  FILE_PLACEHOLDER = 'file-placeholder',
  FINDING = 'finding',
  FINDING_GROUP = 'finding-group',
  FINDING_INDICATOR = 'finding-indicator',
  FINDING_NAME_CONTAINER = 'finding-name-container',
  FINDINGS_COUNTER = 'findings-counter',
  FOOTER = 'footer',
  HEADER = 'header',
  ICON_ABOUT = 'about-icon',
  ICON_ABOUT_SOLID = 'about-solid-icon',
  ICON_ARROW = 'arrow-icon',
  ICON_ARROW_UP_DOWN = 'arrow-up-down-icon',
  ICON_BULLET_POINT = 'bullet-point',
  ICON_ONBOARDING_CONFIG = 'onboarding-config-icon',
  ICON_ONBOARDING_EXPLAIN = 'onboarding-explain-icon',
  ICON_ONBOARDING_SUBJECTIVE = 'onboarding-subjective-icon',
  ICON_CALENDAR = 'icon-calendar',
  ICON_COLUMNS = 'icon-columns',
  ICON_CHEVRON = 'chevron-icon',
  ICON_CHEVRON_PATH = 'chevron-icon__path',
  ICON_CHEVRON_SVG = 'chevron-icon__svg',
  ICON_CHEVRON_BOLD = 'chevron-bold-icon',
  ICON_CHEVRON_BOLD_PATH = 'chevron-bold-icon__path',
  ICON_CHEVRON_BOLD_SVG = 'chevron-bold-icon__svg',
  ICON_CHEVRON_CIRCLE = 'chevron-circle-icon',
  ICON_CHEVRON_CIRCLE_SOLID = 'chevron-circle-solid-icon',
  ICON_CONTRAST = 'contrast-icon',
  ICON_HELP = 'help-icon',
  ICON_HELP_SOLID = 'help-solid-icon',
  ICON_HOME_24_FILLED = 'home-24-filled',
  ICON_HOME_24_REGULAR = 'home-24-regular',
  ICON_LATERALITY_BILATERAL = 'laterality-bilateral-icon',
  ICON_LATERALITY_LEFT = 'laterality-left-icon',
  ICON_LATERALITY_RIGHT = 'laterality-right-icon',
  ICON_LOCALISATION_MULTI = 'localisation-multi-icon',
  ICON_LOCALISATION_NONE = 'localisation-none-icon',
  ICON_LOCALISATION_SINGLE = 'localisation-single-icon',
  ICON_PRIORITY = 'priority-icon',
  ICON_SAVE = 'icon-save',
  ICON_SAVE_SOLID = 'icon-save-solid',
  ICON_SEARCH = 'icon-search',
  ICON_SHARE = 'icon-share',
  ICON_SIGN_IN = 'sign-in-icon',
  ICON_SIGN_IN_SOLID = 'sign-in-solid-icon',
  ICON_SIGN_OUT = 'sign-out-icon',
  ICON_SIGN_OUT_SOLID = 'sign-out-solid-icon',
  ICON_STUDIES = 'studies-icon',
  ICON_STUDIES_SOLID = 'studies-solid-icon',
  ICON_TEXT_COLUMN = 'text-column-icon',
  ICON_TEXT_COLUMN_SOLID = 'text-column-icon-solid',
  ICON_UNDO = 'undo-icon',
  ICON_WARNING = 'warning-icon',
  ICON_WINDOW_SETTINGS = 'window-settings-icon',
  ICON_WINDOW_SETTINGS_SOLID = 'window-settings-solid-icon',
  ICON_DISMISS = 'dismiss-icon',
  ICON_PAPER = 'paper-icon',
  ICON_EXPAND = 'expand-icon',
  ICON_ISO_ALERT = 'iso-alert-icon',
  ICON_USER_GUIDE = 'user-guide-icon',
  IMAGE = 'image',
  IMAGE_CONTROLS = 'image-controls',
  IMAGE_CONTROLS__PAN = 'image-controls__pan',
  IMAGE_CONTROLS__RESET = 'image-controls__reset',
  IMAGE_CONTROLS__WINDOW = 'image-controls__window',
  IMAGE_CONTROLS__ZOOM = 'image-controls__zoom',
  IMAGE_FILE_VIEWER = 'image-file-viewer',
  IMAGE_STATUS__WINDOW_WIDTH = 'image-status__window-width',
  IMAGE_STATUS__WINDOW_CENTER = 'image-status__window-center',
  IMAGE_STATUS__ZOOM = 'image-status__zoom',
  LATERAL_SEGMENT = 'lateral-segment',
  LOADING_INDICATOR = 'loading-indicator',
  LOCALISATION_CONTAINER = 'localisation-container',
  LOGO = 'logo',
  LOCALISATION_TOGGLE = 'localisation-toggle',
  LOGO_CONTAINER = 'logo-container',
  LOGO_WRAPPER = 'logo-wrapper',
  LOGO_BREADCRUMBS = 'logo-breadcrumbs',
  LOGO_ADMIN = 'logo-admin',
  LOGO_DEMO = 'logo-demo',
  LOGO_VIEWER = 'logo-viewer',
  LOGO_RESULTS = 'logo-results',
  LUNGS = 'lungs',
  MENU = 'menu',
  MENU_ITEM = 'menu-item',
  MODAL_CONTAINER = 'modal__container',
  MODAL_OVERLAY = 'modal__overlay',
  MODALITY_THUMBNAIL_CARD_CONTAINER = 'modality-thumbnail-card-container',
  MODALITY_THUMBNAIL_CARD_IMAGE = 'modality-thumbnail-card-image',
  NAV_CONTAINER = 'nav-container',
  NO_PERMISSION = 'no-permission',
  NO_PRIORITY_CONTAINER = 'no-priority__container',
  NO_PRIORITY_TEXT = 'no-priority__text',
  ONBOARDING_CARD_CONTAINER = 'onboarding-card__container',
  ONBOARDING_SIDEBAR_CONTAINER = 'onboarding-sidebar__container',
  PAGINATION_CURRENT_DIV = 'pagination-current-div',
  PRIORITY_TAG = 'priority-tag',
  PROGRESS_BAR = 'progress-bar',
  PROGRESS_BAR__BAR = 'progress-bar__bar',
  RESULTS_PER_PAGE_SELECTOR = 'results-per-page-selector',
  SAMPLE = 'sample',
  SAMPLE__COUNT = 'sample__count',
  SEGMENT = 'segment',
  SIDEBAR = 'sidebar',
  SIDEBAR__HEADER = 'sidebar__header',
  SIDEBAR__STUDY_IDS = 'sidebar__study-ids',
  SIDEBAR__IRRELEVANT_FINDINGS = 'sidebar__irrelevant-findings',
  STUDY_RESULT = 'study_result',
  SUPPORT = 'support',
  SWAP = 'swap',
  SWAP_SOLID = 'swap-solid',
  TEXT_FIELD__INPUT = 'text-field__input',
  TRASH = 'trash',
  UPLOAD_INFORMATION = 'upload-information',
  UPLOAD_INFORMATION_ITEM = 'upload-information__item',
  UPLOAD_HINT = 'upload-hint',
  UPLOAD_VIEW = 'upload_view',
  UPLOADS_REMAINING_CONTAINER = 'uploads-remaining',
  USER = 'user',
  USER_LOGGED_IN = 'user-logged-in',
  USER_NAME_LOGGED_IN = 'username-logged-in',
  USER_INFORMATION_FORM = 'user-information-form',
  USER_SOLID = 'user-solid',
}

export default ReactTestingLibraryDataProperties;
