import styled from 'styled-components';

export const HelpContainer = styled.div.attrs({ role: 'button', focusable: true })`
  display: flex;
  border: none;
  line-height: 2em;
  align-items: center;
  color: white;
  border-radius: 0.5em;
  opacity: 0.75;
  padding: 0.65em 1em;

  &:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.15);
  }

  span {
    padding-left: 0.5em;
  }
`;

export const HelpText = styled.div`
  line-height: 0;
  margin-left: 0.25rem;
`;

export const HelpModalTitle = styled.div`
  font-size: 1.25em;
  padding-bottom: 0.5rem;
`;

export const HelpModalParagraph = styled.p`
  margin: 0.5em 0;
  text-align: center;
`;
