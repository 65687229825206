import dayjs from 'dayjs';
import configuration from 'configuration';
import DateFormats from 'enums/DateFormats';
import formatDateTime from './formatDateTime';
import DateTime from './types/DateTime';

const { FORMAT_D_MMM_YYYY, FORMAT_D_MM_YYYY_SLASHES } = DateFormats;

type GetDateInformationFromUtcDateProps = { dateTime: DateTime; displayFormat?: string; fallback?: string };

const getFormattedDateTimeInformation = ({
  dateTime,
  displayFormat,
  fallback = '',
}: GetDateInformationFromUtcDateProps): string => {
  if (!dayjs(dateTime).isValid()) {
    return fallback;
  }

  if (displayFormat) {
    return formatDateTime(dateTime, displayFormat);
  }
  const { isViewer } = configuration;
  // REVIEW the logic below after 1.1
  // Date format FORMAT_D_MM_YYYY_SLASHES_SLASHES is preferred for the viewer findings sidebar e.g. 12/12/2014
  // Date format FORMAT_D_MMM_YYYY is preferred for the demo findings sidebar e.g. 12th Dec YYYY
  return isViewer
    ? formatDateTime(dateTime, FORMAT_D_MM_YYYY_SLASHES(displayFormat))
    : formatDateTime(dateTime, FORMAT_D_MMM_YYYY(displayFormat));
};

export default getFormattedDateTimeInformation;
