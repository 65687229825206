import { CtbClassificationExternal } from '@annaliseai/api-specifications';
import { VIEW_SLICES_MAX_COUNT } from '@annaliseai/api-specifications/build/core/Constants';
import uuid from 'helpers/uuid';
import Finding, { Parent } from 'types/study/Finding';
import CtbFindingViewer from 'types/viewer/ctbViewer/CtbFindingViewer';

type GetCtbFindingViewers = (_: {
  relevantFindings: CtbClassificationExternal[];
  viewsMapByFindingLabel: Record<string, CtbFindingViewer['viewUuidsMap'] | undefined>;
  findingsMapByLabel: Record<string, Finding>;
  parentsMapByLabel: Record<string, Parent>;
}) => {
  findingViewersMap: Record<Finding['uuid'], CtbFindingViewer>;
};

const { AXIAL: MAX_AXIAL_SLICES } = VIEW_SLICES_MAX_COUNT;

const getCtbFindingViewers: GetCtbFindingViewers = ({
  relevantFindings,
  viewsMapByFindingLabel,
  findingsMapByLabel,
  parentsMapByLabel = {},
}) => {
  const findingViewersMap: Record<Finding['uuid'] | Parent['uuid'], CtbFindingViewer> = {};
  const parentsWithChildLabels: { label: string; relatedLabels: string[] }[] = [];

  Object.keys(viewsMapByFindingLabel).forEach(label => {
    parentsMapByLabel[label] &&
      parentsWithChildLabels.push({ label, relatedLabels: parentsMapByLabel[label]?.relatedLabels });
  });
  relevantFindings.forEach(({ label, defaultWindow, keyView, keyViewSlices }) => {
    const parentLabel = parentsWithChildLabels.find(parent => parent.relatedLabels.includes(label))?.label;
    const finding = {
      uuid: parentLabel ? parentsMapByLabel[parentLabel].uuid : findingsMapByLabel[label].uuid,
      label: parentLabel ? parentsMapByLabel[parentLabel].label : label,
    };
    const { AXIAL: AXIAL_SLICE_INDEX } = keyViewSlices;
    findingViewersMap[finding.uuid] = {
      uuid: uuid('ctb-finding-viewer'),
      window: defaultWindow,
      keyView,
      keyViewSlices: {
        ...keyViewSlices,
        // Axial indexes are reversed
        AXIAL: MAX_AXIAL_SLICES - AXIAL_SLICE_INDEX - 1,
      },
      viewUuidsMap: viewsMapByFindingLabel[finding.label] || null,
    };
  });

  return {
    findingViewersMap,
  };
};

export default getCtbFindingViewers;
