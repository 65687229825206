import { WORKER_MESSAGE } from 'imageQueue/constants';
import { FetchImagesInWebWorkerArgs, UpdateCachedImagesArgs } from 'imageQueue/types';

const { BASE, INIT, IS_COMPLETED, SEGMENT, UPDATE_UI } = WORKER_MESSAGE;

const initWebWorkerManager = () => {
  let worker: Worker;

  const getWorker = () => worker;

  const fetchImagesInWebWorker = ({ baseImageIdsByView, classifications }: FetchImagesInWebWorkerArgs) => {
    // clear previous worker and all event listeners
    worker?.terminate();

    // eslint-disable-next-line
    const cornerstone = require('cornerstone-core');

    // clear the previous cache during init, in
    // images have different expiry signatures each time the backend provides the image urls, therefore
    // keys stored in cornerstone cache will be slightly different each time the same study is reloaded
    cornerstone?.imageCache?.purgeCache();

    const workerInstance = new Worker(new URL('../fetchImagesInWebWorker.ts', import.meta.url));
    worker = workerInstance;

    // post initialisation message
    worker?.postMessage({
      baseImageIdsByView,
      classifications,
      taskType: INIT,
    });

    const handleEvent = (event: MessageEvent) => {
      if (!event) return;

      const { taskType, imageId, bytes } = event.data;

      // we need to pass in `pngBytes` for base images, as the cornerstone helper `png-loader` is expecting this: https://github.com/annaliseai/cornerstone-helpers/blob/main/%40annaliseai/png-loader/src/pngLoader.ts
      const options = taskType === BASE ? { pngBytes: bytes } : { bytes };

      if (taskType === BASE || taskType === SEGMENT) {
        // cache the image in cornerstone
        cornerstone.loadAndCacheImage(imageId, options);
        // then tell the web worker that it's been cached
        worker?.postMessage({ taskType, imageId });
        return;
      }
    };

    worker?.addEventListener('message', handleEvent);

    return worker;
  };

  const updateCachedImages = ({
    setCachedBaseImages,
    isMounted,
    imageCache,
    baseImageIds,
  }: UpdateCachedImagesArgs): (() => void) => {
    const handleEvent = (event: MessageEvent) => {
      if (!event) return;

      const { taskType } = event.data;

      if (taskType === UPDATE_UI && isMounted) {
        setCachedBaseImages(baseImageIds.map(baseImageId => (imageCache[baseImageId] ? 1 : 0)));
      }
    };

    worker?.addEventListener('message', handleEvent);

    return () => {
      worker?.removeEventListener('message', handleEvent);
    };
  };

  // Note: this function is never initialised in the web-demo project, though it was left in for future usage
  // In black-widow this is used in the sidebar findgins to delay synthetic mouse event handlers (handleMouseEnter & handleMouseLeave)
  const updateIsImagesDownloadComplete = ({ setIsCompleted }: { setIsCompleted: (isCompleted: boolean) => void }) => {
    const getIsCompleted = (event: MessageEvent) => {
      const { taskType, isCompleted } = event.data;

      if (taskType !== IS_COMPLETED) {
        return;
      }

      if (isCompleted) {
        setIsCompleted(true);
        worker?.removeEventListener('message', getIsCompleted);
      }
    };

    worker?.addEventListener('message', getIsCompleted);

    return () => {
      worker?.removeEventListener('message', getIsCompleted);
    };
  };

  return {
    getWorker,
    fetchImagesInWebWorker,
    updateCachedImages,
    updateIsImagesDownloadComplete,
  };
};

export const { getWorker, fetchImagesInWebWorker, updateCachedImages, updateIsImagesDownloadComplete } =
  initWebWorkerManager();
