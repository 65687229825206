import { chevronLeft24Filled, chevronRight24Filled } from '@annaliseai/anna-icons';
import IconWrapper from '@annaliseai/icon-wrapper';
import React, { FC } from 'react';
import Button, { ButtonSize, ButtonVariant } from 'components/Buttons/Button/Button';
import ButtonText from 'components/Buttons/ButtonText/ButtonText';
import PaginationContainer from 'components/WorklistPagination/WorklistPagination.styles';
import PaginationIndexes from './PaginationIndexes';

const { ICON, SMALL } = ButtonSize;
const { DEFAULT } = ButtonVariant;

type PaginationProps = {
  canNextPage: boolean;
  canPreviousPage: boolean;
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
  nextPage: () => void;
  pageCount: number;
  pageIndex: number;
  previousPage: () => void;
};

const paginationButtonProps = {
  size: ICON,
  variant: DEFAULT,
};

const firstPageIndex = 0;
const incrementIndex = 1;

// Used for the private demo list, clientside pagination

const Pagination: FC<PaginationProps> = ({
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  pageCount,
  pageIndex,
  previousPage,
}) => (
  <PaginationContainer>
    <Button onClick={() => gotoPage(firstPageIndex)} disabled={!canPreviousPage} {...paginationButtonProps}>
      <ButtonText size={SMALL}>{'<<'}</ButtonText>
    </Button>
    <Button onClick={() => previousPage()} disabled={!canPreviousPage} title={'Previous'} {...paginationButtonProps}>
      <IconWrapper svg={chevronLeft24Filled} fill={'inherit'} />
    </Button>
    <PaginationIndexes currentPageIndex={pageIndex + incrementIndex} gotoPage={gotoPage} pageCount={pageCount} />
    <Button onClick={() => nextPage()} disabled={!canNextPage} title={'Next'} {...paginationButtonProps}>
      <IconWrapper svg={chevronRight24Filled} fill={'inherit'} />
    </Button>
    <Button onClick={() => gotoPage(pageCount - incrementIndex)} disabled={!canNextPage} {...paginationButtonProps}>
      <ButtonText size={SMALL}>{'>>'}</ButtonText>
    </Button>
  </PaginationContainer>
);

export default Pagination;
