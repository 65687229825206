import encodeToJp2000Base64 from 'helpers/cornerstone/encodeToJp2000Base64';
import getJp2000Base64 from 'helpers/cornerstone/getJp2000Base64';
import { CornerstoneWADOImage } from 'types/cornerstone/CornerstoneImage';
import {
  implicitLittleEndianTransferSyntax,
  jp2000LosslessTransferSyntax,
  jpegLosslessFirstOrderTransferSyntax,
  jpegLosslessTransferSyntax,
  explicitLittleEndianTransferSyntax,
} from './transferSyntaxes';

const dicomToBytesParserMapping: Record<
  string,
  ((image: CornerstoneWADOImage) => Promise<string> | string) | undefined
> = {
  [jp2000LosslessTransferSyntax]: getJp2000Base64,
  [explicitLittleEndianTransferSyntax]: encodeToJp2000Base64,
  [jpegLosslessFirstOrderTransferSyntax]: encodeToJp2000Base64,
  [jpegLosslessTransferSyntax]: encodeToJp2000Base64,
  [implicitLittleEndianTransferSyntax]: encodeToJp2000Base64,
};

export default dicomToBytesParserMapping;
