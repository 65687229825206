class UuidMapper {
  // disabled no-explicit-any eslint rule as the complexity to make this type checked is not worth it
  // this.data mutates to different data types across the class calling journey
  // this should be refactored to something more simple to use to achieve the same data transformation
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private data: any | undefined;

  /* Initializes the array of strings(uuids) to start mapping from */
  public from = (mapArray: string[] | undefined): UuidMapper => {
    if (mapArray) this.data = mapArray;
    return this;
  };

  /* Maps the existing data to `mapArray` parameter and saves the result as a new data */
  public mapFrom = (mapArray: string[]): UuidMapper => {
    const tmpData = this.data;
    this.data = mapArray;
    return this.mapTo(tmpData);
  };

  /* Maps the items of the data(uuids) to `mapTo` parameter */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public mapTo = <T>(mapTo: Record<string, any>): UuidMapper => {
    if (this.data) this.data = this.data.map((uuid: string) => mapTo[uuid]) as T;
    return this;
  };

  /* Maps the items of the data(uuids) to `mapKey of ` `mapTo` parameter */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public mapToKey = <T>(mapTo: Record<string, any>, mapKey: string): UuidMapper => {
    this.data = this.data.map((uuid: string) => mapTo[uuid][mapKey]) as T;
    return this;
  };

  /* Replaces uuids of `targetKey` of current data with `replacementData` */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public replaceUuidsWith = (replacementData: Record<string, any>, targetKey: string): UuidMapper => {
    if (replacementData && this.data)
      if (Array.isArray(this.data)) {
        this.data = this.data.map(item => ({
          ...item,
          [targetKey]: item[targetKey].map(
            (uuid: string) => replacementData[uuid],
          ) as (typeof replacementData)[keyof typeof replacementData],
        }));
      } else {
        this.data[targetKey] = this.data[targetKey].map((uuid: string) => replacementData[uuid]);
      }
    return this;
  };

  /* returns the current data */
  public result = (): typeof this.data => this.data;
}

export default UuidMapper;
