import { CtbSegmentationExternal } from '@annaliseai/api-specifications';
import uuid from 'helpers/uuid';
import { Parent } from 'types/study/Finding';

const getParents: (parents: CtbSegmentationExternal[]) => { parentsMapByLabel: Record<string, Parent> } = (
  parents: CtbSegmentationExternal[],
) => {
  const parentsMapByLabel: Record<string, Parent> = {};
  parents.forEach(item => {
    parentsMapByLabel[item.label] = {
      uuid: uuid('parent'),
      name: item.labelName,
      isParent: true,
      ...item,
    };
  });

  return {
    parentsMapByLabel,
  };
};

export default getParents;
