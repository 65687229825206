import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LoginStates from 'enums/LoginStates';

export type Login = {
  clientId: string;
  clientSecret: string;
  redirect?: string;
};

export type Logout = {
  redirect?: string;
};

type LoginFulfilled = Login;

export type AuthState = {
  loginState: LoginStates;
  loginFulfilled?: Login;
  login?: Login;
};

export const initialState: AuthState = {
  loginState: LoginStates.INITIAL,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    login: (state: AuthState, { payload }: PayloadAction<Login>): AuthState => ({
      ...state,
      login: payload,
      loginState: LoginStates.SENDING,
    }),
    logout: (_, { payload }: PayloadAction<Logout>) => {
      return {
        ...initialState,
        logout: payload,
      };
    },
    loginFailed: (state: AuthState): AuthState => ({
      ...state,
      loginState: LoginStates.FAILED,
    }),
    loginFulfilled: (state: AuthState, { payload }: PayloadAction<LoginFulfilled>): AuthState => ({
      ...state,
      loginFulfilled: payload,
      loginState: LoginStates.FULFILLED,
    }),
  },
});

export const authActions = authSlice.actions;
export type LoginType = ReturnType<typeof authActions.login>;
export type LoginFulfilledType = ReturnType<typeof authActions.loginFulfilled>;
export type LoginFailedType = ReturnType<typeof authActions.loginFailed>;
export type LogoutType = ReturnType<typeof authActions.logout>;
export type AuthActionTypes = LoginType | LoginFulfilledType | LoginFailedType | LogoutType;
