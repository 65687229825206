import { ViewType } from '@annaliseai/api-specifications';
import CornerstoneImageId from 'types/cornerstone/CornerstoneImageId';
import Finding, { Parent } from 'types/study/Finding';
import { FindingLocalisationType } from 'types/study/Localisation';

const { NO_LOCALISATION } = FindingLocalisationType;
const { AXIAL } = ViewType;

export type GetAdjustedLocalisationTypeProps = {
  segmentSliceIdsMap?: Record<Finding['uuid'], Record<ViewType, Record<number, CornerstoneImageId>>> | undefined;
  findingUuid?: Finding['uuid'] | Parent['uuid'] | null;
  localisation: FindingLocalisationType | null;
};

const getAdjustedLocalisationType = (
  adjustedLocalisationTypeProps: GetAdjustedLocalisationTypeProps,
): FindingLocalisationType | null => {
  const { segmentSliceIdsMap, findingUuid, localisation } = adjustedLocalisationTypeProps;
  const segmentViewsByFinding = segmentSliceIdsMap && findingUuid && segmentSliceIdsMap[findingUuid as Finding['uuid']];
  const segmentImageIds = segmentViewsByFinding && segmentViewsByFinding[AXIAL];

  // When AI data indicates localisation however no associated slice to view, display as per 'no localisation'
  const adjustedLocalisation =
    segmentImageIds && Object.keys(segmentImageIds).length === 0 ? NO_LOCALISATION : localisation;

  return adjustedLocalisation;
};

export default getAdjustedLocalisationType;
