import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Row, useAsyncDebounce } from 'react-table';

const SearchInput = styled.input`
  border: 0;
  background-color: #181a1b;
  border-color: #736b5e;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  &:focus {
    outline: none;
    box-shadow: rgba(25, 97, 157, 0.5) 0px 0px 0px 3px;
  }
`;

type SearchBoxProps = {
  preGlobalFilteredRows: Row[];
  setGlobalFilter: (filterValue: string) => void;
  globalFilter: string;
};

const SearchBox: FC<SearchBoxProps> = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <span>
      <label htmlFor="search">Search: </label>
      <SearchInput
        id="search"
        type="text"
        value={value || ''}
        onChange={handleChange}
        placeholder={`${count} records...`}
      />
    </span>
  );
};

export default SearchBox;
