import React from 'react';
import determineIndicatorColor from 'screens/AdminPortal/components/utils/determineIndicatorColor';
import {
  ServiceCardHeader,
  ServiceCardSubHeader,
  ServiceCardWrapper,
  ServiceHeaderContainer,
  StatusIndicator,
  StatusIndicatorContainer,
  SubServiceCount,
  SystemStatusContainer,
} from './ServiceCard.styles';

type ServiceCardProps = {
  onClick: () => void;
  name: string;
  version: string;
  status?: string;
  runningSubComponentCount: number;
  totalSubComponentCount: number;
  active?: boolean;
};
const ServiceCard = (props: ServiceCardProps): JSX.Element => {
  const { name, version, runningSubComponentCount, totalSubComponentCount, onClick, active = false } = props;
  const { color: indicatorColor, ariaLabel } = determineIndicatorColor({
    runningSubServiceCount: runningSubComponentCount,
    subServiceCount: totalSubComponentCount,
  });
  return (
    <ServiceCardWrapper active={active} onClick={onClick}>
      <ServiceHeaderContainer>
        <ServiceCardHeader>{name}</ServiceCardHeader>
        <ServiceCardSubHeader>{version}</ServiceCardSubHeader>
      </ServiceHeaderContainer>
      <SystemStatusContainer>
        <StatusIndicatorContainer>
          <StatusIndicator aria-label={ariaLabel} $indicatorColor={indicatorColor} />
          <p>System Status</p>
        </StatusIndicatorContainer>
        <SubServiceCount>{`${runningSubComponentCount}/${totalSubComponentCount}`}</SubServiceCount>
      </SystemStatusContainer>
    </ServiceCardWrapper>
  );
};

export default ServiceCard;
