import Routes from 'enums/Routes';

const { HOME, ADMIN, LIST, LOGIN, PROGRESS, STAGE, USER_INFORMATION, VIEWER, CXR_SAMPLE, CTB_SAMPLE } = Routes;

const matomoRouteMappings: Record<Routes, { name: string; checkOrigin: boolean; checkCookies: boolean }> = {
  [HOME]: { name: 'home', checkOrigin: false, checkCookies: true },
  [ADMIN]: { name: 'admin', checkOrigin: false, checkCookies: false },
  [LIST]: { name: 'list', checkOrigin: false, checkCookies: true },
  [LOGIN]: { name: 'login', checkOrigin: false, checkCookies: true },
  [PROGRESS]: { name: 'loading', checkOrigin: false, checkCookies: true },
  [STAGE]: { name: 'upload', checkOrigin: false, checkCookies: false },
  [USER_INFORMATION]: { name: 'details', checkOrigin: false, checkCookies: false },
  [VIEWER]: { name: 'findings', checkOrigin: true, checkCookies: false },
  [CXR_SAMPLE]: { name: 'cxr_sample', checkOrigin: false, checkCookies: true },
  [CTB_SAMPLE]: { name: 'ctb_sample', checkOrigin: false, checkCookies: true },
};

export default matomoRouteMappings;
