import { ViewPositionType } from '@annaliseai/api-specifications';

const { PA, LAT, AP } = ViewPositionType;
const frontViewOrderPosition = [PA, AP, LAT];
const latViewOrderPosition = [LAT, AP, PA];

const compareCxrViewPositions = (a: ViewPositionType, b: ViewPositionType, isLatViewByDefault: boolean): number => {
  const order = isLatViewByDefault ? latViewOrderPosition : frontViewOrderPosition;
  return order.indexOf(a) - order.indexOf(b);
};

export default compareCxrViewPositions;
