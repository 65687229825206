import { PostSegmentsStatusRes, Status } from '@annaliseai/api-specifications';
import log from 'loglevel';
import client from 'api/client';
import endpoints from 'api/endpoints';
import { POLLING } from 'constants/durations';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';

const { SEGMENT_STATUS_TIMEOUT } = CustomErrors;
const { COMPLETED_ERROR, COMPLETED } = Status;
const { PERIOD, TIMEOUT } = POLLING;

const postSegmentsStatus = async (
  accessionNumber: string,
  findingId: string,
  period = PERIOD,
  timeout = TIMEOUT,
): Promise<PostSegmentsStatusRes> =>
  new Promise((resolve, reject) => {
    if (period <= 0) return reject(new CustomError(SEGMENT_STATUS_TIMEOUT));

    let intervalCount = 0;

    const intervalNumber = setInterval(async () => {
      intervalCount += 1;
      if (intervalCount * period > timeout) {
        clearInterval(intervalNumber);
        return reject(new CustomError(SEGMENT_STATUS_TIMEOUT));
      }

      const { segmentsStatus } = endpoints.v1;
      const path = segmentsStatus.getPath();

      const body = {
        findingsIds: [findingId],
        accessionNumber,
      };

      try {
        const { data } = await client.post(path, body);

        if (!data) return;
        for (const segment of data.findingsSegments) {
          if (segment.status === COMPLETED_ERROR) {
            clearInterval(intervalNumber);
            return reject(new CustomError(SEGMENT_STATUS_TIMEOUT));
          }
          if (segment.status !== COMPLETED) return;
        }
        log.debug('received postSegmentsStatus response=', data);
        clearInterval(intervalNumber);
        return resolve(data);
      } catch (error) {
        clearInterval(intervalNumber);
        return reject(error);
      }
    }, period);
  });

export default postSegmentsStatus;
