// formatPatientAgeSex formats patient age and sex into the format displayed in
// the viewer findings sidebar and the worklist
// if data is missing, it will be replaced by the placeholder (without the '.' between)
// if both age and gender are missing only one placeholder will be used.

const formatPatientAgeSex = (age?: string, sex?: string, placeholder = ''): string => {
  if (age && sex) {
    return `${age}. ${sex}`;
  }
  if (age) {
    return `${age} ${placeholder}`;
  }
  if (sex) {
    return `${placeholder} ${sex}`;
  }
  return placeholder;
};

export default formatPatientAgeSex;
