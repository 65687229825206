import { bytesToBase64 } from 'byte-base64';
import log from 'loglevel';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';
import { CornerstoneWADOImage } from 'types/cornerstone/CornerstoneImage';

const { FAILED_TO_READ_IMAGE_FILE } = CustomErrors;

const getJp2000Base64 = ({ data }: CornerstoneWADOImage): string => {
  try {
    const bytes = cornerstoneWADOImageLoader.wadouri.getEncapsulatedImageFrame(data, 0);

    return bytesToBase64(bytes);
  } catch (error) {
    log.error(FAILED_TO_READ_IMAGE_FILE, error);
    throw new CustomError(FAILED_TO_READ_IMAGE_FILE);
  }
};

export default getJp2000Base64;
