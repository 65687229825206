import { RootState } from 'slices/RootState';
import Localisation from 'types/study/Localisation';
import Uuid from 'types/Uuid';

const selectLocalisationsMap = (
  state: RootState,
): Record<Uuid<'segment-localisation'> | Uuid<'laterality-localisation'>, Localisation> =>
  state.localisations.localisationsMap;

export default selectLocalisationsMap;
