import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InfoState } from './types/InfoState';

export const initialState: InfoState = {};

export const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    fetchVersions: state => ({
      ...state,
    }),
    setVersions: (state, { payload }: PayloadAction<InfoState['versions']>) => ({
      ...state,
      versions: payload,
    }),
    resetVersions: state => ({
      ...state,
      versions: undefined,
    }),
  },
});

export const infoActions = infoSlice.actions;
export type FetchInfoType = ReturnType<typeof infoActions.fetchVersions>;
export type SetInfoType = ReturnType<typeof infoActions.setVersions>;
export type ResetInfoType = ReturnType<typeof infoActions.resetVersions>;
