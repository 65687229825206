import Sample from 'types/Sample';

const ctbSamples: Sample[] = [
  {
    accessionNumber: '10.10856',
    description: '63Y, F. Worsening headache over several days.',
    imageUrl: '/static/samples/brain/10.10856.jpeg',
    studyInstanceUid: '1.2.36.1.2001.1005.78.70.104103660459752824',
  },
  {
    accessionNumber: '10.10825',
    description: '49Y, F. 2 weeks of headaches exacerbated by valsalva and cough.',
    imageUrl: '/static/samples/brain/10.10825.jpeg',
    studyInstanceUid: '1.2.826.0.1.3680043.8.498.46080028809830242123219706429138197812',
  },
  {
    accessionNumber: '10.10827',
    description: '73Y, F. Painless proptosis of left eye.',
    imageUrl: '/static/samples/brain/10.10827.jpeg',
    studyInstanceUid: '1.2.826.0.1.3680043.8.498.79186959701633197776534733593203797379',
  },
  {
    accessionNumber: '10.20101',
    description: '53Y, F. Multiple syncope attacks. Bruised left orbit. Rule out bleed.',
    imageUrl: '/static/samples/brain/10.20101.jpeg',
    studyInstanceUid: '1.2.36.1.2001.1005.78.70.352357133546432321',
  },
  {
    accessionNumber: '10.20102',
    description: '64Y, M. Right neck pain six days ago. Right sided facial droop. ?\u2060CVA. ?\u2060Dissection.',
    imageUrl: '/static/samples/brain/10.20102.jpeg',
    studyInstanceUid: '1.2.36.1.2001.1005.78.70.130470108116833905',
  },
  {
    accessionNumber: '10.10831',
    description: '51Y, F. Occipital headache, neck pain and vomiting.',
    imageUrl: '/static/samples/brain/10.10831.jpeg',
    studyInstanceUid: '1.2.826.0.1.3680043.8.498.21723590128103702125674110569008704081',
  },
  {
    accessionNumber: '10.10841',
    description: '72Y, F. Dysphasia. No weakness. No dysarthria.',
    imageUrl: '/static/samples/brain/10.10841.jpeg',
    studyInstanceUid: '1.2.826.0.1.3680043.8.498.71417730992622073094667424090386134619',
  },
  {
    accessionNumber: '10.10848',
    description: '58Y, M. Left sixth nerve palsy.',
    imageUrl: '/static/samples/brain/10.10848.jpeg',
    studyInstanceUid: '1.2.826.0.1.3680043.8.498.37644147104280684519070015511684931884',
  },
  {
    accessionNumber: '10.10851',
    description: '29Y, M. Sudden onset headache five days ago. Photophobia.',
    imageUrl: '/static/samples/brain/10.10851.jpeg',
    studyInstanceUid: '1.2.826.0.1.3680043.8.498.23473543256610536696225701529074734920',
  },
];

export default ctbSamples;
