import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Error from 'components/Error';
import { errorActions } from 'slices/errorSlice';
import { RootState } from 'slices/RootState';
import Modal from './Modal';

const ErrorModal: FC = _ => {
  const dispatch = useDispatch();
  const { error } = useSelector(({ error }: RootState) => error);

  const onClose = () => {
    dispatch(errorActions.resetError());
  };

  return (
    <Modal onClose={onClose} show={error ? true : false}>
      {error && <Error {...error} />}
    </Modal>
  );
};

export default ErrorModal;
