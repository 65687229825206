import cornerstone, { Image, ImageLoadObject } from 'cornerstone-core';
import { decode, DecodedPng } from 'fast-png';
import { PNG16_PREFIX } from './constants';

//
// This is a cornerstone image loader for PNG Images
//

const calculateSizeInBytes = (imageData: DecodedPng): number => {
  const depthMultiplier = imageData.depth / 8;
  return imageData.width * imageData.height * depthMultiplier;
};

const loadPngImage = (imageId: string, imageData: DecodedPng): Promise<Partial<Image>> => {
  let min = 65535;
  let max = 0;
  for (let i = 0; i < imageData.data.length; i++) {
    min = Math.min(min, imageData.data[i]);
    max = Math.max(max, imageData.data[i]);
  }
  // TODO currently is only returning a Partial<Image>
  // Might want to refactor to return a full cornerstone image in future
  const image = {
    imageId: imageId,
    minPixelValue: min,
    maxPixelValue: max,
    slope: 1,
    intercept: 0,
    windowWidth: 0,
    windowCenter: 0,
    render: cornerstone.renderGrayscaleImage,
    getPixelData: () => imageData.data as Uint8Array | Uint16Array,
    rows: imageData.height,
    columns: imageData.width,
    height: imageData.height,
    width: imageData.width,
    color: false,
    rgba: false,
    columnPixelSpacing: undefined,
    rowPixelSpacing: undefined,
    invert: false,
    sizeInBytes: calculateSizeInBytes(imageData),
  };

  return new Promise(resolve => resolve(image));
};

// Loads an image given a url to an image
const pngLoader = (imageId: string, { pngBytes }: { pngBytes?: ArrayBuffer } = {}): ImageLoadObject => {
  const promise = new Promise<Partial<Image>>((resolve, reject) => {
    (async () => {
      try {
        let pngImage;

        // cornerstone-tools doesn't pass the options object containing pngBytes so we'll handle that case here
        if (!pngBytes) {
          const imageData = imageId.replace(PNG16_PREFIX, '');
          const [rangeStart, rangeEnd, imageUrl] = imageData.split('|');
          pngImage = await fetch(imageUrl, {
            headers: {
              Range: `bytes=${rangeStart}-${rangeEnd}`,
              // Note(marco): This will prevent the browser from optimising the requests.
              // When the url is the same the browser will wait on the first one to finish
              // before starting the second one, and so on for the others.
              // https://stackoverflow.com/questions/27513994/chrome-stalls-when-making-multiple-requests-to-same-resource
              'Cache-Control': 'no-cache',
            },
          });
          try {
            pngBytes = await pngImage.arrayBuffer();
          } catch (error) {
            console.error(error);
          }
        }

        if (pngBytes) {
          const imageData = decode(pngBytes);
          const imageObject = loadPngImage(imageId, imageData);

          resolve(imageObject);
        }
      } catch (error) {
        console.error('Unable to download image', error);
        reject(error);
      }
    })();
  });
  return {
    promise,
    cancelFn: () => undefined,
  };
};

export default pngLoader;
