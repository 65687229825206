import queryString from 'query-string';
import React, { FC, useEffect } from 'react';
import styled, { DataAttributes } from 'styled-components';
import CxrStudyResult from 'components/CxrStudyResult';
import configuration from 'configuration';
import { SAMPLE, UPLOAD } from 'constants/sampleOrUpload';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { retrieveAiGdpr5 } from 'helpers/cookies/aiGdpr5Helper';
import isSample from 'helpers/isSample';
import { isLoggedIn } from 'helpers/limitationHelper';
import { useMatomo } from 'matomo/react';
import { EventActions, EventCategories } from 'types/Matomo';
import ImageStatus from './ImageStatus';

const { STUDY } = EventCategories;
const { LOADED } = EventActions;
const { CXR_VIEWER_CONTAINER, STUDY_RESULT } = ReactTestingLibraryDataProperties;

const CxrViewerContainer = styled.div.attrs<DataAttributes>({ 'data-testid': CXR_VIEWER_CONTAINER })`
  height: 100%;
`;

const StudyResultContainer = styled.div.attrs<DataAttributes>({ 'data-testid': STUDY_RESULT })`
  height: 100%;
`;

const ImageStatusContainer = styled.div`
  position: absolute;
  left: 2rem;
  bottom: 1.5rem;
`;

const CxrViewer: FC = () => {
  const { shouldShowWPZ } = configuration;
  const { accessionNumber } = queryString.parse(window.location.search);
  const { trackEvent } = useMatomo();
  const optin = retrieveAiGdpr5() === 'optin';

  useEffect(() => {
    if (optin && typeof accessionNumber === 'string' && !isLoggedIn()) {
      trackEvent({
        category: STUDY,
        action: LOADED,
        name: isSample(accessionNumber) ? SAMPLE : UPLOAD,
      });
    }
  }, [optin, trackEvent, accessionNumber]);

  return (
    <CxrViewerContainer>
      <StudyResultContainer>
        <CxrStudyResult />
      </StudyResultContainer>
      {shouldShowWPZ && (
        <ImageStatusContainer>
          <ImageStatus />
        </ImageStatusContainer>
      )}
    </CxrViewerContainer>
  );
};

export default CxrViewer;
