import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import DateTime from './types/DateTime';

dayjs.extend(utc);
dayjs.extend(timezone);

const formatDateTime = (dateTime: DateTime, formatter: string, fallback = ''): string => {
  if (!dayjs(dateTime).isValid()) {
    return fallback;
  }

  try {
    return dayjs(dateTime).format(formatter);
  } catch {
    return fallback;
  }
};

export default formatDateTime;
