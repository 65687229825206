import { CtbClassificationExternal, VisionClassificationExternal } from '@annaliseai/api-specifications';
import Finding from 'types/study/Finding';
import Uuid from 'types/Uuid';

export type FindingItem = {
  uuid: Uuid<'finding-item'>;
  isParent?: boolean;
  findingUuid: Finding['uuid'];
};

export type ParentFindingItem = Omit<FindingItem, 'findingUuid'> & {
  parentFindingId: Uuid<'parent'>;
  findings: FindingItem[];
};
export type FindingsGroup = {
  uuid: Uuid<'findings-group'>;
  name: string;
  isFirstFindingsGroup?: boolean;
  findingItemsUuids: FindingItem['uuid'][];
};

type FindingsMenu = {
  groupsUuids: FindingsGroup['uuid'][];
};

export type FindingWithChildren = {
  label: string;
  labelName: string;
  segmentId: string;
  childFindings: CtbClassificationExternal[];
};

export type FindingType = FindingWithChildren | CtbClassificationExternal | VisionClassificationExternal;

export const isParentFindingItem = (finding: FindingItem | ParentFindingItem): finding is ParentFindingItem => {
  return (finding as ParentFindingItem).findings !== undefined;
};
export const isFindingWithChildren = (finding: FindingType): finding is FindingWithChildren => {
  return (finding as FindingWithChildren).childFindings !== undefined;
};
export default FindingsMenu;
