import DateFormats from 'enums/DateFormats';
import formatPatientName from 'helpers/formatPatientName';
import { PatientInformation } from 'types/DisplayStudyInformation';
import { DisplayFormat } from 'types/settings/DisplayData';
import formatDateTime from './dates/formatDateTime';
import formatPatientAgeSex from './formatPatientAgeSex';

const { FORMAT_D_MM_YYYY_SLASHES, FORMAT_D_MMMM_YYYY } = DateFormats;

export type PatientInfoWithTitles = PatientInformation & {
  pidTitle: string;
  patientAgeSexTitle: string;
  patientDobTitle: string;
  patientNameTitle: string;
  patientSexTitle: string;
  patientAgeTitle: string;
  patientAgeSex: string;
};

// getPatientAgeSexTitle is used to generate the title/tooltip for patient age and sex data
// If both age and sex are present, the title returned will be formatted as desired by formatPatientAgeSex
// If age and sex are missing, a message will be returned stating this
// Otherwise the patientAgeTile and patientSexTitles will be returned

const getPatientAgeSexTitle = (
  patientAge: string,
  patientSex: string,
  patientAgeTitle: string,
  patientSexTitle: string,
) => {
  if (patientAge && patientSex) {
    return formatPatientAgeSex(patientAge, patientSex, '-');
  } else if (!patientAge && !patientSex) {
    return 'Patient age and sex not available';
  } else {
    return `${patientAgeTitle} - ${patientSexTitle}`;
  }
};

// The formatPatientInformation helper function formats the patient data for display and titles (tooltips).
// Missing data is replaced with a placeholder (if one is provided).
// Titles (for use as tooltips) are returned for each piece of data used in the web viewer.
// Where data is missing, tooltips display a message indicating which data is missing.

const formatPatientInformation = (
  patient: PatientInformation | null,
  displayFormat?: DisplayFormat,
  placeholder = '',
): Required<PatientInfoWithTitles> => {
  const patientAgeFormatted = patient?.patientAge?.replace(/^0+/, '');
  const patientAgeTitle = patientAgeFormatted || 'Patient age not available';
  const patientSexTitle = patient?.patientSex || 'Patient sex not available';
  const patientAgeSexTitle = getPatientAgeSexTitle(
    patientAgeFormatted as string,
    patient?.patientSex as string,
    patientAgeTitle,
    patientSexTitle,
  );
  const patientAgeSex = formatPatientAgeSex(patientAgeFormatted, patient?.patientSex, placeholder);

  const patientBirthDateFormatted = patient?.patientBirthDate
    ? formatDateTime(
        patient?.patientBirthDate,
        FORMAT_D_MM_YYYY_SLASHES(displayFormat),
        patient?.patientBirthDate, // if birthdate exists but is unable to be parsed, it is returned unchanged
      )
    : placeholder;

  const patientBirthDateLongFormatted = patient?.patientBirthDate
    ? formatDateTime(patient?.patientBirthDate, FORMAT_D_MMMM_YYYY(), patient?.patientBirthDate)
    : undefined;

  const patientNameFormatted = patient?.patientName && formatPatientName(patient.patientName);

  return {
    patientId: patient?.patientId || placeholder,
    patientName: patientNameFormatted || placeholder,
    patientAge: patientAgeFormatted || placeholder,
    patientSex: patient?.patientSex || placeholder,
    patientAgeSex,
    patientBirthDate: patientBirthDateFormatted,
    patientBirthDateLong: patientBirthDateLongFormatted || placeholder,
    pidTitle: patient?.patientId || 'Patient ID not available',
    patientDobTitle: patientBirthDateLongFormatted || 'Patient date of birth not available',
    patientNameTitle: patientNameFormatted || 'Patient name not available',
    patientAgeTitle,
    patientAgeSexTitle,
    patientSexTitle,
  };
};

export default formatPatientInformation;
