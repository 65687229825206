import Cookies from 'js-cookie';
import CookieKeys from 'enums/CookieKeys';

const { AI_GDPR_5 } = CookieKeys;

const UserChoice = {
  OPT_IN: 'optin',
  OPT_OUT: 'optout',
};

export const storeAiGdpr5 = (accepted: boolean): void => {
  const { OPT_IN, OPT_OUT } = UserChoice;
  Cookies.set(AI_GDPR_5, accepted ? OPT_IN : OPT_OUT, { expires: 30 });
};

export const retrieveAiGdpr5 = (): string | undefined => Cookies.get(AI_GDPR_5);
