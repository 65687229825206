import { colors } from '@annaliseai/anna-design-tokens';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, FormikValues, withFormik } from 'formik';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Input from 'components/Input';
import TermsAndConditions from 'components/TermsAndConditions';
import TransparentButton from 'components/TransparentButton';
import { PORTRAIT } from 'constants/orientation';
import { BREAK_POINTS } from 'constants/sizes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { Heading, Text, TextContainer, TextGroup } from 'layouts/ResponsiveContainers';
import { UserInformationReqBody } from 'types/UserInformation';
import userInformationSchema from './validationSchema';

const { USER_INFORMATION_FORM } = ReactTestingLibraryDataProperties;
const { SAMPLE_SCREEN_LARGE_MIN_WIDTH } = BREAK_POINTS;
const { WHITE, WHITE_40, PRIMARY_SOLID } = colors;

const Container = styled.div`
  background-color: #5436bd;
  padding: 2rem;
`;

const FormGroup = styled.div<{ isPortrait: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

const FormContainer = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1 36rem;
`;

const FieldsContainer = styled.div`
  margin: 0 2rem;
  flex: 1 0 40%;
`;

const ErrorText = styled.div`
  color: ${WHITE};
  font-size: 0.875rem;
  padding-top: 0.25rem;
`;

const TextFieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InputContainer = styled.div`
  flex-direction: row;
  flex: 1 0 50%;
  padding: 0.5rem;
  height: 4.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 2.75rem;
  margin: 2rem 0;
  padding-right: 0.5rem;
  align-items: center;
`;

const SubmitButton = styled(TransparentButton)`
  border-color: ${WHITE_40};
  color: ${WHITE_40};
  font-weight: 600;
  font-size: 0.75rem;
  white-space: nowrap;
  line-height: 2;
  border-radius: 0.5em;
  margin-left: 0.5rem;
  padding: 0 1.125rem;
  &:not([disabled]) {
    background-color: ${WHITE};
    color: ${PRIMARY_SOLID};
    outline: none;
  }

  &:hover:not([disabled]) {
    background-color: ${WHITE};
    color: ${PRIMARY_SOLID};
    outline: none;
  }
`;

const UserInfoTextContainer = styled(TextContainer)`
  @media (max-width: 897px) {
    ${({ isPortrait }) => isPortrait && `text-align: center;`}
  }
`;

const FormTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 0.5rem;
  flex: 1 1 29%;
  max-height: 13.125rem;
`;

const TermsContainer = styled.div`
  margin-top: -4rem;
  @media (max-width: 897px) {
    margin-top: 0;
    display: flex;
    justify-content: center;
  }
  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    font-size: 1.125rem;
    margin: 0 3rem;
  }
`;

const Checkbox = styled(Field)`
  height: 1rem;
  width: 1rem;
  margin: 0 0.5rem;
  &:hover {
    cursor: pointer;
  }
`;

const OptInContainer = styled.div`
  display: flex;
  font-size: 0.625rem;
  &:hover {
    cursor: pointer;
  }
  label {
    &:hover {
      cursor: pointer;
    }
  }
`;

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  jobtitle: '',
  organisationname: '',
  optin: '',
};

const formikEnhancer = withFormik({
  mapPropsToValues: () => initialValues,
  mapPropsToErrors: () => initialValues,
  validationSchema: userInformationSchema,
  handleSubmit: async (_values: UserInformationReqBody) => null,
  validateOnBlur: true,
  displayName: 'UserInformationForm',
});

const renderErrorText = (msg: string) => <ErrorText>{msg}</ErrorText>;

const UserInformation: FC<FormikValues> = ({
  handleChange,
  isValid,
  handleBlur,
  values: { firstname, lastname, email, jobtitle, organisationname, optin },
}) => (
  <FieldsContainer>
    <TextFieldContainer>
      <InputContainer>
        <Input
          name="firstname"
          value={firstname}
          onChange={handleChange}
          placeholder="Your first name"
          onBlur={handleBlur}
          required
        />
        <ErrorMessage name="firstname" render={renderErrorText} />
      </InputContainer>
      <InputContainer>
        <Input
          name="lastname"
          value={lastname}
          onChange={handleChange}
          placeholder="Your last name"
          onBlur={handleBlur}
          required
        />
        <ErrorMessage name="lastname" render={renderErrorText} />
      </InputContainer>
      <InputContainer>
        <Input
          name="email"
          value={email}
          onChange={handleChange}
          placeholder="Your email"
          onBlur={handleBlur}
          required
        />
        <ErrorMessage name="email" render={renderErrorText} />
      </InputContainer>
      <InputContainer>
        <Input
          name="jobtitle"
          value={jobtitle}
          onChange={handleChange}
          placeholder="Your role"
          onBlur={handleBlur}
          required
        />
        <ErrorMessage name="jobtitle" render={renderErrorText} />
      </InputContainer>
      <InputContainer>
        <Input
          name="organisationname"
          value={organisationname}
          onChange={handleChange}
          placeholder="Your organisation"
          onBlur={handleBlur}
          required
        />
        <ErrorMessage name="organisationname" render={renderErrorText} />
      </InputContainer>
    </TextFieldContainer>
    <ButtonContainer>
      <OptInContainer>
        <Checkbox type="checkbox" name="optin" id="optin" />
        <label htmlFor="optin">Send me info on Annalise products & events (unsubscribe any time)</label>
        {!optin && <input type="hidden" name="optin" value="false" />}
      </OptInContainer>
      <SubmitButton type="submit" disabled={!isValid}>
        <FontAwesomeIcon icon={faCheckCircle} /> View AI Findings
      </SubmitButton>
    </ButtonContainer>
  </FieldsContainer>
);

interface UserInformationProps {
  onSubmitCallback: () => void;
}

const EnhancedForm = formikEnhancer(UserInformation);

const UserInformationContainer: FC<UserInformationProps> = ({ onSubmitCallback }) => {
  const isPortrait = useMediaQuery({ orientation: PORTRAIT });
  const { REACT_APP_PARDOT_FORM_URL } = process.env;
  return (
    <Container>
      <FormGroup isPortrait={isPortrait}>
        <FormTextContainer>
          <UserInfoTextContainer isPortrait={isPortrait}>
            <Heading>View the clinical findings Annalise demo has detected</Heading>
            <TextGroup>
              <Text>Please enter your details to view findings for this study.</Text>
              <Text>Registration required to ensure security and privacy.</Text>
              <Text>All fields are required.</Text>
            </TextGroup>
          </UserInfoTextContainer>
        </FormTextContainer>
        <FormContainer
          action={REACT_APP_PARDOT_FORM_URL}
          method="post"
          onSubmit={onSubmitCallback}
          data-testid={USER_INFORMATION_FORM}
        >
          <EnhancedForm />
        </FormContainer>
      </FormGroup>
      <TermsContainer>
        <TermsAndConditions />
      </TermsContainer>
    </Container>
  );
};

export default UserInformationContainer;
