import { resultsListConfig, resultsListConfigHK } from 'components/WorklistTable/WorklistTable.constants';
import { ColumnIds } from 'components/WorklistTable/WorklistTypes';
import configuration from 'configuration';

/**
 * Returns a boolean indicating whether a column should be available (for use in the column selector and worklist).
 * A `false` setting means the column should not exist for the user.
 * Note: temporary HK implementation, pending organisation configuration/admin portal
 * @param {ColumnIds} id - The ID of the column to check for presence in the column selector and worklist.
 * @returns {boolean} `true` if the column should be available, `false` if it should not be available.
 */

export const maybeShowColumn = (id: ColumnIds): boolean =>
  configuration.isBespokeSpecial ? resultsListConfigHK[id].isPresent : resultsListConfig[id].isPresent;

export default maybeShowColumn;
