import { CtbLaterality, SegmentLateralityType } from '@annaliseai/api-specifications';

interface FindCtbLateralityParams {
  lateralities: CtbLaterality[];
  findingLabel: string;
}

const { NONE } = SegmentLateralityType;

const findCtbLaterality = ({ findingLabel, lateralities }: FindCtbLateralityParams): SegmentLateralityType | null => {
  const { laterality } =
    lateralities.find(
      ({ laterality, relatedLabels }) =>
        // Treat Laterality NONE as if the finding had no localisation at all
        laterality !== NONE && relatedLabels.includes(findingLabel),
    ) || {};

  return laterality || null;
};

export default findCtbLaterality;
