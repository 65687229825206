import { PredictionErrorCode } from '@annaliseai/api-specifications';
import CustomErrors from 'enums/CustomErrors';

const {
  REQUIRED_AP_PA_IMAGE_ERROR: PREDICTION_REQUIRED_AP_PA_IMAGE_ERROR,
  REQUIRED_CXR_ERROR: PREDICTION_REQUIRED_CXR_ERROR,
  IMAGE_DOWNLOAD_ERROR: PREDICTION_IMAGE_DOWNLOAD_ERROR,
  MODEL_ERROR: PREDICTION_MODEL_ERROR,
  PROCESSING_ERROR: PREDICTION_PROCESSING_ERROR,
  PROCESSING_TTL_EXPIRED_ERROR: PREDICTION_PROCESSING_TTL_EXPIRED_ERROR,
  MAX_IMAGE_VIOLATION_ERROR: PREDICTION_MAX_IMAGE_VIOLATION_ERROR,
  MIN_AGE_VIOLATION_ERROR: PREDICTION_MIN_AGE_VIOLATION_ERROR,
  CONTRAST_SERIES_NOT_SUPPORTED_ERROR: PREDICTION_CONTRAST_SERIES_NOT_SUPPORTED_ERROR,
  SLICE_THICKNESS_TOO_HIGH_ERROR: PREDICTION_SLICE_THICKNESS_TOO_HIGH_ERROR,
  INSUFFICIENT_IMAGES_ERROR: PREDICTION_INSUFFICIENT_IMAGES_ERROR,
  TOO_MANY_IMAGES_ERROR: PREDICTION_TOO_MANY_IMAGES_ERROR,
  SERIES_NOT_PRIMARY_ERROR: PREDICTION_SERIES_NOT_PRIMARY_ERROR,
  ATTRIBUTES_SCORE_TOO_LOW_ERROR: PREDICTION_ATTRIBUTES_SCORE_TOO_LOW_ERROR,
  ATTRIBUTES_IO_ERROR: PREDICTION_ATTRIBUTES_IO_ERROR,
  ATTRIBUTES_INVALID_INPUT_ERROR: PREDICTION_ATTRIBUTES_INVALID_INPUT_ERROR,
  ATTRIBUTES_PREDICTION_ERROR: PREDICTION_ATTRIBUTES_PREDICTION_ERROR,
  REGISTRATION_INVALID_DICOM_ERROR: PREDICTION_REGISTRATION_INVALID_DICOM_ERROR,
  REGISTRATION_PREDICTION_OOD_ERROR: PREDICTION_REGISTRATION_PREDICTION_OOD_ERROR,
  REGISTRATION_POOR_MIS_SCORE_ERROR: PREDICTION_REGISTRATION_POOR_MIS_SCORE_ERROR,
  REGISTRATION_GENERAL_ERROR: PREDICTION_REGISTRATION_GENERAL_ERROR,
  IO_ERROR: PREDICTION_IO_ERROR,
  REGISTRATION_ARCHIVE_ERROR: PREDICTION_REGISTRATION_ARCHIVE_ERROR,
  MODEL_CONFIG_ERROR: PREDICTION_MODEL_CONFIG_ERROR,
  POST_PROCESSING_ERROR: PREDICTION_POST_PROCESSING_ERROR,
  SEGMENTS_PROCESSING_ERROR: PREDICTION_SEGMENTS_PROCESSING_ERROR,
} = PredictionErrorCode;
const {
  REQUIRED_AP_PA_IMAGE_ERROR,
  REQUIRED_CXR_ERROR,
  IMAGE_DOWNLOAD_ERROR,
  MODEL_ERROR,
  PROCESSING_ERROR,
  PROCESSING_TTL_EXPIRED_ERROR,
  MAX_IMAGE_VIOLATION_ERROR,
  MIN_AGE_VIOLATION_ERROR,
  UNEXPECTED_BACKEND_ERROR,
  CONTRAST_SERIES_NOT_SUPPORTED_ERROR,
  SLICE_THICKNESS_TOO_HIGH_ERROR,
  INSUFFICIENT_IMAGES_ERROR,
  TOO_MANY_IMAGES,
  SERIES_NOT_PRIMARY_ERROR,
  ATTRIBUTES_SCORE_TOO_LOW_ERROR,
  ATTRIBUTES_IO_ERROR,
  ATTRIBUTES_INVALID_INPUT_ERROR,
  ATTRIBUTES_PREDICTION_ERROR,
  REGISTRATION_INVALID_DICOM_ERROR,
  REGISTRATION_PREDICTION_OOD_ERROR,
  REGISTRATION_POOR_MIS_SCORE_ERROR,
  REGISTRATION_GENERAL_ERROR,
  IO_ERROR,
  REGISTRATION_ARCHIVE_ERROR,
  MODEL_CONFIG_ERROR,
  POST_PROCESSING_ERROR,
  SEGMENTS_PROCESSING_ERROR,
} = CustomErrors;

const getErrorCode = (visionError?: PredictionErrorCode): CustomErrors => {
  switch (visionError) {
    case PREDICTION_REQUIRED_AP_PA_IMAGE_ERROR:
      return REQUIRED_AP_PA_IMAGE_ERROR;
    case PREDICTION_REQUIRED_CXR_ERROR:
      return REQUIRED_CXR_ERROR;
    case PREDICTION_IMAGE_DOWNLOAD_ERROR:
      return IMAGE_DOWNLOAD_ERROR;
    case PREDICTION_MODEL_ERROR:
      return MODEL_ERROR;
    case PREDICTION_PROCESSING_ERROR:
      return PROCESSING_ERROR;
    case PREDICTION_PROCESSING_TTL_EXPIRED_ERROR:
      return PROCESSING_TTL_EXPIRED_ERROR;
    case PREDICTION_MAX_IMAGE_VIOLATION_ERROR:
      return MAX_IMAGE_VIOLATION_ERROR;
    case PREDICTION_MIN_AGE_VIOLATION_ERROR:
      return MIN_AGE_VIOLATION_ERROR;
    case PREDICTION_CONTRAST_SERIES_NOT_SUPPORTED_ERROR:
      return CONTRAST_SERIES_NOT_SUPPORTED_ERROR;
    case PREDICTION_SLICE_THICKNESS_TOO_HIGH_ERROR:
      return SLICE_THICKNESS_TOO_HIGH_ERROR;
    case PREDICTION_INSUFFICIENT_IMAGES_ERROR:
      return INSUFFICIENT_IMAGES_ERROR;
    case PREDICTION_TOO_MANY_IMAGES_ERROR:
      return TOO_MANY_IMAGES;
    case PREDICTION_SERIES_NOT_PRIMARY_ERROR:
      return SERIES_NOT_PRIMARY_ERROR;
    case PREDICTION_ATTRIBUTES_SCORE_TOO_LOW_ERROR:
      return ATTRIBUTES_SCORE_TOO_LOW_ERROR;
    case PREDICTION_ATTRIBUTES_IO_ERROR:
      return ATTRIBUTES_IO_ERROR;
    case PREDICTION_ATTRIBUTES_INVALID_INPUT_ERROR:
      return ATTRIBUTES_INVALID_INPUT_ERROR;
    case PREDICTION_ATTRIBUTES_PREDICTION_ERROR:
      return ATTRIBUTES_PREDICTION_ERROR;
    case PREDICTION_REGISTRATION_INVALID_DICOM_ERROR:
      return REGISTRATION_INVALID_DICOM_ERROR;
    case PREDICTION_REGISTRATION_PREDICTION_OOD_ERROR:
      return REGISTRATION_PREDICTION_OOD_ERROR;
    case PREDICTION_REGISTRATION_POOR_MIS_SCORE_ERROR:
      return REGISTRATION_POOR_MIS_SCORE_ERROR;
    case PREDICTION_REGISTRATION_GENERAL_ERROR:
      return REGISTRATION_GENERAL_ERROR;
    case PREDICTION_IO_ERROR:
      return IO_ERROR;
    case PREDICTION_REGISTRATION_ARCHIVE_ERROR:
      return REGISTRATION_ARCHIVE_ERROR;
    case PREDICTION_MODEL_CONFIG_ERROR:
      return MODEL_CONFIG_ERROR;
    case PREDICTION_POST_PROCESSING_ERROR:
      return POST_PROCESSING_ERROR;
    case PREDICTION_SEGMENTS_PROCESSING_ERROR:
      return SEGMENTS_PROCESSING_ERROR;
    default:
      return UNEXPECTED_BACKEND_ERROR;
  }
};

export default getErrorCode;
