import log from 'loglevel';
import cornerstone from 'cornerstone-core';
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';
import { CornerstoneImage, CornerstoneWebImage } from 'types/cornerstone/CornerstoneImage';
import CornerstoneImageId from 'types/cornerstone/CornerstoneImageId';
import { CornerstoneProvider } from 'types/cornerstone/CornerstoneProvider';
import { getImageIdPayload } from './imageIdHelper';

type LoadImageOptions = {
  cache: boolean;
  provider?: CornerstoneProvider;
};

const { FAILED_TO_READ_IMAGE_FILE } = CustomErrors;

export const loadImage = async (
  imageId: CornerstoneImageId,
  options: LoadImageOptions = {
    cache: false,
  },
): Promise<CornerstoneImage> => {
  const { cache, provider } = options;
  const load = cache ? cornerstone.loadAndCacheImage : cornerstone.loadImage;

  try {
    if (provider) {
      // Put function to the cornerstone storage to get information in the loader internals
      cornerstone.metaData.addProvider(provider);
    }

    const image = await load(imageId);

    if (provider) {
      cornerstone.metaData.removeProvider(provider);
    }

    return image;
  } catch (error) {
    log.error(error);
    throw new CustomError(FAILED_TO_READ_IMAGE_FILE);
  }
};

export const getWebLoader = (
  imageId: CornerstoneImageId,
  { bytes }: { bytes?: ArrayBuffer } = {},
): { cancelFn: () => void; promise: CornerstoneWebImage } => {
  if (bytes) {
    return {
      promise: cornerstoneWebImageLoader
        .arrayBufferToImage(bytes)
        .then((image: unknown) => cornerstoneWebImageLoader.createImage(image, imageId)),
      cancelFn: () => undefined,
    };
  }

  const url = getImageIdPayload(imageId);
  return cornerstoneWebImageLoader.loadImage(url);
};
