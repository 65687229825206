import { RootState } from 'slices/RootState';
import { CxrFindingView } from 'types/viewer/cxrViewer/CxrFindingViewer';

const selectActiveFindingViews = ({
  cxrViewer: { activeFinding, findingViewsMap },
}: RootState): CxrFindingView[] | null => {
  return (
    activeFinding &&
    activeFinding.findingViewUuids &&
    activeFinding.findingViewUuids.map(viewUuid => findingViewsMap[viewUuid])
  );
};

export default selectActiveFindingViews;
