import client from 'api/client';
import endpoints from 'api/endpoints';
import configuration from 'configuration';
import { APP_LOCALE, APP_REGION } from 'slices/settingsSlice';
import { DocsManifest, DocsManifestRegion, DocsUrls } from 'types/DocsManifest';

const getDocsManifest = async (): Promise<DocsUrls> => {
  const { appVersion } = configuration;
  const { documents } = endpoints.v1;
  const path = documents.getPath();
  const {
    data: { documentsBucketUrl },
  } = await client.get(path);

  const manifestUrl = '/webviewer/manifest.json';
  const docsManifest: DocsManifest = await fetch(`${documentsBucketUrl}${manifestUrl}`, { cache: 'no-store' }).then(
    res => res.json(),
  );

  const docKeys: DocsUrls = {
    userGuide: '',
    performanceSpecifications: '',
    privacyPolicy: '',
    legalNotices: '',
    regulatoryCertification: '',
  };

  const regionDocsManifestFormattedUrls = Object.keys(docKeys).reduce((acc, docKey) => {
    const regionDocsManifest: DocsManifestRegion = docsManifest[appVersion as string][APP_REGION];
    const docUrl = (regionDocsManifest[docKey as keyof DocsUrls][APP_LOCALE] as string) || '';
    const absoluteUrl = docUrl?.startsWith('/') ? `${documentsBucketUrl}${docUrl}` : docUrl;
    return {
      ...acc,
      [docKey]: absoluteUrl,
    };
  }, docKeys);

  return regionDocsManifestFormattedUrls;
};

export default getDocsManifest;
