import { ImageWithBytes } from '@annaliseai/api-specifications';
import isEmptyFile from 'helpers/file/isEmptyFile';
import getCxrImageWithBytesFromImageFile from './getCxrImageWithBytesFromImageFile';

const getCxrImagesWithBytesFromImageFiles = async (files: File[]): Promise<ImageWithBytes[]> => {
  const promises: Promise<ImageWithBytes>[] = [];

  for (const file of files) {
    if (!isEmptyFile(file)) {
      promises.push(getCxrImageWithBytesFromImageFile(file));
    }
  }

  return await Promise.all(promises);
};

export default getCxrImagesWithBytesFromImageFiles;
