import { questionCircle16Regular } from '@annaliseai/anna-icons';
import IconWrapper from '@annaliseai/icon-wrapper';
import React, { useState } from 'react';
import Button, { ButtonSize, ButtonVariant } from 'components/Buttons/Button/Button';
import ButtonText from 'components/Buttons/ButtonText/ButtonText';
import Modal from 'components/Modals/Modal';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import useStrings from 'hooks/useStrings';
import { useMatomo } from 'matomo/react';
import { EventActions, EventCategories } from 'types/Matomo';
import { HelpModalParagraph, HelpModalTitle } from './Help.styles';

const { REQUESTED_HELP } = EventActions;
const { STUDY_LINK } = EventCategories;
const { MEDIUM } = ButtonSize;
const { DEFAULT } = ButtonVariant;
const { ICON_HELP } = ReactTestingLibraryDataProperties;

const Help = (): JSX.Element => {
  const { trackEvent } = useMatomo();

  const [modalOpen, setModalOpen] = useState(false);

  const { CONTACT_SUPPORT, EMAIL_SUPPORT, HELP, MAIL_TO_HREF, TITLE_HELP_MODAL } = useStrings();

  const handleClick = () => {
    // Only toggle behaviour when modal is closed so email link can be clicked/copied without closing the modal
    !modalOpen && setModalOpen(!modalOpen);

    trackEvent({
      category: STUDY_LINK,
      action: REQUESTED_HELP,
    });
  };

  const HelpModal = () => {
    return (
      <Modal show={modalOpen} onClose={() => setModalOpen(false)}>
        <HelpModalTitle>{TITLE_HELP_MODAL} </HelpModalTitle>
        <HelpModalParagraph>
          {CONTACT_SUPPORT} <a href={MAIL_TO_HREF}>{EMAIL_SUPPORT}</a>
        </HelpModalParagraph>
      </Modal>
    );
  };

  return (
    <Button onClick={handleClick} variant={DEFAULT} size={MEDIUM}>
      <IconWrapper svg={questionCircle16Regular} fill={'inherit'} size={1.33} testid={ICON_HELP} />
      <ButtonText size={MEDIUM}>{HELP}</ButtonText>
      <HelpModal />
    </Button>
  );
};

export default Help;
