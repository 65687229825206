import { SegmentLateralityType } from '@annaliseai/api-specifications';
import styled, { DataAttributes } from 'styled-components';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

type LateralSegmentProps = {
  laterality: SegmentLateralityType;
  height?: number;
};

const { LATERAL_SEGMENT } = ReactTestingLibraryDataProperties;
const { LEFT, RIGHT, BILATERAL } = SegmentLateralityType;

const LateralSegment = styled.div.attrs<DataAttributes>({
  'data-testid': LATERAL_SEGMENT,
})<LateralSegmentProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  height: 100%;
  top: 0;
  pointer-events: none;

  &:before,
  &:after {
    content: '';
    position: relative;
    top: ${({ height = 100 }): number => (100 - height) / 2}%;
    background-color: rgba(219, 0, 255, 0.35);
    clip-path: polygon(100% 0, 0% 50%, 100% 100%);
    width: 100%;
  }

  /* Note: Left and Right are flipped because the images are flipped too */
  &:before {
    opacity: ${({ laterality }): string => (laterality === RIGHT || laterality === BILATERAL ? `1` : '0')};
    transform: rotate(180deg);
    width: 40px;
    height: ${({ height = 100 }): number => height}%;
  }
  &:after {
    opacity: ${({ laterality }): string => (laterality === LEFT || laterality === BILATERAL ? `1` : '0')};
    height: ${({ height = 100 }): number => height}%;
    width: 40px;
  }
`;

export default LateralSegment;
