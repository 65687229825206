import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Finding from 'types/study/Finding';

export type FindingsState = {
  findingsMap: Record<Finding['uuid'], Finding>;
  hasFindingBeenSelectedForStudy?: boolean;
};

export const initialState: FindingsState = {
  findingsMap: {},
  hasFindingBeenSelectedForStudy: false,
};

export const findingsSlice = createSlice({
  name: 'findings',
  initialState,
  reducers: {
    reset: _ => ({
      ...initialState,
    }),
    setFindingsMap: (state, { payload: findingsMap }: PayloadAction<FindingsState['findingsMap']>) => ({
      ...state,
      findingsMap,
    }),
    setHasFindingBeenSelectedForStudy: (
      state,
      { payload: hasFindingBeenSelectedForStudy }: PayloadAction<FindingsState['hasFindingBeenSelectedForStudy']>,
    ) => ({
      ...state,
      hasFindingBeenSelectedForStudy,
    }),
  },
});

export const findingsActions = findingsSlice.actions;
