import { EncodeRequest, EncodeResponse, MessageTypes } from './types/CodecWorkerApi';
import ImageMetadata from './types/ImageMetadata';
import CodecWorker from './worker/codec.worker';

const { ENCODE } = MessageTypes;

const encode = async (pixelDataBuffer: ArrayBuffer, imageMetadata: ImageMetadata): Promise<ArrayBuffer> => {
  const codecWorker = new CodecWorker();

  const requestMessage: EncodeRequest = {
    type: ENCODE,
    data: {
      pixelDataBuffer,
      ...imageMetadata,
    },
  };
  codecWorker.postMessage(requestMessage);

  const encodedPixelDataBuffer = await new Promise<EncodeResponse['data']>(resolve => {
    const handler = ({ data: payload }: MessageEvent<EncodeResponse>) => {
      const { type, data } = payload;

      if (type !== ENCODE) {
        return;
      }

      resolve(data);

      codecWorker.removeEventListener('message', handler);
    };
    codecWorker.addEventListener('message', handler);
  });

  return encodedPixelDataBuffer;
};

export default encode;
