import { SEARCH_ATTRS } from 'components/SearchFilter/SearchFilter.constants';
import { KeyValuePairs } from 'types/KeyValuePairs';

// loop object and remove any items which are null/undefined or empty strings
const retainValidParamValues = (obj: KeyValuePairs): KeyValuePairs => {
  const result: KeyValuePairs = {};
  Object.entries(obj).forEach(([name, value]) => {
    // we want to retain meaningful values, such as -1, 0, strings
    if (name === SEARCH_ATTRS['name'] && typeof value === 'string') value = value.trim();
    if (value !== '' && value !== null && value !== undefined) result[name] = value;
  });
  return result;
};

export default retainValidParamValues;
