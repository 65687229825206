import React, { ReactElement, ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import Overlay from 'components/Overlay';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { CloseButton, ContentContainer, FooterContainer, ModalContainer } from './MenuItemModal.styles';
import { handleKeyDown, trapFocusModal } from './Modal.utils';

const { MODAL_OVERLAY } = ReactTestingLibraryDataProperties;

type ModalProps = {
  hasFooter?: boolean;
  onClose?: () => void;
  show?: boolean;
  children?: ReactNode;
};

const MenuItemModal = ({ children, onClose, show = false, hasFooter = true }: ModalProps): ReactElement => {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  const previouslyFocusedElementRef = useRef<HTMLElement | null>(null);
  const firstFocusableElementRef = useRef<HTMLElement | null>(null);
  const lastFocusableElementRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    if (show) {
      previouslyFocusedElementRef.current = document.activeElement as HTMLElement;
      const modalContainer = modalContainerRef?.current;
      modalContainer?.focus();

      trapFocusModal(firstFocusableElementRef.current, lastFocusableElementRef.current, modalContainer);
    }
  }, [show]);

  const handleClose = () => {
    previouslyFocusedElementRef.current?.focus();
    onClose?.();
  };

  const focusModalContainer = 0;

  return (
    <>
      {show &&
        createPortal(
          <>
            <Overlay data-testid={MODAL_OVERLAY} />
            <ModalContainer
              ref={modalContainerRef}
              onKeyDown={onClose && handleKeyDown?.(onClose, previouslyFocusedElementRef.current)}
              tabIndex={focusModalContainer}
            >
              <ContentContainer>{children}</ContentContainer>
              {hasFooter && (
                <FooterContainer>
                  <CloseButton onClick={handleClose}>Close</CloseButton>
                </FooterContainer>
              )}
            </ModalContainer>
          </>,
          document.body,
        )}
    </>
  );
};

export default MenuItemModal;
