import strings from 'constants/strings';

const { TEXT_DISCLAIMER, TITLE_DISCLAIMER } = strings.demo;

const demoDisclaimerProps = {
  tooltip: TITLE_DISCLAIMER,
  text: TEXT_DISCLAIMER,
};

export default demoDisclaimerProps;
