import { colors } from '@annaliseai/anna-design-tokens';
import React from 'react';
import styled, { css, DataAttributes } from 'styled-components';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';

const { WHITE } = colors;
const { ALERT_ICON } = ReactTestingLibraryDataProperties;

const Wrapper = styled.div.attrs<DataAttributes>({
  'data-testid': ALERT_ICON,
})`
  display: flex;
  justify-content: center;
`;
const Container = styled.div.attrs<{ size: number }>(() => ({}))<{ size: number }>`
  ${({ size }) => css`
    width: ${size}rem;
    height: ${size}rem;
  `}
`;

const Alert = ({ color = WHITE, size = 1.5 }: Icon): JSX.Element => (
  <Wrapper>
    <Container size={size}>
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 0C4.02071 0 0 4.04734 0 9C0 13.9793 4.04734 18 9 18C13.9793 18 18 13.9527 18 9C18 4.02071 13.9793 0 9 0ZM9 16.7219C4.73964 16.7219 1.27811 13.2604 1.27811 9C1.27811 4.73964 4.73964 1.27811 9 1.27811C13.2604 1.27811 16.7219 4.73964 16.7219 9C16.7219 13.2604 13.2604 16.7219 9 16.7219Z"
          fill={color}
        />
        <path
          d="M9.00019 13.073C8.78717 13.073 8.60078 12.9931 8.46764 12.86C8.36113 12.7268 8.28125 12.5671 8.28125 12.3541C8.28125 12.1677 8.36113 11.9813 8.49427 11.8481C8.76054 11.5819 9.23983 11.5819 9.5061 11.8481C9.63924 11.9813 9.71912 12.1677 9.71912 12.3541C9.71912 12.5671 9.63924 12.7268 9.5061 12.86C9.37297 13.0197 9.18658 13.073 9.00019 13.073Z"
          fill={color}
        />
        <path
          d="M9.00019 10.1447C8.60078 10.1447 8.28125 9.82519 8.28125 9.42578V5.64472C8.28125 5.24531 8.60078 4.92578 9.00019 4.92578C9.39959 4.92578 9.71912 5.24531 9.71912 5.64472V9.42578C9.71912 9.82519 9.39959 10.1447 9.00019 10.1447Z"
          fill={color}
        />
      </svg>
    </Container>
  </Wrapper>
);

export default Alert;
