import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Disclaimer from 'components/Disclaimer/Disclaimer';
import Header from 'components/Header';
import SearchFilter from 'components/SearchFilter/SearchFilter';
import WorklistTable from 'components/WorklistTable';
import { RESULTS_DISCLAIMER } from 'components/WorklistTable/WorklistTable.constants';
import { BREAK_POINTS } from 'constants/sizes';
import ScreenVariant from 'enums/ScreenVariant';
import { FooterContainer } from 'layouts/ResponsiveContainers';
import { selectInstitutionNames, selectRequestingServices } from 'selectors/settings/selectSettings';
import { settingsActions } from 'slices/settingsSlice';

const { SMALL_DESKTOP } = BREAK_POINTS;
const { RESULTS } = ScreenVariant;

const Container = styled.section`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1.8fr;
  grid-template-rows: max-content minmax(0, 1fr);
  gap: 0 0;
  grid-template-areas:
    'nav'
    'main';
`;

const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  grid-area: main;
`;

const WorklistWrapper = styled.div`
  width: 100%;
  max-width: ${SMALL_DESKTOP}px;
  align-self: center;
`;

const Footer = styled(FooterContainer)`
  height: auto;
  margin-bottom: 1rem;
`;

const Worklist = (): JSX.Element => {
  const requestingServices = useSelector(selectRequestingServices);
  const institutionNames = useSelector(selectInstitutionNames);
  const dispatch = useDispatch();

  // Fetch requesting services if we haven't attempted to fetch them yet
  useEffect(() => {
    !requestingServices && dispatch(settingsActions.fetchRequestingServices());
    !institutionNames && dispatch(settingsActions.fetchInstitutionNames());
  }, [dispatch, requestingServices, institutionNames]);

  return (
    <Container>
      <Header screenVariant={RESULTS} />
      <BodyContainer>
        <WorklistWrapper>
          <SearchFilter />
          <WorklistTable />
          <Footer>
            <Disclaimer text={RESULTS_DISCLAIMER} />
          </Footer>
        </WorklistWrapper>
      </BodyContainer>
    </Container>
  );
};

export default Worklist;
