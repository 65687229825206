import { types } from 'matomo/js';

export type TrackEventHook = (params: types.TrackEventParams) => void | undefined;

export type MatomoEnv = {
  accountUrl: string;
  siteId: number;
  srcUrl: string;
  trackerUrl: string;
  urlBase: string;
};

export enum EventCategories {
  DETAILS = 'Details',
  ERROR = 'Error',
  IMAGES = 'Images',
  LIMIT_REACHED_PAGE = 'LimitReachedPage',
  SAMPLE_STUDY = 'Sample Study',
  STUDY = 'Study',
  STUDY_LINK = 'StudyLink',
  WEBSITE_NAVIGATION = 'WebsiteNavigation',
  WEBSITE_NAVIGATION_FAILED = 'WebsiteNavigationFailed',
  WEEKLY_SCAN_LIMIT = 'WeeklyScanLimit',
  CTB_VIEWER = 'CtbViewer',
}

export enum EventActions {
  EXPIRED = 'Expired',
  HIT = 'Hit',
  IMAGE_UPLOAD = 'ImageUpload',
  LOADED = 'Loaded',
  SELECTED = 'Selected',
  SHARED = 'Shared',
  REQUESTED_HELP = 'RequestedHelp',
  SUPPLIED = 'Supplied',
  VIEWED = 'Viewed',
  VISITED = 'Visited',
  GENERIC = 'Generic',
  CTB_VIEWS = 'CtbViews',
  CTB_WINDOWS = 'CtbWindows',
  PARENT_EXPANDED = 'ParentExpanded',
  CTB_SCROLL = 'CtbScroll',
}

export enum EventNames {
  CORONAL = 'Coronal',
  SAGITTAL = 'Sagittal',
  AXIAL = 'Axial',
  BRAIN = 'Brain',
  BONE = 'Bone',
  SOFT_TISSUE = 'SoftTissue',
  STROKE = 'Stroke',
  SUBDURAL = 'Subdural',
  MOUSE_WHEEL = 'MouseWheel',
  SCROLLBAR = 'Scrollbar',
  IMAGE_PANEL = 'ImagePanel',
}

export type TrackEventParams = types.TrackEventParams;

export type InstanceParams = types.UserOptions;
