import { Constants, CtbPredictionSegmentViews, ViewType } from '@annaliseai/api-specifications';
import { CtbSegmentImageId, UICtbSegmentViews } from 'imageQueue/types';

type Test = {
  AXIAL: never[];
  CORONAL: never[];
  SAGITTAL: never[];
};

type Keys = 'AXIAL' | 'CORONAL' | 'SAGITTAL';

const { AXIAL, CORONAL, SAGITTAL } = ViewType;
const { VIEW_SLICES_MAX_COUNT } = Constants;

const normaliseCtbSegmentViews = (views: CtbPredictionSegmentViews): UICtbSegmentViews => {
  const keys = Object.keys(views) as Keys[];
  const nextViews = keys.reduce(
    (acc: Test, currentValue: Keys) => {
      const url = views[currentValue].url
        .replace(
          'annalise-ai-optimus-rocket-images-dev-apse2.s3.amazonaws.com',
          'rocket-images.dev.apse2.annaliseai.io',
        )
        .replace(
          'annalise-ai-optimus-rocket-images-mrmc-apse1.s3.amazonaws.com',
          'rocket-images.mrmc.apse1.annaliseai.io',
        );
      const segmentView = [...Array(VIEW_SLICES_MAX_COUNT[currentValue])];
      const { AXIAL: AXIAL_VIEW_SLICES_MAX_COUNT } = VIEW_SLICES_MAX_COUNT;

      views[currentValue].slicesInfo.forEach(({ index, bytesRange, isActivated }) => {
        if (isActivated) {
          segmentView[index] = {
            url,
            index: currentValue === AXIAL ? AXIAL_VIEW_SLICES_MAX_COUNT - index - 1 : index,
            bytesRange,
          } as CtbSegmentImageId;
        }
      });
      return {
        ...acc,
        [currentValue]: currentValue === AXIAL ? segmentView.reverse() : segmentView,
      };
    },
    {
      [AXIAL]: [],
      [CORONAL]: [],
      [SAGITTAL]: [],
    } as Test,
  );

  return nextViews;
};

export default normaliseCtbSegmentViews;
