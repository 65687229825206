import { colors } from '@annaliseai/anna-design-tokens';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Sample from 'components/sample/Sample';
import ctbSamples from 'constants/ctbSamples';
import Routes from 'enums/Routes';
import useRedirectIfLoggedIn from 'hooks/useRedirectIfLoggedIn';
import { useMatomo } from 'matomo/react';
import { EventActions, EventCategories } from 'types/Matomo';

const { VIEWER } = Routes;
const { WHITE } = colors;

const MIN_LARGE_SCREEN_WIDTH = 1360;
const MIN_LARGE_SCREEN_HEIGHT = 900;

const CtbSampleScreenContainer = styled.div`
  color: ${WHITE};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SamplesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 1.5rem;
`;

const CtbSampleScreen: FC = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const { SAMPLE_STUDY } = EventCategories;
  const { VISITED } = EventActions;
  const isSmallScreenWidth = useMediaQuery({
    maxWidth: MIN_LARGE_SCREEN_WIDTH - 1,
  });
  const isSmallScreenHeight = useMediaQuery({
    maxHeight: MIN_LARGE_SCREEN_HEIGHT - 1,
  });

  const isSmallScreen = isSmallScreenWidth || isSmallScreenHeight;

  useRedirectIfLoggedIn();

  const onSampleClick = (accessionNumber: string, studyInstanceUid: string, description: string) => {
    const query = queryString.stringify({
      accessionNumber,
      studyInstanceUid,
    });
    trackEvent({
      category: SAMPLE_STUDY,
      action: VISITED,
      name: `CTB - ${description}`,
    });
    dispatch(push(`${VIEWER}?${query}`));
  };

  return (
    <CtbSampleScreenContainer>
      <SamplesContainer>
        {ctbSamples.map(({ accessionNumber, studyInstanceUid, ...restConfig }, index) => (
          <Sample
            key={index}
            size={isSmallScreen ? 'small' : 'medium'}
            {...restConfig}
            onClick={() => onSampleClick(accessionNumber, studyInstanceUid, restConfig.description)}
          />
        ))}
      </SamplesContainer>
    </CtbSampleScreenContainer>
  );
};

export default CtbSampleScreen;
