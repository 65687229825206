import { colors } from '@annaliseai/anna-design-tokens';
import { chevronRight24Filled } from '@annaliseai/anna-icons';
import IconWrapper from '@annaliseai/icon-wrapper';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import loganColors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { PRIMARY, BLACK } = colors;
const { BACKGROUNDS_WINDOW, GREY_6 } = loganColors;
const { SAMPLE } = ReactTestingLibraryDataProperties;

type Size = 'medium' | 'small';

const Container = styled.button<{ size: Size }>`
  ${({ size }) =>
    size === 'small'
      ? css`
          width: 18rem;
        `
      : css`
          width: 26.563rem;
        `}
  display: grid;
  grid-template-columns: max-content auto max-content;
  align-items: center;
  padding: 0.5rem;
  background-color: ${BACKGROUNDS_WINDOW};
  border-radius: 8px;
  text-align: left;
  &:hover {
    cursor: pointer;
    background-color: ${PRIMARY};
  }
`;

const ImageContainer = styled.img<{ size: Size }>`
  ${({ size }) =>
    size === 'small'
      ? css`
          width: 6.5rem;
          height: 6.5rem;
          margin-right: 1rem;
        `
      : css`
          width: 10.5rem;
          height: 10.5rem;
          margin-right: 1.5rem;
        `}
  background-color: ${BLACK};
  border-radius: 8px;
  object-fit: contain;
`;

const DataContainer = styled.div`
  margin-right: 0.5rem;
`;

const Description = styled.div<{ size: Size }>`
  ${({ size }) =>
    size === 'small'
      ? css`
          font-size: 0.75rem;
          margin-bottom: 0.25rem;
        `
      : css`
          font-size: 1.125rem;
          margin-bottom: 0.5rem;
        `}
  overflow: hidden;
`;

const Count = styled.div<{ size: Size }>`
  ${({ size }) =>
    size === 'small'
      ? css`
          font-size: 0.625rem;
        `
      : css`
          font-size: 0.75rem;
        `}
  color: ${GREY_6};
`;

const IconContainer = styled.div`
  margin-right: 0.5rem;
`;

export type SampleProps = {
  imageUrl: string;
  description: string;
  frontCount?: number;
  latCount?: number;
  size?: Size;
  onClick: () => void;
};

const Sample: FC<SampleProps> = ({ size = 'medium', imageUrl, description, latCount, frontCount, onClick }) => (
  <Container onClick={onClick} data-testid={SAMPLE} size={size}>
    <ImageContainer src={imageUrl} size={size} />
    <DataContainer>
      <Description size={size}>{description}</Description>
      <Count size={size}>
        {[
          { count: frontCount, label: 'FRONT' },
          { count: latCount, label: 'LAT' },
        ]
          .filter((item): item is { count: number; label: string } => item.count !== undefined)
          .map(({ count, label }) => `${count} ${label}`)
          .join(' - ')}
      </Count>
    </DataContainer>
    <IconContainer>
      <IconWrapper svg={chevronRight24Filled} />
    </IconContainer>
  </Container>
);

export default Sample;
