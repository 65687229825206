import isPropValid from '@emotion/is-prop-valid';

// This implements the default behavior from styled-components v5 for use with v6
// https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
const shouldForwardProp = (propName: string, target: unknown): boolean => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};

export default shouldForwardProp;
