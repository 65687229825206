import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import Header from 'components/Header';
import { APP } from 'constants/sizes';
import PublicTitleArea from './PublicTitleArea';

const { HEADER_HEIGHT } = APP;

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1.8fr;
  grid-template-rows: max-content minmax(0, 1fr);
  gap: 0;
  grid-template-areas:
    'nav'
    'main';
`;

const PageContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: calc(100vh - ${HEADER_HEIGHT}rem);
  padding-bottom: 3rem;
  grid-area: main;
`;

const PublicLayout = ({ children }: { children?: ReactNode }): ReactElement => {
  return (
    <Container>
      <Header />
      <PageContainer>
        <PublicTitleArea />
        {children}
      </PageContainer>
    </Container>
  );
};

export default PublicLayout;
