import { PhotometricInterpretation } from '@annaliseai/api-specifications';
import DicomTags from 'enums/DicomTags';
import { CornerstoneWADOImage } from 'types/cornerstone/CornerstoneImage';

export const getStudyInstanceUid = ({ data }: CornerstoneWADOImage): string =>
  data.string(DicomTags.STUDY_INSTANCE_UID);

export const getSopClassUid = ({ data }: CornerstoneWADOImage): string => data.string(DicomTags.SOP_CLASS_UID);

export const getTransferSyntax = ({ data }: CornerstoneWADOImage): string => data.string(DicomTags.TRANSFER_SYNTAX);

export const getHighBit = ({ data }: CornerstoneWADOImage): number => data.uint16(DicomTags.HIGH_BIT);

export const getBitsAllocated = ({ data }: CornerstoneWADOImage): number => data.uint16(DicomTags.BITS_ALLOCATED);

export const getBitsStored = ({ data }: CornerstoneWADOImage): number => data.uint16(DicomTags.BITS_STORED);

export const getSamplesPerPixel = ({ data }: CornerstoneWADOImage): number => data.uint16(DicomTags.SAMPLES_PER_PIXEL);

export const getPhotometricInterpretation = ({ data }: CornerstoneWADOImage): PhotometricInterpretation =>
  data.string(DicomTags.PHOTOMETRIC_INTERPRETATION);

export const getModality = ({ data }: CornerstoneWADOImage): string => data.string(DicomTags.MODALITY);

export const getPixelSpacing = ({ columnPixelSpacing, rowPixelSpacing }: CornerstoneWADOImage): number[] =>
  columnPixelSpacing && rowPixelSpacing ? [columnPixelSpacing, rowPixelSpacing] : [];
