import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from 'components/Header';
import LightButton from 'components/LightButton';
import TextField from 'components/TextField';
import configuration from 'configuration';
import { isLoginSending } from 'enums/LoginStates';
import Routes from 'enums/Routes';
import useRedirectIfLoggedIn from 'hooks/useRedirectIfLoggedIn';
import { authActions } from 'slices/authSlice';
import { RootState } from 'slices/RootState';
import LocationState from 'types/LocationState';
import { INTRO_TEXT, PASSWORD_PLACEHOLDER, LOGIN_TEXT, USERNAME_PLACEHOLDER } from './constants';
import Footer from './Footer/Footer';
import {
  BodyBackground,
  BodyContainer,
  ButtonContainer,
  Container,
  FormContainer,
  Intro,
  TextFieldContainer,
} from './LoginScreen.styles';

const { LIST, HOME } = Routes;

const LoginScreen: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation<LocationState>();
  const { isDemo, isViewer } = configuration;

  useRedirectIfLoggedIn();

  const { loginState } = useSelector(({ auth }: RootState) => auth);
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    let redirect: string = HOME;
    if (location.state) {
      redirect = location.state.redirect;
    }
    if (isDemo) {
      redirect = LIST;
    }
    if (isViewer) {
      redirect = HOME;
    }

    dispatch(authActions.login({ clientId, clientSecret, redirect }));
  };

  const onUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setClientId(event.target.value.trim());
  };

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setClientSecret(event.target.value.trim());
  };

  return (
    <Container>
      <Header />
      <BodyBackground>
        <BodyContainer>
          <Intro>{INTRO_TEXT}</Intro>
          <FormContainer onSubmit={onSubmit} disabled={isLoginSending(loginState)}>
            <TextFieldContainer>
              <TextField
                autoComplete="off"
                type="text"
                placeholder={USERNAME_PLACEHOLDER}
                onChange={onUsernameChange}
                id="clientId"
                disabled={isLoginSending(loginState)}
              />
            </TextFieldContainer>
            <TextFieldContainer>
              <TextField
                autoComplete="off"
                type="password"
                placeholder={PASSWORD_PLACEHOLDER}
                onChange={onPasswordChange}
                id="clientSecret"
                disabled={isLoginSending(loginState)}
              />
            </TextFieldContainer>
            <ButtonContainer>
              <LightButton type="submit" disabled={!clientId || !clientSecret || isLoginSending(loginState)}>
                {LOGIN_TEXT}
              </LightButton>
            </ButtonContainer>
          </FormContainer>
        </BodyContainer>
      </BodyBackground>
      <Footer />
    </Container>
  );
};

export default LoginScreen;
