import strings from 'constants/strings';
import { AppStrings, Strings } from 'constants/strings/strings.types';

const useStrings = (): Strings => {
  const mode = process.env.REACT_APP_APPLICATION_MODE as keyof AppStrings;

  return strings[mode];
};

export default useStrings;
