import { RefObject, useEffect } from 'react';
import { imageLoaderPrefixesMapping } from 'constants/imageLoaderPrefixesMapping';
import ImageFormats from 'enums/ImageFormats';
import { displayImage, removeImage } from 'helpers/cornerstone/cornerstoneHelper';
import { addToolsForElement, preventTextSelectionOnMouseMove } from 'helpers/cornerstone/cornerstoneToolsHelper';
import { createImageId } from 'helpers/cornerstone/imageIdHelper';
import { getJp2LoaderProvider } from 'helpers/cornerstone/jp2Loader';
import { loadImage } from 'helpers/cornerstone/loadImageHelper';
import { getImageFormat } from 'helpers/cxrImageHelper';
import { isCornerstoneWebImage } from 'typeguards/cornerstone';
import { CornerstoneWebImage } from 'types/cornerstone/CornerstoneImage';
import { CornerstoneProvider } from 'types/cornerstone/CornerstoneProvider';
import CornerstoneRenderEvent from 'types/cornerstone/CornerstoneRenderEvent';
import { CornerstoneToolsConfig } from 'types/cornerstone/CornerstoneToolsConfig';
import ImageMetadata from 'types/ImageMetadata';
import Synchronizer from 'types/Synchronizer';

type UseDisplayImageProps = {
  containerRef: RefObject<HTMLElement>;
  url?: string;
  synchronizer: Synchronizer;
  render?: CornerstoneWebImage['render'];
  onImageDisplayed?: () => void;
  metadata?: ImageMetadata;
  tools?: CornerstoneToolsConfig;
  onImageRendered?: (event: CornerstoneRenderEvent) => void;
};

const { JP2 } = ImageFormats;

const useDisplayImage = ({
  containerRef,
  url,
  synchronizer,
  render,
  onImageDisplayed,
  metadata,
  tools,
  onImageRendered,
}: UseDisplayImageProps): void => {
  useEffect(() => {
    const element = containerRef.current;
    let isCleanupInvoked = false;
    const onImageRenderedCallback = onImageRendered || (() => undefined);

    if (!element) {
      return;
    }

    removeImage(element);

    if (!url) {
      return;
    }

    (async () => {
      const format = getImageFormat(url);
      const imageId = createImageId(imageLoaderPrefixesMapping[format], url);

      const provider: CornerstoneProvider | undefined =
        format === JP2 && metadata ? getJp2LoaderProvider(imageId, { pixelRepresentation: 0, ...metadata }) : undefined;

      const image = await loadImage(imageId, { cache: true, provider });

      if (isCleanupInvoked) {
        return;
      }

      if (render && isCornerstoneWebImage(image)) {
        image.render = render;
      }

      displayImage(element, image);
      synchronizer.addElement(element);

      if (tools) {
        addToolsForElement(element, tools);
      }

      element.addEventListener('cornerstoneimagerendered', onImageRenderedCallback as EventListener);
      element.addEventListener('mousedown', preventTextSelectionOnMouseMove);

      if (onImageDisplayed) {
        onImageDisplayed();
      }
    })();

    return () => {
      synchronizer.removeElement(element);
      element.removeEventListener('cornerstoneimagerendered', onImageRenderedCallback as EventListener);
      element.removeEventListener('mousedown', preventTextSelectionOnMouseMove);
      isCleanupInvoked = true;
    };
  }, [containerRef, url, synchronizer, render, onImageDisplayed, metadata, tools, onImageRendered]);

  useEffect(() => {
    const element = containerRef.current;

    if (element) {
      return () => {
        removeImage(element);
      };
    }
  }, [containerRef]);
};

export default useDisplayImage;
