const getPaginationRange = (currentPageIndex: number, range: number, pageCount: number): number[] => {
  const actualRange = pageCount >= range ? range : pageCount;

  let startIndex = currentPageIndex - Math.floor(actualRange / 2);
  startIndex = Math.max(startIndex, 1);
  startIndex = Math.min(startIndex, 1 + pageCount - actualRange);

  return Array.from({ length: actualRange }, (_, i) => startIndex + i);
};

export default getPaginationRange;
