import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { WHITE } = colors;
const { ICON_ISO_ALERT } = ReactTestingLibraryDataProperties;

const IsoAlert = ({ color = WHITE, size = 1.5 }: Icon): JSX.Element => (
  <Container size={size} $testId={ICON_ISO_ALERT}>
    <svg width="20" height="20" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99973 0.859375L0 17.853H20L9.99973 0.859375ZM18.8344 17.1866L9.99982 2.17367L1.16558 17.1865L18.8344 17.1866ZM9.33302 13.3551H10.6665V5.02437L9.33312 5.02442L9.33302 13.3551ZM10.1016 14.3613C10.6056 14.4125 10.9995 14.8383 10.9995 15.3553C10.9995 15.3898 10.9977 15.424 10.9943 15.4576C10.9431 15.962 10.5169 16.3555 9.99948 16.3555C9.99945 16.3555 9.99941 16.3555 9.99938 16.3555C9.4473 16.3555 8.99944 15.9078 8.99944 15.3553C8.99944 14.8038 9.4473 14.3561 9.99938 14.3561C10.0339 14.3561 10.068 14.3579 10.1016 14.3613Z"
        fill={color}
      />
    </svg>
  </Container>
);

export default IsoAlert;
