import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

export type LogoProps = {
  className?: string;
  fill?: string;
};

const { LOGO } = ReactTestingLibraryDataProperties;

const Logo: FC<LogoProps> = ({ className, fill = '#666' }) => (
  <svg
    className={className}
    data-testid={LOGO}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M15.9201 14.1002L8.52006 1.29023C8.42006 1.11023 8.22006 0.990234 8.00006 0.990234C7.78006 0.990234 7.58006 1.11023 7.48006 1.29023L0.0800586 14.1002C-0.0299414 14.2902 -0.0299414 14.5202 0.0800586 14.7002C0.190059 14.8802 0.390059 15.0002 0.600059 15.0002C0.600059 15.0002 4.58006 15.0002 4.58006 14.9902C5.46006 14.9502 6.40006 14.6202 7.06006 13.4002L8.00006 11.8002L8.93006 13.4102C9.62006 14.6902 10.6301 14.9902 11.5401 15.0002H15.3901C15.6101 15.0002 15.8101 14.8802 15.9101 14.7002C16.0101 14.5202 16.0301 14.2902 15.9201 14.1002ZM11.6401 13.8002C10.9701 13.8002 10.4301 13.6402 10.0001 12.8402L8.53006 10.2902C8.42006 10.1002 8.22006 9.99023 8.00006 9.99023C7.78006 9.99023 7.58006 10.1102 7.48006 10.2902L6.00006 12.8402C5.57006 13.6402 5.03006 13.8002 4.36006 13.8002H1.65006L8.00006 2.80023L14.3501 13.8002H11.6401Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
