import IMAGE_TOOL_MOUSE_BUTTON_MASK from 'constants/imageToolMouseButtonMask';
import cornerstoneTools from 'cornerstone-tools';
import ToolNames from 'enums/ToolNames';
import { CornerstoneToolsConfig } from 'types/cornerstone/CornerstoneToolsConfig';

export const addToolsForElement = (element: HTMLElement, toolsConfig: CornerstoneToolsConfig): void => {
  Object.values(toolsConfig).forEach(({ tool, defaultConfig }) =>
    cornerstoneTools.addToolForElement(element, tool, defaultConfig),
  );
};

export const activateTool = (element: HTMLElement, toolName: ToolNames): void => {
  cornerstoneTools.setToolActiveForElement(element, toolName, { mouseButtonMask: IMAGE_TOOL_MOUSE_BUTTON_MASK });
};

export const disableTools = (element: HTMLElement, toolConfigs: CornerstoneToolsConfig): void => {
  Object.keys(toolConfigs).forEach(toolName => {
    cornerstoneTools.setToolDisabledForElement(element, toolName);
  });
};

export const preventTextSelectionOnMouseMove = (): void => {
  const onMouseMove = (event: Event) => event.preventDefault();
  const onMouseUp = () => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };
  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
};
