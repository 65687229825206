import { isoAlert } from '@annaliseai/anna-icons';
import IconWrapper from '@annaliseai/icon-wrapper';
import { Container, TextContainer } from './Disclaimer.styles';
import DisclaimerPropsType from './Disclaimer.types';

const Disclaimer = ({ tooltip, text }: DisclaimerPropsType): JSX.Element => (
  <Container title={tooltip}>
    <IconWrapper svg={isoAlert} size={1.2} />
    <TextContainer>{text}</TextContainer>
  </Container>
);

export default Disclaimer;
