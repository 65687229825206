import { Epic, ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import errorTracker from 'helpers/analytics/errorTracker';
import { ActionTypes } from 'slices/ActionTypes';
import { errorActions, SetErrorType } from 'slices/errorSlice';

const setErrorEpic: Epic<ActionTypes, SetErrorType> = action$ =>
  action$.pipe(
    ofType<ActionTypes, SetErrorType>(errorActions.setError.type),
    switchMap(({ payload }) => {
      errorTracker(payload.errorId);
      return EMPTY;
    }),
  );

export default setErrorEpic;
