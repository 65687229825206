import Cookies from 'js-cookie';
import CookieKeys from 'enums/CookieKeys';
import { AuthenticationTokens } from 'types/Tokens';

const { ACCESS_TOKEN, REFRESH_TOKEN, ID_TOKEN } = CookieKeys;

export const storeTokens = ({ token, refreshToken, idToken }: AuthenticationTokens): void => {
  Cookies.set(ACCESS_TOKEN, token);
  Cookies.set(REFRESH_TOKEN, refreshToken);
  Cookies.set(ID_TOKEN, idToken);
};

export const retrieveTokens = (): AuthenticationTokens => {
  const token = Cookies.get(ACCESS_TOKEN) || '';
  const refreshToken = Cookies.get(REFRESH_TOKEN) || '';
  const idToken = Cookies.get(ID_TOKEN) || '';
  return { token, refreshToken, idToken };
};

export const removeTokens = (): void => {
  [ACCESS_TOKEN, REFRESH_TOKEN, ID_TOKEN].forEach(key => Cookies.remove(key));
};

const tokenHelpers = {
  storeTokens,
  retrieveTokens,
  removeTokens,
};

export default tokenHelpers;
