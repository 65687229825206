export type ServiceType = {
  name: string;
  version: string;
  status: string;
  runningSubComponentCount: number;
  totalSubComponentCount: number;
  subComponents: SubServiceType[];
};

export type SubServiceType = {
  name: string;
  version: string;
  status: string;
  statusMessage: string;
};

export type StatusUpdatedType = {
  backend: string;
  ia: string;
};

export type StatusResponseType = {
  backendVersions: ServiceType[] | null;
  integrationAdapterVersions: ServiceType[] | null;
  statusLastUpdate: StatusUpdatedType;
};

export enum ACTIONS {
  CALL_API = 'CALL_API',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type InitialState = {
  services: StatusResponseType | null;
  loading: boolean;
  error: boolean;
};

export type CallApiAction = {
  type: ACTIONS.CALL_API;
};

export type SuccessAction = {
  type: ACTIONS.SUCCESS;
  data: StatusResponseType;
};

export type ErrorAction = {
  type: ACTIONS.ERROR;
};

export type ActionTypes = CallApiAction | SuccessAction | ErrorAction;

export type ServicesProps = {
  infoState: InitialState;
  statusLastUpdate?: StatusLastUpdate;
};

export type StatusLastUpdate = {
  backend: string;
  ia: string;
};
