import { StudyOverview } from '@annaliseai/api-specifications';
import CookieKeys from 'enums/CookieKeys';
import getCookie from 'helpers/cookies/getCookie';
import setCookie from 'helpers/cookies/setCookie';
import { RETAINING_WINDOW } from 'helpers/limitationHelper';

const { UPLOADED_STUDIES } = CookieKeys;

export const storeUploadedStudies = (uploadedStudies: StudyOverview[]): void => {
  setCookie(UPLOADED_STUDIES, uploadedStudies, { expires: RETAINING_WINDOW });
};

export const retrieveUploadedStudies = (): StudyOverview[] => (getCookie(UPLOADED_STUDIES) as StudyOverview[]) || [];
