import { TopSidebarProps } from 'components/sidebar/TopSidebar';
import configuration from 'configuration';
import Routes from 'enums/Routes';
import showLinks from 'helpers/showLinks';
import useSidebarNav from 'hooks/useSidebarNav';
import useStrings from 'hooks/useStrings';
import useStudiesPagination from 'hooks/useStudiesPagination';
import { isLoggedIn } from 'keycloak';

const { HOME, LIST } = Routes;
/**
 * Returns the appropriate props for the top sidebar based on the user's authentication status,
 * configuration (isDemo/isViewer), and whether to display links to navigate to the Results List.
 * @returns {TopSidebarProps} The properties for the top sidebar.
 */
const useTopSidebarProps = (): TopSidebarProps => {
  const { isDemo } = configuration;
  const { RESULTS_LIST } = useStrings();
  const { onNewStudyClick, onShareClick, onUploadsClick } = useSidebarNav();
  const studiesPaginationProps = useStudiesPagination();

  const loggedOutProps: TopSidebarProps = {
    topSidebarType: 'SHARE',
    topSidebarProps: {
      onNewStudyClick,
      onShareClick,
    },
  };

  const loggedInProps: TopSidebarProps = {
    topSidebarType: 'NAV',
    topSidebarProps: {
      ...studiesPaginationProps,
      backToStudiesProps: {
        onUploadsClick,
        route: isDemo ? LIST : HOME,
        label: RESULTS_LIST,
      },
    },
  };

  const hiddenProps: TopSidebarProps = {
    topSidebarType: 'HIDDEN',
  };

  if (!isLoggedIn()) return loggedOutProps;

  return showLinks() ? loggedInProps : hiddenProps;
};

export default useTopSidebarProps;
