import { CtbPredictionCompleted, CtbPredictionSegmentSlicesCompleted } from '@annaliseai/api-specifications';
import { CtbClassificationGroup, CtbFinding } from 'imageQueue/types';
import { getGroupedClassifications } from './getGroupedClassifications';
import getSharedLocalisationParents from './getSharedLocalisationParents';

const sortClassifications = ({
  groupId,
  sharedLocalisationParents,
  findings,
}: {
  groupId: number;
  sharedLocalisationParents: CtbFinding[];
  findings: CtbFinding[];
}) => {
  const newFindingsList = findings;
  let parentAdded = false;
  sharedLocalisationParents.forEach((parent: CtbFinding) => {
    const { groupId: parentGroupId } = parent;
    if (groupId === parentGroupId) {
      parentAdded = true;
      newFindingsList.push(parent);
    }
  });
  if (parentAdded) {
    // Since the list comes from backend already sorted, we have to sort the parent finding into the list as per the displayOrder
    newFindingsList.sort(
      (firstFinding: CtbFinding, secondFinding: CtbFinding) => firstFinding.displayOrder - secondFinding.displayOrder,
    );
  }
  return newFindingsList;
};

const getClassifications = (
  prediction: CtbPredictionCompleted,
  segments: CtbPredictionSegmentSlicesCompleted[],
): CtbClassificationGroup[] => {
  const { segmentations, lateralities, classifications } = prediction.result;
  const { groupedClassifications, sharedLocalisationClassifications } = getGroupedClassifications({
    classifications,
    segmentations,
    segments,
    lateralities,
  });
  const sharedLocalisationParents = getSharedLocalisationParents(sharedLocalisationClassifications);
  return groupedClassifications.map((classification: CtbClassificationGroup) => {
    const { groupId, findings } = classification;
    // Flag to check when to sort - means we will only sort the list a max of how many groups there are
    const sortedFindingsList = sortClassifications({
      groupId,
      sharedLocalisationParents,
      findings,
    });
    return {
      ...classification,
      findings: sortedFindingsList,
    };
  });
};

export default getClassifications;
