import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { ICON_TEXT_COLUMN, ICON_TEXT_COLUMN_SOLID } = ReactTestingLibraryDataProperties;
const { WHITE } = colors;

const ColumnTriple: FC<Icon> = ({ size = 1.5, color = WHITE, fillOpacity = 1 }) => (
  <Container size={size} $testId={ICON_TEXT_COLUMN}>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M4 17C3.44772 17 3 16.5523 3 16V14C3 13.4477 3.44772 13 4 13H16C16.5523 13 17 13.4477 17 14V16C17 16.5523 16.5523 17 16 17H4ZM4 14L4 16H16V14H4ZM4 12C3.44772 12 3 11.5523 3 11V9C3 8.44772 3.44772 8 4 8H16C16.5523 8 17 8.44771 17 9V11C17 11.5523 16.5523 12 16 12H4ZM4 9L4 11H16V9H4ZM3 6C3 6.55229 3.44772 7 4 7H16C16.5523 7 17 6.55228 17 6V4C17 3.44771 16.5523 3 16 3H4C3.44772 3 3 3.44772 3 4L3 6ZM4 6L4 4H16V6H4Z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  </Container>
);

export default ColumnTriple;

export const ColumnTripleSolid: FC<Icon> = ({ size = 1.5, color = WHITE, fillOpacity = 1 }) => (
  <Container size={size} $testId={ICON_TEXT_COLUMN_SOLID}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
      <path
        fill={color}
        fillOpacity={fillOpacity}
        d="M3 16C3 16.5523 3.44772 17 4 17H16C16.5523 17 17 16.5523 17 16V14C17 13.4477 16.5523 13 16 13H4C3.44772 13 3 13.4477 3 14V16ZM3 11C3 11.5523 3.44772 12 4 12H16C16.5523 12 17 11.5523 17 11V9C17 8.44771 16.5523 8 16 8H4C3.44772 8 3 8.44772 3 9V11ZM3 6C3 6.55229 3.44772 7 4 7H16C16.5523 7 17 6.55229 17 6V4C17 3.44771 16.5523 3 16 3H4C3.44772 3 3 3.44772 3 4V6Z"
      />
    </svg>
  </Container>
);
