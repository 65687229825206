import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvents, UserInput } from 'components/Form/Form.types';
import { isKeyboardEvent, isMouseEvent, isSubmitAction } from 'components/Form/Form.utils';
import INPUTS from 'constants/inputs';
import CookieKeys from 'enums/CookieKeys';
import Pages from 'enums/Pages';
import { removeUserSettings, storeUserSettings } from 'helpers/cookies/userSettingsHelper';
import {
  selectCanResetForm,
  selectCanSaveFilters,
  selectCanSearchForm,
  selectStudiesQuery,
} from 'selectors/studyList/selectStudyList';
import { studyListActions } from 'slices/studyListSlice';
import getSavedFilters, { SAVED_FILTERS } from './getSavedFilters';
import { INPUT_ELEMENTS } from './SearchFilter.constants';
import { UseSearchFilterTypes } from './SearchFilter.types';
import { buildPredefinedDates, sanitizeSearchQueryParams } from './SearchFilter.utils';

const { PAGE_1 } = Pages;
const { SEARCH_SELECTION } = CookieKeys;

const { CUSTOM_DATES } = INPUTS;
const { DATE_TYPE_ATTRS, SEARCH_ATTRS } = INPUT_ELEMENTS;

const { setQuery, resetQuery, setCanSearchForm, setCanResetForm, setCanSaveFilters } = studyListActions;

const useSearchFilter = (): UseSearchFilterTypes => {
  const dispatch = useDispatch();
  const canSearchForm = useSelector(selectCanSearchForm) || false;
  const canResetForm = useSelector(selectCanResetForm) || false;
  const canSaveFilters = useSelector(selectCanSaveFilters) || false;
  const studiesQuery = useSelector(selectStudiesQuery);
  const savedFilters = getSavedFilters();
  const {
    createdAfter: savedCreatedAfter,
    createdUntil: savedCreatedUntil,
    dateType: savedDateType,
    requestingService: savedRequestingService,
    institutionName: savedInstitutionName,
    studyType: savedStudyType,
  } = savedFilters;

  // Use query in state if it exists as first choice,
  // if not, use the saved defaults from localStorage,
  // or empty string as fallback
  const {
    createdAfter = savedCreatedAfter || '',
    createdUntil = savedCreatedUntil || '',
    dateType = savedDateType || '',
    requestingService = savedRequestingService || '',
    institutionName = savedInstitutionName || '',
    search = '',
    studyType = savedStudyType || '',
  } = studiesQuery;

  // We need to keep internal state here so that the form can be updated without the query params being set
  // E.g. when the limit (num studies per page) changes, we don't want the other query params to be referenced (until a search is explicitly executed)
  const [values, setValues] = useState({
    createdAfter,
    createdUntil,
    dateType,
    institutionName,
    requestingService,
    search,
    studyType,
  });
  const [isCustomDates, setIsCustomDates] = useState(dateType === CUSTOM_DATES);
  const [isDateDefault, setIsDateDefault] = useState(!dateType);
  const [filtersHaveBeenSaved, setFiltersHaveBeenSaved] = useState(false);

  useEffect(() => {
    const sanitizedQueryParams = sanitizeSearchQueryParams(studiesQuery);
    dispatch(studyListActions.fetch(sanitizedQueryParams));
  }, [dispatch, studiesQuery]);

  // Check if form's local state is otherwise clean
  const isClean = values.requestingService === '' && values.studyType === '' && isDateDefault;

  // Perform a search by updating the query params, based on the internal state values
  const handleSearch = (e: UserInput) => {
    isSubmitAction(e) && e.preventDefault(); // Always prevent default when action is submit
    // If is submit action, is dirty, and the criteria hasn't already been searched, then perform another search
    if (isSubmitAction(e) && canSearchForm) {
      dispatch(setQuery({ ...values, page: PAGE_1 }));
      storeUserSettings(SEARCH_SELECTION, { ...values, page: PAGE_1 });
      dispatch(setCanSearchForm(false));
      !isClean && dispatch(setCanResetForm(true));
    }
  };

  // Update the internal state values
  const handleChange = (e: ChangeEvents) => {
    const { name, value } = e?.target;
    const isSearchChange = name === SEARCH_ATTRS.name;
    const isDateTypeChange = name === DATE_TYPE_ATTRS.name;
    const isDefaultDateSelection = isDateTypeChange && value === '';
    const isCustomDateSelection = isDateTypeChange && value === CUSTOM_DATES;

    // Reset the createdAfter/createdUntil inputs when;
    //  - default date type is selected
    //  - custom dates type is selected
    const resetDates =
      isDefaultDateSelection || isCustomDateSelection
        ? {
            createdAfter: '',
            createdUntil: '',
          }
        : {};

    // Build form input values
    const newValues = {
      ...values,
      ...resetDates,
      [name]: value,
    };

    // Only need to handle this when the date type is updated
    isDateTypeChange && setIsCustomDates(isCustomDateSelection);

    // Can save filters if it's NOT a string search, OR if it is a date change, it's NOT custom dates
    !isSearchChange && !isCustomDateSelection && dispatch(setCanSaveFilters(true));
    !isSearchChange && setFiltersHaveBeenSaved(false);

    dispatch(setCanSearchForm(true));
    dispatch(setCanResetForm(true));
    setValues(newValues);
  };

  // Reset the values, including the default createdAfter/createdUntil values
  const handleReset = () => {
    setValues({
      // if there is a dateType in the saved filters, we need to reconstruct the predefined dates with those values to keep the LAST_X_HOURS relative to right now.
      ...buildPredefinedDates(savedDateType),
      dateType: '',
      institutionName: '',
      requestingService: '',
      search: '',
      studyType: '',
      // overwrite any of the above defaults using the saved filters
      ...savedFilters,
    });

    removeUserSettings(SEARCH_SELECTION);
    dispatch(resetQuery());
    dispatch(setCanSearchForm(false));
    dispatch(setCanResetForm(false));
    dispatch(setCanSaveFilters(false));
    setIsCustomDates(false);
    setIsDateDefault(dateType === savedDateType || !dateType);
  };

  const handleClearSearch = () => {
    let newValues;

    if (isClean) {
      newValues = {
        ...values,
        ...buildPredefinedDates(''),
        search: '',
      };
      dispatch(setCanResetForm(false));
    } else {
      newValues = {
        ...values,
        search: '',
      };
      dispatch(setCanResetForm(true));
    }
    dispatch(setCanSearchForm(true));
    setValues(newValues);
  };

  const handleSaveFilters = () => {
    const { dateType, institutionName, requestingService, studyType } = values;

    const filtersToSave = {
      dateType: dateType !== CUSTOM_DATES && dateType,
      institutionName,
      requestingService,
      studyType,
    };

    try {
      localStorage.setItem(SAVED_FILTERS, JSON.stringify(filtersToSave));
      dispatch(setCanSaveFilters(false));
      setFiltersHaveBeenSaved(true);
    } catch {
      // nothing bad will happen, but we probably don't want to display a success message
      // maybe error state here one day
    }
  };

  return {
    canSearchForm,
    canResetForm,
    canSaveFilters,
    filtersHaveBeenSaved,
    handleChange,
    handleClearSearch,
    handleReset,
    handleSearch,
    handleSaveFilters,
    isCustomDates,
    isKeyboardEvent,
    isMouseEvent,
    values,
  };
};

export default useSearchFilter;
