import styled from 'styled-components';
import { APP } from 'constants/sizes';

const { CONFIDENCE_INDICATOR_HEIGHT, CONFIDENCE_INDICATOR_MARGIN_BOTTOM } = APP;

const IndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${CONFIDENCE_INDICATOR_MARGIN_BOTTOM}rem;
  height: ${CONFIDENCE_INDICATOR_HEIGHT}rem;
`;

export default IndicatorContainer;
