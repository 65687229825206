import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const Warning: FC = () => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={ReactTestingLibraryDataProperties.ICON_WARNING}
  >
    <path
      d="M7.27232 1.85608C7.9655 1.47214 8.83086 1.69523 9.25608 2.34957L9.31128 2.44146L14.4839 11.7803C14.6071 12.0027 14.6717 12.2528 14.6717 12.5071C14.6717 13.301 14.055 13.9508 13.2744 14.0036L13.1717 14.0071H2.82791C2.5737 14.0071 2.32367 13.9425 2.10128 13.8193C1.40805 13.4355 1.13803 12.5836 1.46706 11.876L1.51566 11.7805L6.68686 2.44161C6.82321 2.19537 7.02609 1.99246 7.27232 1.85608ZM8.00029 10.6692C7.63259 10.6692 7.33452 10.9672 7.33452 11.3349C7.33452 11.7026 7.63259 12.0007 8.00029 12.0007C8.36799 12.0007 8.66607 11.7026 8.66607 11.3349C8.66607 10.9672 8.36799 10.6692 8.00029 10.6692ZM7.99885 5.33334C7.65696 5.33347 7.37527 5.59093 7.33688 5.9225L7.33243 6.00025L7.33363 9.33417L7.33814 9.41191C7.37677 9.74346 7.65864 10.0007 8.00053 10.0006C8.34242 10.0005 8.62411 9.74301 8.6625 9.41144L8.66696 9.33369L8.66576 5.99977L8.66124 5.92202C8.62262 5.59048 8.34074 5.33322 7.99885 5.33334Z"
      fill="white"
    />
  </svg>
);

export default Warning;
