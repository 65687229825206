import styled, { css } from 'styled-components';
import { ButtonSize } from 'components/Buttons/Button/Button';
import { ButtonTextLargeCss, ButtonTextMediumCss, ButtonTextSmallCss } from './ButtonText.styles';

type ButtonTextProps = {
  size?: ButtonSize;
};

const { SMALL, MEDIUM, LARGE } = ButtonSize;

const ButtonText = styled.div<ButtonTextProps>(
  ({ size = MEDIUM }) => css`
    ${size === SMALL && ButtonTextSmallCss};
    ${size === LARGE && ButtonTextLargeCss};
    ${size === MEDIUM && ButtonTextMediumCss};
  `,
);

export default ButtonText;
