import uniq from 'lodash.uniq';
import errorsMapping from 'constants/errorsMapping';
import CornerstoneLoaderPrefixes from 'enums/CornerstoneLoaderPrefixes';
import CustomErrors from 'enums/CustomErrors';
import { ValidationResult } from 'types/ValidationResult';
import { validateDicom } from './cornerstone/dicomHelper';
import { getFromFileCache, setToFileCache } from './cornerstone/fileCache';
import { createImageId } from './cornerstone/imageIdHelper';
import validateCxrImageFile from './cxrImage/validateCxrImageFile';
import isDicomFile from './file/isDicomFile';
import isEmptyFile from './file/isEmptyFile';
import isImageFile from './file/isImageFile';

const { NO_IMAGES_SELECTED, UNEXPECTED_FRONTEND_ERROR, MUST_SELECT_SAME_FILE_TYPES, INVALID_FILE_TYPE } = CustomErrors;
const { DICOMFILE } = CornerstoneLoaderPrefixes;

export const NO_EXTENSION = 'noExtension';
const DCM = 'dcm';

export const readableSize = (sizeInBytes: number, decimalSystem = false, decimalPoint = 1): string => {
  const threshold = decimalSystem ? 1000 : 1024;

  if (Math.abs(sizeInBytes) < threshold) {
    return sizeInBytes + ' B';
  }

  const units = decimalSystem
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let unitIndex = -1;
  const roundingPoint = 10 ** decimalPoint;

  do {
    sizeInBytes /= threshold;
    ++unitIndex;
  } while (
    Math.round(Math.abs(sizeInBytes) * roundingPoint) / roundingPoint >= threshold &&
    unitIndex < units.length - 1
  );

  return `${sizeInBytes.toFixed(decimalPoint)} ${units[unitIndex]}`;
};

export const getExtension = ({ name }: File): string =>
  (name.includes('.') && name.split('.').pop()?.toLowerCase()) || NO_EXTENSION;

export const validateFilesCount = async (files: File[]): Promise<ValidationResult<File[]>> => {
  const nonEmptyFiles = files.filter(file => !isEmptyFile(file));
  const value = files;

  if (nonEmptyFiles.length < 1) {
    return { error: errorsMapping[NO_IMAGES_SELECTED], value };
  }

  if (nonEmptyFiles.length > 3) {
    return { error: errorsMapping[UNEXPECTED_FRONTEND_ERROR], value };
  }

  return { value };
};

export const validateFiles = (files: File[]): ValidationResult<File[]> => {
  const nonEmptyFiles = files.filter(file => !isEmptyFile(file));
  const nonEmptyExtensions = nonEmptyFiles.map(getExtension);
  const value = files;

  const fileTypes = uniq(nonEmptyExtensions);

  // Need to allow case where a combination of *.dcm and files without extensions are uploaded
  const onlyContainsDicoms = fileTypes.length === 2 && fileTypes.includes(NO_EXTENSION) && fileTypes.includes(DCM);

  if (fileTypes.length > 1 && !onlyContainsDicoms) {
    return { error: errorsMapping[MUST_SELECT_SAME_FILE_TYPES], value };
  }

  for (const file of nonEmptyFiles) {
    if (!isImageFile(file) && !isDicomFile(file)) {
      return { error: errorsMapping[INVALID_FILE_TYPE], value };
    }
  }

  return { value };
};

export const validateFile = async (index: number, file: File): Promise<ValidationResult<File>> => {
  const value = file;
  if (isDicomFile(file)) {
    const currentFile = getFromFileCache(index);

    setToFileCache(index, file);

    const { error } = await validateDicom(createImageId(DICOMFILE, `${index}`));

    setToFileCache(index, currentFile);
    if (error) {
      return { error, value };
    }
  }

  if (isImageFile(file)) {
    const { error } = await validateCxrImageFile(file);

    if (error) {
      return { error, value };
    }
  }

  return { value };
};

export const generateMatomoSelectionTrackingName = (file: File, transferSyntax?: string | null): string => {
  const { size } = file;
  const extension = getExtension(file);

  return `${extension}:${readableSize(size, true)}${transferSyntax ? `-${transferSyntax}` : ''}`;
};
