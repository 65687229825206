import React, { FC } from 'react';
import styled, { DataAttributes, css } from 'styled-components';
import IconDirection from 'enums/IconDirection';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { UP, DOWN, LEFT, RIGHT } = IconDirection;
const { ICON_CHEVRON, ICON_CHEVRON_PATH, ICON_CHEVRON_SVG } = ReactTestingLibraryDataProperties;

const ROTATE = {
  [UP]: '90',
  [RIGHT]: '180',
  [DOWN]: '-90',
  [LEFT]: '0',
};

interface ChevronProps extends React.SVGAttributes<SVGElement> {
  active?: boolean;
  direction?: IconDirection;
  fill?: string;
}

interface ContainerProps {
  direction?: IconDirection;
}

const Container = styled.div.attrs<DataAttributes>({
  'data-testid': ICON_CHEVRON,
})<ContainerProps>`
  width: 1.5rem;
  height: 1.5rem;

  ${({ direction }) =>
    direction &&
    css`
      transform: rotate(${ROTATE[direction]}deg);
    `}/* keeping this on the container until Safari implements svg 2.0 */
`;

const Chevron: FC<ChevronProps> = ({ active = false, direction = RIGHT, fill = '#FFF' }) => (
  <Container direction={direction}>
    <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={ICON_CHEVRON_SVG}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.162 9.504c.228.228.228.597 0 .825L12.492 14l3.67 3.67c.228.229.228.598 0 .826-.227.228-.597.228-.825 0l-4.083-4.083c-.228-.228-.228-.598 0-.825l4.083-4.084c.228-.228.598-.228.825 0z"
        fill={fill}
        opacity={active ? '1' : '.25'}
        data-testid={ICON_CHEVRON_PATH}
      />
    </svg>
  </Container>
);

export default Chevron;
