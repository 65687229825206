import { Constants, KeyViewSlices, ViewType } from '@annaliseai/api-specifications';

const { AXIAL, CORONAL, SAGITTAL } = ViewType;
const {
  VIEW_SLICES_MAX_COUNT: {
    AXIAL: AXIAL_SLICES_MAX_COUNT,
    CORONAL: CORONAL_SLICES_MAX_COUNT,
    SAGITTAL: SAGITTAL_SLICES_MAX_COUNT,
  },
} = Constants;

const normaliseCtbKeyViewSlices = (keyViewSlices: KeyViewSlices): KeyViewSlices => {
  // Default to mid stack
  const defaults: KeyViewSlices = {
    [AXIAL]: AXIAL_SLICES_MAX_COUNT / 2,
    [CORONAL]: CORONAL_SLICES_MAX_COUNT / 2,
    [SAGITTAL]: SAGITTAL_SLICES_MAX_COUNT / 2,
  };
  return Object.entries(keyViewSlices).reduce((acc, [key, value]) => {
    // Axial values need to be reversed because what the backend sends and
    // what we need to display is flipped.
    // Reverse the original index. For example:
    // * index 0 => 143
    // * index 1 => 142
    // ...
    // * index 142 => 1
    // * index 143 => 0
    if (key === AXIAL) {
      return {
        ...acc,
        [key]: AXIAL_SLICES_MAX_COUNT - 1 - value,
      };
    }

    // No changes for Coronal and Sagittal
    return {
      ...acc,
      [key]: value,
    };
  }, defaults);
};

export default normaliseCtbKeyViewSlices;
