import { DisplayData } from '@annaliseai/api-specifications';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocsUrls } from 'types/DocsManifest';

// TODO: to be moved to env/configurator during localisation toggle/org settings stories
export const APP_LOCALE = 'en';
export const APP_REGION = 'ANZ';

export type SettingsState = {
  requestingServices?: string[];
  institutionNames?: string[];
  docsManifest?: DocsUrls;
  displayData?: DisplayData;
};

export const initialState: SettingsState = {};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    fetchRequestingServices: state => ({
      ...state,
    }),
    setRequestingServices: (state, { payload }: PayloadAction<SettingsState['requestingServices']>) => ({
      ...state,
      requestingServices: payload,
    }),
    resetRequestingServices: state => ({
      ...state,
      requestingServices: undefined,
    }),
    fetchInstitutionNames: state => ({
      ...state,
    }),
    setInstitutionNames: (state, { payload }: PayloadAction<SettingsState['institutionNames']>) => ({
      ...state,
      institutionNames: payload,
    }),
    resetInstitutionNames: state => ({
      ...state,
      institutionNames: undefined,
    }),
    fetchDocsManifest: state => ({
      ...state,
    }),
    setDocsManifest: (state, { payload }: PayloadAction<SettingsState['docsManifest']>) => ({
      ...state,
      docsManifest: payload,
    }),
    resetDocsManifest: state => ({
      ...state,
      docsManifest: undefined,
    }),
    fetchOrgDisplayData: state => ({
      ...state,
    }),
    setOrgDisplayData: (state, { payload }: PayloadAction<SettingsState['displayData']>) => ({
      ...state,
      displayData: payload,
    }),
    resetOrgDisplayData: state => ({
      ...state,
      displayData: undefined,
    }),
  },
});

export const settingsActions = settingsSlice.actions;
export type FetchRequestingServicesType = ReturnType<typeof settingsActions.fetchRequestingServices>;
export type SetRequestingServicesType = ReturnType<typeof settingsActions.setRequestingServices>;
export type ResetRequestingServicesType = ReturnType<typeof settingsActions.resetRequestingServices>;
export type FetchInstitutionNamesType = ReturnType<typeof settingsActions.fetchInstitutionNames>;
export type SetInstitutionNamesType = ReturnType<typeof settingsActions.setInstitutionNames>;
export type ResetInstitutionNamesType = ReturnType<typeof settingsActions.resetInstitutionNames>;
export type FetchDocsManifestType = ReturnType<typeof settingsActions.fetchDocsManifest>;
export type SetDocsManifestType = ReturnType<typeof settingsActions.setDocsManifest>;
export type ResetDocsManifestType = ReturnType<typeof settingsActions.resetDocsManifest>;
export type FetchOrgDisplayDataType = ReturnType<typeof settingsActions.fetchOrgDisplayData>;
export type SetOrgDisplayDataType = ReturnType<typeof settingsActions.setOrgDisplayData>;
export type ResetOrgDisplayDataType = ReturnType<typeof settingsActions.resetOrgDisplayData>;
