import React, { ReactElement } from 'react';
import Header from 'components/Header';
import ScreenVariant from 'enums/ScreenVariant';
import useStrings from 'hooks/useStrings';
import { BodyContainer, Container, NoPermissionsHeader, NoPermissionWrapper } from './NoPermission.styles';

const NoPermission = (): ReactElement => {
  const { NO_PERMISSION } = useStrings();
  const { RESULTS } = ScreenVariant;

  return (
    <Container>
      <Header screenVariant={RESULTS} />
      <BodyContainer>
        <NoPermissionWrapper>
          <NoPermissionsHeader>{NO_PERMISSION}</NoPermissionsHeader>
        </NoPermissionWrapper>
      </BodyContainer>
    </Container>
  );
};

export default NoPermission;
