const createMapFromMapValues = <
  M extends Record<string, { uuid: string }>,
  V extends { uuid: string } = M extends Record<string, infer V> ? V : never,
  I extends string = V extends { uuid: infer I } ? I : never,
>(
  map: M,
): Record<I, V> => {
  return Object.values(map).reduce((acc, curr) => ({ ...acc, [curr.uuid]: curr }), {} as Record<I, V>);
};

export default createMapFromMapValues;
