import uuid from 'helpers/uuid';
import Finding, { Parent } from 'types/study/Finding';
import { FindingItem, FindingType, isFindingWithChildren, ParentFindingItem } from 'types/viewer/FindingsMenu';

const getFindingItems = (
  findings: FindingType[],
  findingsMapByLabel: Record<string, Finding>,
  parentsMapByLabel: Record<string, Parent> = {},
): (FindingItem | ParentFindingItem)[] => {
  return findings.map(item => {
    if (isFindingWithChildren(item)) {
      return {
        uuid: uuid('finding-item'),
        parentFindingId: parentsMapByLabel[item.label].uuid,
        isParent: true,
        findings: item.childFindings.map(childItem => {
          return {
            uuid: uuid('finding-item'),
            findingUuid: findingsMapByLabel[childItem.label].uuid,
            isParent: false,
          };
        }),
      };
    }
    return {
      uuid: uuid('finding-item'),
      findingUuid: findingsMapByLabel[item.label].uuid,
      isParent: false,
    };
  });
};

export default getFindingItems;
