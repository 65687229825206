import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { exhaustMap, switchMap } from 'rxjs/operators';
import { postImagesUpload, postStudiesPredict } from 'api/queries';
import errorsMapping from 'constants/errorsMapping';
import CustomErrors from 'enums/CustomErrors';
import { getRemainingFreeStudies, isLoggedIn } from 'helpers/limitationHelper';
import randomId from 'helpers/random/randomId';
import { ActionTypes } from 'slices/ActionTypes';
import { errorActions, SetErrorType } from 'slices/errorSlice';
import { RunInitStudyEffectType, studyActions } from 'slices/studySlice';
import { UploadImagesType, uploadActions } from 'slices/uploadSlice';

const { LIMIT_FREE_STUDIES_ERROR } = CustomErrors;

const { runUploadImagesEffect } = uploadActions;

// Note: currently only used for CXR image uploads
export const uploadImagesEpic: Epic<ActionTypes, RunInitStudyEffectType | SetErrorType> = action$ =>
  action$.pipe(
    ofType<ActionTypes, UploadImagesType>(runUploadImagesEffect.type),
    switchMap(({ payload }) => {
      const accessionNumber = randomId();
      const studyInstanceUid = randomId(64);
      const userLoggedIn = isLoggedIn();
      const imageIds = payload.map(({ imageInstanceUid }) => imageInstanceUid);

      if ((!userLoggedIn && getRemainingFreeStudies() > 0) || userLoggedIn) {
        return from(postImagesUpload(accessionNumber, studyInstanceUid, payload)).pipe(
          exhaustMap(() =>
            from(postStudiesPredict(accessionNumber, imageIds)).pipe(
              switchMap(_ => of(studyActions.runInitStudyEffect({ studyInstanceUid, accessionNumber }))),
            ),
          ),
        );
      }

      return of(errorActions.setError(errorsMapping[LIMIT_FREE_STUDIES_ERROR]));
    }),
  );

const uploadEpic = combineEpics(uploadImagesEpic);

export default uploadEpic;
