enum CustomErrors {
  INVALID_FILE_TYPE = 'J001',
  FILE_SIZE_TOO_LARGE = 'J002',
  IMAGE_RESOLUTION_TOO_LOW = 'J003',
  INCORRECT_MODALITY = 'J004',
  UNSUPPORTED_TRANSFER_SYNTAX = 'J005',
  UNSUPPORTED_DICOM_FILE = 'J006',
  NO_IMAGES_SELECTED = 'J007',
  TOO_MANY_IMAGES = 'J008',
  THRESHOLD_NOT_SCALABLE = 'J009',
  FAILED_TO_READ_IMAGE_FILE = 'J010',
  STUDY_NOT_FOUND = 'J011',
  FAILED_TO_AUTHENTICATE = 'J012',
  SERVER_ERROR = 'J014',
  BAD_REQUEST = 'J015',
  REQUIRED_AP_PA_IMAGE_ERROR = 'J016',
  REQUIRED_CXR_ERROR = 'J017',
  IMAGE_DOWNLOAD_ERROR = 'J018',
  MODEL_ERROR = 'J019',
  PROCESSING_ERROR = 'J020',
  IMAGE_PROCESSING_ERROR = 'J021',
  PROCESSING_TTL_EXPIRED_ERROR = 'J022',
  UNEXPECTED_NETWORK_ERROR = 'J023',
  MUST_SELECT_SAME_FILE_TYPES = 'J024',
  IMAGE_STATUS_TIMEOUT = 'J025',
  SEGMENT_STATUS_TIMEOUT = 'J026',
  MIN_AGE_VIOLATION_ERROR = 'J027',
  MAX_IMAGE_VIOLATION_ERROR = 'J028',
  SESSION_EXPIRED_ERROR = 'J029',
  CLOUDFLARE_BLOCKED_ERROR = 'J030',
  LIMIT_FREE_STUDIES_ERROR = 'J031',
  INVALID_RESPONSE = 'J032',
  UNEXPECTED_FRONTEND_ERROR = 'J098',
  UNEXPECTED_BACKEND_ERROR = 'J099',
  SHARE_UNAVAILABLE = 'J100',
  // CTB Errors
  CONTRAST_SERIES_NOT_SUPPORTED_ERROR = 'J101',
  SLICE_THICKNESS_TOO_HIGH_ERROR = 'J102',
  INSUFFICIENT_IMAGES_ERROR = 'J103',
  TOO_MANY_IMAGES_ERROR = 'J104',
  SERIES_NOT_PRIMARY_ERROR = 'J105',
  ATTRIBUTES_SCORE_TOO_LOW_ERROR = 'J106',
  ATTRIBUTES_IO_ERROR = 'J107',
  ATTRIBUTES_INVALID_INPUT_ERROR = 'J108',
  ATTRIBUTES_PREDICTION_ERROR = 'J109',
  REGISTRATION_INVALID_DICOM_ERROR = 'J110',
  REGISTRATION_PREDICTION_OOD_ERROR = 'J111',
  REGISTRATION_POOR_MIS_SCORE_ERROR = 'J112',
  REGISTRATION_GENERAL_ERROR = 'J113',
  IO_ERROR = 'J114',
  REGISTRATION_ARCHIVE_ERROR = 'J115',
  MODEL_CONFIG_ERROR = 'J116',
  POST_PROCESSING_ERROR = 'J117',
  SEGMENTS_PROCESSING_ERROR = 'J118',
  // Statuses
  IN_PROGRESS = 'J119',
  PENDING = 'J120',
  INVALID_ENVIRONMENT_VARIABLE = 'J121',
  FAILED_TO_INITIALIZE_KEYCLOAK = 'J122',
  REAL_TIME_UPDATES_ERROR = 'J123',
}

export default CustomErrors;
