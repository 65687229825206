import { scaleConfidenceIndicatorValues } from 'helpers/confidenceHelper';
import uuid from 'helpers/uuid';
import Finding from 'types/study/Finding';

type GetFindings = <
  T extends {
    label: string;
    labelName: string;
    confidence: number;
    predictionProbability: number;
    predictionThreshold: number;
  },
>(_: {
  findings: T[];
}) => {
  findingsMapByLabel: Record<string, Finding>;
};

const getFindings: GetFindings = ({ findings }) => {
  const findingsMapByLabel: Record<string, Finding> = {};

  findings.forEach(({ label, labelName, confidence, predictionProbability, predictionThreshold }) => {
    const {
      confidence: scaledConfidence,
      probability: scaledProbability,
      threshold: scaledThreshold,
    } = scaleConfidenceIndicatorValues({
      confidence,
      probability: predictionProbability,
      threshold: predictionThreshold,
    });

    const finding: Finding = {
      uuid: uuid('finding'),
      name: labelName,
      isParent: false,
      confidence: scaledConfidence,
      predictionProbability: scaledProbability,
      predictionThreshold: scaledThreshold,
    };

    findingsMapByLabel[label] = finding;
  });

  return {
    findingsMapByLabel,
  };
};

export default getFindings;
