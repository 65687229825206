import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { fetchImagesInWebWorker } from 'imageQueue/stateManagers/webWorkerManager';
import getClassifications from 'imageQueue/utils/getClassifications';
import { ActionTypes } from 'slices/ActionTypes';
import { ctbViewerActions } from 'slices/ctbViewerSlice';
import { findingsMenuActions } from 'slices/findingsMenuSlice';
import { findingsActions } from 'slices/findingsSlice';
import { imagesActions } from 'slices/imagesSlice';
import { localisationsActions } from 'slices/localisationsSlice';
import { parentsActions } from 'slices/parentsSlice';
import getCtbRelevantLocalisations from './helpers/ctb/getCtbRelevantLocalisations';
import getCtbStudyNormalisedData from './helpers/ctb/getCtbStudyNormalisedData';
import loadCtbImageSlices from './helpers/ctb/loadCtbImageSlices';
import loadCtbPrediction from './helpers/ctb/loadCtbPrediction';
import loadCtbSegments from './helpers/ctb/loadCtbSegments';

const initCtbStudyEpic: Epic<ActionTypes> = action$ =>
  action$.pipe(
    ofType<ActionTypes, ReturnType<typeof ctbViewerActions.runInitCtbStudyEffect>>(
      ctbViewerActions.runInitCtbStudyEffect.type,
    ),
    switchMap(async ({ payload: studyExternal }) => ({
      studyExternal,
      prediction: await loadCtbPrediction(studyExternal.study.series.seriesInstanceUid),
    })),
    mergeMap(async data => {
      const { studyExternal, prediction } = data;

      const relevantSegmentations = getCtbRelevantLocalisations(
        prediction.result.segmentations,
        prediction.result.classifications.relevant.flatMap(({ predictions }) => predictions),
      );

      const [ctbImageSlices, ctbPredictionSegmentSlices] = await Promise.all([
        loadCtbImageSlices(studyExternal.study.series.seriesInstanceUid, prediction.seriesVersionId),
        loadCtbSegments(
          prediction.id,
          relevantSegmentations.map(({ segmentId }) => segmentId),
        ),
      ]);

      const classifications = getClassifications(prediction, ctbPredictionSegmentSlices);

      return { ...data, classifications, ctbImageSlices, ctbPredictionSegmentSlices };
    }),
    mergeMap(data => {
      const {
        imagesMap,
        localisationsMap,
        findingsMap,
        parentsMap,
        findingViewsMap,
        findingViewersMap,
        findingItemsMap,
        findingsGroupsMap,
        findingsMenu,
        seriesInfo,
        imageSliceIdsMap,
        segmentSliceIdsMap,
      } = getCtbStudyNormalisedData(data);

      // initialise image queue, via web worker
      const { classifications } = data;
      fetchImagesInWebWorker({ baseImageIdsByView: imageSliceIdsMap, classifications });

      return of(
        imagesActions.setImagesMap(imagesMap),
        localisationsActions.setLocalisationsMap(localisationsMap),
        findingsActions.setFindingsMap(findingsMap),
        parentsActions.setParentsMap(parentsMap),
        findingsMenuActions.setFindingsMenu({ findingsMenu, findingsGroupsMap, findingItemsMap }),
        ctbViewerActions.setFindingViewersMap({ findingViewsMap, findingViewersMap }),
        ctbViewerActions.setSeriesInfo(seriesInfo),
        ctbViewerActions.setSliceIdsMap({ imageSliceIdsMap, segmentSliceIdsMap }),
      );
    }),
  );

export default initCtbStudyEpic;
