import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import ScreenVariant from 'enums/ScreenVariant';
import { LogoProps } from './Logo';

export type LogoScreenVariantProps = LogoProps & {
  variant?: string;
  logoFill?: string;
  textFill?: string;
};

const { LOGO_ADMIN, LOGO_DEMO, LOGO_RESULTS, LOGO_VIEWER } = ReactTestingLibraryDataProperties;
const { ADMIN, DEMO, RESULTS, VIEWER } = ScreenVariant;

const viewerLogoPath = (
  <>
    <path d="m34.0186 15.9987c.3481 0 .5897.3469.4689.6735l-4.2128 11.3924c-.0726.1963-.2597.3266-.469.3266h-1.2399c-.2093 0-.3964-.1303-.469-.3266l-4.2128-11.3924c-.1208-.3266.1208-.6735.469-.6735h.9914c.2131 0 .4027.135.4725.3364l3.3043 9.5389h.1291l3.3043-9.5389c.0698-.2014.2594-.3364.4725-.3364z" />
    <path d="m37.3521 28.3912c-.2761 0-.5-.2239-.5-.5v-11.3925c0-.2761.2239-.5.5-.5h.9041c.2761 0 .5.2239.5.5v11.3925c0 .2761-.2239.5-.5.5zm.4682-14.4579c-.3711 0-.6912-.1264-.9601-.3792-.2636-.2528-.3953-.5567-.3953-.9117 0-.3549.1317-.6588.3953-.9116.2689-.2528.589-.3792.9601-.3792s.6885.1264.952.3792c.269.2528.4034.5567.4034.9116 0 .355-.1344.6589-.4034.9117-.2635.2528-.5809.3792-.952.3792z" />
    <path d="m47.0418 28.6494c-1.1941 0-2.2241-.2636-3.0901-.7907-.8605-.5325-1.5248-1.2747-1.9928-2.2268-.4625-.9574-.6938-2.0708-.6938-3.3401 0-1.2694.2313-2.3881.6938-3.3563.468-.9735 1.1188-1.7319 1.9525-2.2752.8391-.5486 1.818-.8229 2.9368-.8229.6454 0 1.2828.1075 1.9121.3227.6293.2151 1.2021.5648 1.7185 1.0488.5163.4787.9278 1.1134 1.2344 1.9041.3065.7907.4598 1.7642.4598 2.9206v.3068c0 .2761-.2238.5-.5.5h-9.0525v-1.6459h7.1162c.2762 0 .5033-.2245.475-.4991-.0517-.5006-.1832-.9581-.3945-1.3726-.2743-.5487-.667-.9817-1.1779-1.299-.5056-.3173-1.1027-.476-1.7911-.476-.7584 0-1.4146.1882-1.9686.5648-.5487.3711-.9709.8552-1.2667 1.4522s-.4438 1.2371-.4438 1.9202v1.0972c0 .9359.1614 1.7293.4841 2.3801.3281.6454.7826 1.1376 1.3635 1.4764.5809.3335 1.2559.5003 2.0251.5003.5002 0 .952-.07 1.3554-.2098.4088-.1452.7611-.3604 1.0569-.6455.1945-.1909.3599-.412.4962-.6633.1238-.2282.3817-.3639.6317-.2937l.9027.2534c.271.0761.4287.3626.313.6192-.1937.4292-.4585.8177-.7945 1.1656-.4572.4679-1.022.8337-1.6943 1.0972-.6723.2582-1.428.3873-2.2671.3873z" />
    <path d="m57.8499 28.3912c-.22 0-.4142-.1438-.4783-.3543l-3.4712-11.3924c-.0978-.3213.1425-.6458.4783-.6458h.9475c.2238 0 .4204.1488.4812.3642l2.5757 9.1238h.1291l2.5444-9.1223c.0602-.2162.2572-.3657.4816-.3657h1.2729c.2249 0 .4221.1501.482.3668l2.5126 9.0889h.1291l2.5755-9.0919c.061-.2152.2575-.3638.4811-.3638h.9478c.3358 0 .5762.3245.4783.6458l-3.4711 11.3924c-.0642.2105-.2583.3543-.4783.3543h-1.124c-.2234 0-.4197-.1482-.4808-.3631l-2.6075-9.1572h-.1936l-2.6075 9.1572c-.0612.2149-.2575.3631-.4809.3631z" />
    <path d="m77.5081 28.6494c-1.194 0-2.2241-.2636-3.09-.7907-.8606-.5325-1.5249-1.2747-1.9928-2.2268-.4626-.9574-.6939-2.0708-.6939-3.3401 0-1.2694.2313-2.3881.6939-3.3563.4679-.9735 1.1187-1.7319 1.9524-2.2752.8391-.5486 1.818-.8229 2.9368-.8229.6454 0 1.2828.1075 1.9121.3227.6293.2151 1.2021.5648 1.7185 1.0488.5163.4787.9278 1.1134 1.2344 1.9041s.4599 1.7642.4599 2.9206v.3068c0 .2761-.2239.5-.5.5h-9.0525v-1.6459h7.1162c.2761 0 .5032-.2245.4749-.4991-.0517-.5006-.1832-.9581-.3945-1.3726-.2743-.5487-.6669-.9817-1.1779-1.299-.5056-.3173-1.1026-.476-1.7911-.476-.7584 0-1.4146.1882-1.9686.5648-.5486.3711-.9709.8552-1.2667 1.4522s-.4437 1.2371-.4437 1.9202v1.0972c0 .9359.1613 1.7293.4841 2.3801.3281.6454.7826 1.1376 1.3634 1.4764.5809.3335 1.256.5003 2.0251.5003.5002 0 .952-.07 1.3554-.2098.4088-.1452.7611-.3604 1.057-.6455.1944-.1909.3598-.412.4961-.6633.1239-.2282.3817-.3639.6317-.2937l.9027.2534c.271.0761.4287.3626.313.6192-.1937.4292-.4585.8177-.7945 1.1656-.4572.4679-1.0219.8337-1.6943 1.0972-.6723.2582-1.428.3873-2.2671.3873z" />
    <path d="m85.6382 28.3912c-.2761 0-.5-.2239-.5-.5v-11.3925c0-.2761.2239-.5.5-.5h.8395c.2761 0 .5.2239.5.5v1.3718h.1291c.2259-.6132.6347-1.1107 1.2263-1.4926.5917-.3819 1.2586-.5728 2.0009-.5728.1398 0 .3146.0027.5244.0081.0082.0002.0163.0004.0243.0006.2567.007.4517.2192.4517.4761v1.034c0 .2285-.2175.4088-.4437.3772-.2259-.0377-.4653-.0565-.7181-.0565-.6024 0-1.1402.1264-1.6136.3792-.4679.2474-.839.5917-1.1134 1.0327-.2689.4357-.4034.9332-.4034 1.4926v7.3421c0 .2761-.2238.5-.5.5z" />
  </>
);

const demoLogoPath = (
  <>
    <path d="m29.3288 28.5c2.4193 0 3.2366-1.5038 3.6616-2.1904h.2288v1.4289c0 .2761.2239.5.5.5h.8635c.2761 0 .5-.2239.5-.5v-15.7385c0-.2761-.2239-.5-.5-.5h-.9289c-.2761 0-.5.2239-.5.5v5.6788h-.1634c-.425-.6538-1.1769-2.1576-3.6289-2.1576-3.1711 0-5.3615 2.5173-5.3615 6.473 0 3.9885 2.1904 6.5058 5.3288 6.5058zm.2616-1.7327c-2.4192 0-3.6616-2.125-3.6616-4.8058 0-2.648 1.2097-4.7077 3.6616-4.7077 2.3538 0 3.5961 1.8962 3.5961 4.7077 0 2.8443-1.275 4.8058-3.5961 4.8058z" />
    <path d="m43.6863 28.5c2.3282 0 4.0841-1.0629 4.8199-2.6909.1159-.2565-.0416-.543-.3126-.6191l-.9267-.2601c-.25-.0702-.5081.0656-.6324.2936-.5613 1.0292-1.6068 1.5438-2.9482 1.5438-2.2639 0-3.825-1.463-3.9149-4.1519h8.613c.2761 0 .5-.2239.5-.5v-.3173c0-4.675-2.7788-6.2769-5.3942-6.2769-3.4 0-5.6558 2.6807-5.6558 6.5384s2.2231 6.4404 5.8519 6.4404zm-3.9149-7.5519c.1308-1.9534 1.512-3.6943 3.7188-3.6943 2.0923 0 3.4327 1.5693 3.4327 3.6943z" />
    <path d="m51.3582 27.7385c0 .2761.2239.5.5.5h.9289c.2761 0 .5-.2239.5-.5v-7.3462c0-1.8389 1.3404-3.1385 2.8442-3.1385 1.463 0 2.4846.9563 2.4846 2.3866v8.0981c0 .2761.2239.5.5.5h.9616c.2761 0 .5-.2239.5-.5v-7.6731c0-1.6183 1.0134-2.8116 2.7788-2.8116 1.3731 0 2.55.7274 2.55 2.5827v7.902c0 .2761.2239.5.5.5h.9288c.2762 0 .5-.2239.5-.5v-7.902c0-2.9504-1.5855-4.3153-3.825-4.3153-1.798 0-3.1139.8254-3.7596 2.125h-.1307c-.6212-1.3404-1.7164-2.125-3.3673-2.125-1.6347 0-2.8443.7846-3.3673 2.125h-.1635v-1.4616c0-.2761-.2239-.5-.5-.5h-.8635c-.2761 0-.5.2239-.5.5z" />
    <path d="m76.0015 28.5c3.4 0 5.6885-2.5827 5.6885-6.4731 0-3.9231-2.2885-6.5057-5.6885-6.5057s-5.6884 2.5826-5.6884 6.5057c0 3.8904 2.2884 6.4731 5.6884 6.4731zm0-1.7327c-2.5827 0-3.7596-2.2231-3.7596-4.7404s1.1769-4.7731 3.7596-4.7731 3.7596 2.2558 3.7596 4.7731-1.1769 4.7404-3.7596 4.7404z" />
  </>
);

const resultsLogoPath = (
  <>
    <path d="m25.1348 28.1327c-.2762 0-.5-.2239-.5-.5v-11.3925c0-.2761.2238-.5.5-.5h.8395c.2761 0 .5.2239.5.5v1.3718h.1291c.2259-.6132.6346-1.1107 1.2263-1.4926s1.2586-.5728 2.0009-.5728c.1398 0 .3146.0027.5244.008.0081.0002.0162.0005.0242.0007.2568.0069.4518.2192.4518.476v1.0341c0 .2284-.2175.4087-.4437.3771-.2259-.0376-.4653-.0564-.7181-.0564-.6024 0-1.1403.1264-1.6136.3792-.4679.2474-.8391.5916-1.1134 1.0327-.2689.4356-.4034.9332-.4034 1.4926v7.3421c0 .2761-.2238.5-.5.5z" />
    <path d="m37.509 28.3908c-1.194 0-2.224-.2635-3.09-.7906-.8606-.5325-1.5249-1.2748-1.9928-2.2268-.4626-.9574-.6938-2.0708-.6938-3.3402 0-1.2693.2312-2.3881.6938-3.3563.4679-.9735 1.1188-1.7319 1.9525-2.2751.839-.5487 1.818-.823 2.9367-.823.6455 0 1.2828.1076 1.9121.3228.6293.2151 1.2022.5647 1.7185 1.0488.5164.4787.9278 1.1134 1.2344 1.904.3066.7907.4599 1.7642.4599 2.9207v.3068c0 .2761-.2239.5-.5.5h-9.0525v-1.6459h7.1162c.2761 0 .5033-.2245.4749-.4992-.0516-.5005-.1831-.958-.3945-1.3726-.2743-.5486-.6669-.9816-1.1779-1.2989-.5056-.3174-1.1026-.476-1.7911-.476-.7584 0-1.4146.1882-1.9686.5647-.5486.3711-.9708.8552-1.2667 1.4523-.2958.597-.4437 1.237-.4437 1.9201v1.0973c0 .9359.1614 1.7292.4841 2.3801.3281.6454.7826 1.1375 1.3635 1.4764.5809.3335 1.2559.5002 2.025.5002.5003 0 .9521-.0699 1.3555-.2098.4088-.1452.7611-.3603 1.0569-.6454.1944-.1909.3598-.412.4962-.6633.1238-.2283.3816-.3639.6316-.2937l.9028.2534c.271.0761.4287.3625.3129.6191-.1936.4292-.4585.8178-.7945 1.1656-.4571.468-1.0219.8337-1.6942 1.0973-.6724.2581-1.4281.3872-2.2672.3872z" />
    <path d="m53.7247 18.0279c.119.2596-.0375.5528-.3122.6305l-.764.2162c-.2498.0707-.5091-.0641-.6376-.2897-.0802-.1409-.1736-.2796-.2802-.4162-.2044-.2743-.4841-.5003-.8391-.6777-.355-.1775-.8095-.2663-1.3635-.2663-.7584 0-1.3904.1748-1.896.5244-.5002.3443-.7503.7826-.7503 1.3151 0 .4733.1721.8472.5164 1.1215.3442.2743.8821.5029 1.6136.6857l1.8395.4519c1.108.2689 1.9336.6804 2.4768 1.2344.5433.5486.8149 1.2559.8149 2.1218 0 .71-.2044 1.3447-.6131 1.9041-.4035.5594-.9682 1.0004-1.6943 1.3231-.7262.3228-1.5706.4841-2.5334.4841-1.264 0-2.3101-.2743-3.1384-.8229-.7019-.4649-1.1856-1.1113-1.4511-1.9392-.082-.2558.0838-.5166.3444-.5817l.8592-.2148c.2665-.0666.5319.0968.6396.3495.1866.4372.4593.7808.8181 1.0308.484.3334 1.116.5002 1.8959.5002.8875 0 1.5921-.1883 2.1139-.5648.5271-.3819.7906-.839.7906-1.3715 0-.4303-.1506-.7907-.4518-1.0811-.3012-.2959-.7638-.5164-1.3877-.6616l-2.0654-.4841c-1.1349-.2689-1.9686-.6858-2.5011-1.2505-.5271-.5702-.7906-1.2829-.7906-2.1381 0-.6992.1963-1.3177.5889-1.8556.398-.5379.9386-.9601 1.6217-1.2667.6885-.3066 1.4684-.4599 2.3397-.4599 1.2264 0 2.1891.269 2.8884.8068.5697.4349 1.0058.9824 1.3082 1.6423z" />
    <path d="m64.4214 23.0659v-6.8257c0-.2761.2239-.5.5-.5h.9041c.2761 0 .5.2239.5.5v11.3925c0 .2761-.2239.5-.5.5h-.9041c-.2761 0-.5-.2239-.5-.5v-1.5977h-.1291c-.2904.6293-.7422 1.1645-1.3554 1.6055-.6132.4357-1.3877.6535-2.3236.6535-.7745 0-1.463-.1694-2.0654-.5083-.6024-.3442-1.0757-.8606-1.42-1.549-.3442-.6939-.5163-1.5679-.5163-2.6221v-7.3744c0-.2761.2238-.5.5-.5h.904c.2762 0 .5.2239.5.5v7.2453c0 .9036.2528 1.6243.7584 2.1622.511.5379 1.1618.8068 1.9525.8068.4733 0 .9547-.121 1.4442-.3631.4948-.242.909-.6131 1.2424-1.1133.3389-.5003.5083-1.1376.5083-1.9122z" />
    <path d="m70.8193 11.6094c.2762 0 .5.2238.5.5v15.5233c0 .2761-.2238.5-.5.5h-.904c-.2762 0-.5-.2239-.5-.5v-15.5233c0-.2762.2238-.5.5-.5z" />
    <path d="m79.2986 15.7402c.2761 0 .5.2239.5.5v.6136c0 .2761-.2239.5-.5.5h-5.4222c-.2761 0-.5-.2239-.5-.5v-.6136c0-.2761.2239-.5.5-.5zm-4.0504-2.469c0-.2762.2239-.5.5-.5h.904c.2762 0 .5.2238.5.5v11.3115c0 .5379.078.9413.234 1.2102.1614.2636.3658.4411.6132.5325.2528.0861.519.1291.7987.1291.2098 0 .3819-.0107.5164-.0323.1795-.0359.3549.0779.3954.2565l.2182.964c.0552.2437-.0793.4887-.323.5442-.0387.0088-.0791.0176-.1212.0264-.2313.0538-.5244.0807-.8794.0807-.5379 0-1.065-.1156-1.5813-.3469-.511-.2313-.9359-.5836-1.2748-1.0569-.3335-.4733-.5002-1.0704-.5002-1.7911z" />
    <path d="m90.6289 18.0279c.1189.2596-.0376.5528-.3123.6305l-.7639.2162c-.2499.0707-.5092-.0641-.6377-.2897-.0802-.1409-.1736-.2796-.2802-.4162-.2044-.2743-.4841-.5003-.8391-.6777-.355-.1775-.8095-.2663-1.3635-.2663-.7584 0-1.3904.1748-1.8959.5244-.5003.3443-.7504.7826-.7504 1.3151 0 .4733.1721.8472.5164 1.1215.3442.2743.8821.5029 1.6136.6857l1.8395.4519c1.108.2689 1.9336.6804 2.4769 1.2344.5432.5486.8148 1.2559.8148 2.1218 0 .71-.2044 1.3447-.6131 1.9041-.4034.5594-.9682 1.0004-1.6943 1.3231-.7261.3228-1.5706.4841-2.5334.4841-1.264 0-2.3101-.2743-3.1384-.8229-.7019-.4649-1.1856-1.1113-1.4511-1.9392-.082-.2558.0839-.5166.3444-.5817l.8592-.2148c.2665-.0666.5319.0968.6397.3495.1865.4372.4592.7808.818 1.0308.4841.3334 1.1161.5002 1.896.5002.8874 0 1.592-.1883 2.1138-.5648.5271-.3819.7906-.839.7906-1.3715 0-.4303-.1506-.7907-.4518-1.0811-.3012-.2959-.7637-.5164-1.3877-.6616l-2.0654-.4841c-1.1349-.2689-1.9686-.6858-2.5011-1.2505-.5271-.5702-.7906-1.2829-.7906-2.1381 0-.6992.1963-1.3177.5889-1.8556.3981-.5379.9386-.9601 1.6217-1.2667.6885-.3066 1.4684-.4599 2.3397-.4599 1.2264 0 2.1892.269 2.8884.8068.5697.4349 1.0058.9824 1.3083 1.6423z" />
  </>
);

const adminLogoPath = (
  <>
    <path d="m27.8624 28.6641c-.7853 0-1.498-.1479-2.138-.4438-.6401-.3012-1.1484-.7342-1.5249-1.2989-.3765-.5702-.5647-1.2586-.5647-2.0654 0-.71.1398-1.2855.4195-1.7266.2797-.4464.6535-.796 1.1215-1.0488.4679-.2528.9843-.4411 1.549-.5648.5702-.1291 1.143-.2313 1.7185-.3066.753-.0968 1.3635-.1694 1.8314-.2178.4734-.0538.8176-.1425 1.0327-.2663.2206-.1237.3308-.3388.3308-.6454v-.0645c0-.7961-.2178-1.4146-.6535-1.8557-.4303-.441-1.0838-.6616-1.9605-.6616-.909 0-1.6217.1991-2.138.5971-.3632.2799-.6506.5732-.8621.8798-.1467.2127-.4133.3284-.6567.2415l-.8767-.3131c-.2715-.097-.4073-.4045-.2692-.6575.2881-.5275.6411-.9542 1.0591-1.2802.5433-.425 1.1349-.7208 1.775-.8875.6454-.1721 1.2801-.2582 1.9041-.2582.398 0 .8552.0484 1.3715.1452.5217.0915 1.0246.2824 1.5087.5728.4895.2905.8956.7289 1.2183 1.3151.3227.5863.4841 1.3716.4841 2.3559v7.6648c0 .2762-.2239.5-.5.5h-.9041c-.2761 0-.5-.2238-.5-.5v-1.1781h-.0968c-.1291.2689-.3442.5567-.6454.8632-.3012.3066-.7019.5675-1.2022.7826-.5002.2152-1.1107.3228-1.8314.3228zm.2904-1.7105c.7531 0 1.3877-.1479 1.9041-.4437.5217-.2958.9144-.6777 1.1779-1.1457.269-.4679.4034-.96.4034-1.4764v-1.7427c-.0807.0968-.2581.1856-.5325.2663-.2689.0753-.5809.1425-.9358.2017-.3497.0537-.6912.1022-1.0247.1452-.3281.0376-.5943.0699-.7987.0968-.4949.0645-.9574.1694-1.3877.3147-.4249.1398-.7692.3523-1.0327.6373-.2582.2797-.3873.6616-.3873 1.1457 0 .6616.2447 1.1618.7342 1.5006.4948.3335 1.1215.5002 1.8798.5002z" />
    <path d="m41.2994 28.6318c-1.0327 0-1.9444-.2609-2.735-.7826-.7907-.5271-1.4092-1.2694-1.8557-2.2268-.4464-.9628-.6696-2.1003-.6696-3.4127 0-1.3017.2232-2.4312.6696-3.3886.4465-.9574 1.0677-1.697 1.8637-2.2187.7961-.5217 1.7158-.7826 2.7593-.7826.8068 0 1.4442.1345 1.9121.4034.4733.2636.8337.5648 1.0811.9036.2528.3335.4491.6078.589.8229h.1614v-5.5994c0-.2761.2238-.5.5-.5h.904c.2761 0 .5.2239.5.5v15.5233c0 .2762-.2239.5-.5.5h-.8395c-.2761 0-.5-.2238-.5-.5v-1.404h-.2259c-.1399.2259-.3389.5109-.597.8552-.2582.3388-.6267.6427-1.1054.9117-.4787.2635-1.116.3953-1.9121.3953zm.2582-1.7104c.7638 0 1.4092-.199 1.9363-.5971.5271-.4034.9278-.9601 1.2022-1.67.2743-.7154.4114-1.541.4114-2.4769 0-.9251-.1344-1.7346-.4034-2.4285-.2689-.6992-.6669-1.2425-1.194-1.6297-.5271-.3927-1.178-.589-1.9525-.589-.8068 0-1.4791.2071-2.017.6213-.5325.4087-.9332.9654-1.2021 1.67-.2636.6993-.3954 1.4845-.3954 2.3559 0 .8821.1345 1.6835.4034 2.4043.2743.7153.6777 1.2855 1.2102 1.7104.5379.4195 1.2049.6293 2.0009.6293z" />
    <path d="m50.8333 28.3736c-.2761 0-.5-.2238-.5-.5v-11.3924c0-.2762.2239-.5.5-.5h.8395c.2762 0 .5.2238.5.5v1.4363h.1614c.2582-.6616.675-1.1753 1.2505-1.541.5756-.3711 1.2667-.5567 2.0735-.5567.8176 0 1.498.1856 2.0412.5567.5487.3657.9763.8794 1.2828 1.541h.1291c.3174-.6401.7934-1.1484 1.4281-1.5249.6347-.3819 1.3957-.5728 2.2832-.5728 1.108 0 2.0143.3469 2.7189 1.0408.7046.6884 1.0569 1.7615 1.0569 3.2191v7.7939c0 .2762-.2238.5-.5.5h-.904c-.2762 0-.5-.2238-.5-.5v-7.7939c0-.9144-.2501-1.5679-.7503-1.9605-.5003-.3927-1.0892-.589-1.7669-.589-.8714 0-1.5464.2636-2.0251.7907-.4787.5217-.7181 1.1833-.7181 1.9847v7.568c0 .2762-.2238.5-.5.5h-.9363c-.2761 0-.5-.2238-.5-.5v-7.9875c0-.7046-.2286-1.2721-.6858-1.7024-.4571-.4357-1.0461-.6535-1.7668-.6535-.4949 0-.9575.1318-1.3877.3953-.425.2636-.7692.6294-1.0327 1.0973-.2582.4626-.3873.9977-.3873 1.6055v7.2453c0 .2762-.2239.5-.5.5z" />
    <path d="m70.1822 28.3736c-.2761 0-.5-.2238-.5-.5v-11.3924c0-.2762.2239-.5.5-.5h.904c.2762 0 .5.2238.5.5v11.3924c0 .2762-.2238.5-.5.5zm.4682-14.4579c-.3712 0-.6912-.1264-.9601-.3791-.2636-.2528-.3954-.5567-.3954-.9117s.1318-.6589.3954-.9117c.2689-.2528.5889-.3792.9601-.3792.3711 0 .6884.1264.952.3792.2689.2528.4034.5567.4034.9117s-.1345.6589-.4034.9117c-.2636.2527-.5809.3791-.952.3791z" />
    <path d="m76.5801 20.9188v6.9548c0 .2762-.2239.5-.5.5h-.9041c-.2761 0-.5-.2238-.5-.5v-11.3924c0-.2762.2239-.5.5-.5h.8396c.2761 0 .5.2238.5.5v1.4363h.1613c.2905-.6293.7315-1.1349 1.3232-1.5168.5916-.3873 1.3554-.5809 2.2913-.5809.8391 0 1.5732.1721 2.2025.5163.6294.3389 1.1188.8553 1.4684 1.5491.3496.6885.5244 1.5598.5244 2.614v7.3744c0 .2762-.2238.5-.5.5h-.904c-.2761 0-.5-.2238-.5-.5v-7.2453c0-.9735-.2528-1.7319-.7584-2.2751-.5056-.5487-1.1994-.823-2.0815-.823-.6078 0-1.1511.1318-1.6298.3953-.4733.2636-.8471.6482-1.1214 1.1538s-.4115 1.1187-.4115 1.8395z" />
  </>
);

const LogoScreenVariant: FC<LogoScreenVariantProps> = ({ className, variant, logoFill, textFill, fill }) => {
  const viewBox = variant === DEMO ? '0 0 82 40' : '0 0 92 40';
  const width = variant === DEMO ? '82' : '92';
  let logoScreenVariantPath;
  let dataTestId;

  switch (variant) {
    case DEMO:
      logoScreenVariantPath = demoLogoPath;
      dataTestId = LOGO_DEMO;
      break;
    case VIEWER:
      logoScreenVariantPath = viewerLogoPath;
      dataTestId = LOGO_VIEWER;
      break;
    case RESULTS:
      logoScreenVariantPath = resultsLogoPath;
      dataTestId = LOGO_RESULTS;
      break;
    case ADMIN:
      logoScreenVariantPath = adminLogoPath;
      dataTestId = LOGO_ADMIN;
      break;
    default:
      logoScreenVariantPath = demoLogoPath;
      dataTestId = LOGO_DEMO;
  }

  return (
    <svg
      className={className}
      data-testid={dataTestId}
      width={width}
      height="40"
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={textFill || fill || '#808080'}>{logoScreenVariantPath}</g>
      <path
        d="m14.6863 21.9353-5.89689-10.208c-.07968-.1434-.23906-.239-.41437-.239s-.33469.0956-.41438.239l-5.89687 10.208c-.08766.1514-.08766.3347 0 .4781.08766.1435.24703.2391.41437.2391 0 0 3.17157 0 3.17157-.008.70125-.0318 1.45031-.2948 1.97625-1.267l.74906-1.275.74109 1.283c.54985 1.02 1.35467 1.259 2.07987 1.267h3.0679c.1754 0 .3347-.0956.4144-.2391.0797-.1434.0956-.3267.008-.4781zm-3.4106-.2391c-.5339 0-.9643-.1275-1.30691-.765l-1.17141-2.032c-.08765-.1514-.24703-.239-.42234-.239s-.33469.0956-.41438.239l-1.17937 2.032c-.34266.6375-.77297.765-1.30688.765h-2.15953l5.06016-8.7656 5.06016 8.7656z"
        fill={logoFill || fill || '#fff'}
      />
    </svg>
  );
};

export default LogoScreenVariant;
