import { GetCtbPredictionSegmentSlicesRes } from '@annaliseai/api-specifications';
import client from 'api/client';
import endpoints from 'api/endpoints';

const getCtbSegment = async ({
  predictionId,
  segmentId,
}: {
  predictionId: string;
  segmentId: string;
}): Promise<GetCtbPredictionSegmentSlicesRes> => {
  const { ctbSegments } = endpoints.v1;
  const path = ctbSegments.getPath({ predictionId, segmentId });

  const { data } = await client.get<GetCtbPredictionSegmentSlicesRes>(path);

  return data;
};

export default getCtbSegment;
