import { EventSourceMessage } from '@microsoft/fetch-event-source';
import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { baseURL } from 'api/client';
import endpoints from 'api/endpoints';
import getServerSentEvents, { maxTimeout } from 'api/queries/getServerSentEvents';
import NotificationEvent from 'enums/NotificationEvent';
import { selectStudies, selectStudiesQuery } from 'selectors/studyList/selectStudyList';
import { notificationActions } from 'slices/notificationSlice';
import { studyListActions } from 'slices/studyListSlice';
import DisplayStudyInformation from 'types/DisplayStudyInformation';

type TimeoutCurrentRef = NodeJS.Timeout | undefined;
type TimeoutRef = { current: TimeoutCurrentRef };

const { LATEST_PREDICTION } = NotificationEvent;

const { resetHeaderNotification, setHeaderNotification } = notificationActions;

const DELAY_CLEAR_NOTIFICATIONS = maxTimeout + 500; // Wait for this amount of time before clearing the notifications
const DELAY_CONFIRMATION = 5_000; // Wait this amount of time before removing the reconnection notification message
const DELAY_DEBOUNCE_FETCH = 2_000; // Wait this amount of time to fetch studies, and only when studies are available
const DELAY_ESCALATION = maxTimeout * 3; // Wait this amount of time before excalating the notification message

const NOTIFICATION_ESCALATED = 'Real-time updates not available. If issue persists, contact IT support.';
const NOTIFICATION_IMMEDIATE = 'Real-time updates not available. Trying to reconnect…';
const NOTIFICATION_RECONNECTED = 'Connection restored. Updating in real-time.';

const { sseLatestPrediction } = endpoints.v1;
export const sseLatestPredicationUrl = `${baseURL}${sseLatestPrediction.getPath()}`;

const useStreamedStudies = (): DisplayStudyInformation[] => {
  const dispatch = useDispatch();
  const studies = useSelector(selectStudies, shallowEqual);
  const studiesQuery = useSelector(selectStudiesQuery);
  const [updatesCounter, setUpdatesCounter] = useState<number>(0);

  // Timeouts saved into refs to retain values across rerenders
  const clearNotificationsTimeout = useRef<TimeoutCurrentRef>();
  const escalationNotificationTimeout = useRef<TimeoutCurrentRef>();
  const confirmationNotificationTimeout = useRef<TimeoutCurrentRef>();
  const debounceFetchTimeout = useRef<TimeoutCurrentRef>();

  const resetTimeout = (timeoutRef: TimeoutRef): void => {
    timeoutRef?.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;
  };

  // Clear all timeouts and notifications
  const clearNotifications = (showReconnectionNotification = false): void => {
    resetTimeout(clearNotificationsTimeout);
    resetTimeout(escalationNotificationTimeout);
    resetTimeout(confirmationNotificationTimeout);

    if (showReconnectionNotification) {
      setUpdatesCounter(prev => prev + 1);
      dispatch(setHeaderNotification(NOTIFICATION_RECONNECTED));
      setTimeout(clearNotifications, DELAY_CONFIRMATION);
    } else {
      dispatch(resetHeaderNotification());
    }
  };

  const handleMessageEvent = (message: EventSourceMessage): void => {
    message?.data === LATEST_PREDICTION && setUpdatesCounter(prev => prev + 1);
  };

  const handleErrorOrCloseEvent = (): void => {
    // Each time handleErrorOrCloseEvent is called we want to reset the timeout which clears all notifications after [DELAY_CLEAR_NOTIFICATIONS] time has passed
    resetTimeout(clearNotificationsTimeout);
    clearNotificationsTimeout.current = setTimeout(() => clearNotifications(true), DELAY_CLEAR_NOTIFICATIONS);

    // The first time handleErrorOrCloseEvent is called we set the immediate message and set a timeout to show the escalated message
    // Subsequent handleErrorOrCloseEvent calls check whether the escalation timeout is in-play before attempting to show notifications again
    if (!escalationNotificationTimeout.current) {
      // Show notification
      dispatch(setHeaderNotification(NOTIFICATION_IMMEDIATE));

      // Start timeout to show escalation notification
      escalationNotificationTimeout.current = setTimeout(() => {
        dispatch(setHeaderNotification(NOTIFICATION_ESCALATED));
      }, DELAY_ESCALATION);
    }
  };

  // Init SSE
  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      await getServerSentEvents(
        controller,
        sseLatestPredicationUrl,
        true,
        handleMessageEvent,
        handleErrorOrCloseEvent,
        handleErrorOrCloseEvent,
      );
    })();

    return () => {
      controller.abort();
      clearNotifications();
      resetTimeout(debounceFetchTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    // If we don't have a timeout running, and it isn't the initial render, debounce the request
    if (!debounceFetchTimeout.current && updatesCounter !== 0) {
      debounceFetchTimeout.current = setTimeout(() => {
        dispatch(studyListActions.fetch(studiesQuery));
        resetTimeout(debounceFetchTimeout);
      }, DELAY_DEBOUNCE_FETCH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updatesCounter]);

  return studies || [];
};

export default useStreamedStudies;
