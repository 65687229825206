import styled from 'styled-components';

export const UsernameText = styled.div`
  font-size: 0.75rem;
  margin: 0 0.25rem;
`;

export const UsernameContainer = styled.div`
  display: flex;
`;
