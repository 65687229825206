// https://dicom.nema.org/medical/dicom/current/output/chtml/part05/sect_6.2.html

// DICOM patient names consist of five components, delimited by ^
// eg. `firstName^lastName^middleName^prefix^suffix`
// Leading and trailing delimiters can be trimmed, but internal delimiters must be used.
// eg. `middleName` and `lastName^^^suffix` are both valid combinations
// For now, put a comma between what we assume is the lastname and firstname, and replace subsequent delimiters with ' '
// There's 32 possible combinations of patient names - we might want to handle more later.

const formatPatientName = (patientName: string): string => {
  const components = patientName.split('^');

  // Find the first component in the array that is NOT an empty string
  const firstComponent = components.find(string => !!string);
  // Get the index of that string - we need to remove up to, and including this item.
  const firstComponentIndex = components.findIndex(string => !!string);
  // Remove all elements up to, and including the first valid component
  const trimmedComponents = components.slice(firstComponentIndex + 1);
  if (trimmedComponents.length >= 1) {
    let result = (firstComponent && `${firstComponent}, `) || '';

    trimmedComponents.forEach(component => {
      component && (result += `${component} `);
    });
    return result.trim();
  }
  return firstComponent || '';
};

export default formatPatientName;
