enum FindingIndicatorType {
  NO_LOCALISATION = 'NO_LOCALISATION',
  BULLET_POINT = 'BULLET_POINT',
  SINGLE_IMAGE_LOCALISATION = 'SINGLE_IMAGE_LOCALISATION',
  MULTIPLE_IMAGES_LOCALISATION = 'MULTIPLE_IMAGES_LOCALISATION',
  LEFT_LATERALITY_LOCALISATION = 'LEFT_LATERALITY_LOCALISATION',
  RIGHT_LATERALITY_LOCALISATION = 'RIGHT_LATERALITY_LOCALISATION',
  BILATERAL_LOCALISATION = 'BILATERAL_LOCALISATION',
}

export default FindingIndicatorType;
