import { LINK_ANNALISE, LINK_PRIVACY_POLICY, LINK_TERMS_CONDITIONS } from 'constants/links';
import { ABOUT_ANNALISE_TEXT, COPYRIGHT, PRIVACY_POLICY_LOGIN_TEXT, TERMS_CONDITIONS_LOGIN_TEXT } from './constants';
import { FooterContainer, FooterLink, ItemsContainer } from './Footer.styles';

// Footer used on the web demo Login screen

const Footer = (): JSX.Element => (
  <FooterContainer>
    <ItemsContainer>
      <FooterLink href={LINK_ANNALISE}>{ABOUT_ANNALISE_TEXT}</FooterLink>
    </ItemsContainer>
    <ItemsContainer>
      <span>{COPYRIGHT}</span>
      <FooterLink href={LINK_TERMS_CONDITIONS}>{TERMS_CONDITIONS_LOGIN_TEXT}</FooterLink>
      <FooterLink href={LINK_PRIVACY_POLICY}>{PRIVACY_POLICY_LOGIN_TEXT}</FooterLink>
    </ItemsContainer>
  </FooterContainer>
);

export default Footer;
