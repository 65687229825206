import dayjs from 'dayjs';
import { INSTITUTION_NAME_OPTIONS, REQUESTING_SERVICES_OPTIONS } from 'components/SearchFilter/SearchFilter.constants';
import { FormattedDateTimes, PredefinedDates, SelectOptions } from 'components/SearchFilter/SearchFilter.types';
import INPUTS from 'constants/inputs';
import DateFormats from 'enums/DateFormats';
import Days from 'enums/Days';
import TimeUnits from 'enums/TimeUnits';
import formatDateTime from 'helpers/dates/formatDateTime';
import { StudyListState } from 'slices/studyListSlice';
import { DisplayFormat } from 'types/settings/DisplayData';

const { FORMAT_24H_HH_MM_SS, FORMAT_YYYY_MM_DD_HYPHENS } = DateFormats;
const { ONE_DAY, TWO_DAYS, SIX_DAYS } = Days;
const { DAYS } = TimeUnits;
const { CUSTOM_DATES, LAST_24_HOURS, LAST_48_HOURS, LAST_7_DAYS } = INPUTS;

/**
 * Date notes:
 * - createdAfter; today - numbers of days
 *    > E.g. last 24 hours; today - 1 day.
 *    > E.g. last 48 hours; today - 2 days.
 *    > E.g. last 7 days; today - 6 days (reason: 00:00:00/23:59:59 appended to createdAfter/createdBefore via sanitizeSearchQueryParams).
 * - createdUntil; today's date
 */
export const buildPredefinedDates = (value: string, displayFormat?: DisplayFormat): PredefinedDates => {
  const dateQuery = {
    createdAfter: '',
    createdUntil: '',
    dateType: value,
  };

  const todaysDate = getTodaysDate();

  const createdAfterDateConversion: FormattedDateTimes = {
    [LAST_24_HOURS]: formatDateTime(
      dayjs(todaysDate).add(-ONE_DAY, DAYS).toDate(),
      FORMAT_YYYY_MM_DD_HYPHENS(displayFormat),
    ),
    [LAST_48_HOURS]: formatDateTime(
      dayjs(todaysDate).add(-TWO_DAYS, DAYS).toDate(),
      FORMAT_YYYY_MM_DD_HYPHENS(displayFormat),
    ),
    [LAST_7_DAYS]: formatDateTime(
      dayjs(todaysDate).add(-SIX_DAYS, DAYS).toDate(),
      FORMAT_YYYY_MM_DD_HYPHENS(displayFormat),
    ),
  };

  // Build start and end dates
  if (value in createdAfterDateConversion) {
    dateQuery.createdAfter = createdAfterDateConversion[value];
    dateQuery.createdUntil = formatDateTime(dayjs(todaysDate).toDate(), FORMAT_YYYY_MM_DD_HYPHENS(displayFormat));
  }

  return dateQuery;
};

/**
 * Date notes:
 * - createdAfter: date provided
 * - createdUntil; date provided
 */
export const buildCustomDates = (
  createdAfter: string,
  createdUntil: string,
  displayFormat?: DisplayFormat,
): PredefinedDates => ({
  createdAfter:
    createdAfter !== '' ? formatDateTime(dayjs(createdAfter).toDate(), FORMAT_YYYY_MM_DD_HYPHENS(displayFormat)) : '',
  createdUntil:
    createdUntil !== '' ? formatDateTime(dayjs(createdUntil).toDate(), FORMAT_YYYY_MM_DD_HYPHENS(displayFormat)) : '',
});

const orgFilterOptions = {
  requestingServices: REQUESTING_SERVICES_OPTIONS,
  institutionNames: INSTITUTION_NAME_OPTIONS,
};

export enum FilterNames {
  RequestingServices = 'requestingServices',
  InstitutionNames = 'institutionNames',
}
/**
 * Build the filterable (hospital or requesting service) options by combining default and available org options
 */
export const buildOrgFilterOptions = (filterName: FilterNames, options?: string[]): SelectOptions[] => {
  const orgOptions = options?.map(option => ({ name: option, value: option })) || [];
  return [...orgFilterOptions[filterName], ...orgOptions];
};

export const getTodaysDate = (format = FORMAT_YYYY_MM_DD_HYPHENS()): string => formatDateTime(new Date(), format);

export const getCurrentTime = (format = FORMAT_24H_HH_MM_SS()): string => formatDateTime(new Date(), format);

/**
 * Builds dates based on timezone, and removes unwanted query params
 * @param params study list query params
 * @returns sanitized study list query params
 */
export const sanitizeSearchQueryParams = (params: StudyListState['query']): StudyListState['query'] => {
  const { dateType } = params;
  const hasDateFilter = !!dateType;
  const isCustomDates = hasDateFilter && dateType === CUSTOM_DATES;
  const isLast24Hours = hasDateFilter && dateType === LAST_24_HOURS;
  const isLast48Hours = hasDateFilter && dateType === LAST_48_HOURS;
  const isPredefinedDates = hasDateFilter && !isCustomDates;

  let formattedParams = { ...params };

  if (isPredefinedDates) {
    formattedParams = {
      ...params,
      ...buildPredefinedDates(dateType),
    };
  } else if (isCustomDates) {
    formattedParams = {
      ...params,
      ...buildCustomDates(params.createdAfter || '', params.createdUntil || ''),
    };
  }

  // Convert the dates to UTC before sending the request to the server
  if (hasDateFilter) {
    // If 24/48 hour filter selected, use current time instead of searching start-to-finish
    const currTime = getCurrentTime();
    const timeCreatedAfter = isLast24Hours || isLast48Hours ? `${currTime}` : `00:00:00`;
    const timeCreatedUntil = isLast24Hours || isLast48Hours ? `${currTime}` : `23:59:59`;

    /**
     * NOTE:
     * The backend is filtering on studyDateTime, derived from DICOM tags, of which the TZ is unknown.
     * The backend "converts" studyDateTime to UTC time (due to the fact that studyDate needs to be sortable).
     * So studyDateTime is in UTC format, though the actual date/time it represents isn't, therefore we need to
     * send the createdAfter and createdUntil dates as they are displayed, though in UTC format (without
     * converting to UTC).
     */
    const utcCreatedAfter = formattedParams.createdAfter ? `${formattedParams.createdAfter}T${timeCreatedAfter}Z` : '';
    const utcCreatedUntil = formattedParams.createdUntil ? `${formattedParams.createdUntil}T${timeCreatedUntil}Z` : '';

    formattedParams = {
      ...params,
      createdAfter: utcCreatedAfter,
      createdUntil: utcCreatedUntil,
    };
  }

  // Sanitize query params
  const { dateType: _, ...sanitizedQueryParams } = formattedParams;

  return sanitizedQueryParams;
};
