import styled, { DataAttributes } from 'styled-components';
import { ButtonLikeBaseCss, ButtonLargeCss, ButtonNoBorderCss } from 'components//Buttons/Button/Button.styles';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { MENU_ITEM } = ReactTestingLibraryDataProperties;
const { SECONDARY_CONTAINER } = colors;

export const NavLink = styled.a.attrs<DataAttributes>({ 'data-testid': MENU_ITEM })`
  ${ButtonLikeBaseCss}
  ${ButtonNoBorderCss}
  ${ButtonLargeCss}

  &:hover {
    background-color: ${SECONDARY_CONTAINER};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  height: 1.25rem;
  width: 1.25rem;
  align-items: center;
  justify-content: center;
`;
