import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { CONFIDENCE_INDICATOR } = ReactTestingLibraryDataProperties;

const staticIndicatorProps = {
  isFullSize: true,
  isScaledDown: false,
  leftLabel: 'ABSENT',
  rightLabel: 'PRESENT',
  testId: CONFIDENCE_INDICATOR,
};

export default staticIndicatorProps;
