import log from 'loglevel';
import client from 'api/client';
import endpoints from 'api/endpoints';
import { VersionsType, VersionType } from 'types/VersionNames';

const getVersions = async (): Promise<VersionsType> => {
  const { info } = endpoints.v1;
  const path = info.getPath();
  const { data } = await client.get(path);

  log.debug('received getInfo response=', data);

  const versions: VersionsType = data.versions.reduce((acc: VersionsType, current: VersionType) => {
    acc[current.name] = current.version;
    return acc;
  }, {});

  return versions;
};

export default getVersions;
