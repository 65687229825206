import axios from 'axios';
import log from 'loglevel';
import { baseURL } from 'api/client';
import endpoints from 'api/endpoints';
import SigningHeaders from 'enums/SigningHeaders';
import { storeCredential } from 'helpers/cookies/credentialHelper';
import Credential from 'types/Credential';

const { X_ANNALISE_AI_CLIENT_ID, X_ANNALISE_AI_CLIENT_SECRET } = SigningHeaders;

const login = async (credential: Credential): Promise<void> => {
  const { self } = endpoints.v1;
  const path = self.getPath();

  const { clientId, clientSecret } = credential;
  const { data } = await axios.get(path, {
    baseURL,
    headers: {
      Accept: 'application/json; charset=UTF-8',
      'Content-Type': 'application/json; charset=UTF-8',
      'x-annalise-ai-app-version': 'logan-0.1.0',
      [X_ANNALISE_AI_CLIENT_ID]: clientId,
      [X_ANNALISE_AI_CLIENT_SECRET]: clientSecret,
    },
  });

  log.debug('received login response=', data);

  storeCredential(credential);
};

export default login;
