import { StudyType } from '@annaliseai/api-specifications';

const { CXR, CTB } = StudyType;

type Modalities<T1, T2> = {
  [CXR]: T1;
  [CTB]: T2;
};

/* Used to reduce modality branching boilerplate code. Guarantees type safety if StudyType changes */
const mapModality = <T1, T2>(
  studyTypeOrUndefined: StudyType | undefined,
  mapping: Modalities<T1, T2>,
): T1 | T2 | undefined => (studyTypeOrUndefined ? mapping[studyTypeOrUndefined] : undefined);

export default mapModality;
