import { CtbGroupedRelevantClassificationExternal, CtbSegmentationExternal } from '@annaliseai/api-specifications';
import { CtbClassificationExternal } from '@annaliseai/api-specifications/build/definitions/core/CtbClassificationsExternal';
import { FindingType } from 'types/viewer/FindingsMenu';
import sortByDisplayOrder from './sortByDisplayOrder';

type SharedLocalisationItem = {
  label: string;
  labelName: string;
  relatedLabels: string[];
  segmentId: string;
};

type CtbClassificationsWithParentFindings = CtbGroupedRelevantClassificationExternal & {
  predictions: FindingType[];
};

const checkForParentFinding = (finding: CtbClassificationExternal, parents: SharedLocalisationItem[]) => {
  return parents.find(({ relatedLabels = [] }: SharedLocalisationItem) => relatedLabels.includes(finding.label));
};

// Outputs one group per classification - eg. 'Priority' and 'Other' with findings grouped by parent/child relationships
const getFamiliesFromClassifications = (
  relevantClassifications: CtbGroupedRelevantClassificationExternal[],
  parents: CtbSegmentationExternal[],
): (CtbGroupedRelevantClassificationExternal & {
  predictions: FindingType[];
})[] => {
  const allIdentifiedParents: string[] = [];
  const groupedFindingsByClassification: CtbClassificationsWithParentFindings[] = [];
  const allFindingsAllGroups = relevantClassifications.flatMap(classification => classification.predictions);

  const getChildren = (parent: { relatedLabels: string | string[] }) => {
    return allFindingsAllGroups.filter(finding => {
      return parent.relatedLabels.includes(finding.label);
    });
  };
  const getFamilies = (findings: CtbClassificationExternal[]) => {
    const families: FindingType[] = [];

    findings.forEach((item: CtbClassificationExternal) => {
      const parent = checkForParentFinding(item, parents);
      const parentHasMultipleChildren = parent && parent?.relatedLabels?.length > 1;
      const findingAlreadySorted =
        parent && allIdentifiedParents.some(identifiedParent => identifiedParent === parent.label);

      if (parentHasMultipleChildren) {
        // don't process children that have already been sorted
        if (findingAlreadySorted) {
          return;
        }

        const { relatedLabels: _, ...rest } = parent;
        allIdentifiedParents.push(parent.label);
        const childFindings = getChildren(parent);
        families.push({
          ...rest,
          childFindings,
        });
      } else {
        families.push(item);
      }
    });

    return families;
  };

  relevantClassifications.forEach(classification => {
    //Needs to be updated to fit CXR (classification.findings)
    const findings = sortByDisplayOrder(classification.predictions);
    const families = getFamilies(findings);

    const { predictions: _, ...rest } = classification;
    groupedFindingsByClassification.push({
      ...rest,
      predictions: families,
    } as CtbGroupedRelevantClassificationExternal & { predictions: FindingType[] });
  });

  return groupedFindingsByClassification;
};

export default getFamiliesFromClassifications;
