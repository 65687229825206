import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
`;

const StyledInput = styled.input`
  align-items: center;
  background-color: ${colors.WHITE};
  color: ${colors.BLACK};
  border-radius: 0.25rem;
  border: 1px solid ${colors.WHITE_40};
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 0.5rem;
  min-height: 38px;
  outline: 0 !important;
  padding: 0.125rem 0.5rem;
  width: 100%;
  &:focus {
    border: 1px solid #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
  &::placeholder {
    color: #808080;
  }
`;

type InputProps = {
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  defaultValue?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  type?: string;
  value?: string;
};

const Input: FC<InputProps> = ({
  name,
  onChange,
  onBlur,
  autoComplete = 'off',
  defaultValue,
  placeholder,
  readOnly = false,
  required = false,
  type = 'text',
  value,
}) => (
  <InputContainer>
    <StyledInput
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      type={type}
      autoComplete={autoComplete}
      defaultValue={defaultValue}
      placeholder={placeholder}
      readOnly={readOnly}
      required={required}
      value={value}
    />
  </InputContainer>
);

export default Input;
