import Expand from 'icons/Expand';
import { AboutModalSubheader, AboutContentWrapper, AboutIcon } from './AboutModal.styles';
import { supportItems, SUPPORT_SUBHEADER, EMAIL_TEXT } from './constants';

const SupportComponent = (): JSX.Element => (
  <>
    <AboutModalSubheader>{SUPPORT_SUBHEADER}</AboutModalSubheader>
    <AboutContentWrapper>
      <AboutIcon>
        <Expand />
      </AboutIcon>
      {EMAIL_TEXT}&nbsp;
      <a href={supportItems.mailToHref}>{supportItems.email}</a>
    </AboutContentWrapper>
  </>
);

export default SupportComponent;
