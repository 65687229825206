enum CornerstoneToolsMouseEvents {
  CORNERSTONE_TOOLS_MOUSE_DOWN = 'cornerstonetoolsmousedown',
  CORNERSTONE_TOOLS_MOUSE_UP = 'cornerstonetoolsmouseup',
  CORNERSTONE_TOOLS_MOUSE_DOWN_ACTIVATE = 'cornerstonetoolsmousedownactivate',
  CORNERSTONE_TOOLS_MOUSE_DRAG = 'cornerstonetoolsmousedrag',
  CORNERSTONE_TOOLS_MOUSE_MOVE = 'cornerstonetoolsmousemove',
  CORNERSTONE_TOOLS_MOUSE_CLICK = 'cornerstonetoolsmouseclick',
  CORNERSTONE_TOOLS_MOUSE_DOUBLE_CLICK = 'cornerstonetoolsmousedoubleclick',
  CORNERSTONE_TOOLS_MOUSE_WHEEL = 'cornerstonetoolsmousewheel',
}

export default CornerstoneToolsMouseEvents;
