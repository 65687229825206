import styled from 'styled-components';
import colors from 'constants/colors';

const { WHITE_50, GREY_9 } = colors;

export const ServiceGridRow = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${props => (props.active ? '16.75rem' : '7.5rem')};
  transition: height ease 0.5s;
`;

export const SubServiceRow = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 0.75rem;
  width: 100%;
  padding: 0 1rem;
`;
export const SubServiceContainer = styled.div<{ active: boolean }>`
  display: flex;
  background: ${GREY_9};
  width: 100%;
  margin-top: 0.5rem;
  position: absolute;
  left: 0;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  opacity: ${props => (props.active ? 1 : 0)};
  height: ${props => (props.active ? '9rem' : '0')};
  transform: translateY(7.5rem);
  transition: ${props =>
    props.active ? 'height ease 0.5s, opacity ease 0.5s 0.25s' : 'height ease 0.5s 0.75s, opacity ease 0.5s'};

  *::-webkit-scrollbar {
    background-color: transparent;
    width: 15px;
  }
  *::-webkit-scrollbar-track {
    background-color: transparent;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 4px solid transparent;
    background-color: ${WHITE_50};
    background-clip: content-box;
  }
`;
