import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import DropzoneStates from 'enums/DropzoneStates';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { isDropzoneStateLoaded } from 'helpers/dropzoneStateHelper';
import { SetDropzoneState } from 'types/SetDropzoneState';

const { LOADED, LOADING } = DropzoneStates;
const { IMAGE_FILE_VIEWER } = ReactTestingLibraryDataProperties;

const Container = styled.img`
  border-radius: var(--dropzone--border-radius);
  height: 4.2rem;
  width: 4.2rem;
  position: absolute;
  left: 1rem;
  object-fit: cover;
`;

interface DicomFileViewerComponentProps {
  index: number;
  file: File;
  dropzoneState: DropzoneStates;
  setDropzoneState: SetDropzoneState;
}

const ImageFileViewer: FC<DicomFileViewerComponentProps> = ({ index, file, dropzoneState, setDropzoneState }) => {
  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    (async () => {
      const current = ref.current;
      if (file && current) {
        const fileReader = new FileReader();

        setDropzoneState(LOADING);
        fileReader.onload = event => {
          const result = event.target?.result;

          if (typeof result === 'string') {
            current.src = result;
            setDropzoneState(LOADED);
          }
        };

        fileReader.readAsDataURL(file);
      }
    })();
  }, [ref, file, index, setDropzoneState]);

  useEffect(() => {
    const current = ref?.current;
    if (!isDropzoneStateLoaded(dropzoneState) && current) {
      current.src = '';
    }
  }, [ref, dropzoneState]);

  return <Container alt="jpeg file viewer" data-testid={IMAGE_FILE_VIEWER} ref={ref} />;
};

export default ImageFileViewer;
