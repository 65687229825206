import * as Yup from 'yup';

// pardot's valid email format is different than standard Yup email format (pardot won't accept a single character TLD)
const userInformationSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string()
    .matches(
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])$/i,
      'Please enter a valid email address',
    )
    .required('Email address is required'),
  jobtitle: Yup.string().required('Job title is required'),
  organisationname: Yup.string().required('Organisation name is required'),
  optin: Yup.boolean(),
});

export default userInformationSchema;
