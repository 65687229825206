import styled, { DataAttributes } from 'styled-components';
import PrimaryButton from 'components/PrimaryButton';
import colors from 'constants/colors';
import Z_INDEXES from 'constants/zIndexes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { MODAL_CONTAINER } = ReactTestingLibraryDataProperties;
const { MODAL } = Z_INDEXES;
const { PRIMARY_OUTLINE, SECONDARY, WHITE } = colors;

export const ModalContainer = styled.div.attrs<DataAttributes>({
  'data-testid': MODAL_CONTAINER,
  'aria-label': 'Modal',
  role: 'dialog',
  'aria-modal': 'true',
})`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 0;
  background: ${SECONDARY};
  box-shadow: 0rem 1.25rem 2.5rem rgba(0, 0, 0, 0.5);
  border-radius: 0.625rem;
  max-height: 100%;
  max-width: 23.5rem;
  font-family: 'Inter';
  z-index: ${MODAL};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
  align-items: left;
  color: ${WHITE};
  margin: 1em;
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 3em;
  margin: 1em auto;
  padding: 0 2.5rem 1.5rem 2.5rem;
`;

export const CloseButton = styled(PrimaryButton)`
  align-items: center;
  height: 2.5rem;
  width: 18.5rem;
  border: 0.063rem solid ${PRIMARY_OUTLINE};
  font-size: 0.75rem;
  font-weight: 400;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;
