import { RootState } from 'slices/RootState';
import Image from 'types/study/Image';

const selectActiveImage = ({
  cxrViewer: { activeFinding, defaultBaseImageUuid, findingViewsMap, activeView },
  images: { imagesMap },
}: RootState): Image | null => {
  const findingViewUuid = activeFinding?.findingViewUuids;
  const imageUuid = findingViewUuid?.length
    ? findingViewsMap[activeView || findingViewUuid[0]]?.baseImageUuid
    : defaultBaseImageUuid;

  if (!imageUuid) {
    return null;
  }

  return imagesMap[imageUuid];
};

export default selectActiveImage;
