import { CtbPredictionCompleted, Status } from '@annaliseai/api-specifications';
import { interval, throwError } from 'rxjs';
import { exhaustMap, first, map, timeoutWith } from 'rxjs/operators';
import { getCtbPrediction } from 'api/queries';
import getErrorCode from 'api/queries/getErrorCode';
import { POLLING } from 'constants/durations';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';

const { UNEXPECTED_BACKEND_ERROR } = CustomErrors;
const { COMPLETED, COMPLETED_ERROR } = Status;
const { PERIOD, TIMEOUT } = POLLING;

const loadCtbPrediction = async (seriesInstanceUid: string): Promise<CtbPredictionCompleted> =>
  await interval(PERIOD)
    .pipe(
      exhaustMap(async () => await getCtbPrediction(seriesInstanceUid)),
      map(prediction => {
        const { status } = prediction;
        if (status === COMPLETED_ERROR) {
          throw new CustomError(getErrorCode(prediction.error.code));
        }

        return prediction;
      }),
      first((prediction): prediction is CtbPredictionCompleted => prediction.status === COMPLETED),
      timeoutWith(TIMEOUT, throwError(new CustomError(UNEXPECTED_BACKEND_ERROR))),
    )
    .toPromise();

export default loadCtbPrediction;
