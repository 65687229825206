import log from 'loglevel';
import client from 'api/client';
import endpoints from 'api/endpoints';

const getRequestingServices = async (): Promise<string[]> => {
  const { studiesRequestingServices } = endpoints.v1;
  const path = studiesRequestingServices.getPath();
  const { data } = await client.get(path);
  log.debug('received getStudies response=', data);
  return data;
};

export default getRequestingServices;
