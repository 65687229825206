import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import DisplayError from 'types/DisplayError';

export type ErrorState = {
  error?: DisplayError;
};

export const initialState: ErrorState = {};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<DisplayError>) => ({ ...state, error: action.payload }),
    resetError: _ => ({ ...initialState }),
  },
});

export const errorActions = errorSlice.actions;
export type SetErrorType = ReturnType<typeof errorActions.setError>;
export type ResetErrorType = ReturnType<typeof errorActions.resetError>;
export type ErrorActionTypes = ResetErrorType | SetErrorType;
