import { colors } from '@annaliseai/anna-design-tokens';
import { ReactElement } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { WHITE } = colors;
const { ICON_SAVE } = ReactTestingLibraryDataProperties;

const Save = ({ size = 1, color = WHITE }: Icon): ReactElement => (
  <Container size={size} $testId={ICON_SAVE}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M4 2.39999C3.11635 2.39999 2.4 3.11634 2.4 3.99999V12C2.4 12.8836 3.11635 13.6 4 13.6H6.40307C6.4089 13.5087 6.42315 13.416 6.44651 13.3226L6.57716 12.8H4.8V9.19999C4.8 8.97908 4.97909 8.79999 5.2 8.79999H9.55491L10.3549 7.99999H5.2C4.53726 7.99999 4 8.53725 4 9.19999L4 12.8C3.55817 12.8 3.2 12.4418 3.2 12V3.99999C3.2 3.55817 3.55817 3.19999 4 3.19999L4.8 3.19999V5.19999C4.8 5.86274 5.33726 6.39999 6 6.39999L9.2 6.39999C9.86274 6.39999 10.4 5.86274 10.4 5.19999V3.19999L10.7029 3.19999C10.9151 3.19999 11.1186 3.28428 11.2686 3.43431L12.5657 4.73136C12.7157 4.88139 12.8 5.08488 12.8 5.29705V6.40243C13.0694 6.39005 13.3405 6.42487 13.6 6.50689V5.29705C13.6 4.8727 13.4314 4.46574 13.1314 4.16568L11.8343 2.86862C11.5343 2.56856 11.1273 2.39999 10.7029 2.39999H4ZM5.6 5.19999L5.6 3.19999L9.6 3.19999V5.19999C9.6 5.42091 9.42092 5.59999 9.2 5.59999L6 5.59999C5.77909 5.59999 5.6 5.42091 5.6 5.19999ZM11.8473 7.6382L7.9838 11.5017C7.75854 11.727 7.59874 12.0092 7.52148 12.3183L7.22189 13.5166C7.09161 14.0378 7.56365 14.5098 8.08478 14.3795L9.2831 14.0799C9.59215 14.0027 9.8744 13.8429 10.0997 13.6176L13.9632 9.75406C14.5475 9.16978 14.5475 8.22248 13.9632 7.6382C13.3789 7.05392 12.4316 7.05392 11.8473 7.6382Z"
        fill={color}
      />
    </svg>
  </Container>
);

export default Save;
