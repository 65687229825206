import { UserLoggedIn as UserLoggedInIcon } from 'icons/UserLoggedin';
import { UsernameContainer, UsernameText } from './UserName.styles';

type UserNameProps = { userName: string };

const UserName = ({ userName }: UserNameProps): JSX.Element => {
  return (
    <UsernameContainer>
      <UserLoggedInIcon />
      <UsernameText>{userName}</UsernameText>
    </UsernameContainer>
  );
};

export default UserName;
