import { GetCtbImageSlicesRes } from '@annaliseai/api-specifications';
import client from 'api/client';
import endpoints from 'api/endpoints';

const getCtbBaseImages = async ({
  seriesInstanceUid,
  seriesVersionId,
}: {
  seriesInstanceUid: string;
  seriesVersionId: string;
}): Promise<GetCtbImageSlicesRes> => {
  const { seriesBaseImages } = endpoints.v1;
  const path = seriesBaseImages.getPath({ seriesInstanceUid, seriesVersionId });

  const { data }: { data: GetCtbImageSlicesRes } = await client.get(path);

  return data;
};

export default getCtbBaseImages;
