import Cookies from 'js-cookie';

// Parses js-cookies if they are are in stringified form
// In js-cookie prior to 3.0.0, Cookies.get() had JSON.parse() inbuilt.
// From 3.0.0 have to do ourselves. Temp solution.

const getCookie = (cookieName: string): unknown => {
  try {
    const cookieValue = Cookies.get(cookieName);

    if (!cookieValue) {
      return null;
    }
    // Attempt to parse the cookie value
    try {
      const parsedValue = JSON.parse(cookieValue);
      return parsedValue;
    } catch {
      // If parsing as fails, return the value as is

      return cookieValue;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default getCookie;
