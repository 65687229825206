/* eslint-disable react-hooks/exhaustive-deps */
import { SegmentLateralityType, ViewType } from '@annaliseai/api-specifications';
import { FC, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css, DataAttributes } from 'styled-components';
import errorsMapping from 'constants/errorsMapping';
import cornerstone from 'cornerstone-core';
import CustomErrors from 'enums/CustomErrors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { preventTextSelectionOnMouseMove } from 'helpers/cornerstone/cornerstoneToolsHelper';
import renderSegment from 'helpers/cornerstone/renderSegment';
import {
  getBaseSynchronizer,
  getResizeSynchronizer,
  getSegmentSynchronizer,
} from 'helpers/cornerstone/synchronizerHelper';
import { errorActions } from 'slices/errorSlice';
import { isCornerstoneWebImage } from 'typeguards/cornerstone';
import CornerstoneImageId from 'types/cornerstone/CornerstoneImageId';
import { CornerstoneVoi } from 'types/cornerstone/CornerstoneVoi';
import { Lateralities } from 'types/study/Localisation';
import LateralSegment from './LateralSegment';

const { IMAGE, SEGMENT } = ReactTestingLibraryDataProperties;
const { NONE } = SegmentLateralityType;
const { setError } = errorActions;
const { IMAGE_DOWNLOAD_ERROR } = CustomErrors;

type CornerstoneViewportProps = {
  baseImageId: CornerstoneImageId;
  currentView: ViewType;
  voi: CornerstoneVoi;
  segment?: { type: 'SEGMENT'; value: CornerstoneImageId };
  laterality?: Lateralities;
  isLocalisationVisible: boolean;
  onInitialised?: (elementRef: RefObject<HTMLDivElement>) => void | (() => void);
};

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const BaseContainer = styled.div.attrs<DataAttributes>({ 'data-testid': IMAGE })`
  height: 100%;
`;

const SegmentContainer = styled.div.attrs<DataAttributes>({ 'data-testid': SEGMENT })<{ isVisible: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  pointer-events: none;
  // this border forces the container to render on Windows (SWV-692)
  border-bottom: 1px solid rgba(0, 0, 0, 0.01);
  ${({ isVisible }) =>
    isVisible
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}
`;

const CornerstoneViewport: FC<CornerstoneViewportProps> = ({
  baseImageId,
  currentView,
  voi,
  segment,
  laterality = NONE,
  isLocalisationVisible,
  onInitialised,
}) => {
  const { SAGITTAL } = ViewType;
  const baseRef = useRef<HTMLDivElement | null>(null);
  const segmentRef = useRef<HTMLDivElement | null>(null);
  const synchronizer = useMemo(getResizeSynchronizer, []);
  const baseSynchronizer = useMemo(() => getBaseSynchronizer(synchronizer), [synchronizer]);
  const segmentSynchronizer = useMemo(() => getSegmentSynchronizer(synchronizer), [synchronizer]);
  const cleanupFuncRef = useRef<() => void>(() => undefined);
  const shouldShowLaterality = laterality !== NONE && isLocalisationVisible && currentView !== SAGITTAL;
  const dispatch = useDispatch();
  const [isImageCorrupt, setIsImageCorrupt] = useState(false);

  // Cornerstone initialisation
  useEffect(() => {
    const { current: baseElement } = baseRef;
    const { current: segmentElement } = segmentRef;

    if (!baseElement || !segmentElement) {
      return;
    }

    cornerstone.enable(baseElement);
    cornerstone.enable(segmentElement);

    baseElement.addEventListener('mousedown', preventTextSelectionOnMouseMove);

    if (onInitialised) {
      const cleanupFunc = onInitialised(baseRef);
      if (typeof cleanupFunc === 'function') {
        cleanupFuncRef.current = cleanupFunc;
      }
    }

    return () => {
      const { current: cleanupFunc } = cleanupFuncRef;
      cleanupFunc();
      cornerstone.disable(baseElement);
      cornerstone.disable(segmentElement);
      baseElement.removeEventListener('mousedown', preventTextSelectionOnMouseMove);
    };
  }, []);

  // Base image rendering
  useEffect(() => {
    if (isImageCorrupt) {
      return;
    }

    const { current: baseElement } = baseRef;
    let isCleanupInvoked = false;

    if (!baseElement) {
      return;
    }

    (async () => {
      let image;
      try {
        image = await cornerstone.loadImage(baseImageId);
      } catch (error) {
        setIsImageCorrupt(true);
        dispatch(setError(errorsMapping[IMAGE_DOWNLOAD_ERROR]));
        return;
      }

      if (isCleanupInvoked) {
        return;
      }

      cornerstone.displayImage(baseElement, image, {
        voi,
      });

      baseSynchronizer.addElement(baseElement);
    })();

    return () => {
      baseSynchronizer.removeElement(baseElement);
      isCleanupInvoked = true;
    };
  }, [baseImageId, baseSynchronizer, dispatch, voi]);

  // Segment rendering
  useEffect(() => {
    if (isImageCorrupt) {
      return;
    }

    const { current: segmentElement } = segmentRef;
    let isCleanupInvoked = false;

    if (!segmentElement || !isLocalisationVisible || !segment || segment.type !== 'SEGMENT') {
      return;
    }

    (async () => {
      const { value: segmentId } = segment;
      let image;
      try {
        image = await cornerstone.loadImage(segmentId);
      } catch (error) {
        setIsImageCorrupt(true);
        dispatch(setError(errorsMapping[IMAGE_DOWNLOAD_ERROR]));
        return;
      }

      if (isCornerstoneWebImage(image)) {
        image.render = renderSegment;
      }

      if (isCleanupInvoked) {
        return;
      }

      cornerstone.displayImage(segmentElement, image);
      segmentSynchronizer.addElement(segmentElement);
    })();

    return () => {
      segmentSynchronizer.removeElement(segmentElement);
      isCleanupInvoked = true;
    };
  }, [segment, isLocalisationVisible, segmentSynchronizer, dispatch]);

  return (
    <ImageContainer>
      {!isImageCorrupt && (
        <>
          <BaseContainer ref={baseRef} />
          <SegmentContainer ref={segmentRef} isVisible={isLocalisationVisible && segment?.type === 'SEGMENT'} />
          {shouldShowLaterality && <LateralSegment laterality={laterality} height={50} />}
        </>
      )}
    </ImageContainer>
  );
};

export default CornerstoneViewport;
