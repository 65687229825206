import Cookies from 'js-cookie';
import configuration from 'configuration';
import CookieKeys from 'enums/CookieKeys';

const { ONBOARDING_DONE } = CookieKeys;

export const storeOnboardingDone = (): void => {
  Cookies.set(ONBOARDING_DONE, 'true', { expires: 30 });
};

export const retrieveOnboardingDone = (): boolean => {
  if (configuration.isDemo) {
    return !!Cookies.get(ONBOARDING_DONE);
  }
  return configuration.isViewer;
};
