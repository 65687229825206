import { colors } from '@annaliseai/anna-design-tokens';
import styled, { DataAttributes } from 'styled-components';
import { APP } from 'constants/sizes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { SIDEBAR_WIDTH, HEADER_HEIGHT } = APP;
const { BUTTON_PRIMARY_PRESSED_BG, BLACK, DARK_3 } = colors;
const { SIDEBAR__STUDY_IDS, SIDEBAR__HEADER } = ReactTestingLibraryDataProperties;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${DARK_3};
  width: ${SIDEBAR_WIDTH}px;
  // this must be vh to ensure only sidebar scrolls (not viewer container)
  height: 100vh;
`;

export const TopSidebarContainer = styled.div`
  display: flex;
  height: ${HEADER_HEIGHT}rem;
  min-height: ${HEADER_HEIGHT}rem;
  align-items: center;
  justify-content: space-around;
`;

export const StudyIdsContainer = styled.div.attrs<DataAttributes>({
  'data-testid': SIDEBAR__STUDY_IDS,
})`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 2.5rem 1.5rem 2rem;
  gap: 0.25rem;
`;

export const PatientDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.125rem 0;
`;

export const PrimaryRow = styled.div`
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.02rem;
  text-shadow: 1px 1px 0 ${BLACK};
`;
export const SecondaryRow = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.041rem;
  text-shadow: 1px 1px 0 ${BLACK};
  color: #ccc;
`;

export const TertiaryRow = styled.div`
  font-size: 0.688rem;
  line-height: 1rem;
  letter-spacing: 0.045rem;
  text-shadow: 1px 1px 0 ${BLACK};
  color: #848484;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderContainer = styled.div.attrs<DataAttributes>({
  'data-testid': SIDEBAR__HEADER,
})`
  border-radius: 0.75rem;
  border: 1px solid var(--sidebar--header--border-color);
  background-color: var(--sidebar__header--background-hover);
  padding: 12px 14px 12px 32px;
  margin: 0 12px 12px 12px;
  display: flex;
  font-weight: 500;
`;

export const Header = styled.span`
  flex-grow: 1;
  text-shadow: 1px 1px 0 ${BUTTON_PRIMARY_PRESSED_BG};
`;

export const FindingContainer = styled.div`
  overflow-x: hidden;
  // make scrollbar always 'visible' so that the badges can be aligned with the Header
  overflow-y: scroll;
  // this is necessary to display finding arrows on hover
  margin-left: -2em;
  // applicable to Firefox only
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 5px;
  }
`;
