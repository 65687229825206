import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './RootState';

export type ViewerState = {
  isLocalisationToggleOn: boolean;
};

export const initialState: ViewerState = {
  isLocalisationToggleOn: true,
};

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    setIsLocalisationToggleOn: (state, { payload }: PayloadAction<ViewerState['isLocalisationToggleOn']>) => ({
      ...state,
      isLocalisationToggleOn: payload,
    }),
  },
});

export const viewerActions = viewerSlice.actions;

export const selectIsLocalisationToggleOn = ({ viewer }: RootState): ViewerState['isLocalisationToggleOn'] =>
  viewer.isLocalisationToggleOn;
