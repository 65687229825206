import { CheckboxItem, Label, NativeCheckbox } from 'components/Menu/Checkbox/Checkbox.styles';
import { CheckboxProps } from 'components/Menu/Checkbox/Checkbox.types';
import { CheckboxChecked, CheckboxUnchecked } from 'components/Menu/Checkbox/CheckboxIcons';

const Checkbox = ({ id, isChecked, isDisabled, onChange, label }: CheckboxProps): JSX.Element => {
  return (
    <CheckboxItem>
      <Label $isDisabled={isDisabled} $isChecked={isChecked}>
        <NativeCheckbox disabled={isDisabled} checked={isChecked} onChange={onChange} id={id} name={label} />
        {isChecked ? <CheckboxChecked /> : <CheckboxUnchecked />}
        {label}
      </Label>
    </CheckboxItem>
  );
};

export default Checkbox;
