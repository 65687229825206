import { StudyExternalV2, PostFilterStudiesV2Res } from '@annaliseai/api-specifications';
import { interval, throwError } from 'rxjs';
import { exhaustMap, first, timeoutWith } from 'rxjs/operators';
import { postStudiesFilter } from 'api/queries';
import { POLLING } from 'constants/durations';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';

const { STUDY_NOT_FOUND } = CustomErrors;
const { PERIOD, TIMEOUT } = POLLING;

const getTime = (date?: string, time?: string) => {
  const [year, month, day] = date ? date.split('-') : [0, 1, 0];
  const [hours, minutes, seconds] = time ? time.split(':') : [0, 0, 0];
  return new Date(+year, +month - 1, +day, +hours, +minutes, +seconds).getTime();
};

const selectRelevantStudy = (studiesResponse: StudyExternalV2[]): StudyExternalV2 =>
  studiesResponse
    // Sort filtered studies by newest date/time and select the first one
    .sort((a, b) => {
      const {
        study: { studyTime: studyTimeA = '', studyDate: studyDateA },
      } = a;
      const {
        study: { studyTime: studyTimeB = '', studyDate: studyDateB },
      } = b;

      if (studyDateB !== studyDateA) {
        return getTime(studyDateB, studyTimeB) - getTime(studyDateA, studyTimeA);
      }

      return studyTimeB < studyTimeA ? -1 : 0;
    })[0];

const loadStudyExternal = async (accessionNumber: string, studyInstanceUid?: string): Promise<StudyExternalV2> => {
  const postStudiesFilterResponse: PostFilterStudiesV2Res = await interval(PERIOD)
    .pipe(
      exhaustMap(async () => await postStudiesFilter(accessionNumber)),
      first((data): data is PostFilterStudiesV2Res => data.studies !== null),
      timeoutWith(TIMEOUT, throwError(new CustomError(STUDY_NOT_FOUND))),
    )
    .toPromise();

  let studyExternal: StudyExternalV2;
  if (studyInstanceUid) {
    studyExternal = postStudiesFilterResponse.studies.filter(
      studyExternal => studyExternal.study.studyInstanceUid === studyInstanceUid,
    )[0];
  } else {
    studyExternal = selectRelevantStudy(postStudiesFilterResponse.studies);
  }

  if (!studyExternal) {
    throw new CustomError(STUDY_NOT_FOUND);
  }

  return studyExternal;
};

export default loadStudyExternal;
