import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Image from 'types/study/Image';

export type ImagesState = {
  imagesMap: Record<Image['uuid'], Image>;
};

export const initialState: ImagesState = {
  imagesMap: {},
};

export const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    reset: _ => ({
      ...initialState,
    }),
    setImagesMap: (state, { payload: imagesMap }: PayloadAction<ImagesState['imagesMap']>) => ({
      ...state,
      imagesMap,
    }),
  },
});

export const imagesActions = imagesSlice.actions;
