import Sample from 'types/Sample';

const cxrSamples: Sample[] = [
  {
    accessionNumber: '47PSWNRQPBRZNEDK',
    description: 'Motor vehicle accident',
    frontCount: 1,
    imageUrl: '/static/samples/mva_trauma_front.jpg',
    studyInstanceUid: '1.2.36.1.2001.1005.78.916764692627017195447524028546396303592147',
  },
  {
    accessionNumber: '2GVQKH7GBQATZ4SM',
    description: 'Outpatient screening',
    frontCount: 1,
    imageUrl: '/static/samples/outpatient_screening_front.jpg',
    studyInstanceUid: '1.2.36.1.2001.1005.78.104870763233520480475104075914255159963210',
  },
  {
    accessionNumber: 'TDMW9JY6ENDBAJHV',
    description: 'Routine post-op',
    frontCount: 1,
    imageUrl: '/static/samples/routine_post_op_front.jpg',
    latCount: 1,
    studyInstanceUid: '1.2.36.1.2001.1005.78.403679803680825720582075028123025779655625',
  },
];

export default cxrSamples;
