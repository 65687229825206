export enum VersionNames {
  AGENT = 'agent',
  ANNALISE_MODEL_SERVER = 'annalise-model-server',
  ANNALISE_BACKEND_UDI = 'annaliseBackendUDI',
  ANNALISE_BACKEND_VERSION = 'annaliseBackendVersion',
  ANNALISE_CXR_VERSION = 'annaliseCxrVersion',
  CLUSTER_AGENT = 'cluster-agent',
  CONNECTOR_CXR = 'connector-cxr',
  CTB_CONNECTOR = 'ctb-connector',
  ENTERPRISE_VERSION = 'enterprise-version',
  GATEWAY = 'gateway',
  GROOT = 'groot',
  NOTIFICATION_SERVICE = 'notification-service',
  ROCKET = 'rocket',
  SECONDARY_CAPTURE = 'secondary-capture',
  WEB_VIEWER_BFF = 'web-viewer-bff',
}

export type VersionType = {
  name: VersionNames;
  version: string;
};

export type VersionsType = { [key in VersionNames]: string };
