import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItemModal from 'components/Modals/MenuItemModal';
import { PLACEHOLDER_DASH } from 'constants/strings';
import selectVersions from 'selectors/viewer/selectVersions';
import { infoActions } from 'slices/infoSlice';
import { VersionNames } from 'types/VersionNames';
import { ModalTitle, ModalSubtitle } from './AboutModal.styles';
import { AboutModalProps } from './AboutModal.types';
import {
  supportItems,
  ABOUT_WEB_VIEWER_MODAL_TITLE_TEXT,
  ABOUT_WEB_VIEWER_MODAL_SUBTITLE_TEXT,
  VERSION_ENTERPRISE_SUBHEADER,
  UDI_WEB_VERSION,
  VERSION_WEB_SUBHEADER,
} from './constants';
import DocumentationItemComponent from './DocumentationItemComponent';
import InfoPanelComponent from './InfoPanelComponent';
import SupportComponent from './SupportComponent';
import VersionComponent from './VersionComponent';

const { ANNALISE_BACKEND_UDI, ANNALISE_BACKEND_VERSION } = VersionNames;

const AboutModalComponent = ({ modalOpen, onClose }: AboutModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const versions = useSelector(selectVersions);

  useEffect(() => {
    !versions && dispatch(infoActions.fetchVersions());
  }, [dispatch, versions]);

  const udiEnterprise = versions?.[ANNALISE_BACKEND_UDI] || PLACEHOLDER_DASH;
  const versionEnterprise = versions?.[ANNALISE_BACKEND_VERSION] || PLACEHOLDER_DASH;

  return (
    <MenuItemModal show={modalOpen} onClose={onClose}>
      <ModalTitle>{ABOUT_WEB_VIEWER_MODAL_TITLE_TEXT}</ModalTitle>
      <ModalSubtitle>{ABOUT_WEB_VIEWER_MODAL_SUBTITLE_TEXT}</ModalSubtitle>
      <VersionComponent
        subheader={VERSION_WEB_SUBHEADER}
        udi={UDI_WEB_VERSION}
        version={process.env.REACT_APP_VERSION || PLACEHOLDER_DASH}
      />
      <VersionComponent subheader={VERSION_ENTERPRISE_SUBHEADER} udi={udiEnterprise} version={versionEnterprise} />
      <DocumentationItemComponent />
      <SupportComponent />
      <InfoPanelComponent items={supportItems.infoPanelItems} />
    </MenuItemModal>
  );
};

export default AboutModalComponent;
