import { StudyType } from '@annaliseai/api-specifications';
import { ComponentType } from 'react';
import ctbImage from 'assets/ctb.jpg';
import cxrImage from 'assets/cxr.jpg';
import CtbViewer from 'components/CtbViewer';
import CxrViewer from 'components/CxrViewer';
import Routes from 'enums/Routes';

const { CTB, CXR } = StudyType;
const { CTB_SAMPLE, CXR_SAMPLE } = Routes;

type ModalityConfigItem = {
  header: string;
  viewerComponent: ComponentType;
  demoRoute: Routes;
  demoImage: string;
};

type ModalityConfig = Record<StudyType, ModalityConfigItem>;

const modalityConfig: ModalityConfig = {
  [CXR]: {
    header: 'Chest X-ray',
    viewerComponent: CxrViewer,
    demoRoute: CXR_SAMPLE,
    demoImage: cxrImage,
  },
  [CTB]: {
    header: 'CT Brain',
    viewerComponent: CtbViewer,
    demoRoute: CTB_SAMPLE,
    demoImage: ctbImage,
  },
};

export default modalityConfig;
