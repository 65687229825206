import { SegmentLateralityType, StudyType } from '@annaliseai/api-specifications';
import getAdjustedLocalisationType from 'epics/study/helpers/ctb/getAdjustedLocalisationType';
import UuidMapper from 'helpers/UuidMapper';
import { RootState } from 'slices/RootState';
import Finding, { Parent } from 'types/study/Finding';
import Localisation, { FindingLocalisationType, Lateralities, LateralityLocalisation } from 'types/study/Localisation';
import { CxrFindingView } from 'types/viewer/cxrViewer/CxrFindingViewer';
import selectCtbViewer from './ctb/selectCtbViewer';
import selectCxrViewer from './cxr/selectCxrViewer';
import selectActiveFinding from './selectActiveFinding';
import selectLocalisationsMap from './selectLocalisationsMap';
import { selectStudyType } from './selectStudy';

const { CXR, CTB } = StudyType;
const { NO_LOCALISATION, SINGLE_IMAGE, LATERALITY, MULTIPLE_IMAGE } = FindingLocalisationType;

const selectActiveFindingLaterality = (
  state: RootState,
  findingUuid?: Finding['uuid'] | Parent['uuid'] | null,
): {
  laterality: Lateralities | undefined;
  hasLocalisation: boolean;
  localisation: FindingLocalisationType | null;
} => {
  const studyType = selectStudyType(state);
  const activeFinding = selectActiveFinding(state);
  const localisationsMap = selectLocalisationsMap(state);

  const { NONE } = SegmentLateralityType;
  const activeFindingUuid = findingUuid ? findingUuid : activeFinding?.uuid;

  let activeFindingLaterality;
  let hasActiveFindingLocalisation = false;
  let activeFindingLocalisation = null;

  if (studyType === CXR && activeFindingUuid) {
    const { findingViewersMap, findingViewsMap } = selectCxrViewer(state);
    activeFindingLocalisation = NO_LOCALISATION;

    if (findingViewersMap && activeFindingUuid && findingViewersMap[activeFindingUuid] && findingViewsMap) {
      const mapper = new UuidMapper();
      const activeFindingLocalisations = mapper
        .from(findingViewersMap[activeFindingUuid].viewUuids)
        .mapTo(findingViewsMap)
        .result()
        .reduce((allLocalisations: Localisation[], { localisationUuid }: CxrFindingView) => {
          if (localisationUuid && localisationsMap[localisationUuid])
            allLocalisations.push(localisationsMap[localisationUuid]);
          return allLocalisations;
        }, []);
      if (!activeFindingLocalisations.length) {
        activeFindingLocalisation = NO_LOCALISATION;
      } else {
        const { type, laterality } = activeFindingLocalisations[0];
        hasActiveFindingLocalisation = laterality !== NONE;
        if (type === 'LATERALITY') {
          activeFindingLaterality = laterality;
          activeFindingLocalisation = LATERALITY;
        } else {
          if (activeFindingLocalisations.length > 1) {
            activeFindingLocalisation = MULTIPLE_IMAGE;
          } else {
            activeFindingLocalisation = SINGLE_IMAGE;
          }
        }
      }
    }
  } else if (studyType === CTB && activeFindingUuid) {
    const { findingViewersMap, findingViewsMap, segmentSliceIdsMap } = selectCtbViewer(state);
    activeFindingLocalisation = NO_LOCALISATION;

    if (activeFindingUuid && findingViewersMap && findingViewersMap[activeFindingUuid as Finding['uuid']]) {
      const { keyView, viewUuidsMap } = findingViewersMap[activeFindingUuid as Finding['uuid']];
      const view = viewUuidsMap && keyView && findingViewsMap[viewUuidsMap[keyView]];

      if (view) {
        const { type } = view;
        if (type === 'LATERALITY' && view.lateralityUuid) {
          if ('laterality' in localisationsMap[view.lateralityUuid]) {
            const { laterality } = localisationsMap[view.lateralityUuid] as LateralityLocalisation;
            activeFindingLaterality = laterality;
            hasActiveFindingLocalisation = true;
            activeFindingLocalisation = LATERALITY;
          }
        } else if (type === 'SEGMENT') {
          activeFindingLocalisation = getAdjustedLocalisationType({
            segmentSliceIdsMap,
            findingUuid: activeFindingUuid,
            localisation: SINGLE_IMAGE,
          });

          hasActiveFindingLocalisation = activeFindingLocalisation === SINGLE_IMAGE;
        }
      }
    }
  }

  return {
    laterality: activeFindingLaterality,
    hasLocalisation: hasActiveFindingLocalisation,
    localisation: activeFindingLocalisation,
  };
};

export default selectActiveFindingLaterality;
