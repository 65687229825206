const PREV_BUTTON_ATTRS = {
  title: 'Previous',
};

const NEXT_BUTTON_ATTRS = {
  title: 'Next',
};

const BUTTONS = {
  PREV_BUTTON_ATTRS,
  NEXT_BUTTON_ATTRS,
};

export default BUTTONS;
