enum LoginStates {
  INITIAL,
  SENDING,
  FULFILLED,
  FAILED,
}

export const isLoginFulfilled = (loginRequestState: LoginStates): boolean =>
  loginRequestState === LoginStates.FULFILLED;
export const isLoginSending = (loginRequestState: LoginStates): boolean => loginRequestState === LoginStates.SENDING;

export default LoginStates;
