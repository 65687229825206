import { StudyType } from '@annaliseai/api-specifications';
import { RootState } from 'slices/RootState';
import Finding, { Parent } from 'types/study/Finding';

const { CXR, CTB } = StudyType;

const selectActiveFinding = ({
  cxrViewer: { activeFinding: activeFindingCxr },
  ctbViewer: { activeFinding: activeFindingCtb },
  study: { study },
  findings: { findingsMap },
  parents: { parentsMap },
}: RootState): Finding | Parent | null => {
  const studyType = study?.type;
  let activeFinding = null;
  if (studyType === CXR) {
    activeFinding = activeFindingCxr && findingsMap[activeFindingCxr.findingUuid];
  } else if (studyType === CTB) {
    activeFinding =
      (activeFindingCtb && findingsMap[activeFindingCtb.findingUuid as Finding['uuid']]) ||
      (activeFindingCtb && parentsMap[activeFindingCtb.findingUuid as Parent['uuid']]);
  }
  return activeFinding;
};

export default selectActiveFinding;
