import styled, { css } from 'styled-components';
import {
  ButtonBase,
  ButtonIconCss,
  ButtonLargeCss,
  ButtonMediumCss,
  ButtonNoBorderCss,
  ButtonSmallCss,
} from './Button.styles';

export enum ButtonVariant {
  DEFAULT = 'default', // 'no border'
  GHOST = 'ghost',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum ButtonSize {
  ICON = 'icon',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type ButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  onClick?: () => void;
};

const { ICON, SMALL, MEDIUM, LARGE } = ButtonSize;
const { DEFAULT } = ButtonVariant;

// To do: add additional variants (primary, secondary, ghost)
const Button = styled(ButtonBase)<ButtonProps>(
  ({ variant = DEFAULT, size = MEDIUM }) => css`
    ${variant === DEFAULT && ButtonNoBorderCss};
    ${size === ICON && ButtonIconCss};
    ${size === SMALL && ButtonSmallCss};
    ${size === MEDIUM && ButtonMediumCss};
    ${size === LARGE && ButtonLargeCss};
  `,
);

export default Button;
