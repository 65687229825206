import { bool, cleanEnv, num, str } from 'envalid';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';

export const ApplicationModes = ['demo', 'viewer'] as const;
const TRUE = 'true';
const FALSE = 'false';
type ApplicationMode = (typeof ApplicationModes)[number];

type TrackingConfig = {
  enabled: boolean;
  siteId?: number;
  accountUrl?: string;
};

type AuthConfig = {
  realm: string;
  clientId: string;
  url: string;
};

type Configuration = {
  appMode: ApplicationMode;
  appVersion: string | undefined;
  apiUrl: string;
  tracking: TrackingConfig;
  readonly isDemo: boolean;
  readonly isViewer: boolean;
  readonly authConfig: AuthConfig;
};

type FeatureFlags = {
  readonly shouldShowWPZ: boolean;
  readonly isBespokeSpecial: boolean;
};

const env = cleanEnv(process.env, {
  REACT_APP_APPLICATION_MODE: str({ choices: ApplicationModes, default: 'demo' }),
  REACT_APP_API: str({ default: '' }),
  REACT_APP_TRACKING_ENABLED: bool({ default: false }),
  REACT_APP_TRACKING_SITE_ID: num({ default: undefined }),
  REACT_APP_TRACKING_ACCOUNT_URL: str({ default: undefined }),
  REACT_APP_SHOW_WPZ: str({ default: TRUE }),
  REACT_APP_IS_BESPOKE_SPECIAL: str({ default: FALSE }),

  REACT_APP_AUTH_TENANT_ID: str({ default: '' }),
  REACT_APP_AUTH_CLIENT_ID: str({ default: '' }),
  REACT_APP_AUTH_ENDPOINT: str({ default: '' }),
});

if (env.REACT_APP_TRACKING_ENABLED) {
  if (env.REACT_APP_TRACKING_SITE_ID === undefined || env.REACT_APP_TRACKING_ACCOUNT_URL === undefined) {
    const { INVALID_ENVIRONMENT_VARIABLE } = CustomErrors;
    throw new CustomError(
      INVALID_ENVIRONMENT_VARIABLE,
      'Invalid REACT_APP_TRACKING_SITE_ID or REACT_APP_TRACKING_ACCOUNT_URL value',
    );
  }
}

/**
 * App configuration
 *
 * Should:
 * - Contain any configuration the app needs
 * - Be the centralised point to access environment variables
 *
 * Can:
 * - Have helpers to access configuration values as booleans to make it easier to write conditionals ie if(configuration.isDemo) { ... }
 *
 * Usage:
 *  import configuration from 'configuration';
 *  { ... }
 *  configuration.appMode
 *
 */
const configuration: Configuration & FeatureFlags = {
  appMode: env.REACT_APP_APPLICATION_MODE as ApplicationMode,
  apiUrl: env.REACT_APP_API,
  get tracking() {
    if (env.REACT_APP_TRACKING_ENABLED) {
      return {
        enabled: env.REACT_APP_TRACKING_ENABLED,
        siteId: env.REACT_APP_TRACKING_SITE_ID,
        accountUrl: env.REACT_APP_TRACKING_ACCOUNT_URL,
      };
    }
    return {
      enabled: env.REACT_APP_TRACKING_ENABLED,
    };
  },
  // some helpers in form of getters to make dev life easier
  // these can be called like configuration.isDemo
  get isDemo() {
    return configuration.appMode === 'demo';
  },
  get isViewer() {
    return configuration.appMode === 'viewer';
  },
  get appVersion() {
    return process.env.REACT_APP_VERSION;
  },
  get authConfig() {
    return {
      realm: env.REACT_APP_AUTH_TENANT_ID,
      clientId: env.REACT_APP_AUTH_CLIENT_ID,
      url: env.REACT_APP_AUTH_ENDPOINT,
    };
  },
  // Feature Flags
  get shouldShowWPZ() {
    return env.REACT_APP_SHOW_WPZ === TRUE;
  },
  // Note: temporary HK implementation, pending organisation configuration/admin portal
  get isBespokeSpecial() {
    return env.REACT_APP_IS_BESPOKE_SPECIAL === TRUE;
  },
};

export default configuration;
export type { Configuration };
