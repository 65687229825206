import { ImageWithBytesSchema } from '@annaliseai/api-specifications';
import getDisplayError from 'api/client/getDisplayError';
import errorsMapping from 'constants/errorsMapping';
import CustomErrors from 'enums/CustomErrors';
import isCustomError from 'typeguards/isCustomError';
import { ValidationResult } from 'types/ValidationResult';
import getCxrImageWithBytesFromImageFile from './getCxrImageWithBytesFromImageFile';

const { IMAGE_RESOLUTION_TOO_LOW, INCORRECT_MODALITY } = CustomErrors;

const validateCxrImageFile = async (file: File): Promise<ValidationResult<File>> => {
  const value = file;

  let image;
  try {
    image = await getCxrImageWithBytesFromImageFile(file);
  } catch (error) {
    return { error: getDisplayError(isCustomError(error) ? error.errorCode : ''), value };
  }

  if (image.width < 1024 || image.height < 1024) {
    return { error: errorsMapping[IMAGE_RESOLUTION_TOO_LOW], value };
  }

  const { error } = ImageWithBytesSchema.validate(image);

  if (error) {
    return { error: errorsMapping[INCORRECT_MODALITY], value };
  }

  return { value };
};

export default validateCxrImageFile;
