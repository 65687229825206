import { decodeJson } from '@annaliseai/api-specifications';
import { AxiosResponse } from 'axios';
import endpoints, { Endpoint } from 'api/endpoints';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';

const { INVALID_RESPONSE } = CustomErrors;

const endpointsList = Object.values<Record<string, Endpoint>>(endpoints).reduce(
  (acc: Endpoint[], versionEndpoints) => [...acc, ...Object.values<Endpoint>(versionEndpoints)],
  [],
);

const verifyResponse = (response: AxiosResponse): AxiosResponse => {
  const {
    data,
    config: { url = '' },
  } = response;
  const schema = endpointsList.find(endpoint => endpoint.testMatchPath(url))?.getResSchema();

  try {
    if (schema) {
      decodeJson(data, schema);
    }
  } catch (error) {
    throw new CustomError(INVALID_RESPONSE, error instanceof Error ? error.message : '');
  }

  return response;
};

export default verifyResponse;
