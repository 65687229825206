import { GetLatestCtbPredictionRes } from '@annaliseai/api-specifications';
import log from 'loglevel';
import client from 'api/client';
import endpoints from 'api/endpoints';

const getCtbPrediction = async (seriesInstanceUid: string): Promise<GetLatestCtbPredictionRes> => {
  const { seriesPrediction } = endpoints.v1;
  const path = seriesPrediction.getPath({ seriesInstanceUid });

  const { data } = await client.get(path);
  log.debug('received getCtbPrediction response=', data);
  return data;
};

export default getCtbPrediction;
