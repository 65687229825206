import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import getVersions from 'api/queries/getVersions';
import { ActionTypes } from 'slices/ActionTypes';
import { FetchInfoType, SetInfoType, infoActions } from 'slices/infoSlice';

const { fetchVersions, setVersions } = infoActions;

const fetchVersionsEpic: Epic<ActionTypes, SetInfoType> = action$ =>
  action$.pipe(
    ofType<ActionTypes, FetchInfoType>(fetchVersions.type),
    switchMap(async () => {
      const versions = await getVersions();
      return setVersions(versions);
    }),
    catchError(() => EMPTY),
  );

const infoEpic = combineEpics(fetchVersionsEpic);

export default infoEpic;
