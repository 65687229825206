import { replace } from 'connected-react-router';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SidebarNavProps } from 'components/sidebar/SidebarNav';
import Routes from 'enums/Routes';
import { selectStudies } from 'selectors/studyList/selectStudyList';
import { selectStudyIds } from 'selectors/viewer/selectStudy';
import { studyActions } from 'slices/studySlice';

const { VIEWER } = Routes;
const { runResetStudyEffect } = studyActions;

const useStudiesPagination = (): Pick<SidebarNavProps, 'page' | 'totalPages' | 'onPageChange'> => {
  const dispatch = useDispatch();
  const studies = useSelector(selectStudies, shallowEqual);
  const studyIds = useSelector(selectStudyIds, shallowEqual);

  const [page, setPage] = useState(0);

  useEffect(() => {
    if (studies && studyIds) {
      const index = studies.findIndex(
        ({ accessionNumber, studyInstanceUid }) =>
          accessionNumber === studyIds.accessionNumber && studyInstanceUid === studyIds.studyInstanceUid,
      );

      if (index !== -1) {
        setPage(index);
      }
    }
  }, [studies, studyIds]);

  const onPageChange = useCallback(
    (newPage: number): void => {
      const study = studies?.[newPage];
      if (!study) {
        return;
      }

      const studyIds = {
        accessionNumber: study.accessionNumber,
        studyInstanceUid: study.studyInstanceUid,
      };

      dispatch(runResetStudyEffect());

      const query = queryString.stringify(studyIds);
      dispatch(replace(`${VIEWER}?${query}`));
    },
    [dispatch, studies],
  );

  return useMemo(
    () => ({
      page,
      totalPages: studies?.length || 0,
      onPageChange,
    }),
    [onPageChange, page, studies?.length],
  );
};

export default useStudiesPagination;
