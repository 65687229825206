import { colors as annaColors } from '@annaliseai/anna-design-tokens';
import ConfidenceIndicator from '@annaliseai/confidence-indicator';
import React from 'react';
import styled from 'styled-components';
import colors from 'constants/colors';
import { APP } from 'constants/sizes';
import FindingIndicatorType from 'enums/FindingIndicatorType';
import Finding from 'types/study/Finding';
import FindingIndicator from './FindingIndicator';

const { BLACK, DARK_6 } = annaColors;
const { DARK_7, GREY_3 } = colors;
const { BULLET_POINT } = FindingIndicatorType;
const { SIDEBAR_WIDTH } = APP;

const ChildContainer = styled.div`
  width: 100%;
  background-color: ${BLACK};
  pointer-events: none;
`;
const Child = styled.div`
  padding: 4px 0 4px 12px;
  display: grid;
  grid-auto-columns: 1fr;
  max-width: ${SIDEBAR_WIDTH};
  grid-template-columns: 1.5rem 14.5rem 4rem;
  grid-template-rows: 1fr;
  gap: 0.5rem;
  grid-template-areas: 'indicator name confidence';
`;

const Indicator = styled.div`
  grid-area: indicator;
`;

const Name = styled.div`
  font-size: 0.75rem;
  text-shadow: 1px 1px 0 ${BLACK};
  grid-area: name;
  display: flex;
  align-items: center;
  color: ${GREY_3};
`;

const ConfidenceContainer = styled.div`
  grid-area: confidence;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

type ChildFindingProps = Finding & { isParentActive?: boolean };

const ChildFinding = ({ name, predictionProbability, isParentActive = false }: ChildFindingProps): JSX.Element => {
  const confidenceColours = {
    ...(!isParentActive && { confidenceBarColour: DARK_7 }),
    ...(!isParentActive && { confidenceBarScoreColour: DARK_6 }),
  };
  const indicatorProps = {
    isFullSize: false,
    isScaledDown: false,
    scaledError: 0,
    scaledScore: predictionProbability,
    ...confidenceColours,
  };
  return (
    <ChildContainer>
      <Child>
        <Indicator>
          <FindingIndicator fill={GREY_3} findingIndicatorType={BULLET_POINT} isActive={false} />
        </Indicator>
        <Name>{name}</Name>
        <ConfidenceContainer>
          <ConfidenceIndicator {...indicatorProps} />
        </ConfidenceContainer>
      </Child>
    </ChildContainer>
  );
};

export default ChildFinding;
