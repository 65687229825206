import { SegmentLateralityType } from '@annaliseai/api-specifications';
import Uuid from 'types/Uuid';
import Image from './Image';

export type SegmentLocalisation = {
  uuid: Uuid<'segment-localisation'>;
  type: 'SEGMENT';
  imageUuid: Image['uuid'];
};

export type Lateralities = SegmentLateralityType;

export type LateralityLocalisation = {
  uuid: Uuid<'laterality-localisation'>;
  type: 'LATERALITY';
  laterality: Lateralities;
};

export enum FindingLocalisationType {
  NO_LOCALISATION = 'NO_LOCALISATION',
  LATERALITY = 'LATERALITY',
  SINGLE_IMAGE = 'SINGLE_IMAGE',
  MULTIPLE_IMAGE = 'MULTIPLE_IMAGE',
}

type Localisation = SegmentLocalisation | LateralityLocalisation;

export default Localisation;
