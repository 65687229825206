import styled, { css } from 'styled-components';
import { TableCellCss } from './sharedCss';

type TableHeaderProps = {
  width?: string | number;
  align: 'left' | 'center' | 'right';
};

const TableHeader = styled.th<TableHeaderProps>`
  ${TableCellCss}
  ${({ width }) =>
    width &&
    css`
      width: ${width};
      min-width: ${width};
      max-width: ${width};
    `}
    ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
`;

export default TableHeader;
