import ImageMetadata from './ImageMetadata';
import MessagePayload from './MessagePayload';

export enum MessageTypes {
  ENCODE = 'encode',
}

const { ENCODE } = MessageTypes;

export type EncodeRequest = MessagePayload<typeof ENCODE, { pixelDataBuffer: ArrayBuffer } & ImageMetadata>;
export type EncodeResponse = MessagePayload<typeof ENCODE, ArrayBuffer>;
