import Cookies from 'js-cookie';
import CookieKeys from 'enums/CookieKeys';

const { USER_INFORMATION_SUBMITTED } = CookieKeys;

export const storeUserInformationSubmitted = (submitted: string): void => {
  Cookies.set(USER_INFORMATION_SUBMITTED, submitted, { expires: 30 });
};

export const retrieveUserInformationSubmitted = (): boolean => !!Cookies.get(USER_INFORMATION_SUBMITTED);
