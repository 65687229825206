import {
  CtbClassificationExternal,
  CtbLaterality,
  SegmentLateralityType,
  ViewType,
} from '@annaliseai/api-specifications';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';
import uuid from 'helpers/uuid';
import { LateralityLocalisation } from 'types/study/Localisation';
import CtbFindingViewer, { CtbLateralityFindingView } from 'types/viewer/ctbViewer/CtbFindingViewer';
import getCtbRelevantLocalisations from './getCtbRelevantLocalisations';

const { NONE } = SegmentLateralityType;
const { AXIAL, CORONAL, SAGITTAL } = ViewType;
const { UNEXPECTED_BACKEND_ERROR } = CustomErrors;

type GetCtbLateralities = (_: { lateralities: CtbLaterality[]; relevantFindings: CtbClassificationExternal[] }) => {
  lateralityLocalisationsMap: Record<LateralityLocalisation['uuid'], LateralityLocalisation>;
  lateralityFindingViewsMap: Record<CtbLateralityFindingView['uuid'], CtbLateralityFindingView>;
  viewsMapByFindingLabel: Record<string, CtbFindingViewer['viewUuidsMap'] | undefined>;
};

const getCtbLateralities: GetCtbLateralities = ({ lateralities, relevantFindings }) => {
  const lateralityLocalisationsMap: Record<LateralityLocalisation['uuid'], LateralityLocalisation> = {};
  const lateralityFindingViewsMap: Record<CtbLateralityFindingView['uuid'], CtbLateralityFindingView> = {};
  const viewsMapByFindingLabel: Record<string, CtbFindingViewer['viewUuidsMap'] | undefined> = {};

  const relevantLateralities = getCtbRelevantLocalisations(lateralities, relevantFindings);

  relevantLateralities.forEach(({ laterality, relatedLabels }) => {
    if (laterality === NONE) return;

    const lateralityLocalisation: LateralityLocalisation = {
      uuid: uuid('laterality-localisation'),
      type: 'LATERALITY',
      laterality,
    };
    lateralityLocalisationsMap[lateralityLocalisation.uuid] = lateralityLocalisation;

    const lateralityFindingView: CtbLateralityFindingView = {
      uuid: uuid('ctb-laterality-finding-view'),
      type: 'LATERALITY',
      lateralityUuid: lateralityLocalisation.uuid,
    };
    lateralityFindingViewsMap[lateralityFindingView.uuid] = lateralityFindingView;

    const viewsMap: CtbFindingViewer['viewUuidsMap'] = {
      [AXIAL]: lateralityFindingView.uuid,
      [CORONAL]: lateralityFindingView.uuid,
      [SAGITTAL]: lateralityFindingView.uuid,
    };

    const finding = relevantFindings.find(({ label }) => relatedLabels.some(relatedLabel => relatedLabel === label));
    if (!finding) {
      throw new CustomError(UNEXPECTED_BACKEND_ERROR);
    }

    viewsMapByFindingLabel[finding.label] = viewsMap;
  });

  return {
    lateralityLocalisationsMap,
    lateralityFindingViewsMap,
    viewsMapByFindingLabel,
  };
};

export default getCtbLateralities;
