import { PostImageStatusRes, Status, ViewPositionType, VisionStudyImageFinding } from '@annaliseai/api-specifications';
import log from 'loglevel';
import ImageFormats from 'enums/ImageFormats';
import ViewPositions from 'enums/ViewPositions';
import UrlMap from 'types/UrlMap';

const { COMPLETED } = Status;
const { PA, LAT, UNKNOWN, AP } = ViewPositions;
const { PA: TypePA, LAT: TypeLAT, AP: TypeAP } = ViewPositionType;

export const getImageIds = (images: VisionStudyImageFinding[]): string[] =>
  images.map(apiImage => apiImage.imageInstanceUid);

export const getUrlMap = ({ images }: PostImageStatusRes): UrlMap => {
  const urlMap: UrlMap = {};

  images.forEach(apiImageStatus => {
    const { imageInstanceUid: id } = apiImageStatus;
    if (apiImageStatus.source.status === COMPLETED && apiImageStatus.targets.jpeg?.status === COMPLETED) {
      const { url: sourceUrl } = apiImageStatus.source;
      const { url: targetUrl } = apiImageStatus.targets.jpeg;
      urlMap[id] = {
        sourceUrl,
        targetUrl,
      };
    } else {
      log.error('Image not completed', apiImageStatus);
      // TODO: what to do when images are not COMPLETED?
    }
  });

  return urlMap;
};

export const getViewPosition = (apiViewPosition: ViewPositionType): ViewPositions => {
  switch (apiViewPosition) {
    case TypePA:
      return PA;
    case TypeAP:
      return AP;
    case TypeLAT:
      return LAT;
    default:
      return UNKNOWN;
  }
};

export const getImageFormat = (url: string): ImageFormats => {
  const { JP2, PNG, JPEG, UNKNOWN } = ImageFormats;
  const { pathname } = new URL(url);
  const extension = pathname.split('.').pop();
  switch (extension) {
    case JPEG:
      return JPEG;
    case PNG:
      return PNG;
    case JP2:
      return JP2;
    default:
      return UNKNOWN;
  }
};
