import Cookies from 'js-cookie';
import CookieKeys from 'enums/CookieKeys';
import Credential from 'types/Credential';

const { CLIENT_ID, CLIENT_SECRET } = CookieKeys;

export const storeCredential = ({ clientId, clientSecret }: Credential): void => {
  Cookies.set(CLIENT_ID, clientId, { expires: 7 });
  Cookies.set(CLIENT_SECRET, clientSecret, { expires: 7 });
};

export const retrieveCredential = (): Credential => {
  const clientId = Cookies.get(CLIENT_ID) || '';
  const clientSecret = Cookies.get(CLIENT_SECRET) || '';
  return { clientId, clientSecret };
};

export const removeCredential = (): void => {
  [CLIENT_ID, CLIENT_SECRET].forEach(key => Cookies.remove(key));
};
