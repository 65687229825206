import styled from 'styled-components';

const PrimaryButton = styled.button`
  padding: 0 1.5rem;
  background-color: var(--primary-button--background);
  color: var(--primary-button--color);
  border-radius: 0.5em;
  border: 0;
  height: 100%;
  outline: none;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export default PrimaryButton;
