import errorsMapping from 'constants/errorsMapping';
import CustomErrors from 'enums/CustomErrors';
import isDefinedErrorCode from 'typeguards/isDefinedErrorCode';
import DisplayError from 'types/DisplayError';

const { UNEXPECTED_FRONTEND_ERROR } = CustomErrors;

const getDisplayError = (message: CustomErrors | string): DisplayError =>
  isDefinedErrorCode(message) ? errorsMapping[message] : errorsMapping[UNEXPECTED_FRONTEND_ERROR];

export default getDisplayError;
