import { colors as annaColors } from '@annaliseai/anna-design-tokens';
import styled from 'styled-components';
import colors from 'constants/colors';
import { APP } from 'constants/sizes';

const {
  SIDEBAR_WIDTH,
  DISCLAIMER_MARGIN_BOTTOM,
  LOCALISATION_TOGGLE_MARGIN_BOTTOM,
  LOCALISATION_TOGGLE_PADDING_TOP,
  LOCALISATION_TOGGLE_HEIGHT,
  STUDY_CONTAINER_MARGIN_BOTTOM,
} = APP;

const { WHITE } = annaColors;
const { NEUTRAL_BACKGROUND, SIDEBAR } = colors;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr) max-content;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-template-areas:
    'nav nav sidebar'
    'tools main sidebar';
  background-color: ${NEUTRAL_BACKGROUND};
  color: ${WHITE};
`;

export const Main = styled.div`
  height: 100%;
  display: grid;
  grid-area: main;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  justify-content: center;
`;

export const ViewerComponentContainer = styled.div`
  padding-bottom: ${STUDY_CONTAINER_MARGIN_BOTTOM}rem;
`;

export const DisclaimerContainer = styled.div`
  margin-bottom: ${DISCLAIMER_MARGIN_BOTTOM}rem;
  display: flex;
  justify-content: center;
`;

export const SidebarContainer = styled.div`
  grid-area: sidebar;
  width: ${SIDEBAR_WIDTH}px;
  background-color: ${SIDEBAR};
`;

export const ImageControlsContainer = styled.div`
  grid-area: tools;
  display: flex;
`;

export const LocalisationToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${LOCALISATION_TOGGLE_MARGIN_BOTTOM}rem;
  padding-top: ${LOCALISATION_TOGGLE_PADDING_TOP}rem;
  height: ${LOCALISATION_TOGGLE_HEIGHT + LOCALISATION_TOGGLE_PADDING_TOP + LOCALISATION_TOGGLE_MARGIN_BOTTOM}rem;
`;
