import { BytesRange } from '@annaliseai/api-specifications';
import { imageLoaderPrefixSeperator } from 'constants/imageLoaderPrefixesMapping';
import CornerstoneLoaderPrefixes from 'enums/CornerstoneLoaderPrefixes';
import CornerstoneCtbImageSliceId from 'types/cornerstone/CornerstoneCtbImageSliceId';

const { PNG16 } = CornerstoneLoaderPrefixes;

const createCtbImageSliceId = (url: string, { start, end }: BytesRange): CornerstoneCtbImageSliceId =>
  `${PNG16}${imageLoaderPrefixSeperator}${start}|${end}|${url}`;

export default createCtbImageSliceId;
