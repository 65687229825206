import { colors as annaColors } from '@annaliseai/anna-design-tokens';
import { StudyType } from '@annaliseai/api-specifications';
import React, { FC } from 'react';
import styled from 'styled-components';
import ModalityThumbnailCard from 'components/ModalityThumbnailCard';
import modalityConfig from 'constants/viewerConfig';
import useRedirectIfLoggedIn from 'hooks/useRedirectIfLoggedIn';

const { WHITE } = annaColors;

const DemoScreenContainer = styled.div`
  color: ${WHITE};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  > :first-child {
    margin-right: 3.5rem;
  }
`;

const DemoScreen: FC = () => {
  useRedirectIfLoggedIn();
  return (
    <DemoScreenContainer>
      <Content>
        <CardContainer>
          {Object.keys(modalityConfig).map(key => {
            const { header, demoImage, demoRoute } = modalityConfig[key as StudyType];
            return <ModalityThumbnailCard key={key} label={header} image={demoImage} url={demoRoute} />;
          })}
        </CardContainer>
      </Content>
    </DemoScreenContainer>
  );
};

export default DemoScreen;
