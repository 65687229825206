import queryString from 'query-string';
import configuration from 'configuration';
import Routes from 'enums/Routes';

const { VIEWER } = Routes;

/**
 * Determines whether to show links that will be conditional based on location and URL parameters.
 * If the Viewer Screen is reached by a direct link (such as through a CMS), hide the links.
 * @returns {boolean} Indicates whether to show links.
 */

const showLinks = (): boolean => {
  const { isViewer } = configuration;
  const location = window.location.pathname;

  if (location !== VIEWER || !isViewer) return true;

  const { showLinks } = queryString.parse(window.location.search);
  return showLinks === 'true';
};

export default showLinks;
