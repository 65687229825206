import styled, { DataAttributes } from 'styled-components';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { DIVIDER } = ReactTestingLibraryDataProperties;
const { SECONDARY } = colors;

const Divider = styled.div.attrs<DataAttributes>({ 'data-testid': DIVIDER })`
  width: 0.06rem;
  height: 2rem;
  background-color: ${SECONDARY};
  align-self: center;
`;

export default Divider;
