import { resultsListConfig, resultsListConfigHK } from 'components/WorklistTable/WorklistTable.constants';
import { ColumnIds } from 'components/WorklistTable/WorklistTypes';
import configuration from 'configuration';
import CookieKeys from 'enums/CookieKeys';
import { getUserSettings } from 'helpers/cookies/userSettingsHelper';
import maybeShowColumn from './maybeShowColumn';

const { COLUMN_SELECTION } = CookieKeys;

/**
 * Determines the visibility status of columns based on user settings and configuration.
 * For a column to be visible it must have a true value for isPresent in config & true value in user settings cookie.
 * If there is no user settings value, it requires isVisible value from config instead.
 * Note: temporary HK implementation, pending organisation configuration/admin portal
 * @returns {Record<string, boolean>} An object containing column IDs as keys and their visibility status as boolean values.
 */

export const getColumnVisibility = (): Record<string, boolean> => {
  const columnSelection = getUserSettings(COLUMN_SELECTION) || {};
  const config = configuration.isBespokeSpecial ? resultsListConfigHK : resultsListConfig;

  return Object.entries(config).reduce((acc, [id, value]) => {
    // if config column isPresent is false
    if (!maybeShowColumn(id as ColumnIds)) return acc;

    // if user selection is stored in cookie, override initial config
    const columnValue = columnSelection.hasOwnProperty(id) ? (columnSelection[id] as boolean) : value.isVisible;

    return { ...acc, [id]: columnValue };
  }, {});
};

export default getColumnVisibility;
