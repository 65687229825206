import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PatientInformation } from 'types/DisplayStudyInformation';
import Study, { StudyIds } from 'types/study/Study';

export type StudyState = {
  study: null | Study;
  patientInformation: null | PatientInformation;
};

export const initialState: StudyState = {
  study: null,
  patientInformation: null,
};

export const studySlice = createSlice({
  name: 'study',
  initialState,
  reducers: {
    runInitStudyEffect: (_state, _action: PayloadAction<StudyIds>) => undefined,
    runResetStudyEffect: _ => ({ ...initialState }),
    setStudy: (state, { payload: study }: PayloadAction<Study>) => ({
      ...state,
      study,
    }),
    setPatientInformation: (state, { payload: patientInformation }: PayloadAction<PatientInformation>) => ({
      ...state,
      patientInformation,
    }),
  },
});

export const studyActions = studySlice.actions;
export type RunInitStudyEffectType = ReturnType<typeof studyActions.runInitStudyEffect>;
export type ResetStudyType = ReturnType<typeof studyActions.runResetStudyEffect>;
export type SetStudyType = ReturnType<typeof studyActions.setStudy>;
export type StudyActionTypes = ResetStudyType | SetStudyType;
