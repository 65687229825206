import { replace } from 'connected-react-router';
import { FC, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Routes from 'enums/Routes';
import { retrieveUserInformationSubmitted } from 'helpers/cookies/userInformationHelper';
import { isLoggedIn } from 'helpers/limitationHelper';

const { USER_INFORMATION } = Routes;

export const REDIRECT_URL_PARAM = 'redirectUrl';

const UserInformationCheck: FC<{ children: ReactElement }> = ({ children }) => {
  const dispatch = useDispatch();

  const userInformationSubmitted = retrieveUserInformationSubmitted();
  const userLoggedIn = isLoggedIn();

  useEffect(() => {
    if (userLoggedIn) {
      return;
    }

    if (!userInformationSubmitted) {
      const { pathname, search } = window.location;
      const params = new URLSearchParams({
        [REDIRECT_URL_PARAM]: `${pathname}${search}`,
      });
      dispatch(replace(`${USER_INFORMATION}?${params}`));
    }
  }, [dispatch, userInformationSubmitted, userLoggedIn]);

  if (userLoggedIn) {
    return children;
  }

  return userInformationSubmitted ? children : null;
};

export default UserInformationCheck;
