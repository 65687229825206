import { KeyboardEvent, MouseEvent } from 'react';
import Buttons from 'enums/Buttons';
import Keys from 'enums/Keys';
import { UserInput } from './Form.types';

const { ARROW_DOWN_KEY, ENTER_KEY, SPACE_KEY, TAB_KEY } = Keys;
const { BUTTON_0 } = Buttons;

export const isKeyboardEvent = (e: UserInput): e is KeyboardEvent => (e as KeyboardEvent).key !== undefined;
export const isMouseEvent = (e: UserInput): e is MouseEvent => (e as MouseEvent).button !== undefined;
export const isSubmitAction = (e: UserInput): boolean =>
  (isKeyboardEvent(e) && e.key === ENTER_KEY) || (isMouseEvent(e) && e.button === BUTTON_0);
export const isCalendarOpenAction = (e: UserInput): boolean =>
  isKeyboardEvent(e) && (e.key === ARROW_DOWN_KEY || e.key === SPACE_KEY);
export const isTabAction = (e: UserInput): boolean => isKeyboardEvent(e) && e.key === TAB_KEY;
export const isEnterAction = (e: UserInput): boolean => isKeyboardEvent(e) && e.key === ENTER_KEY;
