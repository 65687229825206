import { colors } from '@annaliseai/anna-design-tokens';
import styled from 'styled-components';

const TransparentButton = styled.button`
  padding: 0 2rem;
  background-color: #d6cfef;
  border-color: ${colors.WHITE_40};
  color: ${colors.PRIMARY_SOLID};
  border: 1px solid ${colors.PRIMARY_SOLID};
  border-radius: 2.25rem;
  outline: none;
  text-align: center;
  box-shadow: none;
  height: 100%;

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
    border-color: ${colors.WHITE_40};
    color: ${colors.WHITE_40};
  }

  &:focus {
    outline: none;
    border: 1px solid ${colors.PRIMARY_SOLID};
  }

  &:active {
    background-color: #54368d;
    color: white;
  }

  &:hover:not(:active):not([disabled]) {
    background-color: ${colors.WHITE};
    color: ${colors.PRIMARY_SOLID};
    outline: none;
  }
`;

export default TransparentButton;
