import { EventCategories, EventActions } from 'types/Matomo';
import tracker from './matomoTracker';

/**
 * https://annalise-ai.atlassian.net/wiki/spaces/JAZZ/pages/713883838/Private+Demo+Error+Strings
 * Tracking all errors that exposed to the user
 */

export const TRACKABLE_ERROR_IDS = [
  'J001',
  'J002',
  'J003',
  'J004',
  'J005',
  'J006',
  'J007',
  'J009',
  'J010',
  'J011',
  'J012',
  'J013',
  'J014',
  'J015',
  'J016',
  'J017',
  'J018',
  'J019',
  'J020',
  'J021',
  'J022',
  'J023',
  'J024',
  'J025',
  'J026',
  'J027',
  'J028',
  'J098',
  'J099',
];

const trackError = (errorId: string): void => {
  if (TRACKABLE_ERROR_IDS.includes(errorId)) {
    tracker.trackEvent({
      category: EventCategories.ERROR,
      action: EventActions.GENERIC,
      name: errorId,
    });
  }
};

export default trackError;
