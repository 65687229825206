import { CxrImage } from '@annaliseai/api-specifications';
import { RootState } from 'slices/RootState';
import selectActiveImage from './selectActiveImage';

const selectActiveImageMetadata = (state: RootState): CxrImage | null => {
  const activeImage = selectActiveImage(state);
  return activeImage && state.cxrViewer.imagesMetadataMap[activeImage.uuid];
};

export default selectActiveImageMetadata;
