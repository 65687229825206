import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import history from './browserHistory';
import rootEpic from './epics';
import { ActionTypes } from './slices/ActionTypes';
import rootReducer from './slices/rootReducer';
import { RootState } from './slices/RootState';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const configureStore = (initialState?: RootState): Store<RootState> => {
  const epicMiddleware = createEpicMiddleware<ActionTypes>();

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(epicMiddleware, routerMiddleware(history))),
  );

  epicMiddleware.run(rootEpic);

  return store;
};

export default configureStore();
