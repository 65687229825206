import { push } from 'connected-react-router';
import { StatusCodes } from 'http-status-codes';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, from, merge, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { login } from 'api/queries';
import CustomErrors from 'enums/CustomErrors';
import Routes from 'enums/Routes';
import { removeCredential } from 'helpers/cookies/credentialHelper';
import checkStatusCode from 'helpers/error/checkStatusCode';
import CustomError from 'helpers/error/CustomError';
import { ActionTypes } from 'slices/ActionTypes';
import { authActions, LoginFailedType, LoginFulfilledType, LoginType, LogoutType } from 'slices/authSlice';
import { SetErrorType } from 'slices/errorSlice';
import { ResetInstitutionNamesType, ResetRequestingServicesType, settingsActions } from 'slices/settingsSlice';
import { ResetStudiesType, studyListActions } from 'slices/studyListSlice';
import { ResetStudyType, studyActions } from 'slices/studySlice';
import HistoryAction from 'types/HistoryAction';

const { FAILED_TO_AUTHENTICATE } = CustomErrors;
const { LOGIN } = Routes;
const { UNAUTHORIZED } = StatusCodes;
const {
  login: runLoginEffect,
  loginFulfilled: runLoginFulfilledEffect,
  logout: runLogoutEffect,
  loginFailed,
} = authActions;

const loginEpic: Epic<ActionTypes, HistoryAction | LoginFulfilledType | LoginFailedType | SetErrorType> = action$ =>
  action$.pipe(
    ofType<ActionTypes, LoginType>(runLoginEffect.type),
    switchMap(({ payload }) => {
      const { clientId, clientSecret } = payload;
      if (!(clientId && clientSecret))
        return merge(of(loginFailed(), push(LOGIN)), throwError(new CustomError(FAILED_TO_AUTHENTICATE)));
      return from(login({ clientId, clientSecret })).pipe(
        switchMap(_ => of(authActions.loginFulfilled(payload))),
        catchError(error => {
          return merge(
            of(loginFailed(), push(LOGIN)),
            throwError(checkStatusCode(error, UNAUTHORIZED) ? new CustomError(FAILED_TO_AUTHENTICATE) : error),
          );
        }),
      );
    }),
  );

const loginFulfilledEpic: Epic<ActionTypes, HistoryAction> = action$ =>
  action$.pipe(
    ofType<ActionTypes, LoginFulfilledType>(runLoginFulfilledEffect.type),
    switchMap(({ payload: { redirect } }) => {
      if (redirect) {
        return of(push(redirect));
      } else {
        return EMPTY;
      }
    }),
  );

const logoutEpic: Epic<
  ActionTypes,
  HistoryAction | ResetStudiesType | ResetStudyType | ResetRequestingServicesType | ResetInstitutionNamesType
> = action$ =>
  action$.pipe(
    ofType<ActionTypes, LogoutType>(runLogoutEffect.type),
    switchMap(({ payload: { redirect } }) => {
      removeCredential();

      const actions: (
        | HistoryAction
        | ResetStudiesType
        | ResetStudyType
        | ResetRequestingServicesType
        | ResetInstitutionNamesType
      )[] = [
        studyListActions.resetStudies(),
        studyActions.runResetStudyEffect(),
        settingsActions.resetRequestingServices(),
        settingsActions.resetInstitutionNames(),
      ];

      if (redirect) {
        actions.push(push(redirect));
      }

      return of(...actions);
    }),
  );

const authEpic = combineEpics(loginEpic, loginFulfilledEpic, logoutEpic);

export default authEpic;
