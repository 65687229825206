enum LogoSizes {
  S = 0.5,
  M = 1,
  MM = 1.25,
  ML = 1.75,
  L = 2,
  XL = 2.5,
}

export default LogoSizes;
