import { StudyType } from '@annaliseai/api-specifications';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import ConfidenceBar from 'components/ConfidenceBar/ConfidenceBar';
import CxrImageControls from 'components/CxrImageControls';
import Disclaimer from 'components/Disclaimer/Disclaimer';
import Header from 'components/Header';
import OnboardingSidebar from 'components/OnboardingSidebar';
import Sidebar from 'components/sidebar/Sidebar';
import useTopSidebarProps from 'components/sidebar/useTopSidebarProps';
import LocalisationToggle from 'components/viewer/LocalisationToggle/LocalisationToggle';
import configuration from 'configuration';
import viewerConfig from 'constants/viewerConfig';
import { retrieveOnboardingDone, storeOnboardingDone } from 'helpers/cookies/onBoardingDoneHelper';
import useDisplayInformation from 'hooks/useDisplayInformation';
import useSidebarFindings from 'hooks/useSidebarFindings';
import useStrings from 'hooks/useStrings';
import useViewerScreenRouter from 'hooks/useViewerScreenRouter';
import selectActiveFindingLaterality from 'selectors/viewer/selectActiveFindingLaterality';
import { selectStudyType } from 'selectors/viewer/selectStudy';
import {
  Container,
  DisclaimerContainer,
  ImageControlsContainer,
  LocalisationToggleContainer,
  Main,
  SidebarContainer,
  ViewerComponentContainer,
} from './ViewerScreen.styles';

const { CXR } = StudyType;

const ViewerScreen: FC = () => {
  const { isDemo, shouldShowWPZ } = configuration;
  const [shouldShowOnboarding, setShouldShowOnboarding] = useState(!retrieveOnboardingDone());
  const studyType = useSelector(selectStudyType);

  useViewerScreenRouter();
  const sidebarFindingsProps = useSidebarFindings();
  const studyInformation = useDisplayInformation();
  const { TEXT_DISCLAIMER, TITLE_DISCLAIMER } = useStrings();
  const { hasLocalisation } = useSelector(selectActiveFindingLaterality);
  const topSidebarProps = useTopSidebarProps();

  const ViewerComponent = studyType && viewerConfig[studyType].viewerComponent;
  const showWPZ = shouldShowWPZ && studyType === CXR;

  return (
    <Container>
      {showWPZ && (
        <ImageControlsContainer>
          <CxrImageControls />
        </ImageControlsContainer>
      )}
      <Header />
      <Main>
        <ViewerComponentContainer>{ViewerComponent ? <ViewerComponent /> : null}</ViewerComponentContainer>
        <LocalisationToggleContainer>{hasLocalisation ? <LocalisationToggle /> : null}</LocalisationToggleContainer>
        <ConfidenceBar />
        <DisclaimerContainer>
          <Disclaimer text={TEXT_DISCLAIMER} tooltip={isDemo ? TITLE_DISCLAIMER : undefined} />
        </DisclaimerContainer>
      </Main>

      <SidebarContainer>
        {shouldShowOnboarding ? (
          <OnboardingSidebar
            onClick={() => {
              storeOnboardingDone();
              setShouldShowOnboarding(false);
            }}
          />
        ) : (
          <Sidebar
            headerText={studyType ? viewerConfig[studyType].header : ''}
            topSidebarProps={topSidebarProps}
            studyInformation={studyInformation}
            {...sidebarFindingsProps}
          />
        )}
      </SidebarContainer>
    </Container>
  );
};

export default ViewerScreen;
