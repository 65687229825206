import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FindingsMenu, { FindingItem, FindingsGroup } from 'types/viewer/FindingsMenu';

export type FindingsMenuState = {
  findingsMenu: FindingsMenu | null;
  findingsGroupsMap: Record<FindingsGroup['uuid'], FindingsGroup>;
  findingItemsMap: Record<FindingItem['uuid'], FindingItem>;
};

export const initialState: FindingsMenuState = {
  findingsMenu: null,
  findingsGroupsMap: {},
  findingItemsMap: {},
};

export const findingsMenuSlice = createSlice({
  name: 'findingsMenu',
  initialState,
  reducers: {
    reset: _ => ({
      ...initialState,
    }),
    setFindingsMenu: (state, { payload }: PayloadAction<FindingsMenuState>) => ({
      ...state,
      ...payload,
    }),
  },
});

export const findingsMenuActions = findingsMenuSlice.actions;
