import { push } from 'connected-react-router';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonSize } from 'components/Buttons/Button/Button';
import ButtonText from 'components/Buttons/ButtonText/ButtonText';
import { UserInput } from 'components/Form/Form.types';
import { isEnterAction } from 'components/Form/Form.utils';
import colors from 'constants/colors';
import { IconContainer, NavLink } from './MenuLink.styles';
import { MenuItemProps } from './MenuLink.types';

const { SECONDARY_INVERT } = colors;
const { MEDIUM } = ButtonSize;

export const iconProps = { fillOpacity: 1, color: SECONDARY_INVERT, size: 1.25 };

const MenuLink: FC<MenuItemProps> = ({ action, href, Icon, IconHover, route, payload, text, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  const onMenuItemEnter = () => {
    setIsHovered(true);
  };
  const onMenuItemMouseLeave = () => {
    setIsHovered(false);
  };
  const onMenuItemMouseDown = () => {
    if (onClick) {
      onClick();
      return;
    }
    if (action && payload) {
      dispatch(action(payload));
    } else if (action) {
      action();
    }
    route && dispatch(push(route));
  };

  const onMenuKeyDown = (e: UserInput) => {
    if (isEnterAction(e)) {
      onMenuItemMouseDown();
    }
  };

  return (
    <NavLink
      href={href}
      target={href && '_blank'}
      onClick={onMenuItemMouseDown}
      onKeyDown={onMenuKeyDown}
      onMouseEnter={onMenuItemEnter}
      onMouseLeave={onMenuItemMouseLeave}
      tabIndex={0}
    >
      <IconContainer>
        {isHovered && IconHover ? <IconHover {...iconProps} /> : Icon && <Icon {...iconProps} />}
      </IconContainer>
      <ButtonText size={MEDIUM}>{text}</ButtonText>
    </NavLink>
  );
};

export default MenuLink;
