import { LatestPredictionRecord } from '@annaliseai/api-specifications';
import DateFormats from 'enums/DateFormats';
import formatPatientInformation, { PatientInfoWithTitles } from 'helpers/formatPatientInformation';
import DisplayStudyInformation from 'types/DisplayStudyInformation';
import { DisplayFormat } from 'types/settings/DisplayData';
import convertUTCIsoDateStringToDateTime from './dates/convertUtcIsoDateStringToDateTime';
import convertUtcToTimezone from './dates/convertUtcToTimezone';
import formatDateTime from './dates/formatDateTime';

const { FORMAT_FULL_DAY_DATE_12HR_TIME, FORMAT_24H_TIME, FORMAT_D_MM_YYYY_SLASHES, FORMAT_FULL_DAY_DATE_24HR_TIME } =
  DateFormats;

const formatStudy = (
  study: LatestPredictionRecord,
  displayFormat?: DisplayFormat,
  placeholder = '',
): DisplayStudyInformation & PatientInfoWithTitles => {
  const {
    accessionNumber,
    createdAt,
    institutionName,
    type,
    studyInstanceUid,
    studyUpdatedAt,
    patientId,
    patientName,
    patientBirthDate,
    patientAge,
    patientSex,
    studyDateTime: pseudoUtcStudyDateObject, // DICOM tag time
    priority,
    rank,
    status,
    error,
    requestingService,
  } = study;

  // Convert & format time, date, and date/time strings for updated at date/time
  const updatedDate = studyUpdatedAt
    ? formatDateTime(
        convertUtcToTimezone(studyUpdatedAt, placeholder),
        FORMAT_D_MM_YYYY_SLASHES(displayFormat),
        placeholder,
      )
    : placeholder;

  const updatedTime = studyUpdatedAt
    ? formatDateTime(convertUtcToTimezone(studyUpdatedAt, placeholder), FORMAT_24H_TIME(), placeholder)
    : placeholder;

  const updatedDateTime = studyUpdatedAt
    ? formatDateTime(convertUtcToTimezone(studyUpdatedAt, placeholder), FORMAT_FULL_DAY_DATE_12HR_TIME(), placeholder)
    : placeholder;

  // Convert & format date/time strings for created at date/time
  const createdDateTime = createdAt
    ? formatDateTime(convertUtcToTimezone(createdAt, placeholder), FORMAT_FULL_DAY_DATE_12HR_TIME())
    : placeholder;

  // (Jon): utcStudyDateTime is actually not a Date (this is a rare occurrence where typescript is tricked).
  // What is passed is actually a string. Don't believe me? Try catch `utcStudyDateTime?.toIsoString()` and
  // console.log the error (TypeError: utcStudyDateTime.toISOString is not a function).
  // The following line is creating an actual Date object to avoid a runtime error
  const utcStudyDateTime = pseudoUtcStudyDateObject && new Date(pseudoUtcStudyDateObject);

  // (Jon): We are not converting this UTC date to the timezone here.
  // The reason for this is that the BE stores it in UTC for sorting purpose
  // but it stores the date the PACS sends without transforming it
  // as the IA has no way of knowing what timezone the PACS is in
  // Hence we are using the date directly as it comes
  // otherwise we could have a mismatch of up to 12 hours.
  const transformedStudyDateTime = utcStudyDateTime
    ? convertUTCIsoDateStringToDateTime(utcStudyDateTime.toISOString())
    : placeholder;

  const studyDateTime = transformedStudyDateTime
    ? formatDateTime(transformedStudyDateTime, FORMAT_FULL_DAY_DATE_24HR_TIME(), placeholder)
    : placeholder;

  // Derive the studyDate & studyTime from the formatted studyDateTime
  const studyDate = transformedStudyDateTime
    ? formatDateTime(transformedStudyDateTime, FORMAT_D_MM_YYYY_SLASHES(displayFormat), placeholder)
    : placeholder;

  const studyTime = transformedStudyDateTime
    ? formatDateTime(transformedStudyDateTime, FORMAT_24H_TIME(), placeholder)
    : placeholder;

  // Priority may come to us in KEBAB-CASE, which is not a valid enum key
  const priorityFormatted = priority && priority.replace(/-/, '_');

  const patient = formatPatientInformation(
    {
      patientId,
      patientName,
      patientBirthDate,
      patientAge,
      patientSex,
    },
    displayFormat,
  );

  return {
    accessionNumber,
    institutionName,
    studyInstanceUid,
    studyType: type,
    createdDateTime,
    updatedDate,
    updatedTime,
    updatedDateTime,
    studyDate,
    studyTime,
    studyDateTime,
    error: error || undefined,
    priority: priorityFormatted,
    rank,
    status,
    requestingService,
    ...patient,
  };
};

export default formatStudy;
