import { push } from 'connected-react-router';
import React, { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Routes from 'enums/Routes';
import { selectStudies } from 'selectors/studyList/selectStudyList';
import DataTable from './DataTable';
import PrimaryButton from './PrimaryButton';

type ReactTableRowProps = {
  original: Record<string, unknown>;
};

type ReactTableCellProps = {
  row: ReactTableRowProps;
};

const { VIEWER } = Routes;

const ViewButtonCell: FC<ReactTableCellProps> = ({ row }) => {
  const dispatch = useDispatch();
  const { accessionNumber, studyInstanceUid } = row.original;
  return (
    <PrimaryButton
      name="view"
      onClick={() => {
        dispatch(
          push({
            pathname: VIEWER,
            search: `?accessionNumber=${accessionNumber}&studyInstanceUid=${studyInstanceUid}&showLinks=true`,
          }),
        );
      }}
    >
      {'>'} View
    </PrimaryButton>
  );
};

const columns = [
  { Header: 'Accession number', accessor: 'accessionNumber', id: 'accessionNumber', width: '38%' },
  { Header: 'Modality', accessor: 'studyType', id: 'studyType', width: '11%' },
  { Header: 'Upload date', accessor: 'createdDateTime', id: 'createdDateTime', width: '38%' },
  {
    Header: '',
    accessor: '',
    id: 'viewButton',
    Cell: ViewButtonCell,
    width: '13%',
  },
];

const StudiesDataTable: FC = () => {
  const studies = useSelector(selectStudies, shallowEqual);
  return <DataTable columns={columns} data={studies || []} />;
};

export default StudiesDataTable;
