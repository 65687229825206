import { CtbPredictionSegmentSlicesCompleted, Status } from '@annaliseai/api-specifications';
import { interval, throwError } from 'rxjs';
import { exhaustMap, first, map, timeoutWith } from 'rxjs/operators';
import { getCtbSegment, getCtbSegmentsStatuses } from 'api/queries';
import { POLLING } from 'constants/durations';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';

const { UNEXPECTED_BACKEND_ERROR } = CustomErrors;
const { COMPLETED, COMPLETED_ERROR } = Status;
const { PERIOD, TIMEOUT } = POLLING;

const loadCtbSegments = async (
  predictionId: string,
  relevantSegmentIds: string[],
): Promise<CtbPredictionSegmentSlicesCompleted[]> => {
  await interval(PERIOD)
    .pipe(
      exhaustMap(async () => await getCtbSegmentsStatuses({ predictionId })),
      map(statuses => {
        if (statuses.some(segmentStatus => segmentStatus === COMPLETED_ERROR)) {
          throw new CustomError(UNEXPECTED_BACKEND_ERROR);
        }
        return statuses;
      }),
      first(statuses => statuses.every(status => status === COMPLETED)),
      timeoutWith(TIMEOUT, throwError(new CustomError(UNEXPECTED_BACKEND_ERROR))),
    )
    .toPromise();

  return await Promise.all(
    relevantSegmentIds.map(segmentId =>
      getCtbSegment({
        predictionId,
        segmentId,
      }).then(segment => {
        if (segment.status !== COMPLETED) {
          throw new CustomError(UNEXPECTED_BACKEND_ERROR);
        }
        return segment;
      }),
    ),
  );
};

export default loadCtbSegments;
