import { colors } from '@annaliseai/anna-design-tokens';
import { cloudArrowUp, cloudCheckmark } from '@annaliseai/anna-icons';
import IconWrapper from '@annaliseai/icon-wrapper';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled, { css } from 'styled-components';
import loganColors from 'constants/colors';
import { PORTRAIT } from 'constants/orientation';
import TRANSFER_SYNTAX from 'constants/sessionStorageKeys';
import { BREAK_POINTS } from 'constants/sizes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { retrieveAgreeToTerms, toggleAgreeToTerms } from 'helpers/cookies/termsHelper';
import isEmptyFile from 'helpers/file/isEmptyFile';
import newEmptyFile from 'helpers/file/newEmptyFile';
import {
  generateMatomoSelectionTrackingName,
  getExtension,
  validateFile,
  validateFiles,
  validateFilesCount,
} from 'helpers/fileHelper';
import { cleanupUploadedStudies, getRemainingFreeStudies, isLoggedIn } from 'helpers/limitationHelper';
import { BottomRow, BottomRowItem, Text } from 'layouts/ResponsiveContainers';
import { useMatomo } from 'matomo/react';
import { errorActions } from 'slices/errorSlice';
import { fileActions } from 'slices/fileSlice';
import { EventActions, EventCategories } from 'types/Matomo';
import { ValidationResult } from 'types/ValidationResult';
import CxrSampleInfo from './CxrSampleInfo';
import Dropzone from './Dropzone';
import TermsAndConditions from './TermsAndConditions';
import TransparentButton from './TransparentButton';
import UploadsRemaining from './UploadsRemaining';

const { SAMPLE_SCREEN_LARGE_MIN_WIDTH, IPAD_MINI_BREADTH } = BREAK_POINTS;
const { STUDY, IMAGES } = EventCategories;
const { IMAGE_UPLOAD, SELECTED } = EventActions;
const { HAITI, CURRENT_COLOR, GREY_SEPARATOR } = loganColors;
const { WHITE, WHITE_40, PRIMARY_BRIGHT, DARK_2, BUTTON_SECONDARY_TEXT } = colors;

const InfoUploadWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const UploadGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  padding: 0;
  max-width: 30rem;

  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    max-width: 61rem;
  }
`;

const DropzonesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 2.25rem;
    margin: 0 auto;
  }
  margin: 0;
`;

const DropzoneContainer = styled.div<{ disabled: boolean }>`
  ${({ disabled }) => disabled && 'opacity: 0.35;'}
  margin: 0;
  padding: 0;
  background-color: ${DARK_2};
  border-radius: var(--dropzone--border-radius);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 6rem;
  width: 30rem;
  margin-bottom: 1rem;
  svg {
    font-size: 1.5rem;
  }

  input {
    background: red;
  }
  @media (max-width: ${IPAD_MINI_BREADTH}px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    width: 61rem;
    height: 8rem;
  }
`;

const FilePlaceholderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
`;

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

const Separator = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${GREY_SEPARATOR};
`;

const Agree = styled.div<{ disabled: boolean; agreed: boolean }>`
  ${({ agreed }) =>
    agreed
      ? css`
          color: ${WHITE};
        `
      : css`
          color: ${WHITE_40};
        `}
  white-space: nowrap;
  display: flex;
  align-items: center;
  svg {
    margin: 0 0.5rem 0 0;
    font-size: 0.75rem;
    @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
      font-size: 1.125rem;
    }
  }
  ${({ disabled }) =>
    !disabled
      ? css`
          cursor: pointer;
          &:hover {
            color: ${WHITE};
          }
        `
      : css``}
`;

const UploadsContainer = styled.div`
  margin-top: 0.8rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 2.5rem;
  white-space: nowrap;
  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    height: 3.25rem;
  }
`;

const Button = styled(TransparentButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.6rem;
  border: solid 1px ${PRIMARY_BRIGHT};
  background-color: ${HAITI};
  font-size: 0.9rem;
  color: ${BUTTON_SECONDARY_TEXT};
`;

const IconContainer = styled.div`
  margin-right: 0.5rem;
`;

const TextBlock = styled(Text)`
  display: flex;
`;

const TextUnderlined = styled(Text)`
  text-decoration: underline;
`;

const dropzoneIndices = [0, 1, 2];

const CxrSampleUpload: FC = () => {
  const isPortrait = useMediaQuery({ orientation: PORTRAIT });
  const userAgreeToTerms = retrieveAgreeToTerms();
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();

  const [files, setFiles] = useState<File[]>(dropzoneIndices.map(_ => newEmptyFile()));
  const [agreed, setAgreed] = useState(false);

  useEffect(() => {
    setAgreed(userAgreeToTerms);
  }, [userAgreeToTerms]);

  const onFinish = async (files: File[]) => {
    const validationResultFilesCount = await validateFilesCount(files);
    if (validationResultFilesCount.error) {
      dispatch(errorActions.setError(validationResultFilesCount.error));
      return;
    }

    const validationResultFiles = await validateFiles(files);
    if (validationResultFiles.error) {
      dispatch(errorActions.setError(validationResultFiles.error));
      return;
    }

    if (!isLoggedIn()) {
      trackEvent({
        category: STUDY,
        action: IMAGE_UPLOAD,
        name: getExtension(files[0]), //This will work as same file type is enforced for the 3 files
        value: files.filter(({ name }) => name !== 'name.ext').length,
      });
    }

    dispatch(fileActions.runSetFilesEffect(files));
  };

  const validateOnChangeFile = async (index: number, file: File): Promise<ValidationResult<File>> => {
    const validationResult = await validateFile(index, file);

    const transferSyntax = sessionStorage.getItem(TRANSFER_SYNTAX);

    trackEvent({
      category: IMAGES,
      action: SELECTED,
      name: generateMatomoSelectionTrackingName(file, transferSyntax),
    });

    sessionStorage.removeItem(TRANSFER_SYNTAX);

    if (validationResult.error) {
      dispatch(errorActions.setError(validationResult.error));
    }

    return validationResult;
  };

  const validateOnChangeFiles = (files: File[]): ValidationResult<File[]> => {
    const validationResult = validateFiles(files);

    if (validationResult.error) {
      dispatch(errorActions.setError(validationResult.error));
    }

    return validationResult;
  };

  const onUploadClick = async () => {
    await onFinish(files);
  };

  const onlyEmptyFiles = (files: File[]) => files.filter(file => !isEmptyFile(file)).length === 0;

  const handleAgreeTermsClick = () => {
    if (!disableAgree) {
      toggleAgreeToTerms();
      setAgreed(agreed => !agreed);
    }
  };

  cleanupUploadedStudies();
  const emptyFiles = onlyEmptyFiles(files);
  const zeroFreeStudyLeft = getRemainingFreeStudies() === 0;
  const disableAgree = zeroFreeStudyLeft;
  const disableSubmit = emptyFiles || !agreed;

  const { UPLOAD_VIEW, DROPZONE_CONTAINER } = ReactTestingLibraryDataProperties;

  return (
    <>
      <InfoUploadWrapper>
        <CxrSampleInfo />
        <Separator />
        <UploadGroup>
          <DropzonesContainer data-testid={UPLOAD_VIEW}>
            {dropzoneIndices.map(index => {
              const disableDropzone = (!!index && emptyFiles) || zeroFreeStudyLeft;

              return (
                <DropzoneContainer key={index} data-testid={DROPZONE_CONTAINER} disabled={zeroFreeStudyLeft}>
                  <Dropzone
                    index={index}
                    validateOnChangeFiles={validateOnChangeFiles}
                    validateOnChangeFile={validateOnChangeFile}
                    files={files}
                    setFiles={setFiles}
                    disabled={disableDropzone}
                    contentOnHover={
                      <>
                        <IconContainer>
                          <IconWrapper svg={cloudArrowUp} size={1.25} />
                        </IconContainer>
                        <Text>Drop image here</Text>
                      </>
                    }
                    contentOnLoaded={
                      <>
                        <IconContainer>
                          <IconWrapper svg={cloudCheckmark} size={1.25} />
                        </IconContainer>
                        <Text>Image successfully loaded</Text>
                      </>
                    }
                  >
                    <FilePlaceholderContainer>
                      <IconContainer>
                        <IconWrapper svg={cloudArrowUp} size={1.25} />
                      </IconContainer>
                      <TextBlock>
                        <Text>Drag and drop or&nbsp;</Text>
                        <TextUnderlined>browse files.</TextUnderlined>&nbsp;
                        <Text>{!!index ? 'One FRONT or LAT image.' : 'One FRONT image only.'}</Text>
                      </TextBlock>
                    </FilePlaceholderContainer>
                  </Dropzone>
                </DropzoneContainer>
              );
            })}
          </DropzonesContainer>
          <TermsContainer>
            <BottomRow>
              <BottomRowItem isPortrait={isPortrait}>
                <Agree disabled={disableAgree} agreed={agreed} onClick={handleAgreeTermsClick}>
                  <FontAwesomeIcon icon={agreed ? faCheckSquare : faSquare} /> I agree to the Terms and Conditions
                </Agree>
              </BottomRowItem>
            </BottomRow>
            <BottomRow>
              <BottomRowItem isPortrait={isPortrait}>
                <TermsAndConditions />
              </BottomRowItem>
            </BottomRow>
            <UploadsContainer>
              <BottomRow>
                <BottomRowItem isPortrait={isPortrait}>
                  <ButtonContainer>
                    <Button onClick={onUploadClick} disabled={disableSubmit}>
                      <IconContainer>
                        <IconWrapper svg={cloudArrowUp} size={1.25} fill={CURRENT_COLOR} />
                      </IconContainer>
                      Upload study for analysis
                    </Button>
                  </ButtonContainer>
                </BottomRowItem>
                <BottomRowItem isPortrait={isPortrait}>
                  <UploadsRemaining />
                </BottomRowItem>
              </BottomRow>
            </UploadsContainer>
          </TermsContainer>
        </UploadGroup>
      </InfoUploadWrapper>
    </>
  );
};

export default CxrSampleUpload;
