import Cookies from 'js-cookie';

// Ensures js-cookies are stringified.
// In js-cookie prior to v 3.0.0, Cookies.set() had JSON.stringify inbuilt.
// From 3.0.0 have to do ourselves. Temp solution.

const setCookie = (cookieName: string, value: unknown, options?: Cookies.CookieAttributes): void => {
  let stringValue;
  if (typeof value === 'string') {
    stringValue = value;
  } else {
    stringValue = JSON.stringify(value);
  }

  Cookies.set(cookieName, stringValue, options);
};

export default setCookie;
