import styled, { DataAttributes } from 'styled-components';
import { ButtonIconCss, ButtonLikeBaseCss } from 'components/Buttons/Button/Button.styles';
import ButtonText from 'components/Buttons/ButtonText/ButtonText';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { PAGINATION_CURRENT_DIV } = ReactTestingLibraryDataProperties;
const { SECONDARY_ON, SECONDARY } = colors;

const PaginationCurrentDiv = styled(ButtonText).attrs<DataAttributes>({
  'data-testid': PAGINATION_CURRENT_DIV,
})`
  ${ButtonLikeBaseCss}
  ${ButtonIconCss}
  cursor: pointer;
  color: ${SECONDARY_ON};
  background-color: ${SECONDARY};
`;

export default PaginationCurrentDiv;
