import React, { FC } from 'react';
import styled from 'styled-components';
import { LINK_PRIVACY_POLICY, LINK_TERMS_CONDITIONS } from 'constants/links';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 11rem;
`;

const DocLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 0.6rem;
  &:hover {
    text-decoration: underline;
  }
`;

const TermsAndConditions: FC = () => (
  <Container>
    <DocLink href={LINK_TERMS_CONDITIONS} target="_blank">
      Terms and Conditions
    </DocLink>
    <DocLink href={LINK_PRIVACY_POLICY} target="_blank">
      Privacy policy
    </DocLink>
  </Container>
);

export default TermsAndConditions;
