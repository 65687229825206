import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC, SyntheticEvent } from 'react';
import styled, { DataAttributes, css } from 'styled-components';
import FindingIndicatorType from 'enums/FindingIndicatorType';
import FindingInteractionTypes from 'enums/FindingInteractionTypes';
import IconDirection from 'enums/IconDirection';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import getFindingInteractionType from 'helpers/getFindingInteractionType';
import maybeTruncate from 'helpers/maybeTruncate';
import useFindingIndicatorType from 'hooks/useFindingIndicatorType';
import ChevronBold from 'icons/ChevronBold';
import { ParentFinding } from 'types/finding/FindingGroup';
import FindingType from 'types/study/Finding';
import FindingIndicator from './FindingIndicator';

const { FINDING } = ReactTestingLibraryDataProperties;
const { HOVER, CLICK } = FindingInteractionTypes;
const { BLACK, WHITE, GRAY_1, BUTTON_PRIMARY_PRESSED_BG } = colors;
const LINE_LENGTH = 50;

const { MULTIPLE_IMAGES_LOCALISATION } = FindingIndicatorType;
export type FindingProps = {
  finding: FindingType | ParentFinding;
  isRelevant?: boolean;
  isExpanded?: boolean;
  isFindingActive: boolean;
  setFinding: (finding: FindingType['uuid'] | ParentFinding['uuid']) => void;
  shouldOnMouseOverInteract: (event: SyntheticEvent<EventTarget, MouseEvent>) => boolean;
};

type BorderProps = {
  highlight: boolean;
};

type ContainerProps = {
  relevant: boolean;
  highlight: boolean;
};

const largePolygon = `clip-path: polygon(calc(0% + 1em) 0%, 100% 0, 100% 100%, calc(0% + 1em) 100%, 0% 50%);`;
const smallPolygon = `clip-path: polygon(calc(0% + 1em - 1px) 0%, 100% 0, 100% 100%, calc(0% + 1em - 1px) 100%, 0% 50%);`;

const FindingBorder = styled.div<BorderProps>`
  width: calc(100% + 0.5rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1px;

  ${({ highlight }) =>
    highlight &&
    css`
      margin-left: -1em;
      position: relative;
      border-top-right-radius: 0.25em;
      border-bottom-right-radius: 0.25em;
      background-color: var(--finding--border-color-hover);
      ${largePolygon}
    `};
`;

const FindingContainer = styled.div.attrs<DataAttributes>({ 'data-testid': FINDING })<ContainerProps>`
  display: grid;
  grid-auto-columns: 1fr;
  width: calc(100% - 3px);
  grid-template-columns: 2rem 17rem 1.5rem;
  grid-template-rows: 1fr;
  gap: 0;
  grid-template-areas: 'indicator name chevron';
  padding-left: 12px;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  margin: 1.5px;
  align-items: center;
  ${({ relevant }) =>
    relevant &&
    css`
      cursor: pointer;
    `}

  ${({ highlight }) =>
    highlight &&
    css`
      // offset by width of the largePolygon(original 12px + 1px border + 1em polygon)
      padding-left: calc(12px + 1px + 1em);
      background-color: var(--finding--background-color-hover);
      border-top-right-radius: 0.25em;
      border-bottom-right-radius: 0.25em;
      ${smallPolygon};
    `}
`;

const FindingIndicatorContainer = styled.div`
  width: 2rem;
  min-width: 2rem;
  grid-area: indicator;
`;

type NameProps = {
  highlight: boolean;
};

const Name = styled.div<NameProps>`
  grid-area: name;
  max-width: 100%;
  // stops finding from expanding vertically when name is over two lines
  max-height: 42px;
  word-break: break-word;
  ${({ highlight }) =>
    highlight
      ? css`
          font-weight: 500;
          font-size: 0.9rem;
          letter-spacing: 0.015rem;
          text-shadow: 1px 1px 0 ${BUTTON_PRIMARY_PRESSED_BG};
        `
      : css`
          color: ${GRAY_1};
          font-weight: 300;
          font-size: 0.875rem;
          letter-spacing: 0.04rem;
          text-shadow: 1px 1px 0 ${BLACK};
        `}
`;
const ChevronContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  grid-area: chevron;
`;

const Finding: FC<FindingProps> = ({
  finding,
  isRelevant = false,
  isExpanded = false,
  setFinding: setFindingCallback,
  isFindingActive,
  shouldOnMouseOverInteract,
}) => {
  const { UP, DOWN } = IconDirection;
  const { name, uuid } = finding;
  const formattedName = maybeTruncate(name, LINE_LENGTH);
  const findingIndicatorType = useFindingIndicatorType(isRelevant ? uuid : null);
  const setFinding = () => isRelevant && setFindingCallback(finding.uuid);
  const onMouseEnter = (event: SyntheticEvent<EventTarget, MouseEvent>) => {
    if (getFindingInteractionType() !== HOVER) {
      return;
    }

    if (!shouldOnMouseOverInteract(event)) {
      return;
    }

    setFinding();
  };

  const onClick = () => {
    if (getFindingInteractionType() !== CLICK) {
      return;
    }
    setFinding();
  };

  return (
    <FindingBorder highlight={isFindingActive}>
      <FindingContainer relevant={isRelevant} highlight={isFindingActive} onMouseEnter={onMouseEnter} onClick={onClick}>
        <FindingIndicatorContainer>
          {findingIndicatorType !== null && (
            <FindingIndicator
              findingIndicatorType={finding.isParent ? MULTIPLE_IMAGES_LOCALISATION : findingIndicatorType}
              isActive={isFindingActive}
            />
          )}
        </FindingIndicatorContainer>
        <Name highlight={isFindingActive}>{formattedName}</Name>

        {finding.isParent && (
          <ChevronContainer>
            <ChevronBold
              direction={isExpanded ? UP : DOWN}
              fill={isFindingActive ? WHITE : GRAY_1}
              shadow={isFindingActive ? BUTTON_PRIMARY_PRESSED_BG : BLACK}
            />
          </ChevronContainer>
        )}
      </FindingContainer>
    </FindingBorder>
  );
};

export default Finding;
