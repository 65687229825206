import { colors as annaColors } from '@annaliseai/anna-design-tokens';
import styled, { css } from 'styled-components';
import { CheckboxLabelProps } from 'components/Menu/Checkbox/Checkbox.types';
import colors from 'constants/colors';

const { WHITE, GRAY_5 } = annaColors;
const { SECONDARY_ON, TERTIARY_OUTLINE_VARIANT } = colors;

const defaultColor = SECONDARY_ON;
const activeColor = WHITE;
const disabledColor = GRAY_5;

// Hide the default checkbox
export const NativeCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const CheckboxItem = styled.div`
  padding: 0.25rem 0.5rem;
`;

export const Label = styled.label<CheckboxLabelProps>`
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  color: ${defaultColor};
  position: relative;
  cursor: pointer;
  font-size: 0.75rem;

  &:active,
  &:focus,
  &:focus-within {
    outline: 1px solid ${TERTIARY_OUTLINE_VARIANT};
  }

  & svg {
    fill: ${defaultColor};
  }

  ${({ $isDisabled }) =>
    $isDisabled
      ? css`
          color: ${disabledColor};

          & svg {
            fill: ${disabledColor};
          }

          cursor: default;
        `
      : css`
          &:hover {
            color: ${activeColor};

            & svg {
              fill: ${activeColor};
            }
          }
        `}

  ${({ $isChecked, $isDisabled }) =>
    $isChecked &&
    !$isDisabled &&
    css`
      color: ${activeColor};

      & svg {
        fill: ${activeColor};
      }
    `}
`;
