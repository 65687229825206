import React, { FC } from 'react';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { ICON_ONBOARDING_CONFIG } = ReactTestingLibraryDataProperties;

const OnboardConfig: FC = () => (
  <svg
    data-testid={ICON_ONBOARDING_CONFIG}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M7.988 14.165h10.459c1.131.772 2.477 1.234 3.914 1.234 3.823 0 6.973-3.146 6.973-7.032 0-3.856-3.12-7.033-6.973-7.033-1.468 0-2.813.463-3.914 1.234H7.988c-2.263 0-4.128 1.85-4.128 4.164v3.3c.03 2.252 1.865 4.133 4.128 4.133zm19.786-5.798c0 3.022-2.447 5.46-5.413 5.46-2.997 0-5.443-2.438-5.443-5.46 0-3.023 2.446-5.49 5.443-5.49 2.966 0 5.413 2.467 5.413 5.49zM5.419 6.732c0-1.45 1.162-2.591 2.57-2.591h8.806c-.886 1.172-1.437 2.652-1.437 4.256s.55 3.054 1.437 4.257H7.988c-1.407 0-2.569-1.172-2.569-2.591V6.732z"
      fill="#fff"
    />
    <path
      d="M9.548 10.279c.153.154.336.278.55.278.214 0 .428-.093.55-.278l2.417-2.9c.122-.154.183-.339.152-.524-.03-.185-.122-.37-.244-.493-.153-.124-.337-.185-.52-.185-.184.03-.367.123-.49.246l-1.865 2.221-.826-.987c-.244-.308-.733-.34-1.04-.092-.152.123-.244.308-.244.493-.03.185.03.401.184.525l1.376 1.696zM24.012 17.805H13.554c-1.101-.771-2.447-1.203-3.915-1.203-3.822 0-6.972 3.177-6.972 7.033 0 3.855 3.15 7.032 6.972 7.032 1.438 0 2.783-.462 3.915-1.234h10.428c2.263 0 4.128-1.881 4.128-4.163v-3.3c0-2.314-1.834-4.165-4.098-4.165zM4.227 23.604c0-3.023 2.446-5.46 5.412-5.46 2.997 0 5.444 2.437 5.444 5.46s-2.447 5.46-5.413 5.46c-2.997.03-5.443-2.437-5.443-5.46zM26.58 25.27c0 1.418-1.162 2.59-2.569 2.59h-8.837c.886-1.172 1.437-2.652 1.437-4.256s-.55-3.085-1.437-4.257h8.837c1.407 0 2.57 1.172 2.57 2.591v3.332z"
      fill="#fff"
    />
    <path
      d="M23.4 21.383c-.274-.278-.764-.278-1.039 0L21.2 22.555l-1.193-1.203c-.275-.277-.764-.277-1.04 0-.275.278-.275.74 0 1.049l1.193 1.203-1.192 1.203c-.276.277-.276.74 0 1.049.153.123.306.215.52.215.183 0 .367-.061.52-.216l1.192-1.202 1.193 1.203c.122.123.336.215.52.215.183 0 .367-.061.52-.216.275-.277.275-.74 0-1.048l-1.193-1.203L23.43 22.4c.245-.278.245-.74-.03-1.018z"
      fill="#fff"
    />
    <path
      d="M7.988 14.165h10.459c1.131.772 2.477 1.234 3.914 1.234 3.823 0 6.973-3.146 6.973-7.032 0-3.856-3.12-7.033-6.973-7.033-1.468 0-2.813.463-3.914 1.234H7.988c-2.263 0-4.128 1.85-4.128 4.164v3.3c.03 2.252 1.865 4.133 4.128 4.133zm19.786-5.798c0 3.022-2.447 5.46-5.413 5.46-2.997 0-5.443-2.438-5.443-5.46 0-3.023 2.446-5.49 5.443-5.49 2.966 0 5.413 2.467 5.413 5.49zM5.419 6.732c0-1.45 1.162-2.591 2.57-2.591h8.806c-.886 1.172-1.437 2.652-1.437 4.256s.55 3.054 1.437 4.257H7.988c-1.407 0-2.569-1.172-2.569-2.591V6.732z"
      stroke="#5436BD"
      strokeWidth=".357"
    />
    <path
      d="M9.548 10.279c.153.154.336.278.55.278.214 0 .428-.093.55-.278l2.417-2.9c.122-.154.183-.339.152-.524-.03-.185-.122-.37-.244-.493-.153-.124-.337-.185-.52-.185-.184.03-.367.123-.49.246l-1.865 2.221-.826-.987c-.244-.308-.733-.34-1.04-.092-.152.123-.244.308-.244.493-.03.185.03.401.184.525l1.376 1.696zM24.012 17.805H13.554c-1.101-.771-2.447-1.203-3.915-1.203-3.822 0-6.972 3.177-6.972 7.033 0 3.855 3.15 7.032 6.972 7.032 1.438 0 2.783-.462 3.915-1.234h10.428c2.263 0 4.128-1.881 4.128-4.163v-3.3c0-2.314-1.834-4.165-4.098-4.165zM4.227 23.604c0-3.023 2.446-5.46 5.412-5.46 2.997 0 5.444 2.437 5.444 5.46s-2.447 5.46-5.413 5.46c-2.997.03-5.443-2.437-5.443-5.46zM26.58 25.27c0 1.418-1.162 2.59-2.569 2.59h-8.837c.886-1.172 1.437-2.652 1.437-4.256s-.55-3.085-1.437-4.257h8.837c1.407 0 2.57 1.172 2.57 2.591v3.332z"
      stroke="#5436BD"
      strokeWidth=".357"
    />
    <path
      d="M23.4 21.383c-.274-.278-.764-.278-1.039 0L21.2 22.555l-1.193-1.203c-.275-.277-.764-.277-1.04 0-.275.278-.275.74 0 1.049l1.193 1.203-1.192 1.203c-.276.277-.276.74 0 1.049.153.123.306.215.52.215.183 0 .367-.061.52-.216l1.192-1.202 1.193 1.203c.122.123.336.215.52.215.183 0 .367-.061.52-.216.275-.277.275-.74 0-1.048l-1.193-1.203L23.43 22.4c.245-.278.245-.74-.03-1.018z"
      stroke="#5436BD"
      strokeWidth=".357"
    />
  </svg>
);

export default OnboardConfig;
