import { CtbClassificationExternal } from '@annaliseai/api-specifications';
import filterValidFindings from 'epics/study/helpers/filterValidFindings';

const getCtbRelevantLocalisations = <T extends { relatedLabels: string[] }>(
  localisations: T[],
  predictions: CtbClassificationExternal[],
): T[] => {
  const relevantLabels = new Set(filterValidFindings(predictions).map(({ label }) => label));

  return localisations.filter(({ relatedLabels }) =>
    relatedLabels.some(relatedLabel => relevantLabels.has(relatedLabel)),
  );
};

export default getCtbRelevantLocalisations;
