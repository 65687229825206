import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import DEFAULT_TIMEZONE from './constants/defaultTimezone';
import DateTime from './types/DateTime';

dayjs.extend(utc);
dayjs.extend(timezone);

const convertUtcToTimezone = (dateTime: DateTime, fallback = '', tz = DEFAULT_TIMEZONE): string => {
  if (!dayjs(dateTime).isValid()) {
    return fallback;
  }

  try {
    return dayjs.utc(dateTime).tz(tz).format();
  } catch {
    return fallback;
  }
};

export default convertUtcToTimezone;
