import { chevronLeft24Filled, chevronRight24Filled } from '@annaliseai/anna-icons';
import IconWrapper from '@annaliseai/icon-wrapper';
import React, { FC } from 'react';
import Button, { ButtonSize, ButtonVariant } from 'components/Buttons/Button/Button';
import ButtonText from 'components/Buttons/ButtonText/ButtonText';
import PaginationIndexes from 'components/Table/PaginationIndexes';
import Pages from 'enums/Pages';
import BUTTONS from './WorklistPagination.constants';
import PaginationContainer from './WorklistPagination.styles';

const { PREV_BUTTON_ATTRS, NEXT_BUTTON_ATTRS } = BUTTONS;
const { PAGE_1 } = Pages;
const { ICON, SMALL } = ButtonSize;
const { DEFAULT } = ButtonVariant;

type WorklistPaginationProps = {
  gotoPage: (pageIndex: number) => void;
  isPrevDisabled: boolean;
  isNextDisabled: boolean;
  totalPages: number;
  currentPage: number;
  size?: ButtonSize;
  variant?: ButtonVariant;
};

// Used for the viewer Results List, serverside pagination

const WorklistPagination: FC<WorklistPaginationProps> = ({
  isPrevDisabled,
  isNextDisabled,
  gotoPage,
  totalPages,
  currentPage,
  size = SMALL,
  variant = DEFAULT,
}) => (
  <PaginationContainer>
    <Button
      {...PREV_BUTTON_ATTRS}
      onClick={() => {
        gotoPage(currentPage - PAGE_1);
      }}
      disabled={isPrevDisabled}
      size={size}
      variant={variant}
    >
      <IconWrapper svg={chevronLeft24Filled} fill="inherit" />
      <ButtonText size={size}>Prev</ButtonText>
    </Button>
    <PaginationIndexes
      currentPageIndex={currentPage}
      gotoPage={gotoPage}
      pageCount={totalPages}
      range={5}
      size={ICON}
    />
    <Button
      {...NEXT_BUTTON_ATTRS}
      onClick={() => {
        gotoPage(currentPage + PAGE_1);
      }}
      disabled={isNextDisabled}
      size={size}
      variant={variant}
    >
      <ButtonText size={size}>Next</ButtonText>
      <IconWrapper svg={chevronRight24Filled} fill="inherit" />
    </Button>
  </PaginationContainer>
);

export default WorklistPagination;
