import { ErrorMessage, PredictionErrorCode } from '@annaliseai/api-specifications';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Table } from '@tanstack/table-core/src/types';
import { push } from 'connected-react-router';
import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import getDisplayError from 'api/client/getDisplayError';
import worklistColumns from 'components/WorklistTable/WorklistColumns';
import { ALL, ALL_COL, cookieExpiryOption } from 'components/WorklistTable/WorklistTable.constants';
import CookieKeys from 'enums/CookieKeys';
import CustomErrors from 'enums/CustomErrors';
import Routes from 'enums/Routes';
import { storeUserSettings } from 'helpers/cookies/userSettingsHelper';
import useStreamedStudies from 'hooks/useStreamedStudies';
import { errorActions } from 'slices/errorSlice';
import DisplayStudyInformation from 'types/DisplayStudyInformation';
import getColumnVisibility from './utils/getColumnVisibility';
import maybeEnableHiding from './utils/maybeEnableHiding';
import maybeShowColumn from './utils/maybeShowColumn';
import { ColumnIds } from './WorklistTypes';

const { VIEWER } = Routes;
const { UNEXPECTED_BACKEND_ERROR } = CustomErrors;
const { COLUMN_SELECTION } = CookieKeys;

type ToggleMenuOptions = {
  id: string;
  label: string;
  isChecked: boolean;
  isDisabled: boolean;
  onChange: () => void;
};

export type UseWorklistTableTypes = {
  table: Table<DisplayStudyInformation>;
  toggleMenuOptions: ToggleMenuOptions[];
  handleRowClick: (
    error: ErrorMessage | undefined,
    studyIdentifiers: { accessionNumber: string; studyInstanceUid: string },
  ) => void;
};

const useWorklistTable = (): UseWorklistTableTypes => {
  const studies = useStreamedStudies();
  const columnVisibility = getColumnVisibility();

  const { setError } = errorActions;
  const AllErrors = { ...PredictionErrorCode, ...CustomErrors };
  const dispatch = useDispatch();

  // Set up the table
  const table = useReactTable({
    data: studies,
    columns: worklistColumns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    initialState: {
      columnVisibility: {
        ...columnVisibility,
      },
    },
  });

  const openStudy = (accessionNumber: string, studyInstanceUid: string) => {
    accessionNumber &&
      studyInstanceUid &&
      dispatch(
        push({
          pathname: VIEWER,
          search: `?accessionNumber=${accessionNumber}&studyInstanceUid=${studyInstanceUid}&showLinks=true`,
        }),
      );
  };

  // If there is an error, show error modal, else open the study in the viewer screen
  const handleRowClick = (
    error: ErrorMessage | undefined,
    studyIdentifiers: { accessionNumber: string; studyInstanceUid: string },
  ) => {
    const { accessionNumber, studyInstanceUid } = studyIdentifiers;
    if (error) {
      const displayError = getDisplayError(AllErrors[error.code || UNEXPECTED_BACKEND_ERROR]);
      dispatch(setError(displayError));
      return;
    }
    return openStudy(accessionNumber, studyInstanceUid);
  };

  // Toggle for all columns

  const toggleAllColumns = table.getToggleAllColumnsVisibilityHandler();

  const onChangeAll = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    toggleAllColumns(e);

    const newColumnValues: Record<string, boolean> = {};
    // The new value of each toggleable column should be the same as the new value for 'toggleAll'
    table.getAllLeafColumns().forEach(column => {
      const id = column.id as ColumnIds;

      return (
        // using !!maybeEnableHiding instead of !!column.getCanHide() to get tests to pass, due to mocking gremlins
        // maybeShowColumn checks config isPresent value (e.g. HK studyType.isPresent = false)
        !!maybeEnableHiding(id) && !!maybeShowColumn(id) && (newColumnValues[id] = checked)
      );
    });

    storeUserSettings(COLUMN_SELECTION, newColumnValues, cookieExpiryOption);
  };

  const toggleAll = {
    id: ALL_COL,
    label: ALL,
    isDisabled: false,
    isChecked: table.getIsAllColumnsVisible(),
    onChange: onChangeAll,
  };

  // Set up the individual columns
  const columns = table.getAllLeafColumns().map(column => {
    const { id, columnDef, getIsVisible, getCanHide, getToggleVisibilityHandler } = column;
    const { header } = columnDef;

    const toggleHandler = getToggleVisibilityHandler();

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      toggleHandler(e);
      storeUserSettings(COLUMN_SELECTION, { [id]: checked }, cookieExpiryOption);
    };

    return {
      id: id,
      label: header,
      isChecked: columnVisibility?.[id] ?? getIsVisible(),
      isDisabled: !getCanHide(),
      onChange: onChange,
    };
  });

  const toggleMenuOptions = [toggleAll, ...columns];
  return {
    toggleMenuOptions,
    handleRowClick,
    table,
  } as UseWorklistTableTypes;
};

export default useWorklistTable;
