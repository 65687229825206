import { ReactElement, ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled, { DataAttributes } from 'styled-components';
import Overlay from 'components/Overlay';
import PrimaryButton from 'components/PrimaryButton';
import Z_INDEXES from 'constants/zIndexes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Warning from 'icons/Warning';
import { handleKeyDown, trapFocusModal } from './Modal.utils';

const { MODAL_CONTAINER, MODAL_OVERLAY } = ReactTestingLibraryDataProperties;
const { MODAL } = Z_INDEXES;

const ModalContainer = styled.div.attrs<DataAttributes>({
  'data-testid': MODAL_CONTAINER,
  'aria-label': 'Modal',
  role: 'dialog',
  'aria-modal': 'true',
})`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 0;
  background: rgba(27, 27, 27, 0.9);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  max-height: 100%;
  max-width: 100%;
  z-index: ${MODAL};
`;

const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;

  svg {
    display: inline;
    vertical-align: baseline;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  margin: 1em;
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 3em;
  margin: 1em auto;
  padding: 0 5rem;
`;

const ModalHeader = styled.div`
  background: linear-gradient(0deg, rgba(27, 27, 27, 0.75), rgba(27, 27, 27, 0.75));
  min-height: 3rem;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
`;

const OKButton = styled(PrimaryButton)`
  height: 2.3em;
  width: 25em;
  border: 1px solid #8973ff;
`;

export type ModalProps = {
  hasFooter?: boolean;
  onClose?: () => void;
  show?: boolean;
  children?: ReactNode;
};

const Modal = ({ children, onClose, show = false, hasFooter = true }: ModalProps): ReactElement => {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  const previouslyFocusedElementRef = useRef<HTMLElement | null>(null);
  const firstFocusableElementRef = useRef<HTMLElement | null>(null);
  const lastFocusableElementRef = useRef<HTMLElement | null>(null);
  previouslyFocusedElementRef.current = document.activeElement as HTMLElement;
  useEffect(() => {
    if (show) {
      const modalContainer = modalContainerRef?.current;
      modalContainer?.focus();
      trapFocusModal(firstFocusableElementRef.current, lastFocusableElementRef.current, modalContainer);
    }
  }, [show]);

  const handleClose = () => {
    previouslyFocusedElementRef.current?.focus();
    onClose?.();
  };

  const focusModalContainer = 0;

  return (
    <>
      {show &&
        createPortal(
          <>
            <Overlay data-testid={MODAL_OVERLAY} />
            <ModalContainer
              ref={modalContainerRef}
              onKeyDown={onClose && handleKeyDown?.(onClose, previouslyFocusedElementRef.current)}
              tabIndex={focusModalContainer}
            >
              <ModalHeader>
                <IconContainer>
                  <Warning />
                </IconContainer>
              </ModalHeader>
              <ContentContainer>{children}</ContentContainer>
              {hasFooter && (
                <FooterContainer>
                  <OKButton onClick={handleClose}>OK</OKButton>
                </FooterContainer>
              )}
            </ModalContainer>
          </>,
          document.body,
        )}
    </>
  );
};

export default Modal;
