import CornerstoneToolsMouseEvents from 'types/cornerstone/CornerstoneToolsMouseEvents';
import { EventActions, EventCategories, EventNames } from 'types/Matomo';
import tracker from './matomoTracker';

const trackCtbCornerstoneMouseEvents = ({ eventName }: { eventName: CornerstoneToolsMouseEvents }): void => {
  const { CORNERSTONE_TOOLS_MOUSE_WHEEL, CORNERSTONE_TOOLS_MOUSE_DRAG } = CornerstoneToolsMouseEvents;
  const { CTB_VIEWER } = EventCategories;
  const { CTB_SCROLL } = EventActions;
  const { MOUSE_WHEEL, IMAGE_PANEL } = EventNames;

  switch (eventName) {
    case CORNERSTONE_TOOLS_MOUSE_WHEEL:
      tracker.trackEvent({
        category: CTB_VIEWER,
        action: CTB_SCROLL,
        name: MOUSE_WHEEL,
      });
      break;
    case CORNERSTONE_TOOLS_MOUSE_DRAG:
      tracker.trackEvent({
        category: CTB_VIEWER,
        action: CTB_SCROLL,
        name: IMAGE_PANEL,
      });
      break;
    default:
      console.error(`ERROR: ${eventName} is not a valid event name`);
  }
};

export default trackCtbCornerstoneMouseEvents;
