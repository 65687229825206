// Those are the colors that are currently not in anna
// TODO: take ALL these colours from @annaliseai/anna-design-tokens when available
const colors = {
  BLACK: '#000',
  WHITE: '#FFF',
  CHARCOAL: '#191919',
  DARK_6: '#303030',
  DARK_7: '#444',
  HAITI: '#1b1342',
  WHITE_15: 'rgba(255, 255, 255, 0.15)',
  WHITE_20: 'rgba(255, 255, 255, 0.20)',
  WHITE_50: 'rgba(255, 255, 255, 0.50)',
  GREY_2: '#333',
  GREY_3: '#999', // in anna
  GREY_4: '#666',
  GREY_6: '#808080', // in anna
  GREY_8: '#AAA',
  GREY_9: ' #1B1B1B',
  GREY_HOME: '#aaa',
  GREY_SEPARATOR: 'rgba(217, 217, 217, 0.2)',
  LOCALISATION_OVERLAY: 'rgba(219, 0, 255, 0.35)',
  PURPLE_1: '#5436bd', // in anna
  BACKGROUNDS_WINDOW: '#1e1e1e',
  SIDEBAR: '#1a202c',
  CURRENT_COLOR: 'currentColor',
  RED_1: '#FF797E',
  RED_2: '#FF2E00',
  YELLOW_1: '#FFEF9E',
  YELLOW_2: '#FEF111',
  AQUA_1: '#A5E1FF',
  AQUA_2: '#64C9FA',
  PURPLE_3: '#352685',

  // NEW DESIGN TOKENS 2023
  PRIMARY: '#5436BD',
  PRIMARY_OUTLINE: '#8973FF',
  PRIMARY_ON: '#FFF',
  PRIMARY_HOVER: '#432B97',

  SECONDARY: '#1B1B1B',
  SECONDARY_ON: '#CCC',
  SECONDARY_CONTAINER: '#333',
  SECONDARY_CONTAINER_ON: '#FFF',
  SECONDARY_INVERT: '#848484',
  SECONDARY_OUTLINE: '#444',

  BLUE: '#007CEE',
  GREEN: '#57c81b',
  YELLOW: '#FFc800',
  RED: '#FF0000',

  TERTIARY_ON: '#DB00FF',
  TERTIARY_OUTLINE_VARIANT: '#007CEE',

  NEUTRAL_BACKGROUND: '#000',
  NEUTRAL_BACKGROUND_ON: '#848484',
  NEUTRAL_BACKGROUND_ON_VARIANT: '#CCC',
  NEUTRAL_BACKGROUND_INVERT: '#444',
  NEUTRAL_OUTLINE: '#444',
  NEUTRAL_OUTLINE_VARIANT: '#333',
};

export default colors;
