import { css } from 'styled-components';

export const ButtonTextSmallCss = css`
  font-size: 0.75rem;
  line-height: 1rem;
`;

export const ButtonTextMediumCss = css`
  font-size: 0.75rem;
  line-height: 1rem;
`;

export const ButtonTextLargeCss = css`
  font-size: 0.875rem;
  line-height: 1rem;
`;
