import AnnaLocalisationToggle from '@annaliseai/localisation-toggle';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatomo } from 'matomo/react';
import selectActiveFindingLaterality from 'selectors/viewer/selectActiveFindingLaterality';
import { selectIsLocalisationToggleOn, viewerActions } from 'slices/viewerSlice';
import { EventActions, EventCategories } from 'types/Matomo';
import { TEXT_OFF, TEXT_ON } from './constants';

const { setIsLocalisationToggleOn } = viewerActions;
const { STUDY } = EventCategories;
const { HIT } = EventActions;

const LocalisationToggle: FC = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const isLocalisationToggleOn = useSelector(selectIsLocalisationToggleOn);
  const { hasLocalisation } = useSelector(selectActiveFindingLaterality);

  const onLocalisationToggle = (newToggle: boolean) => {
    dispatch(setIsLocalisationToggleOn(newToggle));
    trackEvent({
      category: STUDY,
      action: HIT,
      name: newToggle ? 'show' : 'hide',
    });
  };

  return (
    <AnnaLocalisationToggle
      isToggleOn={isLocalisationToggleOn}
      onChangeToggle={onLocalisationToggle}
      hasLocalisation={hasLocalisation}
      textOn={TEXT_ON}
      textOff={TEXT_OFF}
    />
  );
};

export default LocalisationToggle;
