import pngLoader from '@annaliseai/png-loader';
import cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import dicomParser from 'dicom-parser';
import CornerstoneLoaderPrefixes from 'enums/CornerstoneLoaderPrefixes';
import { dicomLoader } from './dicomLoader';
import { jp2Loader } from './jp2Loader';
import { getWebLoader } from './loadImageHelper';

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

const { WEB, DICOMFILE, JP2, PNG16 } = CornerstoneLoaderPrefixes;

cornerstone.registerImageLoader(WEB, getWebLoader);
cornerstone.registerImageLoader(DICOMFILE, dicomLoader);
cornerstone.registerImageLoader(JP2, jp2Loader);
cornerstone.registerImageLoader(PNG16, pngLoader);

// Used to fix a warning on initialisation complaining about cornerstone default values which we can't change
cornerstoneTools.getModule('segmentation').configuration.segmentsPerLabelmap = 0;

cornerstoneTools.init({
  mouseEnabled: true,
  touchEnabled: false,
  autoResizeViewports: true,
});
