import React from 'react';
import styled from 'styled-components';
import OnboardConfig from 'icons/OnboardConfig';
import OnboardExplain from 'icons/OnboardExplain';
import OnboardSubjective from 'icons/OnboardSubjective';
import OnboardCardType from 'types/OnboardCardType';

const StyledBreak = styled.hr`
  height: 8px;
  opacity: 0;
`;

const onboardingContent: OnboardCardType[] = [
  {
    description: (
      <>
        Use the Confidence Bar on each finding
        <br /> to help interpret AI results.
      </>
    ),
    title: 'Interpret with confidence',
    Logo: OnboardSubjective,
  },
  {
    description: (
      <>
        Adjust sensitivity and customise finding
        <br /> groups for your clinical setting.
        <StyledBreak /> Here all findings are enabled with
        <br /> ‘critical findings’ tuned for sensitivity by
        <br /> expert radiologists.
      </>
    ),
    title: 'Made for the real world',
    Logo: OnboardConfig,
  },
  {
    description: (
      <>
        Some findings are visually highlighted.
        <br /> Many other findings have anatomical
        <br /> Left/Right indicators.
      </>
    ),
    title: 'Explainability is our priority',
    Logo: OnboardExplain,
  },
];

export default onboardingContent;
