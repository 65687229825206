import configuration from 'configuration';
import strings from 'constants/strings';

const { isDemo } = configuration;
const { TEXT_DISCLAIMER } = strings.viewer;

// Menu items used in navMenuConfig
export const ABOUT_TEXT = 'About Annalise.ai';
export const ADMIN_TEXT = 'Admin tool';
export const BACK_TEXT = 'Switch to Public Demo';
export const CTB_SAMPLE_TEXT = 'CTB demo';
export const CXR_SAMPLE_TEXT = 'CXR demo';
export const EMAIL_SUPPORT = 'support@annalise.ai';
export const ENTERPRISE_ADMINISTRATION_GUIDE_TEXT = 'Annalise Web Viewer User Guide';
export const ENTERPRISE_PERFORMANCE_GUIDE_TEXT = 'Performance Guide';
export const ENTERPRISE_USER_GUIDE_TEXT = 'Annalise Enterprise User Guide';
export const ENTERPRISE_REGULATORY_CERTIFICATIONS_TEXT = 'Regulatory Certifications';
export const HOME_TEXT = 'Annalise demo home';
export const INFO_BOX_READ_USER_GUIDE = 'Read User Guide before use';
export const INFO_BOX_WARNING = TEXT_DISCLAIMER;
export const MAIL_TO_HREF = `mailto:support@annalise.ai?subject=Annalise Web Viewer support`;
export const PRIVACY_POLICY_TEXT = 'Privacy Policy';
export const RESULT_LIST_TEXT = 'Results list';
export const SIGN_IN_TEXT = 'Sign in to Private Demo';
export const SIGN_OUT_TEXT = isDemo ? 'Sign out of Private Demo' : 'Sign out of Web Viewer';
export const LEGAL_NOTICES_TEXT = 'Legal Notices';
export const UPLOADED_TEXT = 'Uploaded studies';
