import ctbSamples from 'constants/ctbSamples';
import cxrSamples from 'constants/cxrSamples';
import Sample from 'types/Sample';

const getSample = (accessionNumber: string): Sample | null =>
  [...cxrSamples, ...ctbSamples].find(
    ({ accessionNumber: sampleAccessionNumber }) => accessionNumber === sampleAccessionNumber,
  ) || null;

export default getSample;
