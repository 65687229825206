import { clock24Regular, person24Regular, shieldTask24Regular } from '@annaliseai/anna-icons';

const cxrSampleInfoItems = [
  {
    title: 'For patients 16yrs+',
    description:
      'Annalise CXR is clinically validated on adult patients 16yrs and over. Please do not upload pediatric studies of any kind.',
    icon: person24Regular,
  },
  {
    title: 'Patient data removed',
    description:
      'Patient meta-data automatically stripped from DICOMS and file-names, and unique identifiers such as Accession Number will be refreshed. Please do not include any other patient identifying information.',
    icon: shieldTask24Regular,
  },
  {
    title: 'Deleted after 48 hours',
    description: 'Images are regularly deleted and are only used for you to test our CXR AI on specific findings.',
    icon: clock24Regular,
  },
];

export default cxrSampleInfoItems;
