import errorsMapping from 'constants/errorsMapping';
import CustomErrors from 'enums/CustomErrors';

class CustomError extends Error {
  public readonly errorCode: CustomErrors;
  public readonly isCustomError: true;

  constructor(errorCode: CustomErrors, message?: string) {
    const { title } = errorsMapping[errorCode];
    super(`[${errorCode}]: ${message || title}`);

    this.errorCode = errorCode;
    this.isCustomError = true;
  }
}

export default CustomError;
