import Cookies from 'js-cookie';
import CookieKeys from 'enums/CookieKeys';
import getCookie from 'helpers/cookies/getCookie';
import setCookie from 'helpers/cookies/setCookie';

const { COLUMN_SELECTION, RESULTS_PER_PAGE_SELECTION, SEARCH_SELECTION } = CookieKeys;

// To add another type of user setting:
// 1. Add the user setting name to the CookieKeys enum
// 2. Add the CookieKey to UserSettingsCookieType
// 3. Add to removeAllUserSettings

export type UserSettingCookieType = `${CookieKeys}`;

export type CookieOptionsType = {
  expires?: number;
};

export type UserSettingType = {
  [key: string]: boolean | number | string;
};

export const storeUserSettings = (
  settingName: UserSettingCookieType,
  setting: UserSettingType,
  options?: CookieOptionsType,
): void => {
  const existingSettings = getUserSettings(settingName);
  const updatedSettings = { ...existingSettings, ...setting };

  setCookie(settingName, updatedSettings, options);
};

export const getUserSettings = (settingName: UserSettingCookieType): UserSettingType =>
  getCookie(settingName) as UserSettingType;

export const removeUserSettings = (settingName: UserSettingCookieType): void => Cookies.remove(settingName);

export const removeAllUserSettings = (): void => {
  [COLUMN_SELECTION, RESULTS_PER_PAGE_SELECTION, SEARCH_SELECTION].forEach(setting => Cookies.remove(setting));
};
