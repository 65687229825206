import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import getDocsManifest from 'api/queries/getDocsManifest';
import getInstitutionNames from 'api/queries/getInstitutionNames';
import getOrgDisplayData from 'api/queries/getOrgDisplayData';
import getRequestingServices from 'api/queries/getRequestingServices';
import { ActionTypes } from 'slices/ActionTypes';
import {
  FetchOrgDisplayDataType,
  FetchDocsManifestType,
  FetchInstitutionNamesType,
  FetchRequestingServicesType,
  SetOrgDisplayDataType,
  SetDocsManifestType,
  SetInstitutionNamesType,
  SetRequestingServicesType,
  settingsActions,
} from 'slices/settingsSlice';

const {
  fetchOrgDisplayData: runFetchOrgDisplayDataEffect,
  fetchDocsManifest: runFetchDocsManifestEffect,
  fetchInstitutionNames: runFetchInstitutionNamesEffect,
  fetchRequestingServices: runFetchRequestingServicesEffect,
  setOrgDisplayData,
  setDocsManifest,
  setRequestingServices,
  setInstitutionNames,
} = settingsActions;

const fetchOrgDisplayDataEpic: Epic<ActionTypes, SetOrgDisplayDataType> = action$ =>
  action$.pipe(
    ofType<ActionTypes, FetchOrgDisplayDataType>(runFetchOrgDisplayDataEffect.type),
    switchMap(async () => {
      const displayData = await getOrgDisplayData();
      return setOrgDisplayData(displayData);
    }),
    catchError(() => EMPTY),
  );

const fetchInstitutionNamesEpic: Epic<ActionTypes, SetInstitutionNamesType> = action$ =>
  action$.pipe(
    ofType<ActionTypes, FetchInstitutionNamesType>(runFetchInstitutionNamesEffect.type),
    switchMap(async () => {
      const institutionNames = await getInstitutionNames();

      return setInstitutionNames(institutionNames);
    }),
  );

const fetchDocsManifestEpic: Epic<ActionTypes, SetDocsManifestType> = action$ =>
  action$.pipe(
    ofType<ActionTypes, FetchDocsManifestType>(runFetchDocsManifestEffect.type),
    switchMap(async () => {
      const docsManifest = await getDocsManifest();
      return setDocsManifest(docsManifest);
    }),
    catchError(() => EMPTY),
  );

const fetchRequestingServicesEpic: Epic<ActionTypes, SetRequestingServicesType> = action$ =>
  action$.pipe(
    ofType<ActionTypes, FetchRequestingServicesType>(runFetchRequestingServicesEffect.type),
    switchMap(async () => {
      const requestingServices = await getRequestingServices();
      return setRequestingServices(requestingServices);
    }),
  );

const settingsEpic = combineEpics(
  fetchOrgDisplayDataEpic,
  fetchDocsManifestEpic,
  fetchInstitutionNamesEpic,
  fetchRequestingServicesEpic,
);

export default settingsEpic;
