import { ImageWithBytes } from '@annaliseai/api-specifications';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';
import randomId from 'helpers/random/randomId';

const { FAILED_TO_READ_IMAGE_FILE } = CustomErrors;

const getCxrImageWithBytesFromImageFile = async (file: File): Promise<ImageWithBytes> => {
  const fileReader = new FileReader();

  return new Promise<ImageWithBytes>((resolve, reject) => {
    fileReader.onload = event => {
      const result = event.target?.result;

      if (typeof result === 'string') {
        const image = new Image();

        image.onload = (event: Event) => {
          const data = result.split(',')[1];

          let loadedImage;
          if (event.composedPath && event.composedPath() instanceof Array && event.composedPath()[0]) {
            // We need composedPath to make testing viable, check mockImageOnload() for more details
            loadedImage = event.composedPath()[0] as HTMLImageElement;
          } else {
            // But composedPath is a recent API, it is not supported by all browsers
            // so we need to fall back to closure ref if necessary
            loadedImage = image;
          }

          const { height, width } = loadedImage;

          const imageWithBytes: ImageWithBytes = {
            sopClassUid: '1.2.840.10008.5.1.4.1.1.7',
            bitsAllocated: 8,
            bitsStored: 8,
            height,
            width,
            highBit: 7,
            samplesPerPixel: 1,
            imageInstanceUid: randomId(64),
            photometricInterpretation: 'MONOCHROME2',
            rescaleIntercept: 0,
            rescaleSlope: 1,
            data,
          };

          resolve(imageWithBytes);
        };

        image.src = result;
      } else {
        reject(new CustomError(FAILED_TO_READ_IMAGE_FILE));
      }
    };
    fileReader.readAsDataURL(file);
  });
};

export default getCxrImageWithBytesFromImageFile;
