import { ReactNode } from 'react';

export type TabId = string;

export type TabItem = {
  id: TabId;
  label: string;
  regularIcon: string;
  activeIcon: string;
  size: number;
  fill: string;
};

export type TabsProps = {
  initialActiveTab?: TabId;
  onChange: (id: TabId) => void;
  tabs: TabItem[];
};

export type TabItemWithIconProps = {
  icon: ReactNode;
  label: string;
};

export enum TabsId {
  VIEW_SAMPLE_STUDIES = 'view-sample-studies',
  UPLOAD_YOUR_OWN_IMAGES = 'upload-your-own-images',
}
