const createMapFromArrayValues = <
  A extends Array<{ uuid: string }>,
  V extends { uuid: string } = A extends Array<infer V> ? V : never,
  I extends string = V extends { uuid: infer I } ? I : never,
>(
  array: A,
): Record<I, V> => {
  return array.reduce(
    (acc, findingGroup) => ({
      ...acc,
      [findingGroup.uuid]: findingGroup,
    }),
    {} as Record<I, V>,
  );
};

export default createMapFromArrayValues;
