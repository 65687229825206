import React, { FC } from 'react';

type SortingHeaderProps = {
  isSorted: boolean;
  isSortedDesc?: boolean;
};

const SortingHeader: FC<SortingHeaderProps> = ({ isSorted, isSortedDesc }) => (
  <span>{isSorted ? (isSortedDesc ? ' ⬇️' : ' ⬆️') : ' ↕'}</span>
);

export default SortingHeader;
