import AnnaWindowingBar from '@annaliseai/windowing-bar';
import styled from 'styled-components';

export const WindowingContainer = styled.div`
  align-self: center;
  justify-self: right;
`;

const WindowingBar = styled(AnnaWindowingBar)`
  height: 22.5rem;
  margin-top: auto;
  margin-bottom: auto;
`;

export default WindowingBar;
