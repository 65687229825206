import dayjs from 'dayjs';

const convertUtcIsoDateStringToDateTime = (dateString: string, fallback = ''): string => {
  if (!dayjs(dateString).isValid()) {
    return fallback;
  }

  const date = dayjs(dateString).toDate();

  return `${date.getUTCFullYear()}-${
    date.getUTCMonth() + 1
  }-${date.getUTCDate()} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`;
};

export default convertUtcIsoDateStringToDateTime;
