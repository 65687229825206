import cloneDepp from 'lodash.clonedeep';

type CtbToolsStateFinding = {
  findingId: string;
  selectedWindow?: string;
  selectedView?: string;
  viewSliderPositions?: CtbToolsStateFindingViewSliderPosition[];
};

type CtbToolsStateFindingViewSliderPosition = {
  viewName: string;
  position: number;
};

type CtbToolsUserStateHelperProps = { state: CtbToolsStateFinding[]; findingId: string };

type SetViewSliderPositionProps = { view: string; position: number };

interface CtbToolsUserStateHelper {
  setWindow(window: string): CtbToolsUserStateHelper;

  setView(view: string): CtbToolsUserStateHelper;

  setViewSliderPosition({ view, position }: SetViewSliderPositionProps): CtbToolsUserStateHelper;

  getView(): string | undefined;

  getWindow(): string | undefined;

  getSliderPositionForCurrentView(): number | undefined;

  generateNewState(): CtbToolsStateFinding[];

  getFinding(): CtbToolsStateFinding;
}

const ctbToolsUserStateHelper = ({ state, findingId }: CtbToolsUserStateHelperProps): CtbToolsUserStateHelper => {
  // clone to prevent mutation so object stays clean
  const _state = cloneDepp(state);

  const getFinding = () => {
    return _state.find(finding => finding.findingId === findingId) as CtbToolsStateFinding;
  };

  // create finding if not found
  if (!getFinding()) {
    _state.push({
      findingId: findingId,
    });
  }

  const setWindow = (window: string) => {
    getFinding().selectedWindow = window;
    return ctbToolsUserStateHelper({ state: _state, findingId: findingId });
  };

  const setView = (view: string) => {
    getFinding().selectedView = view;
    return ctbToolsUserStateHelper({ state: _state, findingId: findingId });
  };

  const setViewSliderPosition = ({ view, position }: SetViewSliderPositionProps) => {
    const finding = getFinding();

    // set current selected view if this hasn't been done
    // the reason we need to do this is the fact the user might not have switched
    // views (so it didn't save) but moved other tools like the slider position
    if (!finding.selectedView) {
      finding.selectedView = view;
    }

    if (!finding.viewSliderPositions) {
      finding.viewSliderPositions = [
        {
          viewName: view,
          position: position,
        },
      ];
      return ctbToolsUserStateHelper({ state: _state, findingId: findingId });
    }

    const viewSliderPosition = finding.viewSliderPositions.find(
      viewSliderPosition => viewSliderPosition.viewName === view,
    );
    if (viewSliderPosition) {
      viewSliderPosition.position = position;
    } else {
      finding.viewSliderPositions.push({
        viewName: view,
        position: position,
      });
    }

    return ctbToolsUserStateHelper({ state: _state, findingId: findingId });
  };

  const getView = () => {
    const finding = getFinding();
    return finding?.selectedView;
  };

  const getWindow = () => {
    const finding = getFinding();
    return finding?.selectedWindow;
  };

  const generateNewState = () => {
    return _state;
  };

  const getSliderPositionForCurrentView = () => {
    const finding = getFinding();
    const viewSliderPosition = finding?.viewSliderPositions?.find(
      viewSliderPosition => viewSliderPosition.viewName === finding.selectedView,
    );
    return viewSliderPosition?.position;
  };

  return {
    setWindow,
    setView,
    setViewSliderPosition,
    getView,
    getWindow,
    getSliderPositionForCurrentView,
    generateNewState,
    getFinding,
  };
};
export default ctbToolsUserStateHelper;
export type {
  CtbToolsUserStateHelperProps,
  CtbToolsUserStateHelper,
  CtbToolsStateFinding,
  CtbToolsStateFindingViewSliderPosition,
};
