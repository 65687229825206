import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import getSavedFilters from 'components/SearchFilter/getSavedFilters';
import { buildPredefinedDates } from 'components/SearchFilter/SearchFilter.utils';
import { STUDY_SORT_DIRECTION, STUDY_SORTBY } from 'constants/studySortBy';
import CookieKeys from 'enums/CookieKeys';
import FetchStates from 'enums/FetchStates';
import Limits from 'enums/Limits';
import Pages from 'enums/Pages';
import { getUserSettings } from 'helpers/cookies/userSettingsHelper';
import DisplayStudyInformation from 'types/DisplayStudyInformation';

const { RESULTS_PER_PAGE_SELECTION, SEARCH_SELECTION } = CookieKeys;
const { INITIAL, SENDING, FAILED, FULFILLED } = FetchStates;
const { PAGE_1 } = Pages;
const { RESULTS_10 } = Limits;
const { STUDY_DATE } = STUDY_SORTBY;
const { DESC } = STUDY_SORT_DIRECTION;

export type Direction = 'asc' | 'desc';

export type StudyListState = {
  fetchState: FetchStates;
  studies?: DisplayStudyInformation[];
  totalRecords?: number;
  lastFetched?: Date;
  query: {
    createdAfter?: string;
    createdUntil?: string;
    dateType?: string;
    direction?: Direction;
    limit?: number;
    page?: number;
    institutionName?: string;
    requestingService?: string;
    search?: string;
    sortBy?: string;
    studyType?: string;
  };
  // Used to retain search/filter form state between screens
  canSearchForm?: boolean;
  canResetForm?: boolean;
  canSaveFilters?: boolean;
};

type FetchedStudies = {
  studies: StudyListState['studies'];
  totalRecords?: StudyListState['totalRecords'];
};

const resultsPerPage = getUserSettings(RESULTS_PER_PAGE_SELECTION);
const searchSelection = getUserSettings(SEARCH_SELECTION);

export const VALID_PARAMS = [
  'createdAfter',
  'createdUntil',
  'direction',
  'limit',
  'page',
  'requestingService',
  'search',
  'sortBy',
  'studyType',
];

// casting as number since Limits conflicts with UserSettingType
export const LIMIT_INIT: Limits = (resultsPerPage?.limit as number) || RESULTS_10;
export const PAGE_INIT = PAGE_1;
export const CAN_RESET_INIT = !!searchSelection;

export const initialState: StudyListState = {
  fetchState: INITIAL,
  query: {
    direction: DESC,
    limit: LIMIT_INIT,
    page: PAGE_INIT,
    sortBy: STUDY_DATE,
    ...searchSelection,
    ...buildPredefinedDates(getSavedFilters().dateType),
    ...getSavedFilters(),
  },
  canSearchForm: false,
  canResetForm: CAN_RESET_INIT,
  canSaveFilters: false,
};

const updateQuery = (oldQuery: StudyListState['query'], newQuery: StudyListState['query']) => ({
  query: {
    ...oldQuery,
    ...newQuery,
  },
});

export const studyListSlice = createSlice({
  name: 'studyList',
  initialState,
  reducers: {
    fetch: (state: StudyListState, { payload }: PayloadAction<StudyListState['query'] | undefined>) => ({
      ...state,
      payload,
      fetchState: SENDING,
    }),
    fetchFailed: state => ({ ...state, fetchState: FAILED }),
    setStudies: (state, { payload }: PayloadAction<FetchedStudies>) => ({
      ...state,
      fetchState: FULFILLED,
      studies: payload.studies,
      totalRecords: payload.totalRecords,
      lastFetched: new Date(),
    }),
    resetStudies: _ => ({ ...initialState }),
    setQuery: (state, { payload }: PayloadAction<StudyListState['query']>) => {
      return {
        ...state,
        ...updateQuery(state.query, payload),
      };
    },
    resetQuery: state => {
      const savedFilters = localStorage.getItem('savedFilters');
      if (savedFilters) {
        const parsedFilters = JSON.parse(savedFilters);
        buildPredefinedDates(parsedFilters.dateType);
        return {
          ...state,
          query: {
            ...initialState.query,
            ...parsedFilters,
            ...buildPredefinedDates(parsedFilters.dateType),
            limit: state.query.limit,
          },
        };
      } else {
        return {
          ...state,
          query: { ...initialState.query, limit: state.query.limit },
        };
      }
    },

    setCanSearchForm: (state, { payload }: PayloadAction<StudyListState['canSearchForm']>) => ({
      ...state,
      canSearchForm: payload,
    }),
    setCanResetForm: (state, { payload }: PayloadAction<StudyListState['canResetForm']>) => ({
      ...state,
      canResetForm: payload,
    }),
    setCanSaveFilters: (state, { payload }: PayloadAction<StudyListState['canSaveFilters']>) => ({
      ...state,
      canSaveFilters: payload,
    }),
  },
});

export const studyListActions = studyListSlice.actions;
export type FetchType = ReturnType<typeof studyListActions.fetch>;
export type FetchFailedType = ReturnType<typeof studyListActions.fetchFailed>;
export type SetStudiesType = ReturnType<typeof studyListActions.setStudies>;
export type ResetStudiesType = ReturnType<typeof studyListActions.resetStudies>;
export type SetQueryType = ReturnType<typeof studyListActions.setQuery>;
export type ResetQueryType = ReturnType<typeof studyListActions.resetQuery>;
export type SetCanSearchFormType = ReturnType<typeof studyListActions.setCanSearchForm>;
export type SetCanResetFormType = ReturnType<typeof studyListActions.setCanResetForm>;
export type StudyListActionTypes = SetStudiesType | ResetStudiesType | FetchType | FetchFailedType;
