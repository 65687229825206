import CornerstoneLoaderPrefixes from 'enums/CornerstoneLoaderPrefixes';
import ImageFormats from 'enums/ImageFormats';

const { JP2, JPEG, PNG, UNKNOWN } = ImageFormats;
const { JP2: JP2PREFIX, WEB } = CornerstoneLoaderPrefixes;

export const imageLoaderPrefixesMapping: Record<ImageFormats, CornerstoneLoaderPrefixes> = {
  [JPEG]: WEB,
  [PNG]: WEB,
  [JP2]: JP2PREFIX,
  [UNKNOWN]: WEB,
};

export const imageLoaderPrefixSeperator = '://';
