import ConfidenceIndicator, { ConfidenceIndicatorProps } from '@annaliseai/confidence-indicator';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import selectActiveFinding from 'selectors/viewer/selectActiveFinding';
import Finding from 'types/study/Finding';
import IndicatorContainer from './ConfidenceBar.styles';
import staticIndicatorProps from './constants';

const ConfidenceBar: FC = () => {
  const activeFinding = useSelector(selectActiveFinding);

  if (!activeFinding || (activeFinding && activeFinding.isParent)) {
    return <IndicatorContainer />;
  }

  const { confidence, predictionProbability } = activeFinding as Finding;

  const indicatorProps: ConfidenceIndicatorProps = {
    ...staticIndicatorProps,
    scaledError: confidence,
    scaledScore: predictionProbability,
  };
  return (
    <IndicatorContainer>
      <ConfidenceIndicator {...indicatorProps} />
    </IndicatorContainer>
  );
};

export default ConfidenceBar;
