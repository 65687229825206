import ToolNames from 'enums/ToolNames';

const { PAN, WWWC, ZOOM } = ToolNames;

const imageToolCursorMapping: Partial<Record<ToolNames, string>> = {
  [PAN]: '/static/icons/pan-cursor.svg',
  [WWWC]: '/static/icons/wwwc-cursor.svg',
  [ZOOM]: '/static/icons/zoom-cursor.svg',
};

export default imageToolCursorMapping;
