import styled from 'styled-components';

const TextField = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 0px;
  border-radius: 0.5em;
  padding: 0 1.5em;
  color: #4a5568;
  outline: 0;
  width: 100%;
  height: 100%;

  &:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }
`;

export default TextField;
