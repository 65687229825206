import { AnnaliseHeaderType } from '@annaliseai/api-specifications';
import { EventSourceMessage, fetchEventSource } from '@microsoft/fetch-event-source';
import retry from 'p-retry-cjs';
import errorsMapping from 'constants/errorsMapping';
import CustomErrors from 'enums/CustomErrors';
import tokenHelpers from 'helpers/cookies/tokenHelper';
import decodeAuthorizationPayload from 'helpers/decodeAuthorizationPayload';
import keycloak, { TOKEN_CHECK_AHEAD_EXPIRY } from 'keycloak';

type OnMessageCallbackType = (message: EventSourceMessage) => void;
type OnErrorCallbackType = (error: unknown) => void;
type OnCloseCallbackType = () => void;

// Options defs here: https://github.com/tim-kos/node-retry#retrytimeoutsoptions
const factor = 1.3; // Multiplier applied to subsequent, consecutive retries
export const maxTimeout = 10_000; // Maximum delay between two consecutive calls
const minTimeout = 500; // Initial delay before first retry attempt
const randomize = true; // Applies slight randomness to delay
const retries = 99999; // Maximum number of retries before ceasing further attempts (no option for infinite retries, hence large number)

const { REAL_TIME_UPDATES_ERROR } = CustomErrors;
const REAL_TIME_UPDATES_ERROR_TITLE = errorsMapping[REAL_TIME_UPDATES_ERROR].title;

/**
 * Initialise an EventSource subscription to receive SSE notification(s) based on endpoint
 * @param controller Abort Controller used to manage cleanup of SSE when parent component destroyed
 * @param endpoint endpoint used to subscribe to specific SSE source
 * @param retryOnClose indicate whether to attempt to reconnect when connection is closed
 * @param onMessageCallback callback function used to perform action when message event occurs
 * @param onErrorCallback callback to perform action when error event occurs
 * @param onCloseCallback callback to perform action when connection is closed
 * @returns Promise AbortController
 */
const getServerSentEvents = async (
  controller: AbortController,
  endpoint: string,
  retryOnClose = false,
  onMessageCallback?: OnMessageCallbackType,
  onErrorCallback?: OnErrorCallbackType,
  onCloseCallback?: OnCloseCallbackType,
): Promise<void> => {
  const { idToken } = tokenHelpers.retrieveTokens();
  const { aud: audienceToken } = decodeAuthorizationPayload(idToken);

  const retryOptions = {
    factor,
    maxTimeout,
    minTimeout,
    randomize,
    retries,
    signal: controller.signal,
  };

  // Init SSE
  try {
    await retry(async () => {
      // Update access token if it expires in next TOKEN_CHECK_AHEAD_EXPIRY seconds
      await keycloak.doUpdate(TOKEN_CHECK_AHEAD_EXPIRY);

      await fetchEventSource(endpoint, {
        method: 'GET',
        headers: {
          [AnnaliseHeaderType.APP_VERSION]: audienceToken,
          // Auth token needs to be retrieved directly here so that the latest token is always sent in the request headers upon retry
          Authorization: `Bearer ${keycloak.getToken()}`,
        },
        signal: controller.signal,
        onopen: async response => {
          if (response.status === 401) {
            // Update access token if it expires in next TOKEN_CHECK_AHEAD_EXPIRY seconds
            await keycloak.doUpdate(TOKEN_CHECK_AHEAD_EXPIRY);
          }

          if (!response.ok) {
            onErrorCallback?.(response);

            // Throwing an error will trigger the backoff to initialise/retry
            throw new Error(REAL_TIME_UPDATES_ERROR_TITLE);
          }
        },
        onmessage: message => onMessageCallback?.(message),
        onerror: error => {
          onErrorCallback?.(error);

          // Throwing an error will trigger the backoff to initialise/retry
          throw new Error(REAL_TIME_UPDATES_ERROR_TITLE);
        },
        onclose: () => {
          onCloseCallback?.();

          // Throwing an error will trigger the backoff to initialise/retry
          if (retryOnClose) throw new Error(REAL_TIME_UPDATES_ERROR_TITLE);
        },
      });
    }, retryOptions);
  } catch {
    // Error handling managed internally to p-retry-cjs & @microsoft/fetch-event-source packages
    // Here we're catching abortion via the abort controller passed into getServerSentEvents
    // E.g. when cleaning up on component unmount
    console.info('SSE connection aborted.');
  }
};

export default getServerSentEvents;
