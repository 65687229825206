import { PostImageStatusReq, PostImageStatusRes, Status } from '@annaliseai/api-specifications';
import log from 'loglevel';
import client from 'api/client';
import endpoints from 'api/endpoints';
import { POLLING } from 'constants/durations';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';

const { IMAGE_STATUS_TIMEOUT } = CustomErrors;
const { COMPLETED_ERROR, COMPLETED } = Status;
const { PERIOD, TIMEOUT } = POLLING;

interface PostImageStatusReqWithAccessionNumber extends PostImageStatusReq {
  accessionNumber: string;
}

const postImagesStatus = async (
  accessionNumber: string,
  imageIds: string[],
  period = PERIOD,
  timeout = TIMEOUT,
): Promise<PostImageStatusRes> =>
  new Promise((resolve, reject) => {
    if (period <= 0) return reject(new CustomError(IMAGE_STATUS_TIMEOUT));
    let intervalCount = 0;
    const intervalNumber = setInterval(async () => {
      intervalCount += 1;
      if (intervalCount * period > timeout) {
        clearInterval(intervalNumber);
        return reject(new CustomError(IMAGE_STATUS_TIMEOUT));
      }

      const { imagesStatus } = endpoints.v1;
      const path = imagesStatus.getPath();

      const body: PostImageStatusReqWithAccessionNumber = {
        imageInstanceUids: imageIds,
        accessionNumber,
      };

      try {
        const { data } = await client.post(path, body);
        const hasData = data?.images?.length > 0;
        if (!hasData) return;
        for (const image of data.images) {
          if (image.targets?.jpeg?.status === COMPLETED_ERROR) {
            log.error('Image COMPLETED_ERROR', image.targets.jpeg.vision.error);
            clearInterval(intervalNumber);
            return reject(new CustomError(IMAGE_STATUS_TIMEOUT));
          }
          if (image.targets?.jpeg?.status !== COMPLETED) return;
        }
        log.debug('received postImagesStatus response=', data);
        clearInterval(intervalNumber);
        return resolve(data);
      } catch (error) {
        clearInterval(intervalNumber);
        return reject(error);
      }
    }, period);
  });

export default postImagesStatus;
