import { CtbFinding } from 'imageQueue/types';
import { SharedLocalisationItem } from './getGroupedClassifications';

const getSharedLocalisationParents = (localisationClassifications: SharedLocalisationItem[]): CtbFinding[] => {
  return localisationClassifications
    .filter(({ children }) => children.length)
    .map((sharedLocalisation: SharedLocalisationItem) => {
      const { children, label, labelName } = sharedLocalisation;
      const [priorityChild] = [...children].sort((a, b) => a.displayOrder - b.displayOrder);
      const [groupId] = children.map(({ groupId }) => groupId).sort((a, b) => a - b);

      return {
        ...priorityChild,
        label,
        labelName,
        groupId,
        isSharedLocalisationParent: true,
      };
    });
};

export default getSharedLocalisationParents;
