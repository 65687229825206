import { replace } from 'connected-react-router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import configuration from 'configuration';
import Routes from 'enums/Routes';
import { isLoggedIn } from 'helpers/limitationHelper';

const { LIST, HOME } = Routes;

const useRedirectIfLoggedIn: () => void = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn()) {
      configuration.isDemo && dispatch(replace(LIST));
      configuration.isViewer && dispatch(replace(HOME));
    }
  }, [dispatch]);
};

export default useRedirectIfLoggedIn;
