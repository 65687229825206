enum DicomTags {
  TRANSFER_SYNTAX = 'x00020010',
  STUDY_INSTANCE_UID = 'x0020000d',
  SOP_CLASS_UID = 'x00080016',
  BITS_ALLOCATED = 'x00280100',
  BITS_STORED = 'x00280101',
  HIGH_BIT = 'x00280102',
  MODALITY = 'x00080060',
  PHOTOMETRIC_INTERPRETATION = 'x00280004',
  SAMPLES_PER_PIXEL = 'x00280002',
}

export default DicomTags;
