import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HelpButton from 'components/Buttons/HelpButton/Help';
import LogoNav from 'components/Nav/LogoNav/LogoNav';
import UserName from 'components/Nav/UserName/UserName';
import configuration from 'configuration';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import ScreenVariant from 'enums/ScreenVariant';
import tokenHelpers from 'helpers/cookies/tokenHelper';
import decodeAuthorizationPayload from 'helpers/decodeAuthorizationPayload';
import showLinks from 'helpers/showLinks';
import selectHeaderNotification from 'selectors/selectNotification';
import {
  ActionsContainer,
  Container,
  HeaderNotification,
  LogoContainer,
  NavigationContainer,
  UsernameContainer,
} from './Header.styles';

const { HEADER } = ReactTestingLibraryDataProperties;

const { ADMIN, DEMO, RESULTS, VIEWER } = ScreenVariant;

type HeaderProps = {
  screenVariant?: ScreenVariant;
  children?: ReactNode;
};

export type HeaderContainerProps = {
  showBorderBottom?: boolean;
};

const Header = ({ children, screenVariant }: HeaderProps): ReactElement => {
  let currentScreenVariant;
  const { isDemo } = configuration;
  const headerNotification = useSelector(selectHeaderNotification);
  const [userName, setUserName] = useState<string>('');
  const [hasName, setHasName] = useState<boolean>(false);

  const getUserName = () => {
    const { idToken } = tokenHelpers.retrieveTokens();
    const decodedIdToken = decodeAuthorizationPayload(idToken);
    const { given_name: givenName, family_name: familyName } = decodedIdToken;
    const hasName = !!givenName || !!familyName;
    setUserName(`${givenName} ${familyName}`);
    setHasName(hasName);
  };

  useEffect(() => {
    getUserName();
  }, []);

  if (screenVariant === undefined) {
    currentScreenVariant = isDemo ? DEMO : VIEWER;
  } else {
    currentScreenVariant = screenVariant;
  }
  const showBorderBottom = screenVariant === RESULTS || screenVariant === ADMIN;

  return (
    <Container data-testid={HEADER} showBorderBottom={showBorderBottom}>
      <NavigationContainer>
        <LogoContainer>
          <LogoNav screenVariant={currentScreenVariant} shouldRedirect={showLinks()} />
        </LogoContainer>
        {!isDemo && hasName && (
          <UsernameContainer>
            <UserName userName={userName}></UserName>
          </UsernameContainer>
        )}
      </NavigationContainer>
      {headerNotification && <HeaderNotification>{headerNotification}</HeaderNotification>}
      <ActionsContainer>
        <HelpButton />
        {children}
      </ActionsContainer>
    </Container>
  );
};

export default Header;
