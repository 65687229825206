import { SegmentLateralityType } from '@annaliseai/api-specifications';
import { StudyType } from '@annaliseai/api-specifications';
import FindingPanelHeader from '@annaliseai/finding-panel-header';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { LoadingIndicator } from 'components';
import subHeadingPhrases from 'constants/findingPanelHeader';
import { APP } from 'constants/sizes';
import selectActiveFindingSegment from 'selectors/viewer/cxr/selectActiveFindingSegment';
import selectActiveFindingViews from 'selectors/viewer/cxr/selectActiveFindingViews';
import selectActiveImage from 'selectors/viewer/cxr/selectActiveImage';
import selectActiveImageMetadata from 'selectors/viewer/cxr/selectActiveImageMetadata';
import selectCxrViewer from 'selectors/viewer/cxr/selectCxrViewer';
import selectDefaultThumbnail from 'selectors/viewer/cxr/selectDefaultThumbnail';
import selectActiveFinding from 'selectors/viewer/selectActiveFinding';
import selectImagesMap from 'selectors/viewer/selectImagesMap';
import selectLocalisationsMap from 'selectors/viewer/selectLocalisationsMap';
import { cxrViewerActions } from 'slices/cxrViewerSlice';
import CxrImage from './CxrImage';

const { FINDING_PANEL_SUBHEADING_HEIGHT, VIEWER_HORIZONTAL_PADDING } = APP;
const { NONE } = SegmentLateralityType;
const { CXR } = StudyType;

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
  place-items: center;
`;

const FindingPanelContainer = styled.div`
  width: fit-content;

  h2 {
    min-height: ${FINDING_PANEL_SUBHEADING_HEIGHT}rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const ImageContainerPreLoad = styled(ImageContainer)`
  position: relative;
  margin-left: ${VIEWER_HORIZONTAL_PADDING}rem;
  margin-top: 2.5rem;
`;

const CxrStudyResult: FC = () => {
  const dispatch = useDispatch();
  const { activeView } = useSelector(selectCxrViewer);
  const localisationsMap = useSelector(selectLocalisationsMap);
  const imagesMap = useSelector(selectImagesMap);
  const activeFinding = useSelector(selectActiveFinding);
  const activeImage = useSelector(selectActiveImage);
  const activeImageMetadata = useSelector(selectActiveImageMetadata);
  const findingViews = useSelector(selectActiveFindingViews) || [];
  const activeFindingSegment = useSelector(selectActiveFindingSegment);
  const defaultThumbnail = useSelector(selectDefaultThumbnail);

  const [viewTypeIndex, setViewTypeIndex] = useState(0);

  // Update the state to include the active view so we can reference the data for the finding panel header
  useEffect(() => {
    findingViews[viewTypeIndex]?.uuid && dispatch(cxrViewerActions.setActiveView(findingViews[viewTypeIndex].uuid));
    // Reason: only want to act on viewTypeIndex updates so we can active the view in state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewTypeIndex]);

  // When the actively displayed image is changed, set the index of the thumbnail to match the index of the active images in the finding views list
  useEffect(() => {
    const activeImageIndex = findingViews.findIndex(({ baseImageUuid }) => baseImageUuid === activeImage?.uuid);
    activeImageIndex >= 0 && setViewTypeIndex(activeImageIndex);
    // Reason: only want to act on activeImage.uuid updates so we can match with the corresponding finding view
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImage?.uuid]);

  if (Object.keys(imagesMap).length < 1) {
    return (
      <ImageContainerPreLoad>
        <LoadingIndicator />
      </ImageContainerPreLoad>
    );
  }

  const activeViewData = findingViews?.find(({ uuid }) => uuid === activeView);
  const localisation = activeViewData?.localisationUuid && localisationsMap[activeViewData.localisationUuid];
  const hasLocalisation = !(
    (localisation?.type === 'LATERALITY' && localisation?.laterality === NONE) ||
    !localisation
  );

  let thumbnails: string[] = [];
  if (activeViewData) {
    thumbnails = findingViews?.map(view => imagesMap[view.thumbnailImageUuid].url);
  } else if (defaultThumbnail) {
    thumbnails = [`${defaultThumbnail?.url}`];
  }

  return (
    <Container>
      <FindingPanelContainer>
        <FindingPanelHeader
          activeImageIndex={viewTypeIndex}
          modality={CXR}
          hasLocalisation={hasLocalisation}
          labelName={`${activeFinding?.name || ''}`}
          onImageChange={setViewTypeIndex}
          laterality={localisation?.type === 'LATERALITY' ? localisation.laterality : undefined}
          headingFontSize={1.125}
          subheadingFontSize={0.75}
          subHeadingPhrases={subHeadingPhrases}
          thumbnailImages={thumbnails}
        />
      </FindingPanelContainer>
      <ImageContainer>
        {activeImage && activeImageMetadata && (
          <CxrImage
            sourceUrl={activeImage.url}
            sourceMetadata={activeImageMetadata}
            segment={{
              url: activeFindingSegment,
              laterality: localisation?.type === 'LATERALITY' ? localisation?.laterality : undefined,
            }}
          />
        )}
      </ImageContainer>
    </Container>
  );
};

export default CxrStudyResult;
