import React from 'react';
import {
  ServiceCardHeader,
  ServiceCardSubHeader,
  ServiceHeaderContainer,
  StatusIndicatorContainer,
  SubServiceCardWrapper,
  SystemStatusContainer,
} from './SubServiceCard.styles';

type ServiceCardProps = {
  name: string;
  version: string;
  status: string;
  statusMessage: string;
};

const ServiceCard = (props: ServiceCardProps): JSX.Element => {
  const { name, version, statusMessage } = props;
  const systemStatusMessage = statusMessage === '' ? 'Running' : statusMessage;
  return (
    <SubServiceCardWrapper>
      <ServiceHeaderContainer>
        <ServiceCardHeader>{name}</ServiceCardHeader>
        <ServiceCardSubHeader>{version}</ServiceCardSubHeader>
      </ServiceHeaderContainer>
      <SystemStatusContainer>
        <StatusIndicatorContainer>
          <p>{systemStatusMessage}</p>
        </StatusIndicatorContainer>
      </SystemStatusContainer>
    </SubServiceCardWrapper>
  );
};

export default ServiceCard;
