import { StudyType } from '@annaliseai/api-specifications';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SidebarProps } from 'components/sidebar/Sidebar';
import IRRELEVANT_FINDINGS_TEXT from 'constants/findingsMenu';
import checkIsImageToolButtonActive from 'helpers/checkIsImageToolButtonActive';
import UuidMapper from 'helpers/UuidMapper';
import selectActiveFinding from 'selectors/viewer/selectActiveFinding';
import { ctbViewerActions } from 'slices/ctbViewerSlice';
import { cxrViewerActions, selectActiveControl } from 'slices/cxrViewerSlice';
import { findingsActions } from 'slices/findingsSlice';
import { RootState } from 'slices/RootState';
import { viewerActions } from 'slices/viewerSlice';
import { DisplayFindingGroup, FindingGroups } from 'types/finding/FindingGroup';
import Finding, { Parent } from 'types/study/Finding';
import { isParentFindingItem } from 'types/viewer/FindingsMenu';

const { CXR } = StudyType;

const useSidebarFindings = (): Pick<
  SidebarProps,
  'findingGroups' | 'findingProps' | 'findingGroupProps' | 'shouldOnMouseOverInteract' | 'findingCount'
> => {
  const dispatch = useDispatch();
  const { findingsMap, findingsMenu, findingItemsMap, findingsGroupsMap, study, parentsMap } = useSelector(
    ({
      findingsMenu: { findingsGroupsMap, findingItemsMap, findingsMenu },
      findings: { findingsMap },
      parents: { parentsMap },
      localisations,
      study: { study },
    }: RootState) => ({
      findingsMenu,
      findingsMap,
      parentsMap,
      localisations,
      findingsGroupsMap,
      findingItemsMap,
      study,
    }),
  );

  const findingGroups = useMemo<FindingGroups | null>(() => {
    const Mapper = new UuidMapper();

    const groups: DisplayFindingGroup[] = Mapper.from(findingsMenu?.groupsUuids)
      .mapTo(findingsGroupsMap)
      .replaceUuidsWith(findingItemsMap, 'findingItemsUuids')
      .result();

    return (
      findingsMenu &&
      findingItemsMap && {
        relevantFindingGroups: groups
          .filter(({ name }: { name: string }) => name !== IRRELEVANT_FINDINGS_TEXT)
          .map((group: DisplayFindingGroup) => {
            let findingCount = 0;
            const findings = group.findingItemsUuids.map(item => {
              if (isParentFindingItem(item)) {
                const childFindings = Mapper.from(item.findings.map(item => item.findingUuid))
                  .mapTo(findingsMap)
                  .result() as Finding[];
                const parent = Mapper.from([item.parentFindingId]).mapTo(parentsMap).result()[0];
                findingCount += item.findings.length;
                return {
                  ...parent,
                  findings: childFindings,
                  isParent: true,
                };
              }
              findingCount++;
              return {
                ...(Mapper.from([item.findingUuid]).mapTo(findingsMap).result()[0] as Finding),
                isParent: false,
              };
            });
            return {
              ...group,
              findingCount,
              findings,
            };
          }),
        irrelevantFindingGroup: groups
          .filter(({ name }: { name: string }) => name === IRRELEVANT_FINDINGS_TEXT)
          .map((group: DisplayFindingGroup) => {
            const findingCount = group.findingItemsUuids.length;
            return {
              ...group,
              findingCount,
              findings: Mapper.from(group.findingItemsUuids.map(item => item.findingUuid))
                .mapTo(findingsMap)
                .result() as Finding[],
            };
          })[0],
      }
    );
  }, [findingItemsMap, findingsGroupsMap, findingsMap, parentsMap, findingsMenu]);

  const findingCount = findingGroups?.relevantFindingGroups.reduce((sum, group) => sum + group.findingCount, 0) || 0;
  const activeFinding = useSelector(selectActiveFinding);
  const displayFindingId = activeFinding?.uuid;
  const activeControl = useSelector(selectActiveControl);
  const isToolActive = activeControl !== null && activeControl !== 'reset';
  const { setActiveFinding } = study?.type === CXR ? cxrViewerActions : ctbViewerActions;

  const setFinding = useCallback(
    (findingUuid: Finding['uuid'] | Parent['uuid']) => {
      const { setIsLocalisationToggleOn } = viewerActions;
      const isFindingActive = displayFindingId === findingUuid;

      dispatch(setActiveFinding(findingUuid as Finding['uuid']));

      if (!isFindingActive) {
        dispatch(setIsLocalisationToggleOn(true));
      }

      // this let us know that user has interacted with a study and selected a finding
      dispatch(findingsActions.setHasFindingBeenSelectedForStudy(true));
    },
    [dispatch, displayFindingId, setActiveFinding],
  );

  const shouldOnMouseOverInteract = useCallback(
    (event: SyntheticEvent<EventTarget, MouseEvent>) =>
      !(checkIsImageToolButtonActive(event.nativeEvent.buttons) && isToolActive),
    [isToolActive],
  );

  return useMemo(
    () => ({
      findingCount,
      findingGroups,
      findingProps: {
        setFinding,
      },
      findingGroupProps: {
        activeFindingId: displayFindingId,
      },
      shouldOnMouseOverInteract,
    }),
    [displayFindingId, findingCount, findingGroups, setFinding, shouldOnMouseOverInteract],
  );
};

export default useSidebarFindings;
