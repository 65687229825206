import { HttpSuccessResponse, ImageWithBytes, PostImageUploadReq } from '@annaliseai/api-specifications';
import log from 'loglevel';
import client from 'api/client';
import endpoints from 'api/endpoints';
import randomId from 'helpers/random/randomId';

const postImagesUpload = async (
  accessionNumber: string,
  studyInstanceUid: string,
  images: ImageWithBytes[],
): Promise<HttpSuccessResponse> => {
  const body: PostImageUploadReq = {
    study: {
      accessionNumber,
      studyInstanceUid,
      description: 'X-RAY CHEST',
      patientId: randomId(),
      patientAge: '50Y',
    },
    series: {
      seriesInstanceUid: randomId(64),
      seriesNumber: 1,
    },
    scan: {},
    images,
  };

  const { imagesUpload } = endpoints.v1;
  const path = imagesUpload.getPath();

  const { data } = await client.post(path, body);
  log.debug('received postImagesUpload response=', data);
  return data;
};

export default postImagesUpload;
