import axios from 'axios';
import log from 'loglevel';
import { combineEpics, Epic } from 'redux-observable';
import { merge, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import getErrorInfo from 'helpers/error/getErrorInfo';
import { ActionTypes } from 'slices/ActionTypes';
import { errorActions } from 'slices/errorSlice';
import authEpic from './authEpic';
import errorEpic from './errorEpic';
import { fileEpic } from './fileEpic';
import infoEpic from './infoEpic';
import settingsEpic from './settingsEpic';
import studyEpic from './study';
import studyListEpic from './studyListEpic';
import uploadEpic from './uploadEpic';

const rootEpic: Epic<ActionTypes> = (action$, store$, dependencies) =>
  combineEpics(
    authEpic,
    errorEpic,
    infoEpic,
    fileEpic,
    settingsEpic,
    studyEpic,
    studyListEpic,
    uploadEpic,
  )(action$, store$, dependencies).pipe(
    catchError((error, caught) => {
      // when switching between studies we cancel API requests that don't have accessionNumber
      // this is intended behaviours we suppress the cancellation error
      if (axios.isCancel(error)) {
        return merge(of(errorActions.resetError()), caught);
      }

      const { displayError, errorMetadata } = getErrorInfo(error);

      log.error(errorMetadata, error);

      return merge(of(errorActions.setError(displayError)), caught);
    }),
  );

export default rootEpic;
