import React, { FC } from 'react';
import styled, { DataAttributes } from 'styled-components';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import useStrings from 'hooks/useStrings';

const { SUPPORT } = ReactTestingLibraryDataProperties;

type SupportProps = {
  message: string;
  errorId: string;
};

const Paragraph = styled.p.attrs<DataAttributes>({
  'data-testid': SUPPORT,
})`
  text-align: center;
  margin: 0.25em 0;
`;

const Support: FC<SupportProps> = ({ message, errorId }) => {
  const { EMAIL_SUPPORT, MAIL_TO_HREF } = useStrings();
  return (
    <Paragraph>
      {message} please contact support at <a href={`${MAIL_TO_HREF} for ${errorId}`}>{EMAIL_SUPPORT}</a>.
    </Paragraph>
  );
};

export default Support;
