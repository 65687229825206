import styled from 'styled-components';

const Table = styled.table`
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  margin: auto;
`;

export default Table;
