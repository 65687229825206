import React, { FC, useEffect, useRef } from 'react';
import useIsMounted from 'react-is-mounted-hook';
import styled from 'styled-components';
import CornerstoneLoaderPrefixes from 'enums/CornerstoneLoaderPrefixes';
import DropzoneStates from 'enums/DropzoneStates';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { displayImage, removeImage } from 'helpers/cornerstone/cornerstoneHelper';
import { createImageId } from 'helpers/cornerstone/imageIdHelper';
import { loadImage } from 'helpers/cornerstone/loadImageHelper';
import { isDropzoneStateLoaded } from 'helpers/dropzoneStateHelper';
import { SetDropzoneState } from 'types/SetDropzoneState';

const { LOADING, LOADED } = DropzoneStates;
const { DICOM_FILE_VIEWER } = ReactTestingLibraryDataProperties;
const { DICOMFILE } = CornerstoneLoaderPrefixes;

const Container = styled.div`
  height: 4.2rem;
  width: 4.2rem;
  position: absolute;
  left: 1rem;
  object-fit: cover;

  canvas {
    border-radius: var(--dropzone--border-radius);
  }
`;

interface DicomFileViewerComponentProps {
  index: number;
  file: File;
  dropzoneState: DropzoneStates;
  setDropzoneState: SetDropzoneState;
}

const DicomFileViewer: FC<DicomFileViewerComponentProps> = ({ index, file, dropzoneState, setDropzoneState }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isMounted = useIsMounted();

  useEffect(() => {
    const element = ref?.current;

    if (file && element) {
      (async () => {
        setDropzoneState(LOADING);

        const image = await loadImage(createImageId(DICOMFILE, `${index}`));
        if (!isMounted()) {
          return;
        }

        displayImage(element, image);

        setDropzoneState(LOADED);
      })();
    }
  }, [ref, file, index, setDropzoneState, isMounted]);

  useEffect(() => {
    if (!isDropzoneStateLoaded(dropzoneState) && ref?.current) {
      removeImage(ref.current);
    }
  }, [ref, dropzoneState]);

  useEffect(() => {
    const element = ref.current;

    if (element) {
      return () => {
        removeImage(element);
      };
    }
  }, []);

  return <Container data-testid={DICOM_FILE_VIEWER} ref={ref} />;
};

export default DicomFileViewer;
