import FindingInteractionTypes from 'enums/FindingInteractionTypes';

const { HOVER } = FindingInteractionTypes;

// Finding interaction type feature toggle
const FINDING_INTERACTION_TYPE = HOVER;

// Use a function instead of a constant to mock in Jest
const getFindingInteractionType = (): FindingInteractionTypes => FINDING_INTERACTION_TYPE;

export default getFindingInteractionType;
