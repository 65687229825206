import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import styled from 'styled-components';
import { BREAK_POINTS } from 'constants/sizes';
import { TabItemWithIconProps } from './types';

const { GRAY } = colors;
const { SAMPLE_SCREEN_LARGE_MIN_WIDTH } = BREAK_POINTS;

const Container = styled.div`
  display: flex;
  color: ${GRAY};
  > svg {
    fill: ${GRAY};
  }
`;

const Text = styled.h3`
  font-size: 0.875rem;
  margin-left: 0.875rem;
  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    font-size: 1.5rem;
  }
`;

const TabItemWithIcon: FC<TabItemWithIconProps> = ({ icon, label }) => {
  return (
    <Container>
      {icon}
      <Text>{label}</Text>
    </Container>
  );
};

export default TabItemWithIcon;
