import selectActiveFindingViews from 'selectors/viewer/cxr/selectActiveFindingViews';
import selectActiveFinding from 'selectors/viewer/selectActiveFinding';
import selectImagesMap from 'selectors/viewer/selectImagesMap';
import selectLocalisationsMap from 'selectors/viewer/selectLocalisationsMap';
import { RootState } from 'slices/RootState';
import Finding from 'types/study/Finding';
import selectCxrViewer from './selectCxrViewer';

const selectActiveFindingSegment = (state: RootState, findingUuid?: Finding['uuid'] | null): string | undefined => {
  const localisationsMap = selectLocalisationsMap(state);
  const imagesMap = selectImagesMap(state);
  const activeFinding = selectActiveFinding(state);

  const activeFindingUuid = findingUuid ? findingUuid : activeFinding?.uuid;

  let activeFindingSegment;

  if (activeFindingUuid) {
    const { activeView } = selectCxrViewer(state);
    const findingViews = selectActiveFindingViews(state) || [];

    const activeViewData = findingViews?.find(({ uuid }) => uuid === activeView);
    const localisation = activeViewData?.localisationUuid && localisationsMap[activeViewData.localisationUuid];

    activeFindingSegment = localisation?.type === 'SEGMENT' ? imagesMap[localisation.imageUuid].url : undefined;
  }

  return activeFindingSegment;
};

export default selectActiveFindingSegment;
