import cornerstone from 'cornerstone-core';
import { CornerstoneEnabledElement } from 'types/cornerstone/CornerstoneEnabledElement';
import { CornerstoneImage } from 'types/cornerstone/CornerstoneImage';
import { CornerstoneViewport } from 'types/cornerstone/CornerstoneViewport';

export const displayImage = (element: HTMLElement, image: CornerstoneImage): void => {
  // try to remove the image if it exists. Used to avoid memory leaks when called displayImage multiple times
  cornerstone.disable(element);

  cornerstone.enable(element);
  cornerstone.displayImage(element, image);
};

export const removeImage = (element: HTMLElement): void => cornerstone.disable(element);

export const getEnabledElement = (element: HTMLElement): CornerstoneEnabledElement =>
  cornerstone.getEnabledElement(element);

export const checkIsElementEnabled = (element: HTMLElement): boolean => {
  try {
    cornerstone.getEnabledElement(element);
    return true;
  } catch {
    return false;
  }
};

export const resetViewport = (element: HTMLElement): void => {
  const { canvas, image } = cornerstone.getEnabledElement(element);
  cornerstone.setViewport(element, cornerstone.getDefaultViewport(canvas, image));
};

export const getDefaultViewport = (element: HTMLElement): CornerstoneViewport => {
  const { canvas, image } = cornerstone.getEnabledElement(element);
  return cornerstone.getDefaultViewport(canvas, image);
};
