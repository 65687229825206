import { ViewType } from '@annaliseai/api-specifications';
import Directions from 'enums/Directions';

const { AXIAL, CORONAL, SAGITTAL } = ViewType;
const { CRANIAL, CAUDAL, ANTERIOR, POSTERIOR, RIGHT, LEFT } = Directions;

const directionsByView: { [key in ViewType]: Directions[] } = {
  [AXIAL]: [CRANIAL, CAUDAL],
  [CORONAL]: [ANTERIOR, POSTERIOR],
  [SAGITTAL]: [RIGHT, LEFT],
};

export default directionsByView;
