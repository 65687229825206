import {
  FindingsSegments,
  SegmentType,
  Status,
  VisionClassificationExternal,
  VisionStudyImageFinding,
} from '@annaliseai/api-specifications';
import CustomErrors from 'enums/CustomErrors';
import CustomError from 'helpers/error/CustomError';
import uuid from 'helpers/uuid';
import Image, { WholeImage } from 'types/study/Image';
import Localisation, { LateralityLocalisation, SegmentLocalisation } from 'types/study/Localisation';

const { LATERALITY, MAP } = SegmentType;
const { COMPLETED } = Status;
const { UNEXPECTED_BACKEND_ERROR } = CustomErrors;

type GetCxrLocalisations = (_: {
  images: VisionStudyImageFinding[];
  relevantFindings: VisionClassificationExternal[];
  findingsSegments: FindingsSegments[];
}) => {
  localisationsMapBySegmentId: Record<string, Localisation>;
  segmentImagesMap: Record<Image['uuid'], Image>;
};

const getCxrLocalisations: GetCxrLocalisations = ({ images, relevantFindings, findingsSegments }) => {
  const localisationsMapBySegmentId: Record<string, Localisation> = {};
  const segmentImagesMap: Record<Image['uuid'], Image> = {};

  const relevantLocalisationLabels = new Set(relevantFindings.map(({ label }) => label));
  const segmentLocalisationUrlsMapBySegmentId: Record<string, string> = {};

  findingsSegments.forEach(findingsSegment => {
    if (findingsSegment.status !== COMPLETED) {
      throw new CustomError(UNEXPECTED_BACKEND_ERROR);
      // TODO: what to do when segments are not COMPLETED?
    }

    findingsSegment.segments.forEach(({ url, id }) => {
      segmentLocalisationUrlsMapBySegmentId[id] = url;
    });
  });

  images.forEach(image => {
    const relevantSegments = image.segments.filter(({ relatedLabels }) =>
      relatedLabels.some(relatedLabel => relevantLocalisationLabels.has(relatedLabel)),
    );

    relevantSegments.forEach(segment => {
      if (segment.type === LATERALITY) {
        const { id, laterality } = segment;
        if (!laterality) {
          /* This should not happen
           * laterality should always be defied if segment.type === LATERALITY */
          throw new CustomError(UNEXPECTED_BACKEND_ERROR);
        }

        const lateralityLocalisation: LateralityLocalisation = {
          uuid: uuid('laterality-localisation'),
          type: 'LATERALITY',
          laterality,
        };

        localisationsMapBySegmentId[id] = lateralityLocalisation;
      } else if (segment.type === MAP) {
        const { id } = segment;

        const segmentLocalisationImage: WholeImage = {
          uuid: uuid('whole-image'),
          type: 'WHOLE',
          url: segmentLocalisationUrlsMapBySegmentId[id],
        };
        segmentImagesMap[segmentLocalisationImage.uuid] = segmentLocalisationImage;

        const segmentLocalisation: SegmentLocalisation = {
          uuid: uuid('segment-localisation'),
          type: 'SEGMENT',
          imageUuid: segmentLocalisationImage.uuid,
        };

        localisationsMapBySegmentId[id] = segmentLocalisation;
      } else {
        throw new CustomError(UNEXPECTED_BACKEND_ERROR);
      }
    });
  });

  return {
    localisationsMapBySegmentId,
    segmentImagesMap,
  };
};

export default getCxrLocalisations;
