import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import newEmptyFile from 'helpers/file/newEmptyFile';

let fileCache: File[] = [];

export const getFileCache = (): File[] => [...fileCache];

export const clearFileCache = (): void => {
  fileCache = [];
  dataSetCacheManager.purge();
  fileManager.purge();
};

export const getFromFileCache = (index: number): File => {
  return fileCache[index] || newEmptyFile();
};

const { dataSetCacheManager, fileManager } = cornerstoneWADOImageLoader.wadouri;

export const setToFileCache = (index: number, file: File): void => {
  const newFileCache = [...fileCache];
  newFileCache[index] = file;

  clearFileCache();

  for (let index = 0; index < newFileCache.length; index++) {
    if (!newFileCache[index]) {
      newFileCache[index] = newEmptyFile();
    }
    const file = newFileCache[index];

    fileCache[index] = file;
    cornerstoneWADOImageLoader.wadouri.fileManager.add(file);
  }
};
