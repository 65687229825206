import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import { CornerstoneWADOImage } from 'types/cornerstone/CornerstoneImage';
import CornerstoneImageId from 'types/cornerstone/CornerstoneImageId';
import { CornerstoneProvider } from 'types/cornerstone/CornerstoneProvider';
import ImageMetadata from 'types/ImageMetadata';
import { getImageIdPayload } from './imageIdHelper';

const TRANSFER_SYNTAX = '1.2.840.10008.1.2.4.90'; // JPEG 2000 Lossless

export const jp2Loader = (imageId: CornerstoneImageId): { promise: Promise<CornerstoneWADOImage> } => {
  const promise = (async () => {
    const url = getImageIdPayload(imageId);
    const arrayBuffer = await fetch(url).then(result => result.arrayBuffer());
    const encodedPixelData = new Uint8Array(arrayBuffer);

    return (await cornerstoneWADOImageLoader.createImage(
      imageId,
      encodedPixelData,
      TRANSFER_SYNTAX,
      {},
    )) as CornerstoneWADOImage;
  })();

  return {
    promise,
  };
};

export type Jp2LoaderProviderData = ImageMetadata & {
  pixelRepresentation: 0; // Assume that pixel encoding is always unsigned
};

// Data substitution for the WADO loader
export const getJp2LoaderProvider = (
  targetImageId: CornerstoneImageId,
  {
    bitsAllocated,
    bitsStored,
    highBit,
    photometricInterpretation,
    rescaleIntercept,
    rescaleSlope,
    samplesPerPixel,
    sopClassUid,
    pixelRepresentation,
  }: Jp2LoaderProviderData,
): CornerstoneProvider => {
  const modules: Record<string, unknown> = {
    imagePixelModule: {
      bitsAllocated,
      bitsStored,
      highBit,
      photometricInterpretation,
      samplesPerPixel,
      pixelRepresentation,
    },
    modalityLutModule: {
      rescaleIntercept,
      rescaleSlope,
    },
    sopCommonModule: {
      sopClassUid,
    },
  };

  return (type: string, imageId: CornerstoneImageId) => {
    if (imageId === targetImageId) {
      return modules[type];
    }
  };
};
