import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Localisation from 'types/study/Localisation';

export type LocalisationsState = {
  localisationsMap: Record<Localisation['uuid'], Localisation>;
};

export const initialState: LocalisationsState = {
  localisationsMap: {},
};

export const localisationsSlice = createSlice({
  name: 'localisations',
  initialState,
  reducers: {
    reset: _ => ({
      ...initialState,
    }),
    setLocalisationsMap: (
      state,
      { payload: localisationsMap }: PayloadAction<LocalisationsState['localisationsMap']>,
    ) => ({
      ...state,
      localisationsMap,
    }),
  },
});

export const localisationsActions = localisationsSlice.actions;
