import React, { useState } from 'react';
import MenuLink from 'components/Menu/MenuLink/MenuLink';
import { MenuItemProps } from 'components/Menu/MenuLink/MenuLink.types';
import ScreenVariant from 'enums/ScreenVariant';
import { useMatomo } from 'matomo/react';
import { EventActions, EventCategories } from 'types/Matomo';
import AboutModalComponent from './AboutModalComponents';

const { REQUESTED_HELP } = EventActions;
const { STUDY_LINK } = EventCategories;

export type AboutButtonProps = {
  screenVariant?: ScreenVariant;
};

const AboutModal = ({ Icon, IconHover, text }: MenuItemProps): JSX.Element => {
  const { trackEvent } = useMatomo();
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    !modalOpen && setModalOpen(!modalOpen);
    trackEvent({
      category: STUDY_LINK,
      action: REQUESTED_HELP,
    });
  };

  return (
    <>
      <MenuLink Icon={Icon} IconHover={IconHover} text={text} onClick={handleClick} />
      <AboutModalComponent modalOpen={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default AboutModal;
